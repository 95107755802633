<div class="main-wrapper-section">
  <div class="common-header">
    <h3>Engage Dashboard</h3>
  </div>
  <div class="card-section">
    <div class="card-panel">
      <div class="flex-form">
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D1 ? clubSiteConfig?.engDashCustomMMT1D1 : "Custom MMT ID1"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          [(ngModel)]="customID1"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D2 ? clubSiteConfig?.engDashCustomMMT1D2 : "Custom MMT ID2"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID2"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D3 ? clubSiteConfig?.engDashCustomMMT1D3 : "Custom MMT ID3"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID3"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D4 ? clubSiteConfig?.engDashCustomMMT1D4 : "Custom MMT ID4"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID4"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D5 ? clubSiteConfig?.engDashCustomMMT1D5 : "Custom MMT ID5"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID5"
                          valueField="id">
          </kendo-combobox>
        </div>
      </div>
      <hr />
      <div class="flex-form">
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D6 ? clubSiteConfig?.engDashCustomMMT1D6 : "Custom MMT ID6"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID6"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D7 ? clubSiteConfig?.engDashCustomMMT1D7 : "Custom MMT ID7"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID7"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D8 ? clubSiteConfig?.engDashCustomMMT1D8 : "Custom MMT ID8"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID8"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D9 ? clubSiteConfig?.engDashCustomMMT1D9 : "Custom MMT ID9"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID9"
                          valueField="id">
          </kendo-combobox>
        </div>
        <div class="form-group">
          <label>{{clubSiteConfig?.engDashCustomMMT1D10 ? clubSiteConfig?.engDashCustomMMT1D10 : "Custom MMT ID10"}}</label>
          <kendo-combobox class="form-control min-width300" [data]="mailMergeTempList"
                          [valuePrimitive]="true"
                          textField="text"
                          [kendoDropDownFilter]="filterSettings"
                          [filterable]="true"
                          
                          [(ngModel)]="customID10"
                          valueField="id">
          </kendo-combobox>
        </div>
      </div>
      <div class="button-group">
        <div class="form-group">
          <button class="primary-btn" (click)="engageSaved()">Save<i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
