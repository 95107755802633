import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { ConfigDetails } from '../../../models/app-config.model';
import { BCMCLPUser, CLPUser, TeamCodes } from '../../../models/clpuser.model';
import { AutomationProcessDD, CMSite, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { DropDownItem, IntDropDownItem, IntDropDownItemListResponse, keyValue, SimpleResponse } from '../../../models/generic-response.model';
import { BCMClassCodesService } from '../../../services/bcmclass-codes.service';
import { CMClubService } from '../../../services/cm-club.service';
import { CountryStateService } from '../../../services/country-state.service';
import { NotificationService } from '../../../services/notification.service';
import { AppConfigService } from '../../../services/shared/app-config.service';
import { GlobalService } from '../../../services/shared/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TeamOfficeService } from '../../../services/team-office.service';

@Component({
    selector: 'app-lm-general',
    templateUrl: './lm-general.component.html',
    styleUrl: './lm-general.component.css'
})
export class LmGeneralComponent implements OnDestroy {

    encryptedUser: string = '';
    user: BCMCLPUser;
    webFormList: keyValue[];
    automationProcessList: AutomationProcessDD[];
    teamFilterDD: TeamCodes[];
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    class1Codes: IntDropDownItem[];
    class1Code: number;
    clubId: number = 0
    classDisplay: string;
    clubText: string;
    clubList: IntDropDownItem[];
    countryDD: DropDownItem[];
    stateList: DropDownItem[];
    sites: CMSite[];
    site: CMSite;
    countryCode: string = "US";
    stateCode: string = "";
    teamCode: number;
    curSiteId: number = 0
    curSite: CMSite = <CMSite>{}
    curTeam: TeamCodes = <TeamCodes>{};
    showSpinner: boolean;
    phLocation: string = '';
    phCity: string = '';
    phAddress: string = '';
    phZip: string = '';
    curSiteSub: Subscription;
    clpCompanyId: number;
    isAI: boolean = false;
    taxRate: number = 0
    constructor(private _localService: LocalService,
        private _route: ActivatedRoute,
        private _notifyService: NotificationService,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _teamOfficeService: TeamOfficeService,
        private _router: Router,
        private bcmClassCodesService: BCMClassCodesService,
        private _clubService: CMClubService,
        private fb: FormBuilder,
        private notifyService: NotificationService,
        private _countryStateService: CountryStateService,
        private _appconfigService: AppConfigService
    ) {

        this._localService.setShowMenu(true)
        this._localService.setIsCmManager(false);
        this._localService.setRouteName("sm-general")
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.encryptedUser = localStorage.getItem("token");
        if (!this.encryptedUser?.includes(":")) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
        }

        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData();
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }

    async loadData() {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || '{}')
        this.curSiteSub = this._clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = value
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
            }
            this.phLocation = this.curSite?.phLocation;
            this.taxRate = this.curSite?.taxRate;
            this.phCity = this.curSite?.city;
            this.phAddress = this.curSite?.address;
            this.phZip = this.curSite?.zip;

            this.countryCode = this.curSite?.country ? this.curSite?.country : "US"
            await this.getCountry();
            this.getCountryStateList(this.countryCode);
            this.stateCode = this.curSite?.state ? this.curSite?.state : ""
        })

        await this.class1CodeGetList()
    }

    async getCountry() {
        await this._countryStateService.getCountryGetList(this.encryptedUser)
            .then(async (result: DropDownItem[]) => {
                if (result) {
                    this.countryDD = UtilityService.clone(result);
                    this.countryDD?.unshift({
                        value: "",
                        text: "--Select--",
                        isSelected: true
                    });

                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeCountry(countryCode) {
        this.stateCode = "";
        if (countryCode == '') {
            this._notifyService.showError("Please select valid country!", "", 3000);
            this.countryCode = "US";
        }
        else
            this.getCountryStateList(countryCode);
    }

    async getCountryStateList(countryCode: string) {
        await this._countryStateService.getCountryStateList(this.encryptedUser, countryCode)
            .then(async (result: DropDownItem[]) => {
                if (result) {
                    this.stateList = UtilityService.clone(result);
                    this.stateList?.unshift({
                        value: "",
                        text: "--Select--",
                        isSelected: false
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async class1CodeGetList() {
        await this.bcmClassCodesService.class1CodeGetList(this.encryptedUser, this.clpCompanyId, this.isAI)
            .then(async (result: IntDropDownItemListResponse) => {
                if (result) {
                    this.class1Codes = UtilityService.clone(result?.intDropDownItemList);
                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchCountryForm() {
        this.countryCode = this.sites[0].country;
        this.stateCode = this.sites[0].state;
    }

    async updateSite() {
        this.site = <CMSite>{};
        this.site = this.curSite;
        this.site.country = this.countryCode;
        this.site.state = this.stateCode;
        this.site.phLocation = this.phLocation;
        this.site.taxRate = this.taxRate;
        this.site.address = this.phAddress;
        this.site.city = this.phCity;
        this.site.zip = this.phZip;

        if (this.site == null || this.site?.siteID == 0) {
            this._notifyService.showError("Site not found. Go back to team.", "Error", 3000);
            return
        }

        if (this.site.taxRate >= 99.99 || this.site.taxRate < 0) {
            this._notifyService.showError("Tax rate should be in range 0.1% - 99.99%", "Error", 3000);
            return
        }
        this.showSpinner = true
        await this._clubService.CMSite_Update_Gen(this.encryptedUser, this.site, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this._notifyService.showSuccess("Site updated successfully", "Success", 3000);
                    localStorage.setItem("curSite", JSON.stringify(this.site))
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("lm-General", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    ngOnDestroy() {
        if (this.curSiteSub) {
            this.curSiteSub.unsubscribe()
        }
    }

    getValid(): boolean {
        let isValid:boolean= false;
        if (this.countryCode == '' || this.stateCode == '' || this.taxRate <= 0 || this.phLocation == null || this.phLocation =='')
            isValid=false;
        else
            isValid = true;
        return isValid;
    }

}
