import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { BCMCLPUser, CLPUser, TeamCodeResponseIEnumerable, TeamCodes, TeamResponse, UserResponse } from '../../models/clpuser.model';
import { IntDropDownItem, SimpleResponse, UserDD } from '../../models/generic-response.model';
import { RoleFeaturePermissions } from '../../models/role-container.model';
import { CMClubService } from '../../services/cm-club.service';
import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TeamOfficeService } from '../../services/team-office.service';
import { CMClub, CMClubResponse, CMSite, CMSiteExistsResponse, CMSiteListResponse } from '../../models/cm-club-service.model';
import { BCMClassCodesService } from '../../services/bcmclass-codes.service';
import { IntDropDownItemListResponse } from '../../models/bcmclass-codes.model';
import { isNullOrUndefined } from 'util';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { AppConfigService } from '../../services/shared/app-config.service';
import { ConfigDetails } from '../../models/app-config.model';

@Component({
    selector: 'app-cm-locations',
    templateUrl: './cm-locations.component.html',
    styleUrl: './cm-locations.component.css'
})
export class CmLocationsComponent implements AfterViewInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<CMSite>;
    displayedColumns: string[] = ['siteName', 'view',];
    newSiteForm: FormGroup
    sfn: any = {}
    bcmDropDown: IntDropDownItem[];
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: BCMCLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    teamDisplay: string = '';
    userList: UserDD[];
    teamCodeDetails: TeamResponse;
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    showAddNewSite: boolean;
    sites: CMSite[];
    class1Code: number;
    clubId: number;
    site: CMSite;
    siteId: number = 0;
    curTeam: TeamCodes = <TeamCodes>{};
    showSpinner: boolean;
    clubConfig: CMClub = <CMClub>{}
    submitted: boolean;
    teamSub: Subscription;
    clpCompanyId: number;
    isAI: boolean = false;
    isSiteMapped: boolean;
    disableBtn: boolean = true
    constructor(private _localService: LocalService,
        private _teamOfficeService: TeamOfficeService,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private fb: FormBuilder,
        private notifyService: NotificationService,
        private clubService: CMClubService,
        private _globalService: GlobalService,
        private _notifyService: NotificationService,
        private _bcmClassCodesService: BCMClassCodesService,
        private _appconfigService: AppConfigService,

        private _router: Router,
    ) {

        this._localService.setShowMenu(true)
        this._localService.setRouteName("cm-locations")
        this._localService.setIsCmManager(true);
        this._localService.updateTeamDD(true)
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.newSiteForm = this.prepareNewSiteFormForm()

    }

    ngAfterViewInit() {
        this.encryptedUser = localStorage.getItem("token");
        this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }

    async loadData() {
        this.teamSub = this._teamOfficeService.curTeam.subscribe(async (data: any) => {
            if (data != 0 && data != null) {
                this.curTeam = data
                if (this.curTeam?.teamCode > 0) {
                    await this.CMClub_Get(this.curTeam?.teamCode)
                    await this.siteSearch()
                    this.getDropDown();
                }
            }
            else {
                this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
                if (this.curTeam?.teamCode > 0) {
                    await this.CMClub_Get(this.curTeam?.teamCode)
                    await this.siteSearch()
                    this.getDropDown();
                }
            }
        })

    }


    async CMClub_Get(teamCode) {

        if (!teamCode || teamCode == 0) {
            this._notifyService?.showError("No team is selected", "Error", 3000)
            return
        }
        this.showSpinner = true
        await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, teamCode, this.isAI).
            then(async (result: CMClubResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clubConfig = response?.clubConfig
                    this.showSpinner = false
                }

            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    private async authenticateR() {

        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareNewSiteFormForm() {
        return this.fb.group({
            bcmDropDown: [-1],
            teamCodeDD: [-1],
        })
    }

    viewSites(site) {
        this._router.navigate(['/sm-dashboard'])

        this.clubService.setCurSite(site);
        localStorage.setItem("curSite", JSON.stringify(site))
    }

    addNewSite() {
        if (this.clubConfig == null || Object.keys(this.clubConfig)?.length == 0) {
            this._notifyService?.showError("Select a team.", "Error", 3000)
            return
        }
        this.showAddNewSite = true

    }

    async siteSearch() {
        if (this.clubConfig?.clubId > 0) {
            this.showSpinner = true
            await this.clubService.siteSearch(this.encryptedUser, 0, this.clubConfig?.clubId, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites
                        this.sites.sort((a, b) => a.siteName?.localeCompare(b.siteName))

                        this.dataSource = new MatTableDataSource(this.sites);
                        this.dataSource.sort = this.sort;
                        this.dataSource.paginator = this.paginator;
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async siteUpdate() {

        if (this.isSiteMapped) {
           return this.notifyService.showError("Site is already mapped with another club.", "Failed", 3000)
        }
        if (Number(this.newSiteForm.controls['bcmDropDown'].value) == -1) {
            this.notifyService.showError("Please Select the Site", "Failed", 3000)
        }
        else if (this.sites.filter(x => x.class1Code == Number(this.newSiteForm.controls['bcmDropDown'].value)).length > 0 && this.sites.filter(x => x.class1Code == Number(this.newSiteForm.controls['bcmDropDown'].value))[0]?.teamCode > 0) {
            this.notifyService.showError("Site already Exists", "Failed", 3000)
        }
        else {
            this.copyFromModel();
            if (this.site?.teamCode > 0) {
                this.submitted = true
                await this.clubService.CMSite_Update(this.encryptedUser, this.site, this.isAI).
                    then(async (result: SimpleResponse) => {
                        if (result) {
                            let response = UtilityService.clone(result);
                            if (response.messageBool) {
                                this.siteId = -1;
                                this.siteSearch();
                                this.notifyService.showSuccess("Site created successfully", "Success", 3000);
                                this.newSiteForm.controls['bcmDropDown'].setValue(-1)
                            }
                            else {
                                this.notifyService.showError("Site Could'nt created", "Failed", 3000)
                            }
                            this.submitted = false
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this.submitted = false
                        this._globalService.error("site-fields.clubConfigGet", err.message, null,
                        );
                        this._utilityService.handleErrorResponse(err);
                    });
            }
            else {
                this.notifyService.showError("Select a team.", "Failed", 3000)
            }
        }
    }

    copyFromModel() {
        this.site = <CMSite>{}
        if (this.newSiteForm.valid) {
            this.site = this.sites.filter(x => x.class1Code == Number(this.newSiteForm.controls['bcmDropDown'].value))[0]
            if (isNullOrUndefined(this.site))
                this.site = <CMSite>{}
            this.site.siteID = this.site.siteID > 0 ? this.site.siteID : this.siteId > 0 ? this.siteId : 0;
            this.site.clubID = this.clubConfig?.clubId;
            this.site.clpcompanyID = this.clpCompanyId;
            this.site.class1Code = Number(this.newSiteForm.controls['bcmDropDown'].value);
            this.site.teamCode = this.curTeam.teamCode;
        }
    }

    async getDropDown() {
        await this.getClass1CodeDD();
        //await this.getTeamCodeDD();
    }

    async getClass1CodeDD() {
        await this._bcmClassCodesService.class1CodeGetList(this.encryptedUser, this.clpCompanyId, this.isAI).
            then(async (result: IntDropDownItemListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.bcmDropDown = response.intDropDownItemList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-locations.getTeamCodeDD", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTeamCodeDD() {
        await this._teamOfficeService.teamCode_GetList(this.encryptedUser, this.clpCompanyId, this.isAI)
            .then(async (result: TeamCodeResponseIEnumerable) => {
                if (result) {
                    let response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeSorting(event) {
        let type = event.target.value
        if (type == 1) {
            this.sites.sort((a, b) => a.siteName.localeCompare(b.siteName))
            this.dataSource = new MatTableDataSource(this.sites);
        }
        else {
            this.sites.sort((a, b) => b.siteName.localeCompare(a.siteName))
            this.dataSource = new MatTableDataSource(this.sites);
        }
    }

    search(query: string) {
        let copyArray = [...this.sites]
        let filteredSites = []
        if (query?.length > 0) {
            copyArray.filter((item) => {
                if (item?.siteName?.toLowerCase().includes(query.toLowerCase()) || item?.teamCodeDisplay?.toLowerCase().includes(query.toLowerCase())) {
                    filteredSites.push(item)
                }
            })
        }
        else {
            filteredSites = [...this.sites]
        }

        this.dataSource = new MatTableDataSource(filteredSites);
    }

    clearInput() {
        this.dataSource = new MatTableDataSource(this.sites);
    }

    ngOnDestroy() {
        if (this.teamSub) {
            this.teamSub.unsubscribe()
        }
    }

    async cMSite_Exits(event) {
        this.disableBtn = true
        this.isSiteMapped = false
        let class1Code = event.target.value
        await this.clubService.cMSite_Exits(this.encryptedUser, class1Code, this.clpCompanyId, this.isAI)
            .then(async (result: CMSiteExistsResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.disableBtn = false
                    if (response?.clubID > 0) {
                        this.isSiteMapped = true
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }
}
