<div class="main-wrapper-section">
  <div class="common-header">
    <h3>General</h3>
  </div>
  <div class="card-section">
    <div class="card-panel">
      <p class="heading-text">The following fields are used to customize each club. <a href="javascript:void();">Full Placeholder List</a></p>

      <form [formGroup]="clubConfigForm" (ngSubmit)="onSubmitClubConfigForm()">
        <div class="flex-form">
          <div class="form-group">
            <label>Club Name</label>
            <input class="form-control" formControlName="clubName" maxlength="50"/>
            <div *ngIf="clubConfigForm.controls.clubName.errors && (clubConfigForm.controls.clubName.touched || clubConfigForm.controls.clubName.dirty)">
              <div class="login-error" *ngIf="clubConfigForm.controls.clubName.errors.required">Club name is required. </div>
            </div>
          </div>
          <div class="form-group">
            <label>Team</label>
            <kendo-combobox *ngIf="userRole >= 3 || slurpyUserId > 0" class="form-control min-width300" [data]="teamFilterDD"
                            [valuePrimitive]="true"
                            textField="display"
                            [kendoDropDownFilter]="filterSettings"
                            [filterable]="true"
                            formControlName="teamCode"
                            (valueChange)="onChangeTeamCode($event)"
                            valueField="teamCode">
            </kendo-combobox>
            <div *ngIf="userRole >= 3 || slurpyUserId > 0">
              <div *ngIf="clubConfigForm.controls.teamCode.errors && (clubConfigForm.controls.teamCode.touched || clubConfigForm.controls.teamCode.dirty)">
                <div class="login-error" *ngIf="clubConfigForm.controls.teamCode.errors.required">Team is required.</div>
              </div>
            </div>
            <p *ngIf="userRole < 3">{{curTeam?.display}}</p>
          </div>
          <div class="form-group" *ngIf="userRole >= 3 || slurpyUserId > 0">
            <label>Owner</label>
            <kendo-combobox  class="form-control min-width300" [data]="userList"
                            [valuePrimitive]="true"
                            textField="text"
                            [kendoDropDownFilter]="filterSettings"
                            [filterable]="true"
                            formControlName="ownerUserId"
                            valueField="value">
            </kendo-combobox>
            <!--<p *ngIf="user?.userRole < 3">{{selectedUser?.text}}</p>-->
          </div>
        </div>
        <div class="flex-form">
          <div class="form-group">
            <label>EIN</label>
            <input class="form-control" formControlName="clubEIN" maxlength="50"/>
          </div>
          <div class="form-group">
            <label>Region</label>
            <input class="form-control" formControlName="clubRegion" maxlength="50"/>
          </div>
          <div class="form-group">
            <label>Territory</label>
            <input class="form-control" formControlName="clubTerritory" maxlength="50"/>
          </div>
        </div>
        <hr />

        <div class="button-group">
          <div class="form-group">
            <button class="primary-btn" type="submit">Save <i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
