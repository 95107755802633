import { MatTableDataSource } from '@angular/material/table';
import { LineStyle } from '@progress/kendo-angular-charts';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { gearIcon, questionCircleIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { BCMCLPUser, CLPUser, UserResponse } from '../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../models/role-container.model';
import { eFeatures } from '../../models/enum.model';
import { UtilityService } from '../../services/shared/utility.service';
import { LocalService } from '../../services/shared/local.service';
import { GlobalService } from '../../services/shared/global.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { AppConfig, ConfigDetails } from '../../models/app-config.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cm-dashboard',
  templateUrl: './cm-dashboard.component.html',
  styleUrl: './cm-dashboard.component.css'
})
export class CmDashboardComponent {
  public value = 82;
  public animation = true;
  public cashFlowData = [
    {
      period: 'Beginning\\nBalance',
      amount: 15000,
    },
    {
      period: 'Jan',
      amount: 17000,
    },
    {
      period: 'Feb',
      amount: 14000,
    },
    {
      period: 'Mar',
      amount: 12000,
    }
  ];
  public style: LineStyle = "smooth";
  public styleArea: LineStyle = "smooth";
  public categories: string[] = ["Jan", "Feb", "Mar", "Apr", "May"];
  public originalData: any[] = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 25 },
    { value: 40 }
  ];
  dataSource = new MatTableDataSource([
    { category: 'Sent', previous: '488,874', current: '1,294,811', difference: '+165%' },
    { category: 'Delivered', previous: '488,874', current: '1,294,811', difference: '+165%' },
    { category: 'Opened', previous: '488,874', current: '1,294,811', difference: '+165%' },
    { category: 'Avg Open Rate', previous: '488,874', current: '1,294,811', difference: '+165%' },
    { category: 'Clicks', previous: '488,874', current: '1,294,811', difference: '+165%' },
  ]);

  displayedColumns: string[] = ['category', 'previous', 'current', 'difference'];
  @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
  public gear: SVGIcon = gearIcon;
  public question: SVGIcon = questionCircleIcon;
  isExpanded = false;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  user: BCMCLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  isAI: boolean = false
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _localService: LocalService,
      private _globalService: GlobalService,
      private titleService: Title,
    private _utilityService: UtilityService,
  ) {
      this.titleService.setTitle('Club Manager');
  }

  ngOnInit() {
    this._localService.setShowMenu(true)
    this._localService.setIsCmManager(true);
    this._localService.setRouteName("cm-dashboard")
    this._localService.updateTeamDD(true)

    this._route.queryParams.subscribe(params => {
      if (params?.isAI) {
        this.isAI = params?.isAI && params?.isAI == 'true' ? true : false
        localStorage.setItem("isAI", params?.isAI)
      }
      else {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
      }
      this.encryptedUser = params?.r;
      if (!this.encryptedUser?.includes(":")) {
        this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
      }
      if (params?.r) {
        this.authenticateR().then(() => {
          if (this.user) {
            localStorage.setItem("token", this.encryptedUser)
            this._router.navigate(['/cm-dashboard']);
          }
          else {
            this._router.navigate(['/unauthorized']);
          }
        });
      }
      else {
        this.encryptedUser = localStorage.getItem("token")
        this.authenticateR().then(() => {
          if (this.user) {
          }
          else {
            this._router.navigate(['/unauthorized']);
          }
        });
      }
    })
  }

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, this.isAI)
      .then(async (result: BCMCLPUser) => {
        if (result) {
          this.user = UtilityService.clone(result);
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._globalService.error("navMenu.authenticateR", err.message, null);
        this._utilityService.handleErrorResponse(err);
      });
  }

}
