import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BCMCLPUser, CLPUser, TeamCodes, UserResponse } from '../../models/clpuser.model';
import { CMClub, CMClubResponse, CMClubSiteConfigListResponse, CMSite } from '../../models/cm-club-service.model';
import { SimpleResponse } from '../../models/generic-response.model';
import { RoleFeaturePermissions } from '../../models/role-container.model';
import { CMClubSiteConfig } from '../../models/cm-club-service.model';
import { CMClubService } from '../../services/cm-club.service';
import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { Subscription } from 'rxjs';
import { TeamOfficeService } from '../../services/team-office.service';
import { OnDestroy } from '@angular/core';
import { AppConfigService } from '../../services/shared/app-config.service';
import { ConfigDetails } from '../../models/app-config.model';

@Component({
  selector: 'app-site-fields',
  templateUrl: './site-fields.component.html',
  styleUrl: './site-fields.component.css'
})
export class SiteFieldsComponent implements OnInit, OnDestroy {
  SFNForm: FormGroup
  encryptedUser: string = ''
  userResponse: UserResponse;
  user: BCMCLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  siteList: CMSite[];
  clubSiteConfig: CMClubSiteConfig = <CMClubSiteConfig>{}
  curTeam: TeamCodes = <TeamCodes>{};
  showSpinner: boolean;
  clubConfig: CMClub = <CMClub>{};
  submitted: boolean;
  teamSub: Subscription;
  clpCompanyId: number;
  isAI: boolean = false;
  constructor(
    private _localService: LocalService,
    private _utilityService: UtilityService,
    private fb: FormBuilder,
    private clubService: CMClubService,
    private _globalService: GlobalService,
    private _teamOfficeService: TeamOfficeService,
    private _notifyService: NotificationService,
    private _appconfigService: AppConfigService,
    private _router: Router,
  ) {
    
    this._localService.setShowMenu(true)
    this._localService.setRouteName("site-fields")
    this._localService.setIsCmManager(true);
    this._localService.updateTeamDD(true)
  }

  ngOnInit() {
    this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
    this.SFNForm = this.prepareSFNForm()
    this.encryptedUser = localStorage.getItem("token");
    this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")

    if (this.encryptedUser) {
      this.authenticateR().then(async () => {
        if (this.user) {
          this.loadData()
        }
        else {
          this._router.navigate(['/unauthorized']);
        }
      });
    }
    else {
      this._router.navigate(['/unauthorized']);
    }
  }

  loadData() {
    this.teamSub = this._teamOfficeService.curTeam.subscribe(async (data: any) => {
      if (data != 0 && data != null) {
        this.curTeam = data
        if (this.curTeam?.teamCode > 0) {
          await this.CMClub_Get(this.curTeam?.teamCode)
          this.clubSiteConfigSearch()
        }
      }
      else {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
        if (this.curTeam?.teamCode > 0) {
          await this.CMClub_Get(this.curTeam?.teamCode)
          this.clubSiteConfigSearch()
        }
      }
    })
  }

  async CMClub_Get(teamCode) {
    if (!teamCode || teamCode == 0) {
      this._notifyService?.showError("No team is selected", "Error", 3000)
      return
    }
    this.showSpinner = true
    await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, teamCode, this.isAI).
      then(async (result: CMClubResponse) => {
        if (result) {
          let response = UtilityService.clone(result);
          this.clubConfig = response?.clubConfig
          this.showSpinner = false
        }

      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false
        this._globalService.error("site-fields.clubConfigGet", err.message, null,
        );
        this._utilityService.handleErrorResponse(err);
      });
  }

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, this.isAI)
        .then(async (result: BCMCLPUser) => {
          if (result) {
            this.user = UtilityService.clone(result);
            this.clpCompanyId = this.user?.cLPCompanyID
          }
        })
        .catch((err: HttpErrorResponse) => {
          this._globalService.error("navMenu.authenticateR", err.message, null);
          this._utilityService.handleErrorResponse(err);
        });
  }

  prepareSFNForm() {
    return this.fb.group({
      cust1Url: [''],
      cust2Url: [''],
      cust3Url: [''],
      cust4Url: [''],
      cust5Url: [''],
      cust1Txt: [''],
      cust2Txt: [''],
      cust3Txt: [''],
      cust4Txt: [''],
      cust5Txt: [''],
      multiTxt1: [''],
      multiTxt2: [''],
      multiTxt3: [''],
      multiTxt4: [''],
      multiTxt5: [''],
      customMMT1: [''],
      customMMT2: [''],
      customMMT3: [''],
      customMMT4: [''],
      customMMT5: [''],
      customMMT6: [''],
      customMMT7: [''],
      customMMT8: [''],
      customMMT9: [''],
      customMMT10: [''],
    })
  }

  patchSiteFields() {
    this.SFNForm.controls['cust1Url'].patchValue(this.clubSiteConfig?.lnkCustom1URL);
    this.SFNForm.controls['cust2Url'].patchValue(this.clubSiteConfig?.lnkCustom2URL);
    this.SFNForm.controls['cust3Url'].patchValue(this.clubSiteConfig?.lnkCustom3URL);
    this.SFNForm.controls['cust4Url'].patchValue(this.clubSiteConfig?.lnkCustom4URL);
    this.SFNForm.controls['cust5Url'].patchValue(this.clubSiteConfig?.lnkCustom5URL);
    this.SFNForm.controls['cust1Txt'].patchValue(this.clubSiteConfig?.mktCustomText1);
    this.SFNForm.controls['cust2Txt'].patchValue(this.clubSiteConfig?.mktCustomText2);
    this.SFNForm.controls['cust3Txt'].patchValue(this.clubSiteConfig?.mktCustomText3);
    this.SFNForm.controls['cust4Txt'].patchValue(this.clubSiteConfig?.mktCustomText4);
    this.SFNForm.controls['cust5Txt'].patchValue(this.clubSiteConfig?.mktCustomText5);
    this.SFNForm.controls['multiTxt1'].patchValue(this.clubSiteConfig?.mktMultitext1);
    this.SFNForm.controls['multiTxt2'].patchValue(this.clubSiteConfig?.mktMultitext2);
    this.SFNForm.controls['multiTxt3'].patchValue(this.clubSiteConfig?.mktMultitext3);
    this.SFNForm.controls['multiTxt4'].patchValue(this.clubSiteConfig?.mktMultitext4);
    this.SFNForm.controls['multiTxt5'].patchValue(this.clubSiteConfig?.mktMultitext5);
    this.SFNForm.controls['customMMT1'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D1);
    this.SFNForm.controls['customMMT2'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D2);
    this.SFNForm.controls['customMMT3'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D3);
    this.SFNForm.controls['customMMT4'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D4);
    this.SFNForm.controls['customMMT5'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D5);
    this.SFNForm.controls['customMMT6'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D6);
    this.SFNForm.controls['customMMT7'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D7);
    this.SFNForm.controls['customMMT8'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D8);
    this.SFNForm.controls['customMMT9'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D9);
    this.SFNForm.controls['customMMT10'].patchValue(this.clubSiteConfig?.engDashCustomMMT1D10);
  }

  async onSubmitClubConfigForm() {
    if (this.clubConfig == null || Object.keys(this.clubConfig)?.length == 0) {
      this._notifyService?.showError("Select a team.", "Error", 3000)
      return
    }
    if (this.SFNForm.valid && this.curTeam?.teamCode > 0) {
      this.submitted = true
      let clubSiteConfig: CMClubSiteConfig = <CMClubSiteConfig>{}
      clubSiteConfig.lnkCustom1URL = this.SFNForm.controls['cust1Url'].value
      clubSiteConfig.lnkCustom2URL = this.SFNForm.controls['cust2Url'].value
      clubSiteConfig.lnkCustom3URL = this.SFNForm.controls['cust3Url'].value
      clubSiteConfig.lnkCustom4URL = this.SFNForm.controls['cust4Url'].value
      clubSiteConfig.lnkCustom5URL = this.SFNForm.controls['cust5Url'].value
      clubSiteConfig.mktCustomText1 = this.SFNForm.controls['cust1Txt'].value
      clubSiteConfig.mktCustomText2 = this.SFNForm.controls['cust2Txt'].value
      clubSiteConfig.mktCustomText3 = this.SFNForm.controls['cust3Txt'].value
      clubSiteConfig.mktCustomText4 = this.SFNForm.controls['cust4Txt'].value
      clubSiteConfig.mktCustomText5 = this.SFNForm.controls['cust5Txt'].value
      clubSiteConfig.mktMultitext1 = this.SFNForm.controls['multiTxt1'].value
      clubSiteConfig.mktMultitext2 = this.SFNForm.controls['multiTxt2'].value
      clubSiteConfig.mktMultitext3 = this.SFNForm.controls['multiTxt3'].value
      clubSiteConfig.mktMultitext4 = this.SFNForm.controls['multiTxt4'].value
      clubSiteConfig.mktMultitext5 = this.SFNForm.controls['multiTxt5'].value
      clubSiteConfig.engDashCustomMMT1D1 = this.SFNForm.controls['customMMT1'].value
      clubSiteConfig.engDashCustomMMT1D2 = this.SFNForm.controls['customMMT2'].value
      clubSiteConfig.engDashCustomMMT1D3 = this.SFNForm.controls['customMMT3'].value
      clubSiteConfig.engDashCustomMMT1D4 = this.SFNForm.controls['customMMT4'].value
      clubSiteConfig.engDashCustomMMT1D5 = this.SFNForm.controls['customMMT5'].value
      clubSiteConfig.engDashCustomMMT1D6 = this.SFNForm.controls['customMMT6'].value
      clubSiteConfig.engDashCustomMMT1D7 = this.SFNForm.controls['customMMT7'].value
      clubSiteConfig.engDashCustomMMT1D8 = this.SFNForm.controls['customMMT8'].value
      clubSiteConfig.engDashCustomMMT1D9 = this.SFNForm.controls['customMMT9'].value
      clubSiteConfig.engDashCustomMMT1D10 = this.SFNForm.controls['customMMT10'].value
      clubSiteConfig.clpCompanyID =  this.clpCompanyId
      clubSiteConfig.iD = this.clubSiteConfig?.iD > 0 ? this.clubSiteConfig?.iD : 0
      clubSiteConfig.clubID = this.clubConfig?.clubId
      clubSiteConfig.teamCode = this.curTeam?.teamCode
      await this.clubService.clubSiteConfigUpdate(this.encryptedUser, clubSiteConfig, this.isAI).
        then(async (result: SimpleResponse) => {
          if (result) {
            let response = UtilityService.clone(result);
            if (this.clubSiteConfig?.iD > 0) {
              this._notifyService?.showSuccess("Site fields updated successfully.", "Success", 3000)
            }
            else {
              this._notifyService?.showSuccess("Site fields created successfully.", "Success", 3000)
            }
            this.clubSiteConfigSearch()

          }
          this.submitted = false
        })
        .catch((err: HttpErrorResponse) => {
          this.submitted = false
          this._globalService.error("contact-task.getLoadUsers", err.message, null,
          );
          this._utilityService.handleErrorResponse(err);
        });
    }
    else {
      this._notifyService?.showError("Select a team.", "Error", 3000)
    }
  }

  async clubSiteConfigSearch() {
    let teamCode = this.curTeam?.teamCode > 0 ? this.curTeam?.teamCode : 0
    if (this.clubConfig?.clubId > 0 && teamCode > 0) {
      await this.clubService.clubSiteConfigSearch(this.encryptedUser, 0, this.clubConfig?.clubId, teamCode, this.clpCompanyId, this.isAI).
        then(async (result: CMClubSiteConfigListResponse) => {
          if (result) {
            let response = UtilityService.clone(result);
            this.clubSiteConfig = response?.clubSiteConfigs[0];
            this.patchSiteFields();
          }
        })
        .catch((err: HttpErrorResponse) => {
          this._globalService.error("site-fields.clubConfigGet", err.message, null,
          );
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  ngOnDestroy() {
    if (this.teamSub) {
      this.teamSub.unsubscribe()
    }
  }
}

