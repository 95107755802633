import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DropDownFilterSettings, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { ConfigDetails } from '../../models/app-config.model';
import { BCMCLPUser, CLPUser, TeamCodes } from '../../models/clpuser.model';
import { CMClub, CMClubResponse, CMSite, CMSiteEmployees, CMSiteEmployeesListResponse, CMSiteListResponse } from '../../models/cm-club-service.model';
import { SimpleResponse, UserDD } from '../../models/generic-response.model';
import { CMClubService } from '../../services/cm-club.service';
import { NotificationService } from '../../services/notification.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TeamOfficeService } from '../../services/team-office.service';
import { UserService } from '../../services/user.service';
declare var $: any;

@Component({
    selector: 'app-cm-employees',
    templateUrl: './cm-employees.component.html',
    styleUrl: './cm-employees.component.css'
})
export class CmEmployeesComponent {
    @ViewChild("dropdownlist") public dropdownlist: DropDownListComponent;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<CMSiteEmployees>;
    displayedColumns: string[] = ['userName', 'siteName', 'phoneNumber', 'email', 'roleName'];
    /*displayedColumns: string[] = ['userName', 'phoneNumber', 'email', 'siteID', 'roleName', 'action'];*/
    allRolesList = [];
    allSitesList = [];
    submitted
    comnExist: boolean = false
    addEmployeeForm: FormGroup;
    encryptedUser: string;
    user: BCMCLPUser;
    userAI: CLPUser;
    corporateOwnedDD = [{ key: "-Any-", value: "-1" }, { key: "Yes", value: "1" }, { key: "No", value: "0" }]
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    isAI: boolean = false;
    clpCompanyId: number = 0;
    curTeam: TeamCodes = <TeamCodes>{};
    showSpinner: boolean;
    clubConfig: CMClub;
    clpUsersDD: UserDD[];
    curSiteSub: Subscription;
    curSite: CMSite = <CMSite>{};
    siteEmployeesList: CMSiteEmployees[];
    sites: CMSite[];
    isEditMem: boolean = false;
    isEmployeeCreate: boolean = false
    selectedUserID: number = 0;
    roleName: string = "--All--";
    filter: string;
    copyAllRoleList = [];
    selectedSiteID: number = 0;
    siteEmployee: CMSiteEmployees = <CMSiteEmployees>{};
    class1Code: number;
    teamSub: Subscription;
    selectedEmp: CMSiteEmployees;

    constructor(private _localService: LocalService,
        private _teamOfficeService: TeamOfficeService,
        private userService: UserService,
        private _utilityService: UtilityService,
        private fb: FormBuilder,
        private clubService: CMClubService,
        private _notifyService: NotificationService,
        private _globalService: GlobalService,
        private _router: Router,
        private _appconfigService: AppConfigService,
    ) {

        this._localService.setShowMenu(true)
        this._localService.setRouteName("cm-employees")
        this._localService.setIsCmManager(true);
        this._localService.updateTeamDD(true)
    }

    ngAfterViewInit() {
        this.encryptedUser = localStorage.getItem("token");
        if (this.encryptedUser) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }
    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
        this.addEmployeeForm = this.prepareMemberForm();
    }

    prepareMemberForm() {
        return this.fb.group({
            employeeName: [0, [Validators.required]],
            site: [0],
            role: ['--All--', [Validators.required]]
        });
    }

    hideModalEmployee() {
        $('#employeeModal').modal('hide');
    }


    private async authenticateR() {

        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadData() {
        this.teamSub = this._teamOfficeService.curTeam.subscribe(async (data: any) => {
            if (data != 0 && data != null) {
                this.curTeam = data
                if (this.curTeam?.teamCode > 0) {
                    this.selectedSiteID = 0
                    this.selectedUserID = 0
                    this.roleName = "--All--"
                    await this.CMClub_Get();
                    await this.siteSearch(true)
                    await this.cLPUserGetListByTeamCode(this.clpCompanyId, this.curTeam?.teamCode)
                    await this.siteEmployeesSearch(true);
                }
            }
            else {
                this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
                if (this.curTeam?.teamCode > 0) {
                    this.selectedSiteID = 0
                    this.selectedUserID = 0
                    this.roleName = "--All--"
                    await this.CMClub_Get();
                    await this.siteSearch(true);
                    this.cLPUserGetListByTeamCode(this.clpCompanyId, this.curTeam?.teamCode)
                    await this.siteEmployeesSearch(true);
                }
            }
        })
        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = await value
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')

            }

        })
    }

    async siteSearch(onPageload: boolean) {
        if (this.clubConfig?.clubId > 0) {
            await this.clubService.siteSearch(this.encryptedUser, 0, this.clubConfig?.clubId, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites;
                        this.sites.unshift({
                            siteID: 0,
                            clubID: 0,
                            class1Code: 0,
                            clpcompanyID: 0,
                            siteName: "--All--",
                            teamCode: 0,
                            address: "",
                            city: "",
                            state: "",
                            country: "",
                            zip: "",
                            taxRate: 0,
                            scheduleSiteVisitURL: "",
                            scheduleOpenHouseURL: "",
                            vesselsURL: "",
                            kioskURL: "",
                            lnkCustom1URL: "",
                            lnkCustom2URL: "",
                            lnkCustom3URL: "",
                            lnkCustom4URL: "",
                            lnkCustom5URL: "",
                            mktCustomText1: "",
                            mktCustomText2: "",
                            mktCustomText3: "",
                            mktCustomText4: "",
                            mktCustomText5: "",
                            mktMultiText1: "",
                            mktMultiText2: "",
                            mktMultiText3: "",
                            mktMultiText4: "",
                            mktMultiText5: "",
                            engDashCustomMMT1D1: "",
                            engDashCustomMMT1D2: "",
                            engDashCustomMMT1D3: "",
                            engDashCustomMMT1D4: "",
                            engDashCustomMMT1D5: "",
                            engDashCustomMMT1D6: "",
                            engDashCustomMMT1D7: "",
                            engDashCustomMMT1D8: "",
                            engDashCustomMMT1D9: "",
                            engDashCustomMMT1D10: "",
                            dtCreated: "",
                            dtModified: "",
                            newLead: 0,
                            newMember: 0,
                            contractSent: 0,
                            contractOpen: 0,
                            waitingCountersign: 0,
                            contractExpiration: 0,
                            contactUpdate: 0,
                            referralRequest: 0,
                            teamCodeDisplay: "",
                            class1Display: "",
                            phLocation: "",
                        })

                        if (onPageload) {
                            this.allSitesList = [{ text: "--All--", value: 0 }]
                            this.sites.forEach((item) => {
                                if (this.allSitesList.findIndex(i => i.text == item?.siteName) == -1)
                                    this.allSitesList.push({ text: item?.siteName, value: item?.siteID });
                            })
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    openEmployee() {
        $('#employeeModal').modal('show');
        this.isEmployeeCreate = true;
    }

    async saveEmployee() {
        let role = this.addEmployeeForm.controls['role'].value
        let user = this.addEmployeeForm.controls["employeeName"].value;
        //let site = this.addEmployeeForm.controls["site"].value;
        this.comnExist = false
        if (this.siteEmployeesList?.length > 0) {
            this.siteEmployeesList.forEach((item) => {
                if (item?.roleName.toLowerCase() == role.toLowerCase() && item?.userID == user) {
                    this._notifyService.showError(`${item?.userName} with role ${role} is already exists!`, "Duplicate", 3000);
                    this.comnExist = true
                }
            })
        }
        if (user == 0) {
            this._notifyService.showError(`Select user.`, "Error", 3000)
            return
        }
        if (role.trim() == '') {
            this._notifyService.showError(`Role is required`, "Error", 3000)
            return
        }

        if (this.addEmployeeForm.valid && !this.comnExist) {
            this.submitted = true
            this.siteEmployee.iD = 0;
            this.siteEmployee.roleName = this.addEmployeeForm.controls['role'].value;
            this.siteEmployee.userID = this.addEmployeeForm.controls['employeeName'].value;
            this.siteEmployee.siteID = 0;
            this.siteEmployee.clpcompanyId = this.clpCompanyId;
            this.siteEmployee.clubID = this.clubConfig?.clubId;
            this.siteEmployee.class1Code = this.class1Code;
            this.submitted = true;
            await this.clubService.CMSiteEmployees_Update(this.encryptedUser, this.siteEmployee, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.siteEmployeesSearch(false)
                        this._notifyService?.showSuccess("Employees updated.", "Success", 3000)
                        this.addEmployeeForm = this.prepareMemberForm();
                        this.hideModalEmployee();
                    }
                    this.submitted = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.submitted = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async siteEmployeesSearch(onPageload: boolean) {
        /*if (this.curSite?.siteID > 0) {*/
        let clubId = this.clubConfig?.clubId > 0 ? this.clubConfig?.clubId : 0
        let roleName = this.roleName == "--All--" ? "" : this.roleName
        await this.clubService.siteEmployeesSearch(this.encryptedUser, this.clpCompanyId, this.selectedSiteID, clubId, 0, this.selectedUserID, roleName, this.isAI).
            then(async (result: CMSiteEmployeesListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.siteEmployeesList = result.listSiteEmployees;
                    this.dataSource = new MatTableDataSource(this.siteEmployeesList);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;

                    if (onPageload) {
                        this.allRolesList = ["--All--", 'Signing Officer']
                        this.siteEmployeesList.forEach((item, i) => {
                            console.log(item['sn']);
                            item['sn'] = i + 1
                            if (this.allRolesList.indexOf(item?.roleName) == -1 && item.roleName.toLowerCase() != "signing officer") {
                                this.allRolesList.push(item?.roleName);
                            }

                        })
                        this.copyAllRoleList = UtilityService.clone(this.allRolesList);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
        /*}*/
    }

    async CMClub_Get() {
        if (this.curTeam?.teamCode > 0) {
            this.showSpinner = true
            await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, this.curTeam?.teamCode, this.isAI).
                then(async (result: CMClubResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubConfig = response?.clubConfig
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async cLPUserGetListByTeamCode(clpCompanyId, teamCode) {
        this.showSpinner = true
        await this.userService.cLPUserGetListByTeamCode(this.encryptedUser, clpCompanyId, teamCode).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clpUsersDD = response
                    this.clpUsersDD.unshift({
                        value: 0,
                        text: "--All--"
                    })
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                    'clpCompanyId: ' + this.clpCompanyId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getSearchFilter() {
        await this.siteEmployeesSearch(false);
    }

    public handleFilter(value) {
        this.filter = value;
        let copyRoles = [...this.copyAllRoleList]
        this.allRolesList = copyRoles.filter(
            (s) => s.toLowerCase().trim().indexOf(value.toLowerCase().trim()) !== -1);
    }

    public addNew(): void {
        this.copyAllRoleList.push(this.filter);
        this.handleFilter(this.filter);
    }

    public onClose(event: any) {
        event.preventDefault();
        if (this.filter && this.filter.trim()?.length > 0) {
            if (this.copyAllRoleList?.length > 0) {
                let index = this.copyAllRoleList.indexOf(this.filter)
                if (index == -1) {
                    this.copyAllRoleList.push(this.filter);
                    this.handleFilter(this.filter)
                }
            } else {
                this.copyAllRoleList.push(this.filter);
                this.handleFilter(this.filter)
            }
        }
        setTimeout(() => {
            if (!this.dropdownlist.wrapper.nativeElement.contains(document.activeElement)) {
                this.dropdownlist.toggle(false);
            }
        });
    }

    onEdit(employee) {
        $('#employeeModal').modal('show');
        this.isEmployeeCreate = true;
        if (employee) {
            this.selectedEmp = this.siteEmployee;
            this.addEmployeeForm.controls['employeeName'].setValue(this.selectedEmp.userID);
            this.addEmployeeForm.controls['role'].setValue(this.selectedEmp.roleName);
            this.addEmployeeForm.controls['site'].setValue(this.selectedEmp.siteID);
        }
    }
}
