import { Component } from '@angular/core';
import { LocalService } from '../../../services/shared/local.service';

@Component({
  selector: 'app-lm-discounts',
  templateUrl: './lm-discounts.component.html',
  styleUrl: './lm-discounts.component.css'
})
export class LmDiscountsComponent {
  constructor(private localService: LocalService) {
    this.localService.setShowMenu(true)
    this.localService.setIsCmManager(false);
    this.localService.setRouteName("sm-discounts")
  }
}
