<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Sites</h3>
    </div>
    <div class="table-section">
        <div class="card-section">
            <div class="card-panel">
                <div class="table-panel">
                    <div class="table-header">
                        <div class="search-input">
                            <input class="form-control" type="text" (keyup)="search($event.target.value)" placeholder="Search" />
                            <div class="button-group">
                                <button class="cancel-btn" *ngIf="user?.slurpyUserId > 0" (click)="addNewSite()">Add Sites <i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <!--<div class="sort-panel">
                          <select class="form-control min-width200" (change)="onChangeSorting($event)">
                            <option value="1">Ascending</option>
                            <option value="2">Descending</option>
                          </select>
                          <i class="fa-solid fa-sliders"></i>
                        </div>-->

                    </div>
                    <form [formGroup]="newSiteForm">
                        <div class="flex-form" style="align-items:center;" *ngIf="showAddNewSite">
                            <div class="form-group">
                                <label>Site</label>
                                <select class="form-control" formControlName="bcmDropDown" (change)="cMSite_Exits($event)">
                                    <option [defaultSelected]=true [value]="-1">--Select Site--</option>
                                    <option *ngFor="let item of bcmDropDown" [value]="item.id">{{ item.text }}</option>
                                </select>
                            </div>
                            <!--<div class="form-group">
                              <label>Team</label>
                              <h6>{{curTeam?.display}}</h6>
                            </div>-->
                            <div class="button-group form-group" style="margin-top:23px">
                                <label></label>
                                <button class="btn cancel-btn" [disabled]="disableBtn" (click)="siteUpdate()">Save<i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
                            </div>
                        </div>
                    </form>
                    <div class="table-body">
                        <table mat-table [dataSource]="dataSource" class="" matSort>
                            <ng-container matColumnDef="siteName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sites</th>
                                <td mat-cell *matCellDef="let element" (click)="viewSites(element)">{{element.siteName}}</td>
                            </ng-container>
                            <!--<ng-container matColumnDef="team">
                              <th mat-header-cell *matHeaderCellDef>Team</th>
                              <td mat-cell *matCellDef="let element">{{element.teamCodeDisplay}}</td>
                            </ng-container>-->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <i class="fa fa-edit"></i>
                                    <i class="fa fa-trash"></i>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="view">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" (click)="viewSites(element)"><button class="btn-primary" (click)="viewSites(element)">View</button></td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator *ngIf="sites?.length > 0" [length]="sites?.length" [pageSizeOptions]="[5, 10, 15, 50]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
