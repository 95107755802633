import { IntDropDownItem, SimpleResponse } from "./generic-response.model";

export interface BCMClassCode {
  classCode: number;
  classCodeTitle: string;
  clpCompanyId: number;
  display: string;
  sOrder: number;
}


export interface IntDropDownItemListResponse extends SimpleResponse {
  intDropDownItemList: IntDropDownItem[];
}
