import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormBuilder, FormGroup } from '@angular/forms';

import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service'
import { CMContractService } from '../../services/cm-contract.service';
import { NotificationService } from '../../services/notification.service';
import { CMClubService } from '../../services/cm-club.service';
import { DocumentMailMergeService } from '../../services/document-mail-merge.service';

import { BCMCLPUser, TeamCodes, ContactCustomField, ContactCustomFieldResponse } from '../../models/clpuser.model';
import { CMSite } from '../../models/cm-club-service.model';
import { IntDropDownItem, IntDropDownItemListResponse, SimpleResponse } from '../../models/generic-response.model';
import { CMClub, CMClubResponse } from '../../models/cm-club-service.model';
import { UserService } from '../../services/user.service';

declare var $: any;

@Component({
    selector: 'app-cm-contract-mapping',
    templateUrl: './cm-contract-mapping.component.html',
    styleUrl: './cm-contract-mapping.component.css'
})
export class CmContractMappingComponent {
    isAI: boolean;
    encryptedUser: string = ''
    user: BCMCLPUser;
    curSite: CMSite = <CMSite>{}
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    clpCompanyId: number;

    curTeam: TeamCodes = <TeamCodes>{};
    curSiteSub: Subscription;
    siteData: IntDropDownItem = <IntDropDownItem>{};
    public siteSelected: number;
    submitted: boolean;

    dtDate: Date = new Date();
    contactCustomFields: ContactCustomField[];

    showSpinner: boolean;

    clubConfig: CMClub = <CMClub>{};
    configForm: FormGroup;
    curClubId: number = 0

    constructor(private localService: LocalService,
        private fb: FormBuilder,
        private _router: Router,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _notifyService: NotificationService,
        private _contractService: CMContractService,
        private _clubService: CMClubService,
        private _userService: UserService,
        private documentMailMergeService: DocumentMailMergeService,
    ) {
        this.localService.setShowMenu(true)
        this.localService.setIsCmManager(true);
        this.localService.setRouteName("cm-contract-mapping")
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.encryptedUser = localStorage.getItem("token");
        this.configForm = this.prepareConfigForm()
        //this.siteForm = this.prepareSiteForm();
        if (!this.encryptedUser?.includes(":")) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
        }
        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadPage();
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }   

    async loadPage() {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
        if (this.curTeam && this.curTeam.teamCode > 0) {
            await this.loadContactCustom();
        }
    }

    async loadContactCustom() {
        await this._userService.contactField_LoadCustomList(this.encryptedUser, this.curTeam.cLPCompanyID).
            then(async (result: ContactCustomField[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contactCustomFields = response;
                    this.contactCustomFields.unshift({
                        customValue: 'Select',
                        customTitle: "-1",
                    });

                    await this.CMClub_Get(this.curTeam.teamCode)


                    this.showSpinner = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                    'clpCompanyId: ' + this.clpCompanyId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async CMClub_Get(teamCode) {
        this.showSpinner = true
        if (!teamCode || teamCode == 0) {
            this._notifyService?.showError("No team is selected", "Error", 3000)
            return
        }
        await this._clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, teamCode, this.isAI).
            then(async (result: CMClubResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clubConfig = response?.clubConfig
                    this.curClubId = this.clubConfig?.clubId
                    this.showSpinner = false

                    this.patchConfigForm();
                }

            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-mapping.CMClub_Get", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchConfigForm() {
        this.configForm.patchValue({
            DLId: this.clubConfig?.DLId,
            EmergencyNameId: this.clubConfig?.EmergencyNameId,
            EmergencyPhoneId: this.clubConfig?.EmergencyPhoneId,
            DOBId: this.clubConfig?.DOBId
        });
    }

    async saveClubMapping() {
        this.clubConfig.DLId = this.configForm.controls['DLId'].value;
        this.clubConfig.EmergencyNameId = this.configForm.controls['EmergencyNameId'].value;
        this.clubConfig.EmergencyPhoneId = this.configForm.controls['EmergencyPhoneId'].value;
        this.clubConfig.DOBId = this.configForm.controls['DOBId'].value;

        await this._clubService.CMClub_Update(this.encryptedUser, this.clubConfig, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (this.curClubId > 0) {
                        this._notifyService?.showSuccess("Club Mapping updated successfully.", "Success", 3000)
                    }
                    else {
                        this._notifyService?.showSuccess("Club created successfully.", "Success", 3000)
                    }
                    if (response.messageInt > 0) {
                        this.curClubId = response.messageInt;
                    }

                    this.submitted = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.submitted = false
                this._globalService.error("cm-contract-mapping.saveClubMapping", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareConfigForm() {
        return this.fb.group({
            DLId: [0],
            EmergencyNameId: [0],
            EmergencyPhoneId: [0],
            DOBId: [0],
        })
    }

    private async authenticateR() {
        await this.localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-mapping.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
