import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { eTempMMDocumentType } from '../models/enum.model';
import { IntDropDownItemListResponse, UserDD } from '../models/generic-response.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentMailMergeService {

    private baseUrl: string;
    private api: string = "api/MailMergeTemplate";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async mailMergeTemplateGetList(encryptedUser: string, clpcompanyID: number, clpuserID: number): Promise<IntDropDownItemListResponse | void> {
        const http$ = await this.httpClient
            .get<IntDropDownItemListResponse>(`${this.baseUrl}/MailMergeTemplate_GetList/${clpcompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompanyID - " + clpcompanyID + "," + "clpuserID - " + clpuserID, encryptedUser, "DocumentMailMergeService", "getCLPCompany_LoadUsers") });

        return http$;
    }

    async bCMMailMergeTemplateGetListByTeam(encryptedUser: string, clpcompanyID: number, teamCode: number, isAI: boolean): Promise<IntDropDownItemListResponse | void> {
        const http$ = await this.httpClient
            .get<IntDropDownItemListResponse>(`${this.baseUrl}/BCMMailMergeTemplate_GetListByTeam/${clpcompanyID}/${teamCode}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompanyID - " + clpcompanyID + "," + "teamCode - " + teamCode, encryptedUser, "DocumentMailMergeService", "bCMMailMergeTemplateGetListByTeam") });

        return http$;
    }

    async emailTemplateGetList(encryptedUser: string, clpcompanyID: number, clpuserID: number): Promise<IntDropDownItemListResponse | void> {
        const http$ = await this.httpClient
            .get<IntDropDownItemListResponse>(`${this.baseUrl}/EmailTemplate_GetList/${clpcompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompanyID - " + clpcompanyID + "," + "clpuserID - " + clpuserID, encryptedUser, "DocumentMailMergeService", "getCLPCompany_LoadUsers") });

        return http$;
    }
}
