import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AppConfig, ConfigDetails } from '../../models/app-config.model';
import { delayedRetryHttp } from './delayed-retry';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private baseUrl: string = '';
  private api: string = "api/BCMAppConfig";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }
  private handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('App Config Service Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('App Config Service Server Side Error: ', errorResponse);
    }

    return throwError("There is a problem with the service. We are notified & working on it. Please try again later.");
  }

  bCMGetConfigKeyValue(encryptedUser: string, configValue: string): Observable<ConfigDetails | void> {

    return this.httpClient
      .get<ConfigDetails>(`${this.baseUrl}/BCMGetConfigKeyValue/${configValue}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(
        catchError(this.handleErrors)
      );
  }

  async getIsNewSO(): Promise<ConfigDetails | void> {
    const a = await this.httpClient
      .get<ConfigDetails>(`${this.baseUrl}/GetIsNewSO`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic '
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "AppConfigService", "getIsNewSO"); });
    return a;
  }

}
