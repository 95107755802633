import { AfterContentChecked } from '@angular/core';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CLPUser, UserResponse } from './models/clpuser.model';
import { GlobalService } from './services/shared/global.service';
import { LocalService } from './services/shared/local.service';
import { UtilityService } from './services/shared/utility.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements AfterContentChecked {
    encryptedUser: string; user: CLPUser;
    userResponse: UserResponse;
    incomingUrl: string;
    isCmManager: boolean;
    isShowMenu: boolean;
    isContract: boolean;
    isClientContract: boolean;

    constructor(public _localService: LocalService,
        private titleService: Title,
        private _utilityService: UtilityService,
        private _globalSErvice: GlobalService,
        private _route: ActivatedRoute,
        private cdref: ChangeDetectorRef,
        private _router: Router
    ) {
        this.titleService.setTitle('Club Manager');

        this._localService.isCmManager.subscribe(value => this.isCmManager = value)
        this._localService.isShowMenu.subscribe(value => this.isShowMenu = value)
        this._localService.isContract.subscribe(value => this.isContract = value)
        this._localService.isClientContract.subscribe(value => this.isClientContract = value)

    }

    ngOnInit() {
        if (localStorage.getItem('title'))
            this.titleService.setTitle(localStorage.getItem('title'));

    }


    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

}
