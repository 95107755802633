import { HttpErrorResponse } from '@angular/common/http';
import { OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BCMCLPUser, CLPUser, TeamCodes, UserResponse } from '../models/clpuser.model';
import { RoleFeaturePermissions } from '../models/role-container.model';
import { GlobalService } from '../services/shared/global.service';
import { LocalService } from '../services/shared/local.service';
import { MenuService } from '../services/shared/menu.service';
import { UtilityService } from '../services/shared/utility.service';
import homeMenu from '../../assets/json/menu.json';
import { gearIcon, questionCircleIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { Title } from '@angular/platform-browser';
import { Search, SearchListResponse } from '../models/search.model';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SearchContactService } from '../services/shared/search-contact.service';
import { SimpleResponse } from '../models/generic-response.model';
import { AppConfigService } from '../services/shared/app-config.service';
import { ConfigDetails } from '../models/app-config.model';
import { TeamOfficeService } from '../services/team-office.service';
import { CMClubService } from '../services/cm-club.service';
import { CMClub, CMClubResponse, FilterTeam_Load_GeneralResponse, CMSite, CMSiteListResponse } from '../models/cm-club-service.model';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import {
    BCMMenu,
    BCMMenuResponse,
    MenuResponse
} from '../models/menu.model';
import { isNullOrUndefined } from 'util';

declare var $: any;

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {
    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
    public gear: SVGIcon = gearIcon;
    public question: SVGIcon = questionCircleIcon;
    isExpanded = false;
    private encryptedUser: string = '';
    private copyEncryptedUser: string = '';
    userResponse: UserResponse;
    user: BCMCLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    userProfileMenu: { text: string; items: ({ text: string; items?: undefined; } | { text: string; items: any[]; })[]; }[];

    homeMenu: any[] = [];
    currentSelectedId: string;
    currentSelectedParentId: string;
    searchList: Search[] = [];
    inputBoxValue: string = "";
    currentSelectedChildId: string;
    simpleResponse: SimpleResponse;
    showSearch: boolean;
    devSoUrl: string;
    isCmManager: boolean;
    teamFilterDD: TeamCodes[];
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    routeName: string = '';
    curTeam: TeamCodes = <TeamCodes>{};
    sites: CMSite[];
    curSiteId: number;
    curTeamCode: number = 0
    curSite: CMSite = <CMSite>{}
    clubConfig: CMClub = <CMClub>{};
    cmManagerSUb: Subscription
    routeNameSUb: Subscription
    updateTeamSub: Subscription
    curSiteSub: Subscription
    menuResponse: BCMMenuResponse;
    menus: BCMMenu[];
    mySoUrl: string = ''
    mySoLink: string;
    clpCompanyId: number;
    slurpyUserId: number;
    userRole: number;
    clpUserId: number;
    firstName: string;
    lastName: string;
    isAI: boolean = false;
    isContract: boolean;

    useImage: boolean;
    companyImage: string = "../../assets/img/sologo.png";
    ready: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        public _localService: LocalService,
        private _globalService: GlobalService,
        private _utilityService: UtilityService,
        private titleService: Title,
        private _searchContactService: SearchContactService,
        private _menuService: MenuService,
        private _teamOfficeService: TeamOfficeService,
        private clubService: CMClubService,
        private _appconfigService: AppConfigService
    ) {

        this.cmManagerSUb = this._localService.isCmManager.subscribe(value => this.isCmManager = value)
        this.routeNameSUb = this._localService.routeName.subscribe(value => this.routeName = value);
        this._localService.isContract.subscribe(value => this.isContract = value)

    }

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            if (params?.isAI) {
                this.isAI = params?.isAI && params?.isAI == 'true' ? true : false
            }
            else {
                this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
            }
            this.encryptedUser = params?.r;
            if (!this.encryptedUser?.includes(":")) {
                this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            }
            if (params?.r) {
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.loadData()
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                this.encryptedUser = localStorage.getItem("token")
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.loadData()
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
        })

    }

    loadData() {
        this.updateTeamSub = this._localService.updateTeam.subscribe(value => {
            if (value) {
                this.filterTeamLoadGeneral()
            }
        });

        this._appconfigService.bCMGetConfigKeyValue(this.encryptedUser, "MySO_URL")
            .subscribe(async (result: ConfigDetails) => {
                if (result) {
                    this.mySoUrl = result?.configValue
                }
            });

        this._appconfigService.bCMGetConfigKeyValue(this.encryptedUser, "MySo_Link")
            .subscribe(async (result: ConfigDetails) => {
                if (result) {
                    this.mySoLink = result?.configValue
                }
            });

        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = value
            if (this.curSite != null) {
                this.curSiteId = this.curSite?.siteID
                await this.CMClub_Get(this.curTeam?.teamCode)
                this.siteSearch()
            }
            else {
                this.curSite = JSON.parse(localStorage.getItem("curSite") || '{}')
                this.curTeam = JSON.parse(localStorage.getItem("curTeam") || '{}')
                this.curSiteId = this.curSite?.siteID
                await this.CMClub_Get(this.curTeam?.teamCode)
                this.siteSearch()
            }
        })

        //this.filterTeamLoadGeneral()
        this.getMenu();
        this.setUserProfileMenu()
        this.homeMenu = homeMenu.homeMenu;
        delete this.homeMenu[0].icon;
        window.localStorage.setItem('sc_lastUser', String(this.clpUserId));
    }

    private async authenticateR() {

        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                    this.slurpyUserId = this.user?.slurpyUserId
                    this.userRole = this.user?.userRole
                    this.clpUserId = this.user?.cLPUserID
                    this.firstName = this.user?.firstName
                    this.lastName = this.user?.lastName

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    setUserProfileMenu() {
        this.userProfileMenu = [
            {
                text: this.firstName + " " + this.lastName,
                items: [
                    { text: "Edit Profile" },
                    {
                        text: "Sign out",
                        items: [],
                    },
                ],
            }
        ];
    }

    private async getMenu() {
        if (this.isAI) {
            await this._menuService.getMenuAI(this.encryptedUser, this.clpUserId, this.clpCompanyId)
                .then(async (result: MenuResponse) => {
                    if (result) {
                        this.menuResponse = UtilityService.clone(result);
                        if (this.menuResponse && this.menuResponse.menus) {
                            this.menus = this.menuResponse.menus;
                            this.useImage = this.menuResponse.useImage;
                            this.companyImage = this.useImage ? `../../assets/logos/logo_${this.clpCompanyId}.png` : this.getDefaultLogo();
                            this.ready = true;
                            var removeUrlArr = [];
                            this.menus.forEach(e => {
                                if (e.text == 'Help') {
                                    (e as any).i_con = 'help-icon';
                                    (e as any).svgIcon = this.question;
                                    delete e.text;
                                } else if (e.text == 'Control Panel') {
                                    (e as any).i_con = 'setting-icon';
                                    (e as any).svgIcon = this.gear;
                                    delete e.text;
                                }
                                delete e.url;
                                removeUrlArr.push(e);
                            })
                            this.menus = removeUrlArr;
                            this.menus = this.mapItems(this.menus);
                            this.menus.forEach(item => {
                                if (item.text == 'Companies' || item.text == 'Contacts' || item.text == 'Leads') {
                                    if (item.items && item.items.length > 0) {
                                        item.items = item.items.filter((data) => !(data.text === 'Recent' && (!data.items || data.items.length === 0)));
                                    }
                                }
                            });
                            this.menus.forEach((z_item, i) => {
                                (z_item as any).uID = i;
                                if (z_item.items.length > 0) {
                                    z_item.items.forEach((f_item, j) => {
                                        (f_item as any).uID = j;
                                        if (f_item.items.length > 0) {
                                            f_item.items.forEach((s_item, k) => {
                                                (s_item as any).uID = k;
                                                if (s_item.items.length > 0) {
                                                    s_item.items.forEach((t_item, l) => {
                                                        (t_item as any).uID = l;
                                                    })
                                                }
                                            })
                                        }
                                    });
                                }
                            });
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'clpUserId ' + this.clpUserId);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            await this._menuService.getMenu(this.encryptedUser, this.clpUserId, this.clpCompanyId)
                .then(async (result: BCMMenuResponse) => {
                    if (result) {
                        this.menuResponse = UtilityService.clone(result);
                        if (this.menuResponse && this.menuResponse.menus) {
                            this.menus = this.menuResponse.menus;
                            this.useImage = this.menuResponse.useImage;
                            this.companyImage = this.useImage ? `../../assets/logos/logo_${this.clpCompanyId}.png` : this.getDefaultLogo();
                            this.ready = true;
                            var removeUrlArr = [];
                            this.menus.forEach(e => {
                                if (e.text == 'Help') {
                                    (e as any).i_con = 'help-icon';
                                    (e as any).svgIcon = this.question;
                                    delete e.text;
                                } else if (e.text == 'Control Panel') {
                                    (e as any).i_con = 'setting-icon';
                                    (e as any).svgIcon = this.gear;
                                    delete e.text;
                                }
                                delete e.url;
                                removeUrlArr.push(e);
                            })
                            this.menus = removeUrlArr;
                            this.menus = this.mapItems(this.menus);
                            this.menus.forEach(item => {
                                if (item.text == 'Companies' || item.text == 'Contacts' || item.text == 'Leads') {
                                    if (item.items && item.items.length > 0) {
                                        item.items = item.items.filter((data) => !(data.text === 'Recent' && (!data.items || data.items.length === 0)));
                                    }
                                }
                            });
                            this.menus.forEach((z_item, i) => {
                                (z_item as any).uID = i;
                                if (z_item.items.length > 0) {
                                    z_item.items.forEach((f_item, j) => {
                                        (f_item as any).uID = j;
                                        if (f_item.items.length > 0) {
                                            f_item.items.forEach((s_item, k) => {
                                                (s_item as any).uID = k;
                                                if (s_item.items.length > 0) {
                                                    s_item.items.forEach((t_item, l) => {
                                                        (t_item as any).uID = l;
                                                    })
                                                }
                                            })
                                        }
                                    });
                                }
                            });
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'clpUserId ' + this.clpUserId);
                    this._utilityService.handleErrorResponse(err);
                });
        }

    }

    getDefaultLogo() {
        switch (this.user?.userRole) {
            case 3: {//nimbus
                return '../../assets/logos/nimbuslogo.png';
            }
            case 2: {//Cirrus
                return '../../assets/logos/cirruslogo.png';
            }
            case 0: {//StratusExpress
                return '../../assets/logos/stratusxlogo.png';
            }
            default: {
                return '../../assets/logos/stratuslogo.png';
            }
        }
    }

    private mapItems(menu: any, path?: string): any[] {
        return menu.map((item) => {
            const result: any = {
                svgIcon: item.svgIcon,
                icon: item.icon,
                text: item.text,
                path: item.url,
                i_con: item?.i_con,
            };
            if (item.items) {
                result.items = this.mapItems(item.items, item.url);
            }
            return result;
        });
    }

    public onSelectHomeDesktop(itemObj): void {
        let e = itemObj?.originalEvent
        let item = itemObj?.item
        var arr = this.encryptedUser.split(":");
        if (item?.text && item?.text != '') {
            switch (item?.text) {
                case "My Documents":
                    if (!e?.ctrlKey) {
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'document/default.aspx'
                        window.location.href = url
                        this.titleService.setTitle('My Documents');
                        localStorage.setItem("title", 'My Documents');
                    }
                    else {
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'document/default.aspx');
                        w.document.title = 'My Documents';
                        /*this.titleService.setTitle('My Documents');*/
                    }
                    break;
                case "My Profile":
                    if (!e?.ctrlKey) {                        
                        //this._router.navigate(['/edit-profile', this.clpUserId]);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'profile/default.aspx'
                        window.location.href = url
                        this.titleService.setTitle('Edit Profile');
                        localStorage.setItem("title", 'Edit Profile');
                    }
                    else {
                        //var w = window.open("edit-profile/" + this.clpUserId);
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'profile/default.aspx');
                        w.document.title = 'Edit Profile';
                    }

                    break;
                case "My Schedule":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/calender/scheduler']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/scheduler.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('Calender Scheduler');
                        localStorage.setItem("title", 'Calender Scheduler');
                    }
                    else {
                        //var w = window.open("calender/scheduler");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/scheduler.aspx');
                        w.document.title = 'Calender Scheduler';
                    }
                    break;
                case "My Call List":
                    var routeUrl: string = '';
                    routeUrl = this.clpCompanyId === 1226 ? 'call-ih' : 'call';
                    if (!e?.ctrlKey) {
                        //this._router.navigateByUrl(routeUrl);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/calllist.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('Call');
                        localStorage.setItem("title", 'Call');
                    }
                    else {
                        //var w = window.open(routeUrl);
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/calllist.aspx');
                        w.document.title = 'Call';
                    }
                    break;
                case "View My Goals":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'goal/default.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('View My Goal');
                        localStorage.setItem("title", 'View My Goal');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'goal/default.aspx');
                        w.document.title = 'View My Goal';
                    }
                    break;
                case "My Tasks":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'task/default.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('View My Task');
                        localStorage.setItem("title", 'View My Task');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'task/default.aspx');
                        w.document.title = 'View My Goal';
                    }
                    break;
                case "Home":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'home/default.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('My SalesOptima');
                        localStorage.setItem("title", 'My SalesOptima');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'home/default.aspx');
                        w.document.title = 'My SalesOptima';
                    }
                    break;
                case "My Mailings":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'marketing/default.aspx?cat=2'
                        window.location.href = url;
                        this.titleService.setTitle('My Mailing');
                        localStorage.setItem("title", 'My Mailing');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'marketing/default.aspx?cat=2');
                        w.document.title = 'My Mailing';
                    }
                    break;
                case "Setup My Goals":

                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'goal/setup.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('Setup Goal');
                        localStorage.setItem("title", 'Setup Goal');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'goal/setup.aspx');
                        w.document.title = 'Setup Goal';
                    }

                    break;
                case "New Appointment":
                    if (this.clpCompanyId == 1226 || this.clpCompanyId == 1321) {
                        if (!e?.ctrlKey) {
                            //this._router.navigate(['/goal']);
                            let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/appt.aspx?mde=n'
                            window.location.href = url;
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            //var w = window.open("/goal");
                            var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/appt.aspx?mde=n');
                            w.document.title = 'New Appointment';
                        }
                    }
                    else {
                        if (!e?.ctrlKey) {
                            //this._router.navigate(['/goal']);
                            let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/appt.aspx?mde=n'
                            window.location.href = url;
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            //var w = window.open("/goal");
                            var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/appt.aspx?mde=n');
                            w.document.title = 'New Appointment';
                        }
                    }
                    break;
                case "Appointment List":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/appts.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('Appointment List');
                        localStorage.setItem("title", 'Appointment List');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/appts.aspx');
                        w.document.title = 'Appointment List';
                    }
                    break;
                case "Bulk Appointment Wizard":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'marketing/bulkappts.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('Bulk Appointment Wizard');
                        localStorage.setItem("title", 'Bulk Appointment Wizard');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'marketing/bulkappts.aspx');
                        w.document.title = 'Bulk Appointment Wizard';
                    }
                    break;
                case "New Recurring Appointment":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/recurringappt.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('New Recurring Appointment');
                        localStorage.setItem("title", 'New Recurring Appointment');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/recurringappt.aspx');
                        w.document.title = 'New Recurring Appointment';
                    }
                    break;
                case "Dynamic Schedulers":
                    if (!e?.ctrlKey) {
                        //this._router.navigate(['/goal']);
                        let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/pcallist.aspx'
                        window.location.href = url;
                        this.titleService.setTitle('Dynamic Schedulers');
                        localStorage.setItem("title", 'Dynamic Schedulers');
                    }
                    else {
                        //var w = window.open("/goal");
                        var w = window.open(this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'calendar/pcallist.aspx');
                        w.document.title = 'Dynamic Schedulers';
                    }
                    break;
                case "My Calendar":
                case "My Goals":
                    {
                        break;
                    }
                default:
                    //$('#underConstruction').modal('show');
                    break;
            }
            this.currentSelectedId = "-1";
            this.currentSelectedParentId = "-1";
        }
    }

    goToLink(value: string, str: string = "") {
        var arr = this.encryptedUser.split(':');
        if (value) {
            var id = this.searchList.filter(item => item.searchText == value)[0]?.searchValue;
            if (id?.includes("ct")) {
                var contactId = id?.split("ct")[1];

                let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'contact/viewnew.aspx?cid=' + contactId;
                window.location.href = url
                this.titleService.setTitle('View Contact');
                localStorage.setItem("title", 'View Contact');

                //this._router.navigate(['/contact', this.clpUserId, contactId]);
                //this.autocomplete.reset();
            }
            else if (id?.includes("cp")) {
                var companyId = id?.split("cp")[1];
                this._router.navigateByUrl(`company-create?cid=${companyId}`);
                this.autocomplete.reset();
            }
            else {
                this.autocomplete.reset();
                let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'home/search.aspx?txt=' + this.inputBoxValue;
                window.location.href = url
                //this._router.navigateByUrl(`/home/search?txt=${this.inputBoxValue}`);
            }
        }
        else {
            this.autocomplete.reset();
            let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'home/search.aspx?txt=' + this.inputBoxValue;
            window.location.href = url
            //this._router.navigateByUrl(`/home/search?txt=${this.inputBoxValue}`);

        }
    }

    getSearchData(txt: string, value: string = "") {
        let Search: Search = <Search>{};

        Search.searchText = txt;
        this.inputBoxValue = txt;
        Search.searchValue = "";
        this.getContactSearch(Search);
    }

    getContactSearch(searchData: Search) {
        this._searchContactService.getContactSearchData(this.encryptedUser, this.clpUserId, searchData, this.isAI)
            .then(async (result: SearchListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.searchList = response.searchList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, null, "clpUserId " + this.clpUserId + "," + "searchData " + searchData);
                this._utilityService.handleErrorResponse(err);
            });
    }

    public onSelectParentDesktop(itemObj) {
        let e = itemObj?.originalEvent
        let item = itemObj?.item
        if (item.items.length == 0) {
            if (item.path != "") {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                if (item.text != "") {
                    switch (item.text) {
                        case "View": {
                            if (item.path?.includes("lead-detail")) {
                                let leadId = Number(item.path.split("lead-detail/")[1]);
                                if (!e?.ctrlKey) {

                                    window.location.href = item?.path;
                                }
                                else {
                                    window.open(item?.path);
                                }
                            }
                            else if (item.path?.includes("/company-create")) {
                                let cid = Number(item.path.split("cid=")[1]);
                                if (!e?.ctrlKey) {
                                    window.location.href = item?.path;
                                }
                                else {
                                    window.open(item?.path);
                                }
                                break;
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    window.location.href = item?.path;
                                }
                                else {
                                    window.open(item?.path);
                                }
                            }
                            break;
                        }
                        case "Create Referral": {
                            let rId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                rId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "View History": {
                            let contactId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path?.substring(lastIndex + 1);
                                contactId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "View Documents": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "View Appointments": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path
                            }
                            else {
                                window.open(item?.path);
                            }

                            break;
                        }
                        case "New Lead": {
                            let cid = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                cid = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "New Contact": {
                            let cid = Number(item.path.split("cid=")[1]);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "View Tasks": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path
                            }
                            else {
                                window.open(item?.path)
                            }
                            break;
                        }
                        case "Edit": {
                            if (item.path?.includes("lead-detail")) {
                                let value = item.path?.split("lead-detail/")[1];
                                let leadId = Number(value?.split("&")[0]);
                                if (!e?.ctrlKey) {
                                    window.location.href = item?.path
                                }
                                else {
                                    window.open(item?.path);
                                }
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    window.location.href = item?.path
                                }
                                else {
                                    window.open(item?.path);
                                }
                            }
                            break;
                        }
                        case "New Task": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        default: {
                            if (!e?.ctrlKey) {
                                if (item.path?.includes("/help/")) {
                                    window.open(item.path);
                                }
                                else {
                                    window.location.href = item.path;
                                }
                            }
                            else {
                                if (item.path?.includes("/help/")) {
                                    window.open(item.path);
                                }
                                else {
                                    window.open(item.path)
                                }
                            }
                            this.titleService.setTitle(item.text);
                            localStorage.setItem("title", item.text);
                            break;
                        }

                    }

                }
                this.toggleNavBar();
            }
            else {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                this.toggleNavBar();
            }
        } else {
            if (item.text == 'My Contacts') {
                this._router.navigate(['/contacts']);
            }
            if (item.icon) {
                var split_string = item.icon?.split("/");
                var newitem = split_string[split_string.length - 1];
                newitem = newitem.replace(/ /g, "").toLowerCase();
            } else if (item.text) {
                var newitem = item.text.replace(/ /g, "").toLowerCase();
            }

        }

    }

    toggleNavBar() {
        let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
        if (element.getAttribute('aria-expanded') == 'true') {
            element.click();
        }
    }

    getContactIdFromPath(inputString: string) {
        const lastIndex = inputString.lastIndexOf('/');
        if (lastIndex !== -1 && lastIndex + 1 < inputString.length) {
            const part2 = inputString.substring(lastIndex + 1);
            if (part2.includes('&')) {
                let value = part2?.split("&")[0];
                return value
            }
            return part2
        }
        return 0
    }


    onSelectUserMenu(item) {
        var arr = this.encryptedUser.split(':')
        switch (item?.item?.text) {
            case "Edit Profile":
                let url = this.mySoUrl + '?r=' + arr[0] + '&ReturnUrl=' + this.mySoLink + 'profile/default.aspx';
                window.location.href = url
                break;
            case "Sign out":
                this.logout();
                break;
        }
    }

    public onSelectParent(item, type: string, e: MouseEvent) {
        type = '' + type;
        if (item.items.length == 0) {
            if (item.path != "") {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                if (item.text != "") {
                    switch (item.text) {
                        case "View": {
                            if (item.path?.includes("lead-detail")) {
                                let leadId = Number(item.path.split("lead-detail/")[1]);
                                if (!e?.ctrlKey) {
                                    window.location.href = item?.path;
                                }
                                else {
                                    window.open(item?.path);
                                }
                            }
                            else if (item.path?.includes("/company-create")) {
                                let cid = Number(item.path.split("cid=")[1]);
                                if (!e?.ctrlKey) {
                                    window.location.href = item?.path;
                                }
                                else {
                                    window.open(item?.path);
                                }
                                break;
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    window.location.href = item?.path
                                }
                                else {
                                    window.open(item?.path);
                                }
                            }
                            break;
                        }
                        case "Create Referral": {
                            let rId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                rId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "View History": {
                            let contactId = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path?.substring(lastIndex + 1);
                                contactId = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "View Documents": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "New Lead": {
                            let cid = 0;
                            const lastIndex = item.path?.lastIndexOf('/');
                            if (lastIndex !== -1 && lastIndex + 1 < item.path?.length) {
                                const part2 = item.path.substring(lastIndex + 1);
                                cid = Number(part2);
                            }
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "New Contact": {
                            let cid = Number(item.path.split("cid=")[1]);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "View Tasks": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {;
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            break;
                        }
                        case "Edit": {
                            if (item.path?.includes("lead-detail")) {
                                let value = item.path?.split("lead-detail/")[1];
                                let leadId = Number(value?.split("&")[0]);
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/lead-detail/', leadId]);
                                    window.location.href = item?.path;
                                }
                                else {
                                    window.open("lead-detail/" + leadId);
                                    window.open(item?.path);
                                }
                            }
                            else {
                                let contactId = Number(this.getContactIdFromPath(item.path));
                                if (!e?.ctrlKey) {
                                    this._router.navigate(['/contact/', this.clpUserId, contactId]);
                                    window.location.href = item?.path;
                                }
                                else {
                                    window.open("contact/" + this.clpUserId + "/" + contactId);
                                    window.open(item?.path);
                                }
                            }
                            break;
                        }
                        case "New Task": {
                            let lastSlashIndex = item.path.lastIndexOf('/');
                            let secondLastSlashIndex = item.path.lastIndexOf('/', lastSlashIndex - 1);
                            let result = item.path.substring(secondLastSlashIndex + 1, lastSlashIndex);
                            let id = Number(result);
                            let category = item.path.substring(lastSlashIndex + 1, lastSlashIndex + 2);
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path + "?isNew=true";
                            }
                            else {
                                window.open(item?.path + "?isNew=true");
                            }
                            break;
                        }
                        default: {
                            if (!e?.ctrlKey) {
                                window.location.href = item?.path;
                            }
                            else {
                                window.open(item?.path);
                            }
                            this.titleService.setTitle(item.text);
                            localStorage.setItem("title", item.text);
                            break;
                        }

                    }

                }
                this.toggleNavBar();
            }
            else {
                this.currentSelectedId = "-1";
                this.currentSelectedParentId = "-1";
                this.currentSelectedChildId = "-1";
                this.toggleNavBar();
            }
        } else {
            if (item.i_con) {
                var split_string = item.i_con?.split("/");
                var newitem = split_string[split_string.length - 1];
                newitem = newitem.replace(/ /g, "").toLowerCase();
            } else if (item.text) {
                var newitem = item.text.replace(/ /g, "").toLowerCase();
            }

            switch (type) {
                case "0": {
                    //for hiding toggler on click of icon
                    if (item.i_con) {
                        this.toggleNavBar();
                        if (this.showSearch)
                            this.showSearch = !this.showSearch;
                    }


                    if (this.currentSelectedParentId == newitem)
                        this.currentSelectedParentId = "-1";
                    else
                        this.currentSelectedParentId = newitem;
                    break;
                }
                case "1": {
                    if (this.currentSelectedId == newitem)
                        this.currentSelectedId = "-1";
                    else
                        this.currentSelectedId = newitem;
                    break;
                }
                case "2": {
                    if (this.currentSelectedChildId == newitem)
                        this.currentSelectedChildId = "-1";
                    else
                        this.currentSelectedChildId = newitem;
                    break;
                    break;
                }
                default: {
                    break;
                }
            }
        }

    }

    onMouseOver(item, type: string) {
        this.menus?.forEach(z_item => {
            if (type == '0') {
                if (((!isNullOrUndefined(item?.text) && z_item.text == item?.text) || (!isNullOrUndefined(item.icon) && z_item.icon == item.icon)) && (z_item as any).uID == item?.uID)
                    (z_item as any).dClass = 'active';
                else
                    (z_item as any).dClass = '';
            }
            if (z_item.items.length > 0) {
                z_item.items.forEach(f_item => {
                    if (type == '1') {
                        if (((!isNullOrUndefined(item?.text) && f_item?.text == item?.text) || (!isNullOrUndefined(item.icon) && f_item.icon == item.icon)) && (f_item as any).uID == item?.uID)
                            (f_item as any).dClass = 'active';
                        else
                            (f_item as any).dClass = '';
                    }
                    if (f_item.items.length > 0) {
                        f_item.items.forEach(s_item => {
                            if (type == '2') {
                                if (((!isNullOrUndefined(item?.text) && s_item?.text == item?.text) || (!isNullOrUndefined(item.icon) && s_item.icon == item.icon)) && (s_item as any).uID == item?.uID)
                                    (s_item as any).dClass = 'active';
                                else
                                    (s_item as any).dClass = '';
                            }
                            if (s_item.items.length > 0) {
                                s_item.items.forEach(t_item => {
                                    if (type == '3') {
                                        if (((!isNullOrUndefined(item?.text) && t_item?.text == item?.text) || (!isNullOrUndefined(item.icon) && t_item.icon == item.icon)) && (t_item as any).uID == item?.uID)
                                            (t_item as any).dClass = 'active';
                                        else
                                            (t_item as any).dClass = '';
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

    }

    logout() {
        this._localService.userTokenSignout(this.encryptedUser, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result && result.messageBool) {
                    localStorage.removeItem("token");
                    localStorage.clear();
                    this.titleService.setTitle('SalesOptima');
                    if (this.mySoUrl) {
                        window.location.href = this.mySoUrl;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("localservice.logout", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    public onSelectHome(item, e: MouseEvent): void {
        if (item.text && item.text != '') {
            switch (item.text) {
                case "My Documents":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('My Documents');
                        localStorage.setItem("title", 'My Documents');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'My Documents';
                    }

                    break;
                case "My Profile":
                    if (!e?.ctrlKey) {
                        let url = this.mySoUrl + '/security/authenticate.aspx?r=' + this.encryptedUser + '&ReturnUrl=' + this.mySoLink + 'profile/default.aspx'
                        window.location.href = url
                        this.titleService.setTitle('Edit Profile');
                        localStorage.setItem("title", 'Edit Profile');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Edit Profile';
                    }

                    break;
                case "My Schedule":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('Calender Scheduler');
                        localStorage.setItem("title", 'Calender Scheduler');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Edit Profile';
                    }
                    break;
                case "My Call List":
                    var routeUrl: string = '';
                    routeUrl = this.clpCompanyId === 1226 ? 'call-ih' : 'call';
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('Call');
                        localStorage.setItem("title", 'Call');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Call';
                    }
                    break;
                case "View My Goals":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('View My Goal');
                        localStorage.setItem("title", 'View My Goal');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'View My Goal';
                    }
                    break;
                case "My Tasks":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('View My Task');
                        localStorage.setItem("title", 'View My Task');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'View My Task';
                    }
                    break;
                case "Home":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('Home');
                        localStorage.setItem("title", 'Home');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Home';
                    }
                    break;
                case "My Mailings":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('My Mailing');
                        localStorage.setItem("title", 'My Mailing');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'My Mailing';
                    }
                    break;
                case "Setup My Goals":

                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('Setup Goal');
                        localStorage.setItem("title", 'Setup Goal');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Setup Goal';
                    }

                    break;
                case "New Appointment":
                    if (this.clpCompanyId == 1226 || this.clpCompanyId == 1321) {
                        if (!e?.ctrlKey) {
                            window.location.href = item?.path;
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            var w = window.open(item?.path);
                            w.document.title = 'New Appointment';
                        }
                    }
                    else {
                        if (!e?.ctrlKey) {
                            window.location.href = item?.path;
                            this.titleService.setTitle('New Appointment');
                            localStorage.setItem("title", 'New Appointment');
                        }
                        else {
                            var w = window.open(item?.path);
                            w.document.title = 'New Appointment';
                        }
                    }
                    break;
                case "Appointment List":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('Appointment List');
                        localStorage.setItem("title", 'Appointment List');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Appointment List';
                    }
                    break;
                case "Bulk Appointment Wizard":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('Bulk Appointment Wizard');
                        localStorage.setItem("title", 'Bulk Appointment Wizard');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Bulk Appointment Wizard';
                    }
                    break;
                case "New Recurring Appointment":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('New Recurring Appointment');
                        localStorage.setItem("title", 'New Recurring Appointment');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'New Recurring Appointment';
                    }
                    break;
                case "Dynamic Schedulers":
                    if (!e?.ctrlKey) {
                        window.location.href = item?.path;
                        this.titleService.setTitle('Dynamic Schedulers');
                        localStorage.setItem("title", 'Dynamic Schedulers');
                    }
                    else {
                        var w = window.open(item?.path);
                        w.document.title = 'Dynamic Schedulers';
                    }
                    break;
                default:
                    break;
            }
            this.currentSelectedId = "-1";
            this.currentSelectedParentId = "-1";
        }
    }

    toggleSearch() {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            this.toggleNavBar();
    }

    resetMenuState() {
        this.currentSelectedParentId = "-1";
        this.currentSelectedId = "-1";
        this.currentSelectedChildId = "-1";
    }

    getUniqueId(name: string) {
        if (name?.includes('.svg')) {
            var split_string = name?.split("/");
            var newitem = split_string[split_string.length - 1];
            newitem = newitem.replace(/ /g, "").toLowerCase();
            return newitem;
        }
        else
            return name?.replace(/ /g, "").toLowerCase();
    }

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

    isShowIcon(value: string) {
        if (value?.includes("cpgear"))
            return true;
        else
            return false;
    }

    goToCM() {
        this._router.navigate(['/cm-general'])
    }


    async filterTeamLoadGeneral() {
        await this.clubService.filterTeamLoadGeneral(this.encryptedUser, this.clpCompanyId, 0, false, this.isAI)
            .then(async (result: FilterTeam_Load_GeneralResponse) => {
                if (result) {
                    this.teamFilterDD = UtilityService.clone(result?.filterMappedTeams);
                    this.teamFilterDD.unshift({
                        teamCode: 0,
                        cLPCompanyID: this.clpCompanyId,
                        display: "Select",
                        sOrder: 0,
                        companyName: ""
                    });
                    if (this.userRole >= 3 || this.slurpyUserId > 0) {
                        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
                        //this.curTeamCode = 0
                        this.curTeamCode = this.curTeam?.teamCode > 0 ? this.curTeam?.teamCode : 0
                    }
                    else {
                        this.curTeam = result?.team
                        localStorage.setItem("curTeam", JSON.stringify(this.curTeam))
                        this._teamOfficeService.setCurTeam(this.curTeam)
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async siteSearch() {
        if (this.clubConfig?.clubId > 0) {
            await this.clubService.siteSearch(this.encryptedUser, 0, this.clubConfig?.clubId, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onChangeSite(siteId) {
        this.curSiteId = siteId
        this.curSite = this.sites.filter((item) => item?.siteID == siteId)[0]
        localStorage.setItem("curSite", JSON.stringify(this.curSite))
        this.clubService.setCurSite(this.curSite)

    }

    async onChangeTeam(teamCode) {
        this.curTeam = this.teamFilterDD?.filter((item) => item?.teamCode == teamCode)[0]
        this._teamOfficeService.setCurTeam(this.curTeam)
        localStorage.setItem("curTeam", JSON.stringify(this.curTeam))
        await this.CMClub_Get(this.curTeam?.teamCode)
        this.siteSearch()
    }

    newClub() {
        this.curTeamCode = 0
        localStorage.removeItem("curTeam")
        localStorage.removeItem("curSite")
        this._teamOfficeService.setCurTeam(null)
        this.clubService.setCurClubId(-1)
    }

    async CMClub_Get(teamCode) {
        if (!teamCode || teamCode == 0) {
            return
        }
        await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, teamCode, this.isAI).
            then(async (result: CMClubResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clubConfig = response?.clubConfig
                }

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }




    ngOnDestroy() {
        if (this.cmManagerSUb) {
            this.cmManagerSUb.unsubscribe()
        }
        if (this.routeNameSUb) {
            this.routeNameSUb.unsubscribe()
        }
        if (this.updateTeamSub) {
            this.updateTeamSub.unsubscribe()
        }
        if (this.curSiteSub) {
            this.curSiteSub.unsubscribe()
        }
    }
}
