<div class="custom-header">
    <div class="sub-header">
        <a (click)="mobileMenu();" class="menu-mobile"><i class="fa fa-bars"></i></a>
        <nav>
            <div>
                <img src="https://carefreeboats.com/wp-content/uploads/2024/06/cropped-CBC-Blue-270.png" style="width:130px;">
            </div>
        </nav>
        <div class="counter-panel">
            <img src="https://gen.sendtric.com/countdown/unjqlgsks9?to={{unixDate}}" style="display: block;" />
        </div>
    </div>
</div>


<div [hidden]="!isUserVerified">
    <mat-drawer-container>
        <mat-drawer class="mat-drawer">
            <div class="nav-listing mobile-menu" id="mobileMenu">
                <mat-nav-list>
                    <div class="icon-alignment-panel">
                        <table class="table">
                            <tr>
                                <td class="tblHeading" colspan="2">1. Verify Information</td>
                            </tr>
                            <tr [ngClass]="{'active': currentActive === '15', 'stagedone': navigation?.isDetails === true, 'disabled-row' : navigation?.isPaymentCollect}" (click)="showDivItem(stepper,'#tdIsSOCDoc','15',0)">
                                <td class="clsHeader">Details</td>
                                <td class="clsLink" id="tdIsSOCDoc"><i [ngClass]="navigation?.isDetails ? 'fa fa-check-square':'fa fa-square-o'"></i></td>
                            </tr>
                            <tr>
                                <td class="tblHeading" colspan="2">2. Sign Documents</td>
                            </tr>
                            <tr [ngClass]="{'active': currentActive === 'd'+item?.cMContractNavID, 'stagedone': item?.isAccepted === true, 'disabled-row' : navigation?.isPaymentCollect}" *ngFor="let item of contractNavList;let i=index" (click)="showDivItem(stepper,'#product','d'+item?.cMContractNavID,1,i)">
                                <td class="clsHeader">{{item?.cmProductName}}</td>
                                <td class="clsLink"><i [ngClass]="item?.isAccepted?'fa fa-check-square':'fa fa-square-o'"></i> </td>
                            </tr>
                            <tr>
                                <td class="tblHeading" colspan="2">3. Payment Setup</td>
                            </tr>
                            <tr [ngClass]="{'active': currentActive === ('p'+mf.cmMemberFeeID), 'stagedone': mf?.isDone === true, 'disabled-row' : navigation?.isPaymentCollect}" *ngFor="let mf of memberFees" (click)="showDivItem(stepper,'#tdFee','p'+mf.cmMemberFeeID,2)">
                                <td class="clsHeader">{{mf.cmFeeName}}</td>
                                <td class="clsLink" id="tdFee{{mf.cmMemberFeeID}}"><i [ngClass]="mf?.isDone?'fa fa-check-square':'fa fa-square-o'"></i></td>
                            </tr>
                            <tr>
                                <td class="tblHeading" colspan="2">4. Finish & {{pay_download ? 'Pay' : 'Download'}}</td>
                            </tr>
                            <tr [ngClass]="{'active': currentActive === '19', 'stagedone': navigation?.isReview === true, 'disabled-row' : disableReview() || navigation?.isPaymentCollect}" (click)="showDivItem(stepper,'#tdReview','19',3)">
                                <td class="clsHeader">Review</td>
                                <td class="clsLink" id="tdReview"><i [ngClass]="navigation?.isReview?'fa fa-check-square':'fa fa-square-o'"></i></td>
                            </tr>
                            <tr [ngClass]="{'active': currentActive === '20', 'stagedone': navigation?.isPaymentCollect === true, 'disabled-row' : !navigation?.isReview}" (click)="showDivItem(stepper,'#tdPay','20',3)">
                                <td class="clsHeader">{{pay_download ? 'Pay' : 'Download'}}</td>
                                <td class="clsLink" id="tdPay"><i [ngClass]="navigation?.isPaymentCollect ?'fa fa-check-square':'fa fa-square-o'"></i></td>
                            </tr>
                            <tr></tr>
                        </table>
                    </div>
                </mat-nav-list>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <mat-progress-bar mode="determinate" value="{{progressPreFetch}}" class="progress_style"></mat-progress-bar>
            <mat-horizontal-stepper labelPosition="bottom" #stepper class="alignment-all-section">
                <div class="nav-document">
                    <mat-step [stepControl]="contactForm">
                        <!--<ng-template matStepLabel>Verify Information</ng-template>-->
                        <div class="main-wrapper-section">
                            <div class="card-section">
                                <div class="card-panel">
                                    <div class="heading-text">
                                        <h4> Personal Information</h4>
                                        <span>Please verify the information we have for you is correct.</span>
                                    </div>
                                    <hr />
                                    <form [formGroup]="contactForm" (ngSubmit)="saveContact()" class="personal-information">
                                        <div class="form-area">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">First Name</label>
                                                        <input type="text" name="txtFirstName" formControlName="txtFirstName" autocomplete="off" id="txtFirstName" class="form-control">
                                                        <div *ngIf="contactForm.controls.txtFirstName.errors && (contactForm.controls.txtFirstName.touched || contactForm.controls.txtFirstName.dirty)">
                                                            <div class="login-error" *ngIf="contactForm.controls.txtFirstName.errors.required">First name is required. </div>
                                                            <div class="login-error" *ngIf="contactForm.controls.txtFirstName.errors.invalidName">Enter valid name. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Last Name</label>
                                                        <input type="text" name="txtLastName" formControlName="txtLastName" autocomplete="off" id="txtLastName" class="form-control">
                                                        <div *ngIf="contactForm.controls.txtLastName.errors && (contactForm.controls.txtLastName.touched || contactForm.controls.txtLastName.dirty)">
                                                            <div class="login-error" *ngIf="contactForm.controls.txtLastName.errors.required">Last name is required. </div>
                                                            <div class="login-error" *ngIf="contactForm.controls.txtLastName.errors.invalidName">Enter valid name. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Email</label>
                                                        <input type="text" name="txtEmail" formControlName="txtEmail" autocomplete="off" id="txtEmail" class="form-control">
                                                        <div *ngIf="contactForm.controls.txtEmail.errors && (contactForm.controls.txtEmail.touched || contactForm.controls.txtEmail.dirty)">
                                                            <div class="login-error" *ngIf="contactForm.controls.txtEmail.errors.required">Email is required. </div>
                                                            <div *ngIf="contactForm.controls.txtEmail.errors.pattern">Invalid Email</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Mobile</label>
                                                        <input type="text" name="txtMobilePhone" formControlName="txtMobilePhone" autocomplete="off" id="txtMobilePhone" class="form-control">
                                                        <div *ngIf="contactForm.controls.txtMobilePhone.errors && (contactForm.controls.txtMobilePhone.touched || contactForm.controls.txtMobilePhone.dirty)">
                                                            <div class="login-error" *ngIf="contactForm.controls.txtMobilePhone.errors.required">Mobile number is required. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Home Phone</label>
                                                        <input type="text" name="txtHomePhone" formControlName="txtHomePhone" autocomplete="off" id="txtHomePhone" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Address</label>
                                                        <input type="text" name="txtAddress" formControlName="txtAddress" autocomplete="off" value="Add 1" id="txtAddress" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">City</label>
                                                        <input type="text" name="txtCity" formControlName="txtCity" autocomplete="off" value="NEW YORK" id="txtCity" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Country</label>
                                                        <select class="form-control" formControlName="txtCountry" (valueChange)="onChangeCountry($event)">
                                                            <option *ngFor="let item of countryDD" [value]="item?.value">{{item?.text}}</option>
                                                        </select>
                                                        <div *ngIf="contactForm.controls.txtCountry.errors && (contactForm.controls.txtCountry.touched || contactForm.controls.txtCountry.dirty)">
                                                            <div class="login-error" *ngIf="contactForm.controls.txtCountry.errors.required">Please Select Country. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">State</label>
                                                        <select class="form-control" formControlName="txtState">
                                                            <option *ngFor="let item of stateList" [value]="item?.value">{{item?.text}}</option>
                                                        </select>
                                                        <div *ngIf="contactForm.controls.txtState.errors && (contactForm.controls.txtState.touched || contactForm.controls.txtState.dirty)">
                                                            <div class="login-error" *ngIf="contactForm.controls.txtState.errors.required">Please Select State. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Zip</label>
                                                        <input type="text" name="txtZip" formControlName="txtZip" autocomplete="off" value="247001" id="ContentPlaceHolder1_txtZip" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Driver's License</label>
                                                        <input type="text" name="dl" formControlName="dl" autocomplete="off" id="dl" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Date of Birth</label>
                                                        <input type="text" name="dl" formControlName="dob" autocomplete="off" id="dob" class="form-control">
                                                        <!--<kendo-datepicker formControlName="dob" [format]="format"></kendo-datepicker>-->
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Emergency Contact Name</label>
                                                        <input type="text" name="emgContactName" formControlName="emgContactName" autocomplete="off" id="emgContactName" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="fldtitle">Emergency Contact Phone</label>
                                                        <input type="text" name="txtEmgCntPhone" formControlName="txtEmgCntPhone" autocomplete="off" id="txtEmgCntPhone" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="">
                                            <button type="submit" class="primary-btn" [disabled]="disableDetailsSave">Save & Continue <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </mat-step>

                    <mat-step [stepControl]="productDocsGroup" [aria-labelledby]="navigation?.isDetails === false ? 'unselectable' : null">
                        <form [formGroup]="productDocsGroup" class="form-horizontal document-section">
                            <!--<ng-template matStepLabel>Sign Documents</ng-template>-->
                            <div class="main-wrapper-section" #outerContainerDocRef>
                                <div class="card-section">
                                    <div>
                                        <div class="card-panel">
                                            <div class="heading-text"><h4>{{contractList?.cmProductName}}</h4></div>
                                            <div class="submit-btn" *ngIf="!isSignaturePad">
                                                <button type="submit" *ngIf="!contractList?.isAccepted" [disabled]="showSpinner" (click)="productAccept(contractList?.cMContractNavID,contractList?.cmProductName)" class="btn btn-success">Accept & Sign <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                                <button type="submit" *ngIf="!contractList?.isAccepted" [disabled]="showSpinner" (click)="productAcceptAll()" class="btn btn-success agreement-btn">Accept & Sign All <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                                <button type="button" [disabled]="isDownloaded" (click)="downloadPreview()" class="primary-btn margin-left15">
                                                    Download Preview&nbsp;<i class="fa-solid fa-download"></i>&nbsp;
                                                    <i class="fa fa-spinner fa-spin" [hidden]="!isDownloaded"></i>
                                                </button>
                                            </div>
                                            <div>
                                                <iframe #iframeRef [srcdoc]="contractList?.htmlText | safeHtml" onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"></iframe>
                                                <!--<iframe #iframeRef [srcdoc]="contractList?.htmlText | safeHtml" ></iframe>-->
                                            </div>
                                            <div class="submit-btn" *ngIf="!isSignaturePad">
                                                <button type="submit" *ngIf="!contractList?.isAccepted" [disabled]="showSpinner" (click)="productAccept(contractList?.cMContractNavID,contractList?.cmProductName)" class="btn btn-success">Accept & Sign <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                                <button type="submit" *ngIf="!contractList?.isAccepted" [disabled]="showSpinner" (click)="productAcceptAll()" class="btn btn-success agreement-btn">Accept & Sign All <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                                <button type="button" [disabled]="isDownloaded" (click)="downloadPreview()" class="primary-btn margin-left15">
                                                    Download Preview&nbsp;<i class="fa-solid fa-download"></i>&nbsp;
                                                    <i class="fa fa-spinner fa-spin" [hidden]="!isDownloaded"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" id="finalSign" [hidden]="!isSignaturePad" style="background: #f2f5fa">
                                        <div id="divFinalSignature">
                                            <div class="margin-top10">
                                                <div class="row">
                                                    <div class="col-lg-7 col-md-12 col-sm-12">
                                                        <div class="sign-bg">
                                                            <signature-pad #sigpadFinal class="signature-pad" id="signFinal" [options]="signataurePadOptionsFinal" title="{{titleMsg}}" (onEndEvent)="signatureFinalOnEnd()"></signature-pad>
                                                            <div class="display-flex margin-top10" style="align-items:center">
                                                                <label for="signatureFinalName" style="text-align:left; min-width:150px;"><b>Enter your Full Name &nbsp;</b></label>
                                                                <input style="width:auto" class="form-control" type="text" maxlength="50" id="signatureFinalName" formControlName="initialFinalSignature" (keyup)="initialFinalOnPress($event)" [attr.disabled]="initialFinalDisabled ? '' : null" repHtmlTag />
                                                                <button class="primary-btn margin-left15" (click)="drawClearFinal()" title="Clear Signature Full">Clear Signature Full</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-12 col-sm-12">
                                                        <div class="sign-bg">
                                                            <signature-pad #sigpad2 class="signature-pad1" id="sign2" [options]="signataurePadOptionsFinal" title="{{titleMsg}}" (onEndEvent)="signature2OnEnd()"></signature-pad>
                                                            <!--<label class="col-form-label"><b>{{person}}</b> Signature</label>-->
                                                            <div class="display-flex margin-top10" style="align-items:center">
                                                                <label for="initial" style="text-align:left; min-width:90px;"><b>Enter Initial</b></label>
                                                                <input style="width:90px;" class="form-control" type="text" maxlength="3" id="initial" formControlName="initial" (keyup)="initialChange($event)" [attr.disabled]="initial2Disabled ? '' : null" repHtmlTag />
                                                                <button class="primary-btn margin-left15" style="min-width:auto" (click)="drawClearInitial()" title="Clear Signature Initial">Clear Sign</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accept-sign-btn">
                                                <div class="submit-btn">
                                                    <div style="justify-content:center;">
                                                        <button class="btn btn-success" [disabled]="showSpinner" (click)="productAcceptSign(contractList?.cMContractNavID, contractList?.cmProductName, false, true)">Accept & Sign <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                                        <button type="submit" *ngIf="!contractList?.isAccepted" [disabled]="showSpinner" (click)="productAcceptSign(contractList?.cMContractNavID, contractList?.cmProductName, true, true)" class="btn btn-success agreement-btn">Accept & Sign All <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                                        <button type="button" [disabled]="isDownloaded" (click)="downloadPreview()" class="primary-btn margin-left15">
                                                            Download Preview&nbsp;<i class="fa-solid fa-download"></i>&nbsp;
                                                            <i class="fa fa-spinner fa-spin" [hidden]="!isDownloaded"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="paymentForm" [aria-labelledby]="navigation?.isDetails === false ? 'unselectable' : null">
                        <form [formGroup]="paymentForm">
                            <!--<ng-template matStepLabel>Payment Configuration</ng-template>-->
                            <div class="main-wrapper-section">
                                <div class="card-section">
                                    <div class="card-panel" id="divFee{{memberFee?.cmMemberFeeID}}">
                                        <div class="heading-text">
                                            <div class="sign-up-fee-header">
                                                <div>
                                                    <div class="subject" *ngIf="memberFee?.feeTotal > 0"><h5>The <b>{{memberFee?.cmFeeName}}</b> is <b>{{memberFee?.feeBase | currency}}</b> <span *ngIf="memberFee?.isTaxable"> plus applicable sales tax </span>.</h5></div>
                                                    <div class="subject" *ngIf="memberFee?.feeTotal == 0"><h5>We need to keep a card on file for <b>{{memberFee?.cmFeeName}}</b></h5></div>
                                                    <h6 *ngIf="memberFee?.feeTotal > 0">How would you like to pay for this?</h6>
                                                    <h6 *ngIf="memberFee?.feeTotal == 0">Note: These vary from month to month.</h6>
                                                </div>
                                                <div class="total-price" *ngIf="memberFee?.feeTotal>0">
                                                    <span class="total-price-label">Total with Tax</span>
                                                    <h4>{{memberFee?.feeTotal | currency}}</h4>
                                                </div>
                                                <div class="text-right outstanding-amount">
                                                    <label>Outstanding Amount</label>
                                                    <h4>{{displayBeingPaid}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sign-up-middle">
                                            <div class="sign-up-layout">
                                                <div class="row">
                                                    <div class="col-sm-12" formArrayName="paymentMethods">
                                                        <div>
                                                            <div class="row margin-top10" [ngClass]="i>0 ?'label-mark':''" *ngFor="let payfee of paymentForm.get('paymentMethods')['controls']; let i=index" [formGroupName]="i">
                                                                <div class="col-sm-5">
                                                                    <label class="">Payment Source</label>
                                                                    <!--<div><input type="hidden" formControlName="cMPaymentItemID" /></div>-->
                                                                    <div class="">
                                                                        <div class="">
                                                                            <select class="form-control" formControlName="cMPaymentTxnID" (change)="onPaySourceChange($event,i, payfee)">
                                                                                <option value="-1">Select Payment Method</option>
                                                                                <option *ngFor="let pay of paySourceDD" [value]="pay.cMPaymentTxnID">{{pay.paymentMethodSummary}}<span *ngIf="payType == 3 || payType == 4"> ({{contactDetails?.firstName}} {{contactDetails?.lastName}})</span></option>
                                                                                <option value="0" class="add-payment-method">+ Add New Payment Method</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <div class="display-flex">
                                                                        <div *ngIf="memberFee?.feeTotal>0">
                                                                            <label>Enter Amount</label>
                                                                            <input class="form-control" [readonly]="memberFee?.feeTotal == 0" formControlName="signupFee" (change)="holdPayState()" (keyup)="onChangeAmount(true,i, payfee)" appCurrency style="text-align : right" />
                                                                        </div>
                                                                        <div>
                                                                            <a *ngIf="paymentMethods?.length > 1 && !payfee.get('isPaymentDone')?.value" style="cursor:pointer;" title="Remove Payment Source" (click)="cMPaymentItem_Delete(payfee, i); "><i class="material-icons" style="color:#dc3545;">remove_circle</i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="split-btn" *ngIf="memberFee?.feeTotal > 0 && paymentTrxs?.length > 0 && memberFee?.isPaymentSplitable">
                                                    <button class="btn black-btn" (click)="addPaymentMethod()">Split</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="save-footer">
                                            <button class="primary-btn" [disabled]="paymentTrxs?.length == 0" (click)="saveMemberPayment(memberFee?.cmFeeName,memberFee?.cmMemberFeeID)">Save <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                            <button class="primary-btn margin-left15" [hidden]="hideMemberPaymentAll()" (click)="saveMemberPaymentAll()">Apply to All Fees <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="paymentCollection" [aria-labelledby]="navigation?.isDetails === false ? 'unselectable' : null">
                        <form [formGroup]="paymentCollection">
                            <!--<ng-template matStepLabel>Payment</ng-template>-->
                            <div class="main-wrapper-section">
                                <div class="card-section">
                                    <div class="card-panel" id="divReview" [hidden]="currentActive != '19'">
                                        <div class="heading-text">
                                            <div class="sign-up-fee">
                                                <h4>Review</h4>
                                            </div>
                                        </div>
                                        <div class="sign-up-middle">
                                            <div class="sign-up-layout" *ngFor="let pr of paymentReview">
                                                <div class="row">
                                                    <iframe [srcdoc]="pr?.html | safeHtml" onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="submit-btn">
                                            <button type="submit" [hidden]="navigation?.isReview" (click)="saveReview()" class="btn btn-success">Accept &amp; Sign <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                                        </div>
                                    </div>
                                    <div class="card-panel" id="divPay" [hidden]="currentActive != '20'">
                                        <div class="heading-text">
                                            <div class="sign-up-fee">
                                                <h4>Payment details</h4>
                                            </div>
                                        </div>

                                        <div class="">
                                            <div class="sign-up-layout" *ngFor="let item of paymentChargeItems">
                                                <div class="success-design" [ngClass]="item?.isPaymentDone ? 'done-payment' : ''" [hidden]="userId == 0 && (item?.paymentType > 2 && !item?.isPaymentDone)">
                                                    <div class="credit-card">
                                                        <div class="row">
                                                            <div class="col-sm-4">
                                                                <label *ngIf="item?.paymentType == 1">Credit Card</label>
                                                                <div style="max-width: 300px;">
                                                                    <h5>{{item?.paymentMethodSummary}}</h5>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <label>Amount to Charge</label>
                                                                <div>
                                                                    <h6><b>{{item?.Total | currency}}</b></h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2" *ngIf="item?.isPaymentDone">
                                                                <div class="text-center">
                                                                    <img src="../../../assets/img/paymentSuccess.gif" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-4">
                                                            </div>
                                                            <div class="col-sm-8 payment-details">
                                                                <table class="">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Fee</th>
                                                                            <th>Base</th>
                                                                            <th>Tax</th>
                                                                            <th>Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let sb of item?.subFees">
                                                                            <td><b>{{sb?.feeName}}</b></td>
                                                                            <td>{{sb?.feeBase | currency}}</td>
                                                                            <td>{{sb?.feeTax | currency}}</td>
                                                                            <td>{{sb?.feeTotal | currency}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="save-footer" style="border:none;">
                                                        <button class="primary-btn" *ngIf="!item?.isPaymentDone && !chargeFailed" [disabled]="showSpinner" (click)="onClickCharge(item)">{{item?.paymentType == 1 ? 'Charge' : 'Collected'}} {{item?.Total | currency}}</button>
                                                        <button class="primary-btn" *ngIf="!item?.isPaymentDone && chargeFailed" [disabled]="showSpinner" (click)="onClickCharge(item)">Retry {{item?.Total | currency}}</button>
                                                        <button class="primary-btn margin-left15" *ngIf="!item?.isPaymentDone && item?.paymentType == 1 && chargeFailed" [disabled]="showSpinner" (click)="onEditCard(item)">Update Card</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="success-payment" *ngIf="navigation?.isPaymentCollect">
                                            <h3>Thank you!</h3>
                                            <button class="primary-btn" [hidden]="isDownload" [disabled]="!isDownload" style="color:black">
                                                Please wait. Your contract will be ready soon! &nbsp;
                                                <i class="fa fa-spinner fa-spin"></i>
                                            </button>
                                            <button class="primary-btn" [hidden]="!isDownload" [disabled]="isDownloaded" (click)="downloadContract()">
                                                Download Contract &nbsp;<i class="fa-solid fa-download"></i>
                                                <i class="fa fa-spinner fa-spin" [hidden]="!isDownloaded"></i>
                                            </button>

                                        </div>

                                        <div class="success-payment" *ngIf="contactPaymentComplete">
                                            <h3>Thank you!</h3>
                                            <h6>Once the remaining initial fees are collected, your contract will be available.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                </div>
            </mat-horizontal-stepper>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

<div class="payment-method">
    <div id="PaymentMethodModal" class="modal fade" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Payment Method</h4>
                    <button type="button" class="close" (click)="hideModal()">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="payment-layout">

                        <div class="form-group">
                            <label>Payment Type</label>
                            <div class="margin-top10" style="display:flex; align-items:center" *ngIf="contractSetting?.isEntryCC">
                                <input type="radio" id="test2" value="card" name="rdPayType" [checked]="payType == 'card'" (change)="showHidePaytype('card')">
                                <div class="margin-left15">
                                    <label for="test2" style="margin-bottom:0px"><img style="margin-right:10px" src="..\assets\img\credit_card-icon.svg" />Credit or Debit Card</label>
                                </div>
                            </div>
                            <!--<div class="margin-top10" style="display:flex; align-items:center" *ngIf="contractSetting?.isEntryACH">
                                <input type="radio" id="test1" value="bank" name="rdPayType" [checked]="payType == 'bank'" (change)="showHidePaytype('bank')">
                                <div class="margin-left15">
                                    <label for="test1" style="margin-bottom:0px"> <img style="margin-right:10px" src="..\assets\img\bank-icon.svg" />Bank Account (ACH)</label>
                                </div>
                            </div>-->
                            <div class="margin-top10" style="display:flex; align-items:center" *ngIf="contractSetting?.isEntryCash">
                                <input type="radio" id="test3" value="cash" name="rdPayType" [checked]="payType == 'cash'" (change)="showHidePaytype('cash')">
                                <div class="margin-left15">
                                    <label for="test3" style="margin-bottom:0px"><img style="margin-right:10px" src="..\assets\img\cash-icon.svg" />Cash</label>
                                </div>
                            </div>
                            <div class="margin-top10" style="display:flex; align-items:center" *ngIf="contractSetting?.isEntryCheck">
                                <input type="radio" id="test4" value="check" name="rdPayType" [checked]="payType == 'check'" (change)="showHidePaytype('check')">
                                <div class="margin-left15">
                                    <label for="test4" style="margin-bottom:0px"><img style="margin-right:10px" src="..\assets\img\check-icon.svg" />Physical Check</label>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <form [formGroup]="ccForm">
                            <div *ngIf="payType == 'card'" class="credit-card">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label [ngClass]="{'has-error': creditcardform.creditCard.errors && (creditcardform.creditCard.touched || creditcardform.creditCard.dirty)}">Credit Card Number <span>*</span></label>
                                        <div>
                                            <input #creditCard formControlName="creditCard" type="tel" class="form-control" autocomplete="creditCard" ccNumber>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <label [ngClass]="{'has-error': creditcardform.exp.errors && (creditcardform.exp.touched || creditcardform.exp.dirty)}">Expiration<span>*</span></label>
                                        <div>
                                            <input formControlName="exp" type="text" class="form-control" placeholder="MM/YY" mask="00/00" autocomplete="expirationDate" (keyup)="verifyExp($event)">
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <label class="control-label" for="cvc" [ngClass]="{'has-error': creditcardform.cvc.errors && (creditcardform.cvc.touched || creditcardform.cvc.dirty)}"><b>CVC<span>*</span></b></label>
                                        <div>
                                            <input formControlName="cvc" type="tel" class="form-control" autocomplete="off" ccCvc maxlength="4">
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="control-label" for="zipcode" [ngClass]="{'has-error': creditcardform.zipcode.errors && (creditcardform.zipcode.touched || creditcardform.zipcode.dirty)}"><b>Zip<span>*</span></b></label>
                                        <div>
                                            <input formControlName="zipcode" type="text" minLength="5" maxLength="7" class="form-control" (keyup)="changeUpperCase($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="row margin-top20">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.name.errors && (creditcardform.name.touched || creditcardform.name.dirty)}">Name on Card<span>*</span></label>
                                            <input formControlName="name" class="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.phone.errors && (creditcardform.phone.touched || creditcardform.phone.dirty)}">Billing Phone Number<span>*</span></label>
                                            <input class="form-control" formControlName="phone" />
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.email.errors && (creditcardform.email.touched || creditcardform.email.dirty)}">Billing Email<span>*</span></label>
                                            <input class="form-control" formControlName="email" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.address.errors && (creditcardform.address.touched || creditcardform.address.dirty)}">Billing Address<span>*</span></label>
                                            <input class="form-control" formControlName="address" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.city.errors && (creditcardform.city.touched || creditcardform.city.dirty)}">City<span>*</span></label>
                                            <input class="form-control" formControlName="city" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.country.errors && (creditcardform.country.touched || creditcardform.country.dirty)}">Country<span>*</span></label>
                                            <kendo-combobox [data]="countryDD"
                                                            class="form-control"
                                                            [valuePrimitive]="true"
                                                            [kendoDropDownFilter]="filterSettings"
                                                            [filterable]="true"
                                                            textField="text"
                                                            formControlName="country"
                                                            valueField="value"
                                                            (valueChange)="onChangeCountry($event)">
                                            </kendo-combobox>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.state.errors && (creditcardform.state.touched || creditcardform.state.dirty)}">State<span>*</span></label>
                                            <kendo-combobox [data]="stateList"
                                                            class="form-control"
                                                            [valuePrimitive]="true"
                                                            [kendoDropDownFilter]="filterSettings"
                                                            [filterable]="true"
                                                            formControlName="state"
                                                            textField="text"
                                                            valueField="value">
                                            </kendo-combobox>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>

                        <form [formGroup]="bankForm">
                            <div *ngIf="payType == 'bank'">
                                <h6>Bank Account Details Form</h6>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.routingNumber.errors && (bankform.routingNumber.touched || bankform.routingNumber.dirty)}">ABA/Routing Number</label>
                                            <input class="form-control" formControlName="routingNumber" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.accountNumber.errors && (bankform.accountNumber.touched || bankform.accountNumber.dirty)}">Account Number</label>
                                            <input class="form-control" formControlName="accountNumber" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Account Type</label>
                                            <div><input type="hidden" formControlName="cMPaymentItemID" /></div>
                                            <select class="form-control" formControlName="accountType">
                                                <option value="checking">Personal Checking</option>
                                                <option value="savings">Personal Savings</option>
                                                <option value="businesschecking">Business Checking</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.institution.errors && (bankform.institution.touched || bankform.institution.dirty)}">Financial Institution</label>
                                            <input class="form-control" formControlName="institution" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3" [hidden]="bankform.accountType.value == 'businesschecking'">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.accountName.errors && (bankform.accountName.touched || bankform.accountName.dirty)}">Account Holder's First and Last Name</label>
                                            <input class="form-control" formControlName="accountName" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3" [hidden]="bankform.accountType.value != 'businesschecking'">
                                        <div class="form-group">
                                            <label>Business Name on Account</label>
                                            <input class="form-control" formControlName="businessName" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.accountPhone.errors && (bankform.accountPhone.touched || bankform.accountPhone.dirty)}">Account Holder's Phone</label>
                                            <input class="form-control" formControlName="accountPhone" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.accountEmail.errors && (bankform.accountEmail.touched || bankform.accountEmail.dirty)}">Account Holder's Email</label>
                                            <input class="form-control" formControlName="accountEmail" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.accountAddress.errors && (bankform.accountAddress.touched || bankform.accountAddress.dirty)}">Billing Address</label>
                                            <input class="form-control" formControlName="accountAddress" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.accountCity.errors && (bankform.accountCity.touched || bankform.accountCity.dirty)}">City</label>
                                            <input class="form-control" formControlName="accountCity" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': bankform.accountZip.errors && (bankform.accountZip.touched || bankform.accountZip.dirty)}">Zip</label>
                                            <input class="form-control" formControlName="accountZip" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>State</label>
                                            <kendo-combobox [data]="stateList"
                                                            class="form-control"
                                                            [valuePrimitive]="true"
                                                            [kendoDropDownFilter]="filterSettings"
                                                            [filterable]="true"
                                                            formControlName="accountState"
                                                            textField="text"
                                                            valueField="value">
                                            </kendo-combobox>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Country</label>
                                            <kendo-combobox [data]="countryDD"
                                                            class="form-control"
                                                            [valuePrimitive]="true"
                                                            [kendoDropDownFilter]="filterSettings"
                                                            [filterable]="true"
                                                            textField="text"
                                                            formControlName="accountCountry"
                                                            valueField="value"
                                                            (valueChange)="onChangeCountry($event)">
                                            </kendo-combobox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div *ngIf="payType == 'cash'">
                            <h5>Cash</h5>
                        </div>

                        <div *ngIf="payType == 'check'">
                            <h5>Physical Check</h5>
                        </div>

                        <div class="modal-footer" style="justify-content:flex-start">
                            <div class="form-group">
                                <button class="btn primary-btn" [hidden]="navigation?.isReview" (click)="onVerify()" [disabled]="disableOnVerify() || isPayment">Save <i *ngIf="isPayment" class="fa fa-spinner fa-spin"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Product Modal-->
<div id="deletePayConfirmation" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <h6 class="modal-title">Are you sure you want to delete this card?</h6>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success" (click)="deleteCard()">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>


<div class="verify-panel" [hidden]="isUserVerified">
    <form class="form-horizontal" [formGroup]="verifyUserForm">
        <div class="modal-content">
            <div class="modal-header modal-header-sub">
                <h5 class="modal-title">Welcome, {{cFirstName}}. First, let's make sure it's you.</h5>
                <h6>Please enter the email or mobile you gave to {{uName}}.</h6>
            </div>
            <div>
                <div class="form-group col-sm-12 margin-top15">
                    <label class="control-label" for="txtMsg">Email</label>
                    <div>
                        <input type="text" name="txtEmail" formControlName="email" autocomplete="off" id="txtEmail" class="form-control">
                        <div *ngIf="verifyUserForm.controls.email.errors && (verifyUserForm.controls.email.touched || verifyUserForm.controls.email.dirty)">
                            <div class="login-error" *ngIf="verifyUserForm.controls.email.errors.pattern">Invalid Email</div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-12">
                    <label class="control-label" for="txtMsg">Mobile</label>
                    <div>
                        <input type="text" name="txtMobilePhone" formControlName="mobile" autocomplete="off" id="txtMobilePhone" class="form-control" [mask]="mobile_mask">
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center modal-footer-sub">
                <div class="row">
                    <div class="col-sm-12">
                        <button style="min-width: 200px;" type="submit" class="btn btn-success" (click)="verifyUser()">Begin</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="payment-method">
    <div id="editCardModal" class="modal fade" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Update Card</h4>
                    <button type="button" class="close" (click)="hideEditCardModal()">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="payment-layout">

                        <form [formGroup]="ccForm">
                            <div class="credit-card">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label [ngClass]="{'has-error': creditcardform.creditCard.errors && (creditcardform.creditCard.touched || creditcardform.creditCard.dirty)}">Credit Card Number <span>*</span></label>
                                        <div>
                                            <input #creditCard formControlName="creditCard" type="tel" class="form-control" autocomplete="creditCard" ccNumber>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <label [ngClass]="{'has-error': creditcardform.exp.errors && (creditcardform.exp.touched || creditcardform.exp.dirty)}">Expiration<span>*</span></label>
                                        <div>
                                            <input formControlName="exp" type="text" class="form-control" placeholder="MM/YY" mask="00/00" autocomplete="expirationDate" (keyup)="verifyExp($event)">
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <label class="control-label" for="cvc" [ngClass]="{'has-error': creditcardform.cvc.errors && (creditcardform.cvc.touched || creditcardform.cvc.dirty)}"><b>CVC<span>*</span></b></label>
                                        <div>
                                            <input formControlName="cvc" type="tel" class="form-control" autocomplete="off" ccCvc maxlength="4">
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="control-label" for="zipcode" [ngClass]="{'has-error': creditcardform.zipcode.errors && (creditcardform.zipcode.touched || creditcardform.zipcode.dirty)}"><b>Zip<span>*</span></b></label>
                                        <div>
                                            <input formControlName="zipcode" type="text" minLength="5" maxLength="7" class="form-control" (keyup)="changeUpperCase($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="row margin-top20">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.name.errors && (creditcardform.name.touched || creditcardform.name.dirty)}">Name on Card<span>*</span></label>
                                            <input formControlName="name" class="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.phone.errors && (creditcardform.phone.touched || creditcardform.phone.dirty)}">Billing Phone Number<span>*</span></label>
                                            <input class="form-control" formControlName="phone" />
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.email.errors && (creditcardform.email.touched || creditcardform.email.dirty)}">Billing Email<span>*</span></label>
                                            <input class="form-control" formControlName="email" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.address.errors && (creditcardform.address.touched || creditcardform.address.dirty)}">Billing Address<span>*</span></label>
                                            <input class="form-control" formControlName="address" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.city.errors && (creditcardform.city.touched || creditcardform.city.dirty)}">City<span>*</span></label>
                                            <input class="form-control" formControlName="city" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.country.errors && (creditcardform.country.touched || creditcardform.country.dirty)}">Country<span>*</span></label>
                                            <kendo-combobox [data]="countryDD"
                                                            class="form-control"
                                                            [valuePrimitive]="true"
                                                            [kendoDropDownFilter]="filterSettings"
                                                            [filterable]="true"
                                                            textField="text"
                                                            formControlName="country"
                                                            valueField="value"
                                                            (valueChange)="onChangeCountry($event)">
                                            </kendo-combobox>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label [ngClass]="{'has-error': creditcardform.state.errors && (creditcardform.state.touched || creditcardform.state.dirty)}">State<span>*</span></label>
                                            <kendo-combobox [data]="stateList"
                                                            class="form-control"
                                                            [valuePrimitive]="true"
                                                            [kendoDropDownFilter]="filterSettings"
                                                            [filterable]="true"
                                                            formControlName="state"
                                                            textField="text"
                                                            valueField="value">
                                            </kendo-combobox>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>

                        <div class="modal-footer" style="justify-content:flex-start">
                            <div class="form-group">
                                <button class="btn primary-btn"  (click)="onUpdateCreditCard()" [disabled]="disableOnVerify()">Save <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
