import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/generic-response.model';
import { Product, ProductDDResponse, ProductResponse, RateSheetDescription, SimpleProductResponse } from '../models/product.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

    private baseUrl: string;
    private api: string = 'api/Product';


    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async cMProductGetUnused(encryptedUser: string, clpCompanyId: number, clubId : number, siteId : number, isAI: boolean): Promise<SimpleProductResponse | void> {
        const a = await this.httpClient
            .get<SimpleProductResponse>(`${this.baseUrl}/CMProduct_GetUnused/${clpCompanyId}/${clubId}/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clubId - " + clubId + "," + "siteId - " + siteId, encryptedUser, "ProductService", "cMProductGetUnused"); });
        return a;
    }

    async CMProduct_Get(encryptedUser: string, clpCompanyId: number, clubId: number, siteId: number, isAI: boolean): Promise<ProductResponse | void> {
        const a = await this.httpClient
            .get<ProductResponse>(`${this.baseUrl}/CMProduct_Get/${clpCompanyId}/${clubId}/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clubId - " + clubId + "," + "siteId - " + siteId, encryptedUser, "ProductService", "cMProductGet"); });
        return a;
    }

    async cMProductDelete(encryptedUser: string, productId: number, isAI: boolean): Promise<ProductResponse | void> {
        const a = await this.httpClient
            .get<ProductResponse>(`${this.baseUrl}/CMProduct_Delete/${productId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "productId - " + productId, encryptedUser, "ProductService", "CMProductDelete"); });
        return a;
    }

    async cMProductUpdate(encryptedUser: string, product: Product, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMProduct_Update?isAI=${isAI}`, product, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - "+ encryptedUser, encryptedUser, "ProductService", "cMProductUpdate"); });
        return a;
    }

    async cMProductaveRateSheetDescription(encryptedUser: string, rateSheetDescription: RateSheetDescription[], isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMProduct_SaveRateSheetDescription?isAI=${isAI}`, rateSheetDescription, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "ProductService", "cMProductaveRateSheetDescription"); });
        return a;
    }

    async cMProductPublishToDraft(encryptedUser: string, siteId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMProduct_PublishToDraft/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "siteId - " + siteId, encryptedUser, "ProductService", "cMProductPublishToDraft"); });
        return a;
    }

    async cMProductDraftToPublish(encryptedUser: string, siteId: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMProduct_DraftToPublish/${siteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "siteId - " + siteId, encryptedUser, "ProductService", "cMProductDraftToPublish"); });
        return a;
    }

    async cMProductclone(encryptedUser: string, clpcompanyId: number, clubId : number, siteId: number, toSiteId : number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMProductclone/${clpcompanyId}/${clubId}/${siteId}/${toSiteId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "siteId - " + siteId, encryptedUser, "ProductService", "cloneProducts"); });
        return a;
    }
}
