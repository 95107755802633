import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BCMClassCode, IntDropDownItemListResponse } from '../models/bcmclass-codes.model';
import { TeamCodeResponseIEnumerable, } from '../models/clpuser.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class BCMClassCodesService {

    private baseUrl: string;
    private api: string = 'api/BCMClassCodes';

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async class1CodeGetList(encryptedUser: string, clpCompanyId: number, isAI: boolean): Promise<IntDropDownItemListResponse | void> {
        const a = await this.httpClient
            .get<IntDropDownItemListResponse>(`${this.baseUrl}/Class1Code_GetList/${clpCompanyId}/${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "BCMClassCodesService", "class1CodeGetList"); });
        return a;
    }

}
