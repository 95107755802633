<div class="left-side-panel">
  <div class="align-panel">
    <h5>{{curTeam?.display}}</h5>
  </div>
  <ul>
      <li [ngClass]="{'active' : routeName == 'cm-dashboard'}"><a (click)="goToLink('cm-dashboard')">Dashboard</a></li>
      <li [ngClass]="{'active' : routeName == 'cm-general'}"><a (click)="goToLink('cm-general')">General</a></li>
      <li [ngClass]="{'active' : routeName == 'cm-locations'}"><a (click)="goToLink('cm-locations')">Sites</a></li>
      <li [ngClass]="{'active' : routeName == 'site-fields'}"><a (click)="goToLink('site-fields')">Site Field Names</a></li>
      <li [ngClass]="{'active' : routeName == 'cm-payment'}"><a (click)="goToLink('cm-payment')">Payment Processing</a></li>
      <li [ngClass]="{'active' : routeName == 'cm-contract-mapping'}"><a (click)="goToLink('cm-contract-mapping')">Contract Mapping</a></li>
      <li [ngClass]="{'active' : routeName == 'cm-contracts'}"><a (click)="goToLink('cm-contracts')">Contracts</a></li>
      <li [ngClass]="{'active' : routeName == 'cm-employees'}"><a (click)="goToLink('cm-employees')">Employees</a></li>
      <li><a href="javascript:void()">Vessels</a></li>
  </ul>
</div>
