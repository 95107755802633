import { CdkStepper } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ElementRef, HostListener, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SignaturePad } from 'angular2-signaturepad';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CreditCard, CreditCardValidators } from 'angular-cc-library';

import { CLPUser, TeamCodes, TeamResponse, UserResponse } from '../../models/clpuser.model';
import { CMContractNav, CMContractNavListResponse, CMNavigation, CMNavigationResponse, Contact, ContactDetails, CMContract, ContractResponse, eCMNavigation_Update_Type, CC, Bank, CheckCash, ContractAcceptation, eContractFeeType, CMPaymentTxnResponse, CMPaymentTxn, CMContractFeeListResponse, CMContractFee, CMMemberFeeListResponse, CMMemberFee, PaymentReviewListResponse, PaymentReview, CMPaymentItem, CMPaymentItem_Charge, PayListResponse, Pay, ContractPdfConvertContainer, RetrieveCardResponse, Card, CMContractLog, CMSession } from '../../models/cm-contract';
import { DropDownItem, SimpleResponse, UserDD } from '../../models/generic-response.model';
import { CMContractSetting, CMContractSettingResponse } from '../../models/cm-contract';
import { RoleFeaturePermissions } from '../../models/role-container.model';

import { CMContractService } from '../../services/cm-contract.service';
import { CountryStateService } from '../../services/country-state.service';
import { NotificationService } from '../../services/notification.service';
import { PaymentService } from '../../services/payment.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { Title } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { CountryPlaceholder } from '../../models/country-state.model';
import { CountryCode } from 'libphonenumber-js';

//For jquery
declare var $: any;

@Component({
    selector: 'app-cm-contract-client',
    templateUrl: './cm-contract-client.component.html',
    styleUrl: './cm-contract-client.component.css'
})
export class CmContractClientComponent implements OnDestroy, AfterViewInit {
    @ViewChild('iframeRef') iframeRef!: ElementRef<HTMLIFrameElement>;
    @ViewChild('outerContainerDocRef') outerContainerDocRef!: ElementRef<HTMLIFrameElement>;
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    userAI: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    teamFilterDD: TeamCodes[];
    userList: UserDD[] = [{
        value: 0,
        text: "Select",
    }];
    clubConfigForm: FormGroup

    teamCodeDetails: TeamResponse;

    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };

    showSpinner: boolean;
    isDownload: boolean = false;
    isDownloaded: boolean = false;
    isPreviewDownloaded: boolean = false;
    selectedUser: string;
    curTeam: TeamCodes = <TeamCodes>{};
    curTeamCode: number = 0
    isBtnDisabled: boolean;
    private teamSub: Subscription;
    private clubIdSub: Subscription;
    submitted: boolean;
    clpCompanyId: number;
    slurpyUserId: number;
    userRole: number;
    isAI: boolean = false;
    @ViewChild('stepper') stepper: CdkStepper;
    @ViewChild('sigpadFinal', { static: true }) signaturePadFinal: SignaturePad;
    @ViewChild('sigpad2', { static: true }) signaturePad2: SignaturePad;
    contactForm: FormGroup;
    productDocsGroup: FormGroup;
    countryDD: CountryPlaceholder[];
    stateList: DropDownItem[];

    countryCode: string = "US";
    stateCode: string = "";
    sel_brand: string = "unknown";

    public format: FormatSettings = {
        displayFormat: 'MM/dd/yyyy',
        inputFormat: 'MM/dd/yy'
    };
    public signataurePadOptionsFinal = {
        'minWidth': 1,
        'maxWidth': 2,
        'minHeight': 150,
        'maxHeight': 200,
        penColor: 'black',
        backgroundColor: '#fff',
    };
    contactId: number;
    contactEmail: string;
    vendorCustomerId: string;
    memberFeeid: number = 0;
    contactDetails: any = [];
    contacts: ContactDetails[];
    contact: ContactDetails = <ContactDetails>{};
    contractSetting: CMContractSetting = <CMContractSetting>{};
    contactData: Contact = <Contact>{};
    contractId: number = 0;

    userId: number;
    contactPaymentComplete: boolean;

    contracts: CMContract[];
    contract: CMContract;
    contractNavList: CMContractNav[];
    contractList = {} as CMContractNav;
    navigation = {} as CMNavigation;
    currentActive: string = '15';
    isSocDone: boolean = false;
    isFinalSignDone: boolean = false;
    isProductConfig: boolean = false;
    isCollection: boolean = false;
    paymentForm: FormGroup;
    paymentCollection: FormGroup;
    memberFees: CMMemberFee[];
    memberFee = {} as CMMemberFee;
    siteId: number = 0;
    memberId: number = 0;
    stepId: string = "";
    productDoc: string = "The document is loading. Please wait...";
    docTitle: string = '';
    isProductDoc: boolean = false;
    person: string;
    signature: string = '';
    initialFinalDisabled: boolean = false;
    initial2Disabled: boolean = false;
    titleMsg = '';
    isExistFinal: boolean = false;
    base64StrFinal: string;

    isShowCCForm: boolean;
    isShowCheckForm: boolean;
    progressPreFetch: number = 0;
    payType: string = "";
    docId: number = 0;
    unixDate: number;
    isSignaturePad: boolean = true;
    cFirstName: string = '';
    uName: string = '';


    card: CreditCard;
    @ViewChild('creditCard', { static: true }) creditCard_element;

    ccForm: FormGroup;
    bankForm: FormGroup;
    contractAcceptation = {} as ContractAcceptation;
    paymentTrxs: CMPaymentTxn[] = [];
    paymentTrxsFinal: CMPaymentTxn[] = [];
    transactionId: number = 0;
    totalBeingPaid: number = 0.00;
    displayBeingPaid: string = '';
    isPayment: boolean = false;
    paymentReview: PaymentReview[];
    delTransId: number = 0;
    allCardPayments: any = []
    isAllPayDone: boolean = false
    cmPaymentItem = {} as CMPaymentItem;
    contractFees: CMContractFee[];
    cmPaymentItems: CMPaymentItem[]
    outStandingAmount: number;
    contractNavCopy: CMContractNav[] = []
    paymentState: any = []
    lastPaymentId: string = ''
    paymentChargeItems: Pay[];
    paySourceDD: any[] = [];
    isPaySetup: boolean = true

    disableDetailsSave: boolean = false;
    mobile_mask: string = '(000) 000-0000';

    isUserVerified = false
    verifyUserForm: FormGroup
    placeHolder: string = "";
    isSubscribe: any;
    contractUrl: string = '';
    contractPdfConvertContainer: ContractPdfConvertContainer;

    isAllDocAccepted: boolean = false
    toggleMobileMenu: boolean = false;

    lastcMPaymentTxnID: number = -1;
    chargeFailed: boolean = false;
    editCardPayTxnId: number;
    editCardDetails: Card;
    isForPreviewDownload: boolean = false;

    pay_download: boolean = true;
    sessionId: string = '';
    startF2F: boolean = true;
    is_client: boolean = false;
    dlFront: File;
    dlBack: File;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".jpeg"],
        maxFileSize: 5242880
    };
    placeholder: string = ''
    constructor(private _localService: LocalService,
        private _utilityService: UtilityService,
        private fb: FormBuilder,
        private _notifyService: NotificationService,
        private _globalService: GlobalService,
        private _countryStateService: CountryStateService,
        private _contractService: CMContractService,
        private _paymentService: PaymentService,
        private _route: ActivatedRoute,
        private renderer: Renderer2,
        private titleService: Title,
        public datepipe: DatePipe,
    ) {
        this.titleService.setTitle('Contract');
        this._localService.setShowMenu(false);
        this._localService.setIsContract(false);
        this._localService.setIsClientContract(true);
        this._localService.setRouteName("cm-contract-client");
        this._localService.setIsCmManager(false);
    }

    ngAfterViewInit(): void {
        this.resizeCanvas();
    }

    ngOnInit() {

        this.contactForm = this.prepareContactForm();
        this.paymentForm = this.preparePaymentForm();
        this.productDocsGroup = this.prepareDocForm();
        this.paymentCollection = this.prepareCollectionForm();
        this.ccForm = this.prepareCCForm();
        this.bankForm = this.prepareBankForm();
        this.verifyUserForm = this.prepareVerifyUserForm();
        this.ccForm.reset();
        this.bankForm.reset();

        //this.addPaymentMethod();
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;

        this._route.paramMap.subscribe(queryParams => {
            this.contactId = +queryParams.get('contactId');
            this.contractId = +queryParams.get('contractId');
            this.userId = +queryParams.get('userId');

            if (this.userId > 0) {
                this.isUserVerified = true;
                this.is_client = false;
                this.authenticate();
            }
            else {
                //UserId will be 0 on independent links;
                this.is_client = true;
                $('#verifyUserModal').modal('show');
                this.authenticate();
            }
        });
        this.resizeCanvas();
    }

    authenticate() {
        this.authenticateR(this.contactId).then(async () => {
            if (this.encryptedUser) {
                if (!this.encryptedUser?.includes(":")) {
                    this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
                }
                this.unixTimestamp()
                localStorage.setItem("token", this.encryptedUser);
                this.loadPage();
                //this.getContractFee();
            }
        });
    }

    private prepareVerifyUserForm(): FormGroup {
        return this.fb.group({
            email: ['', [Validators.pattern(/^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/)]],
            mobile: [''],
        });
    }

    async verifyUser() {
        if (this.verifyUserForm.controls['mobile'].value == '' && this.verifyUserForm.controls['email'].value == '') {
            this._notifyService.showError("Either your Mobile phone or Email address is required.", "", 5000);
            return;
        }

        if (this.verifyUserForm.valid) {
            let email = this.verifyUserForm.controls['email'].value
            let mobile = this.verifyUserForm.controls['mobile'].value
            await this._localService.validateContact(this.contactId, email, mobile, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        if (response.messageBool) {
                            this.isUserVerified = true
                            setTimeout(() => {
                                this.resizeCanvas();
                            }, 2000)
                            this.getCurrentLocation()
                            this.cmMemberStatus(3);
                        }
                        else {
                            this._notifyService.showError("We didn't recognize what you gave us. Please try again.", "", 7000);
                        }

                        this.verifyUserForm.controls['email'].patchValue('');
                        this.verifyUserForm.controls['mobile'].patchValue('');
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm-contract.verifyUser", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    private async authenticateR(contactId) {
        await this._localService.authenticate(this.encryptedUser, this.userId, contactId, this.isAI)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.user = UtilityService.clone(result?.user);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                    this.encryptedUser = result?.encryptedToken;
                    this.cFirstName = result?.cFirstName;
                    this.uName = this.user?.firstName + ' ' + this.user?.lastName;
                    await this.getContractDetails();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    preparePaymentForm() {
        return this.fb.group({
            //cMPaymentTxnID: ["-1"],
            payFee: ["$0.00"],
            contractFee: ["$0.00"],
            paymentMethods: this.fb.array([]),
            totalPrice: ["$0.00"],
            //signupFee: ["$0.00"],
            membershipFee: ["$0.00"],
            paycharge: [''],
            //---credit card----
            ccFirstName: [''],
            ccLastName: [''],
            ccNumber: [''],
            ccDtExpire: [''],
            ccCVC: [''],
            ccZipCode: [''],
            //---Cheque or ACH----
            aBANum: [''],
            aCNum: [''],
            aCType: [''],
            financialInstitute: [''],
            businessName: [''],
            aCHolderName: [''],
            aCPhone: [''],
            aCEmail: [''],
            aCAddress: [''],
            aCCity: [''],
            aCZip: [''],
            aCState: [''],
            aCCountry: [''],
        })
    }

    prepareDocForm() {
        return this.fb.group({
            initialFinalSignature: [''],
            initial: ['']
        })
    }

    prepareCollectionForm() {
        return this.fb.group({
            isCollection: [false],
        })
    }

    newPaymentMethod(): FormGroup {
        return this.fb.group({
            paymentSource: 1,
            totalAmount: 0.00,
        })
    }

    removePaymentMethod(fee, index: number): void {
        if (this.paymentMethods?.length > 1) {
            this.paymentMethods.removeAt(index);
        }

        let stIndex = this.paymentState.findIndex((ind) => ind?.cMPaymentTxnID == fee?.value.cMPaymentTxnID)
        if (stIndex != -1) {
            this.paymentState.splice(index, 1);
        }

        this.onChangeAmount(false)
    }

    async addPaymentMethod() {
        this.CheckSession(async () => {
            let _paymentSource = this.fb.group({
                cMPaymentItemID: 0,
                cMContractFeeID: 0,
                //cMPaymentTxnID: this.paymentTrxs[0]?.cMPaymentTxnID > 0 ? this.paymentTrxs[0]?.cMPaymentTxnID : -1,
                cMPaymentTxnID: -1,
                signupFee: "$0.00",
                paymentType: this.paymentTrxs[0]?.paymentType > 0 ? this.paymentTrxs[0]?.paymentType : 0,
                isPaymentDone: false
            });
            await this.paymentMethods.push(_paymentSource);

            await this.splitEvenly()
            this.holdPayState()
        });
    }

    prepareContactForm() {
        return this.fb.group({
            txtFirstName: ['', [Validators.required, this.nameValidator]],
            txtLastName: ['', [Validators.required, this.nameValidator]],
            txtEmail: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/)]],
            txtMobilePhone: ['', [Validators.required]],
            txtHomePhone: [''],
            txtAddress: ['', [Validators.required]],
            txtCity: ['', [Validators.required]],
            txtState: ['', [Validators.required]],
            txtCountry: ['US', [Validators.required]],
            txtZip: ['', [Validators.required]],
            dl: ['', [Validators.required]],
            dob: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])(19|20)\d{2}$/)]],
            emgContactName: ['', [Validators.required]],
            txtEmgCntPhone: ['', [Validators.required]],
        })
    }

    nameValidator(control: any) {
        const value = control.value;
        const namePattern = /^[a-zA-Z\s'-]+$/;
        return namePattern.test(value) ? null : { 'invalidName': true };
    }

    @HostListener('window:scroll', ['$event'])
    checkOffsetTop() {
        if (!isNullOrUndefined(this.stepper)) {
            if (this.stepper?.selectedIndex == 0) {
            }
        }
    }

    async loadPage() {
        this.getCountry();
        await this.navigationGet();
        this.productGet(false);
        await this.getContactData(false);
        await this.getVendorCustomerId()
        this.cMPaymentTxn_Load();
        this.getContractFee();
        this.cMPaymentItem_Charge_Get(true);
        this.ContractOpenAutomation()
    }

    async getVendorCustomerId() {
        await this._paymentService.getVendorCustomerId(this.encryptedUser, this.contractId, this.contactId, this.memberId, this.contactEmail, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageString != '') {
                        this.vendorCustomerId = response.messageString;
                    }
                    else {
                        this._notifyService.showError("Vendor CustomerId was not loaded.", "", 7000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getVendorCustomerId", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeCountry(countryCode) {
        this.stateCode = "";
        this.ccForm.controls['state'].setValue("")
        this.getCountryStateList(countryCode);
        var country_Code = this.countryDD.filter(x => x.country_Code == countryCode)[0]?.country_Code;
        this.placeholder = this.countryDD.filter(x => x.country_Code == countryCode)[0]?.placeholder;

        if (!isNullOrUndefined(country_Code) && !isNullOrUndefined(this.placeholder)) {
            this.placeHolder = this._countryStateService.parseSimplePhone(this.placeholder, country_Code as CountryCode);
            this.mobile_mask = this._countryStateService.replaceZero(this.placeHolder);
        }
    }

    async getCountry() {
        await this._countryStateService.getListWithPlaceholder(this.encryptedUser)
            .then(async (result: CountryPlaceholder[]) => {
                if (result) {
                    this.countryDD = UtilityService.clone(result);
                    this.countryDD?.unshift({
                        country_Code: "",
                        country_Name: "--Select--",
                        placeholder: ""
                    });
                    this.onChangeCountry(this.countryCode)
                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCountryStateList(countryCode: string) {
        await this._countryStateService.getCountryStateList(this.encryptedUser, countryCode ? countryCode : "US")
            .then(async (result: DropDownItem[]) => {
                if (result) {
                    this.stateList = UtilityService.clone(result);
                    this.stateList?.unshift({
                        value: "",
                        text: "--Select--",
                        isSelected: false
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactData(isAccept: boolean = false) {
        await this._contractService.LoadContractMemberDisplay(this.encryptedUser, this.contactId, this.isAI).
            then(async (result: any) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response) {
                        this.contactDetails = response;
                        this.patchContactDetails();
                        this.isSocDone = true;
                        this.isProductDoc = false;
                        this.contactEmail = this.contactDetails?.email;
                        if(!this.countryDD.some(item => item.country_Code === this.contactDetails.country))
                            this.countryDD.push({ country_Code: this.contactDetails.country, country_Name: this.contactDetails.country, placeholder: '' });
                        if (!this.stateList?.some(item => item.value === this.contactDetails.state))
                            this.stateList.push({ value: this.contactDetails.state, text: this.contactDetails.state,isSelected: false });

                        if (this.navigation?.isDLUploaded) {
                            //this.showDivItem(this.stepper, '#tdPay', '20', null, false, 3);
                            this.showDivItem(this.stepper, '#tdDLUpload', '21', null, false, 3);
                            this.ProcessorContractPDFConvert_Add();
                        }
                        else {
                            this.currentActive = '15';
                            if (isAccept)
                                this.showDivItem(this.stepper, '#product', 'd' + this.contractNavList[0]?.cMContractNavID, null, false, 1, 0);
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContactData", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchContactDetails() {
        this.contactForm.controls['txtFirstName'].patchValue(this.contactDetails?.firstName);
        this.contactForm.controls['txtLastName'].patchValue(this.contactDetails?.lastName);
        this.contactForm.controls['txtEmail'].patchValue(this.contactDetails?.email);
        this.contactForm.controls['txtMobilePhone'].patchValue(this.contactDetails?.mobile);
        this.contactForm.controls['txtHomePhone'].patchValue(this.contactDetails?.homePhone);
        this.contactForm.controls['txtAddress'].patchValue(this.contactDetails?.add1);
        this.contactForm.controls['txtCity'].patchValue(this.contactDetails?.city);
        this.contactForm.controls['txtState'].patchValue(this.contactDetails?.state);
        this.contactForm.controls['txtCountry'].patchValue(this.contactDetails?.country ? this.contactDetails?.country : "");
        this.contactForm.controls['txtZip'].patchValue(this.contactDetails?.zip);
        this.contactForm.controls['dl'].patchValue(this.contactDetails?.dl);
        this.contactForm.controls['dob'].patchValue(this.cleanDateObject(this.contactDetails?.dob));
        this.contactForm.controls['emgContactName'].patchValue(this.contactDetails?.emergencyCName);
        this.contactForm.controls['txtEmgCntPhone'].patchValue(this.contactDetails?.emergencyCPhone);
    }

    hasObjectChanged(oldObj: any, newObj: any): boolean {
        const oldKeys = Object.keys(oldObj);
        const newKeys = Object.keys(newObj);

        const commonKeys = oldKeys.filter(key => newKeys.includes(key));

        for (const key of commonKeys) {
            if (oldObj[key] !== newObj[key]) {
                return true;
            }
        }

        return false;
    }

    async saveContact() {
        this.CheckSession(async () => {
            this.disableDetailsSave = true;
            this.validateAllFormFields(this.contactForm);

            this.prepareContact();
            if (this.contactForm.valid) {
                this.showSpinner = true
                await this._contractService.CMContact_Create(this.encryptedUser, this.contactData).
                    then(async (result: SimpleResponse) => {
                        if (result) {
                            this.showSpinner = false
                            let response = UtilityService.clone(result);
                            if (response.messageInt > 0) {
                                this.contactId = response.messageInt;
                                let isAnyValueChanged = this.hasObjectChanged(this.contactData, this.contactDetails)
                                if (isAnyValueChanged) {
                                    this.productGet(false)
                                    this.getContactData(false);
                                }
                                else {
                                    this.getContactData(true);
                                }
                                this.navigationUpdate(eCMNavigation_Update_Type.isDetails, true);
                                this._notifyService.showSuccess("Your details have been saved.", "", 3000);
                                this.Log('Save & Continue (Personal Information) clicked. Saved.');
                                this.cmMemberStatus(4);
                                this.disableDetailsSave = false;
                            }
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this.showSpinner = false;
                        this.disableDetailsSave = false;
                        this._globalService.error("cm-contract.CMContact_Create", err.message, null,
                        );
                        this._utilityService.handleErrorResponse(err);
                        this.Log(`Save & Continue (Personal Information) clicked. Error. ${err.message}`);
                    });
            }
            else {
                this.disableDetailsSave = false;
                this._notifyService.showError("Please fill mandatory fields.", "", 3000);
                this.Log('Save & Continue (Personal Information) clicked. Invalid information. Not Saved.');
            }
        });
    }

    cleanDateObject(date: string) {
        if (date && date.length == 10) {
            const [month, day, year] = date.split('/');

            return `${month}${day}${year}`;
        }
        else {
            return '';
        }
    }

    getDateObject(date: string) {
        const month = date.substring(0, 2); // Month is zero-based
        const day = date.substring(2, 4);
        const year = date.substring(4, 8);

        return `${month}/${day}/${year}`;
    }

    async cMContractNavCreate() {
        await this._contractService.cMContractNavCreate(this.encryptedUser, this.contractId, this.contactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {

                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.cMContractNavCreate", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async navigation_Create() {
        await this._contractService.navigation_Create(this.encryptedUser, this.contractId, this.contactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.navigationGet();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.navigation_Create", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async navigationUpdate(navType: eCMNavigation_Update_Type, isNavValue: boolean) {
        this.showSpinner = true
        await this._contractService.navigationUpdate(this.encryptedUser, this.navigation.cMNavigationID, navType, isNavValue, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    this.showSpinner = false
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.navigationGet();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-client.navigationUpdate", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadDocs(cmroductDocId, cmContractNavID) {
        return this._contractService.productGet(this.encryptedUser, this.clpCompanyId, this.contractId, this.contactId, cmroductDocId, cmContractNavID, this.isAI).
            then(async (result: CMContractNavListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response && response?.cmContractNav.length > 0) {
                        let contractNav = response?.cmContractNav[0];
                        if (this.contractNavCopy?.some(c => c.cMContractNavID == contractNav.cMContractNavID) == false)
                            this.contractNavCopy.push(contractNav);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.productGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    redirectNav(isRedirect) {
        this.unixTimestamp();
        setTimeout(() => {
            this.setProgressBar();
        }, 100);

        if (isRedirect) {
            let isDoc = false;
            for (var i = 0; i < this.contractNavList.length; i++) {
                if (this.contractNavList[i].isAccepted)
                    isDoc = true;
                else {
                    isDoc = false;
                    if (this.contractNavList[i].cMContractNavID != this.docId) {
                        this.showDivItem(this.stepper, '#product', 'd' + this.contractNavList[i].cMContractNavID, this.contractNavList[i], false, 1, i);
                        return;
                    }
                }
            }
            if (isDoc) {
                if (this.memberFees?.length > 0) {
                    this.showDivItem(this.stepper, '#tdFee', 'p' + this.memberFees[0]?.cmMemberFeeID, this.memberFees[0], false, 2);

                }
                else {
                    this.showDivItem(this.stepper, '#tdReview', '19', null, false, 3);
                }

            }
        }
    }

    async productGet(isRedirect: boolean = false) {
        await this._contractService.productGet(this.encryptedUser, this.clpCompanyId, this.contractId, this.contactId, 0, 0, this.isAI).
            then(async (result: CMContractNavListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response && response?.cmContractNav.length > 0) {
                        this.contractNavList = response?.cmContractNav;

                        this.contractList = this.contractNavList.filter(x => x.cMContractNavID == this.docId)[0];

                        this.unixTimestamp();
                        setTimeout(() => {
                            this.setProgressBar();
                        }, 100);

                        setTimeout(() => {
                            this.getCurrentLocation();
                        }, 500);

                        if (isRedirect) {
                            let isDoc = false;
                            for (var i = 0; i < this.contractNavList.length; i++) {
                                if (this.contractNavList[i].isAccepted)
                                    isDoc = true;
                                else {
                                    isDoc = false;
                                    if (this.contractNavList[i].cMContractNavID != this.docId) {
                                        this.showDivItem(this.stepper, '#product', 'd' + this.contractNavList[i].cMContractNavID, this.contractNavList[i], false, 1, i);
                                        return;
                                    }
                                }
                            }
                            if (isDoc)
                                this.showDivItem(this.stepper, '#tdFee', 'p' + this.memberFees[0]?.cmMemberFeeID, this.memberFees[0], false, 2);

                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.productGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async navigationGet() {
        await this._contractService.navigationGet(this.encryptedUser, this.contractId, this.contactId, this.isAI).
            then(async (result: CMNavigationResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response && response?.cMNavigation.cMNavigationID > 0) {
                        this.navigation = response?.cMNavigation;

                        setTimeout(() => {
                            this.setProgressBar();
                        }, 100);
                    }
                    else {
                        this.navigation_Create();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.navigationGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    setProgressBar() {
        if (this.contractNavList?.length > 0) {
            const totalProduct = (this.contractNavList?.length + 4) + (this.memberFees?.length);
            if (totalProduct > 0) {
                this.progressPreFetch = 0;
                this.navigation.isDetails ? this.getProgressBar(100 / totalProduct) : 0;
                this.navigation.isSignUpFee ? this.getProgressBar(100 / totalProduct) : 0;
                this.navigation.isMembershipFee ? this.getProgressBar(100 / totalProduct) : 0;
                this.navigation.isClubsideFee ? this.getProgressBar(100 / totalProduct) : 0;
                this.navigation.isReview ? this.getProgressBar(100 / totalProduct) : 0;
                this.navigation.isPaymentCollect ? this.getProgressBar(100 / totalProduct) : 0;
                this.navigation.isDLUploaded ? this.getProgressBar(100 / totalProduct) : 0;
                this.contractNavList.forEach((item) => {
                    if (item.isAccepted)
                        this.getProgressBar(100 / totalProduct);
                });
                this.memberFees.forEach((mem) => {
                    if (mem.isDone)
                        this.getProgressBar(100 / totalProduct);
                });
            }
        }
    }

    getCurrentLocation() {
        if (this.contractNavList?.length > 0) {
            const totalProduct = (this.contractNavList?.length + 3) + (this.memberFees?.length);
            if (totalProduct > 0) {
                if (!this.navigation.isDetails) {
                    this.showDivItem(this.stepper, '#tdIsSOCDoc', '15', null, false, 0)
                }
                else {
                    let latest_doc = null;
                    let doc_position = -1;
                    let latest_doc_position = -1;

                    this.contractNavList.forEach((item) => {
                        doc_position++;

                        if (!item.isAccepted && !latest_doc) {
                            latest_doc = item;
                            latest_doc_position = doc_position;
                        }
                    });

                    if (latest_doc) {
                        this.showDivItem(this.stepper, '#product', 'd' + latest_doc?.cMContractNavID, null, false, 1, latest_doc_position, this.userId == 0 ? false : true);
                    }
                    else {
                        let latest_pay = null;

                        this.memberFees.forEach((item) => {

                            if (!item.isDone && !latest_pay) {
                                latest_pay = item;
                            }
                        });

                        if (latest_pay) {
                            if (this.memberFees?.length > 0) {
                                this.showDivItem(this.stepper, '#tdFee', 'p' + latest_pay?.cmMemberFeeID, latest_pay, false, 2);
                            }
                            else {
                                this.showDivItem(this.stepper, '#tdReview', '19', null, false, 3);
                            }

                        }
                        else {
                            if (!this.navigation.isReview) {
                                this.showDivItem(this.stepper, '#tdReview', '19', null, false, 3);
                            }
                            else {
                                if (!this.navigation.isPaymentCollect) {
                                    this.showDivItem(this.stepper, '#tdPay', '20', null, false, 3);
                                }
                                else {
                                    this.showDivItem(this.stepper, '#tdDLUpload', '21', null, false, 3);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    cc_brand_id(e) {
        var jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
        var amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
        var diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$');
        var visa_regex = new RegExp('^4[0-9]{0,}$'); //4
        var mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
        var maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
        var discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');

        //var cur_val = this.paymentConfigureForm.controls.ccNumber.value;

        //cur_val = cur_val.replace(/\D/g, '');
        //var sel_brand = "unknown";
        //if (cur_val.match(jcb_regex)) {
        //    sel_brand = "jcb";
        //} else if (cur_val.match(amex_regex)) {
        //    sel_brand = "amex";
        //} else if (cur_val.match(diners_regex)) {
        //    sel_brand = "diners_club";
        //} else if (cur_val.match(visa_regex)) {
        //    sel_brand = "visa";
        //} else if (cur_val.match(mastercard_regex)) {
        //    sel_brand = "mastercard";
        //} else if (cur_val.match(discover_regex)) {
        //    sel_brand = "discover";
        //} else if (cur_val.match(maestro_regex)) {
        //    if (cur_val[0] == '5') {
        //        sel_brand = "mastercard";
        //    } else {
        //        sel_brand = "stripe";
        //    }
        //}

        //this.sel_brand = sel_brand;
    }


    prepareContact() {
        this.contactData.contactID = this.contactId
        this.contactData.firstName = this.contactForm.controls["txtFirstName"].value;
        this.contactData.lastName = this.contactForm.controls["txtLastName"].value;
        this.contactData.email = this.contactForm.controls["txtEmail"].value;
        this.contactData.mobile = this.contactForm.controls["txtMobilePhone"].value;
        this.contactData.homePhone = this.contactForm.controls["txtHomePhone"].value;
        this.contactData.add1 = this.contactForm.controls["txtAddress"].value;
        this.contactData.city = this.contactForm.controls["txtCity"].value;
        this.contactData.zip = this.contactForm.controls["txtZip"].value;
        this.contactData.state = this.contactForm.controls["txtState"].value;
        this.contactData.country = this.contactForm.controls["txtCountry"].value;
        this.contactData.dl = this.contactForm.controls["dl"].value;
        this.contactData.dob = this.getDateObject(this.contactForm.controls["dob"].value);
        this.contactData.emergencyCName = this.contactForm.controls["emgContactName"].value;
        this.contactData.emergencyCPhone = this.contactForm.controls["txtEmgCntPhone"].value;
        this.contactData.cLPUserID = this.user.cLPUserID;
        this.contactData.cLPCompanyID = this.user.cLPCompanyID;
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
            }
        });
    }

    async getContractSetting() {
        await this._contractService.cmSiteContractSetting_Get(this.encryptedUser, this.contracts[0]?.siteID, this.isAI).
            then(async (result: CMContractSettingResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contractSetting = response.setting;

                    if (this.contractSetting.isEntryACH)
                        this.showHidePaytype("bank");
                    else if (this.contractSetting.isEntryCC)
                        this.showHidePaytype("card");
                    else if (this.contractSetting.isEntryCheck)
                        this.showHidePaytype("check");
                    else if (this.contractSetting.isEntryCash)
                        this.showHidePaytype("cash");
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.getContractSetting", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContractDetails() {
        await this._contractService.cmContractGet(this.encryptedUser, this.clpCompanyId, 0, 0, this.contractId, this.isAI).
            then(async (result: ContractResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contracts = response.contracts;

                    if (this.contracts?.length > 0) {
                        this.contract = this.contracts[0];

                        this.sessionId = localStorage.getItem("bcm_sessionid");

                        if (!this.sessionId || this.sessionId == '') {
                            this.UpdateSession(() => {
                                this.Log(`Contract has been opened.`);
                            });
                        }
                        else {
                            if (this.sessionId != this.contracts[0]?.sessionId) {
                                this.UpdateSession(() => {
                                    this.Log(`Contract has been opened.`);
                                });
                            }
                            else {
                                this.Log(`Contract has been opened.`);
                            }
                        }

                        this.getContractSetting();
                        this.siteId = this.contracts[0]?.siteID;
                        this.ProcessorContractPDFConvert_GET();
                        this.contracts?.forEach((item) => {
                            item.members?.forEach((mItem) => {
                                if (mItem.contactID == this.contactId) {
                                    this.getMemberFee(mItem.cMMemberID);
                                    this.memberId = mItem.cMMemberID;
                                }
                            });
                        });
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async UpdateSession(callback) {
        let session: CMSession = {
            cmContractID: this.contracts[0]?.cmContractID,
            sessionID: this.sessionId
        };
        await this._contractService.CMContract_Update_SessionId(this.encryptedUser, session, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);

                    this.sessionId = response.messageString;
                    localStorage.setItem("bcm_sessionid", this.sessionId);
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-client.UpdateSession", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async CheckSession(callback) {
        await this._contractService.CMContract_Get_SessionId(this.encryptedUser, this.contractId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);

                    if (response.messageString != this.sessionId) {
                        $('#sessionEndModal').modal('show');
                    }
                    else {
                        if (callback) {
                            callback();
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.GetSession", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    startFace2Face() {
        this.UpdateSession(() => {
            this.startF2F = true;
        })
    }

    hideSessionEndModal() {
        if (!this.is_client) {
            this.startF2F = false;
        }
        else {
            this.isUserVerified = false;
        }
        $('#sessionEndModal').modal('hide');
    }

    async getContractFee() {
        await this._contractService.cMContractFee_Get(this.encryptedUser, this.contractId, this.isAI).
            then(async (result: CMContractFeeListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contractFees = response.contractFees;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractFee", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getMemberFee(_memberId: number, isRedirect: boolean = false) {
        await this._contractService.getMemberFee(this.encryptedUser, this.contractId, _memberId, this.isAI).
            then(async (result: CMMemberFeeListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.memberFees = response.memberFees;
                    if (this.memberFees.length == 1) {
                        if (this.memberFees[0].feeBase == 0) {
                            this.pay_download = false;
                        }
                    }

                    setTimeout(() => {
                        this.setProgressBar();
                    }, 100);
                    if (isRedirect) {
                        let isMember = false;
                        this.isPaySetup = false
                        if (this.memberFees.length > 0) {
                            for (var i = 0; i < this.memberFees.length; i++) {
                                if (this.memberFees[i].isDone)
                                    isMember = true;
                                else {
                                    isMember = false;
                                    if (this.memberFees[i].cmMemberFeeID != _memberId) {
                                        this.showDivItem(this.stepper, '#tdFee', 'p' + this.memberFees[i].cmMemberFeeID, this.memberFees[i], false, 2);
                                        return;
                                    }
                                }
                            }
                        }
                        if (isMember) {
                            this.isPaySetup = true
                            let disableReview = await this.disableReview()
                            if (!disableReview) {
                                this.showDivItem(this.stepper, '#tdReview', '19', null, false, 3);
                            }
                            else {
                                let msg = ''
                                if (!this.navigation?.isDetails) msg += 'details'
                                if (!this.isAllDocAccepted) msg += ' & all documents'
                                this._notifyService.showError(`Please make sure ${msg} are setup to proceed further.`, "", 7000);
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractFee", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getMemberById(_memberId: number) {
        if (this.memberFees.some(m => m.cmMemberFeeID == _memberId) == true)
            this.memberFee = this.memberFees.filter(m => m.cmMemberFeeID == _memberId)[0];
    }

    move(stepper: CdkStepper, index: number) {
        if (stepper) {

            stepper.selectedIndex = index;
        }
    }

    showDivItem(stepper: CdkStepper, divId: string, step: string, obj: any, navigation: boolean, index: number, position: number = 0, isLoad = true) {
        this.CheckSession(async () => {
            this.stepId = divId;
            switch (this.stepId) {
                case "#tdIsSOCDoc": this.currentActive = step; this.isProductDoc = false; this.scrollPageUp("#tdIsSOCDoc"); navigation ? this.Log('Navigation: Details clicked.') : false; break;
                case "#product": this.currentActive = step; this.isProductDoc = true; position == 0 ? this.isSignaturePad = true : this.isSignaturePad = false; this.showContractDocument(+step.replace('d', ''), isLoad); navigation ? this.Log(`Navigation: ${obj?.cmProductName} clicked.`) : false; break;
                case "#tdFee": this.currentActive = step; this.isProductDoc = false; this.scrollPageUp("#divFee" + step); this.memberFeeid = +step.replace('p', ''); this.getMemberById(+step.replace('p', '')); this.CMPaymentItem_Get(+step.replace('p', '')); navigation ? this.Log(`Navigation: ${obj?.cmFeeName} clicked.`) : false; break;
                //case "#tdMemberFee": this.currentActive = step; this.isProductDoc = false; this.scrollPageUp("#divFee"); this.cMPaymentTxn_Load(); navigation ? this.Log('Navigation: Fee clicked.') : false; break;
                //case "#tdDocsideFee": this.currentActive = step; this.isProductDoc = false; this.scrollPageUp("#divdocsideFee"); break;
                case "#tdReview": this.currentActive = step; this.isProductDoc = false; this.scrollPageUp("#divReview"); setTimeout(() => { this.getPaymentReviews(); }, 200); navigation ? this.Log('Navigation: Review clicked.') : false; break;
                case "#tdPay": this.currentActive = step; this.isProductDoc = false; this.scrollPageUp("#divPay"); this.cMPaymentItem_Charge_Get(true); navigation ? this.Log('Navigation: Pay clicked.') : false; break;
                case "#tdDLUpload": this.currentActive = step; this.scrollPageUp("#divDLUpload"); navigation ? this.Log(`Navigation: Driver's License clicked.`) : false; break;
                default: break;
            }

            //this.stepId.includes("Sign") == true ? index = 1 : index = index;
            //this.getTransactionDetails(+step.replace('p', ''));
            if (stepper?.selectedIndex == index) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(this.stepId).length > 0 ? $(this.stepId).offset().top - 104 : 0
                }, 100);
            }
            else {
                if (stepper) {
                    stepper.selectedIndex = index;

                }
                setTimeout(() => {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(this.stepId).offset()?.top - 104
                    }, 100);
                }, 800);
            }
        });
    }

    showContractDocument(_docId, isLoad) {
        if (this.contractNavList.some(cn => cn.cMContractNavID == _docId) == true) {
            this.docId = _docId;
            let _productDoc = this.contractNavList.filter(cn => cn.cMContractNavID == _docId)[0];
            this.docTitle = _productDoc.cmProductName;
            this.productDoc = _productDoc.htmlText;
            this.contractList = this.contractNavList.filter(cn => cn.cMContractNavID == _docId)[0];
        }


        const iframeElement = this.iframeRef?.nativeElement;

        if (iframeElement) {

            if (this.userId == 0 && !isLoad) {
                setTimeout(() => {
                    const outerContainer = this.outerContainerDocRef.nativeElement;
                    this.scrollPage(iframeElement, outerContainer)
                }, 100)
            }
            else {
                this.renderer?.listen(iframeElement, 'load', () => {
                    setTimeout(() => {
                        const outerContainer = this.outerContainerDocRef.nativeElement;
                        this.scrollPage(iframeElement, outerContainer)
                    }, 100)
                });
            }
        }
    }

    matClick(e) {
        setTimeout(() => {
            switch (e.selectedIndex) {
                case 0: this.scrollPageUp('#tdIsSOCDoc'); this.showDivItem(this.stepper, '#tdIsSOCDoc', '15', null, false, 0); break;
                //case 1: this.showDivItem(this.stepper, '#divCPG',); break;
                //case 1: this.scrollPageUp('#divPS'); this.showDivItem(this.stepper, 1, '#divPS'); break;
                //case 2: !this.isSecondContract ? this.scrollPageUp('#dvSOCSignDoc') : this.scrollPageUp('#divPS');
                //    !this.isSecondContract ? this.showDivItem(this.stepper, 2, '#dvSOCSignDoc') : this.showDivItem(this.stepper, 2, '#divPS'); break;
                //case 3: this.scrollPageUp('#divCPG'); this.showDivItem(this.stepper, 3, '#divCPG'); break;
                default: break;
            }
        }, 300);
    }

    scrollPageDown() {
        $([document.documentElement, document.body]).animate({ scrollTop: $(document).height() }, 100);
    }

    scrollPageUp(divId) {
        $([document.documentElement, document.body]).animate({ scrollTop: $(divId).height() }, 100);
    }

    scrollPage(iframeElement: HTMLIFrameElement, outerContainer: HTMLElement) {
        if (iframeElement.contentWindow) {
            const iframeWindow = iframeElement.contentWindow;
            const iframeDocument = iframeElement.contentWindow.document;
            const contentHeight = iframeDocument.body.scrollHeight;

            iframeElement.style.height = contentHeight + 'px';
            iframeWindow.scrollTo(0, 0);
            iframeDocument.body.scrollTop = 0;
            iframeDocument.documentElement.scrollTop = 0;
            outerContainer.scrollTop = 0;
            outerContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    signatureFinalOnEnd() {
        if (this.signaturePadFinal?.isEmpty() == false) {
            this.initialFinalDisabled = true;
        }
    }

    signature2OnEnd() {
        if (this.signaturePad2?.isEmpty() == false) {
            this.initial2Disabled = true;
        }
    }

    initialFinalOnPress(e) {
        if (e.key == "Backspace" || e.key == "Delete") {
            if (this.productDocsGroup.controls.initialFinalSignature.value.length == 0) {
                this.signaturePadFinal.on();
                this.signaturePad2.on();
            }
            return;
        }
        this.signaturePadFinal.off();
        this.signaturePad2.off();
    }

    initialChange(e) {
        if (e.key == "Backspace" || e.key == "Delete") {
            if (this.productDocsGroup.controls.initial.value.length == 0) {
                this.signaturePadFinal.on();
                this.signaturePad2.on();
            }
            return;
        }
        this.signaturePadFinal.off();
        this.signaturePad2.off();
    }

    drawClearFinal(): boolean {
        this.signaturePadFinal.clear();
        this.signaturePadFinal.on();
        this.signaturePad2.on();
        if (this.signaturePadFinal?.isEmpty() == true) {
            this.initialFinalDisabled = false;
            this.productDocsGroup.patchValue({
                initialFinalSignature: ''
            });
        }

        return false;
    }


    drawClearInitial(): boolean {
        this.signaturePad2.clear();
        this.signaturePad2.on();
        if (this.signaturePad2?.isEmpty() == true) {
            this.initial2Disabled = false;
            this.productDocsGroup.patchValue({
                initial: ''
            });
        }

        return false;
    }

    ngOnDestroy() {

    }

    addMethodDetail(i) {
        let productObj = this.paymentForm.get("paymentMethods")['controls'][i].getRawValue();
        switch (+productObj?.paymentOption) {
            case 1:
                this.isShowCheckForm = true;
                this.isShowCCForm = false
                break;
            case 2:
                this.isShowCCForm = true;
                this.isShowCheckForm = false;
                break;
            case 3:
                this.isShowCheckForm = true;
                this.isShowCCForm = false
                break;
            case 4:
                this.isShowCheckForm = false;
                this.isShowCCForm = false
                break;
        }
    }

    changeToNumber(value: string): number {
        if (value != null && value != undefined) {
            if (value?.toString().includes('$'))
                return Number(value?.replace(/\$/g, '').replace(/\,/g, ''));
            else
                return Number(value);
        }
        else {
            return 0
        }
    }

    changeToCurrency(value: number): string {
        let final = '$0';

        if (value > 0) {
            final = `$${this.numberWithCommas((Math.round(value * 100) / 100).toFixed(2))}`;
        }
        return final;
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    onSaveCCInfo() {
    }

    onSaveACH() {
    }


    async productAcceptSign(cMContractNavID: number, docName: string, acceptAll: boolean = false, isSignature: boolean = false) {
        this.CheckSession(async () => {
            this.setValueForDocAccept();
            this.contractAcceptation.contractNavID = cMContractNavID;
            if (isSignature) {
                let penned = this.contractAcceptation.eSigFullImage != '' && this.contractAcceptation.eSigInitialsImage != '';
                let keyed = this.contractAcceptation.eFullName != '' && this.contractAcceptation.eInitials != '';

                if (!penned && !keyed) {
                    this._notifyService.showError("Please sign and initial the document.", "", 7000);

                    this.Log(`Accept & Sign ${acceptAll ? 'All' : ''} Clicked (Signature) ${docName}. No Signature.`);
                    this.enableAll();
                    return;
                }
            }
            this.showSpinner = true
            await this._contractService.cmContractNavisAccepted(this.encryptedUser, this.contractAcceptation, this.isAI)
                .then(async (result: SimpleResponse) => {
                    if (result) {

                        if (result.messageBool) {
                            this.contractNavCopy = [];
                            this.Log(`Accept & Sign ${acceptAll ? 'All' : ''} Clicked${isSignature ? ' (Signature).' : '.'} ${docName} Accepted.`);

                            await this.processLoadDocCalls()

                            const orderMap = new Map(this.contractNavList.map(item => [item.cMContractNavID, item.sOrder]));

                            this.contractNavCopy.sort((a, b) => {
                                const orderA = orderMap.get(a.cMContractNavID) || 0;
                                const orderB = orderMap.get(b.cMContractNavID) || 0;
                                return orderA - orderB;
                            });
                            this.contractNavList = []
                            //this.contractNavList.push(...this.contractNavCopy)
                            this.contractNavList =UtilityService.clone(this.contractNavCopy);

                            if (this.contractNavList.filter(x => x.isAccepted == true && x.cMContractID == this.contractId).length == this.contractNavList.filter(x => x.cMContractID == this.contractId).length) {
                                this.cmMemberStatus(5);

                            }
                            this.cmContractStatus(3);

                            this.redirectNav(true);
                            this.drawClearFinal();
                            this.drawClearInitial();

                            if (!acceptAll) {
                                this._notifyService.showSuccess(docName + " was accepted successfully", "", 3000);
                            }
                        }
                        this.enableAll();
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.enableAll();
                    this.showSpinner = false
                    this._globalService.error("cm-contract-client.productAcceptSign", err.message, null);
                    this._utilityService.handleErrorResponse(err);
                });

            if (acceptAll) {
                this.productAcceptAll();
            }
        });
    }

    async downloadPreview() {
        this.isDownloaded = true;
        //this._notifyService.showSuccess("To do", "", 3000);
        this.isForPreviewDownload = true;
        this.ProcessorContractPDFConvert_Add();

        this.Log(`Download Preview Clicked.`);
    }

    async productAccept(cMContractNavID: number, docName: string) {
        //this.CheckSession(async () => {
        //this.setValueForDocAccept();
        this.contractAcceptation.contractId = this.contractId;
        this.contractAcceptation.contractNavID = cMContractNavID;
        this.showSpinner = true
        await this._contractService.cmContractNavisAccepted(this.encryptedUser, this.contractAcceptation, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {

                        this.Log(`Accept & Sign Clicked. ${docName} Accepted.`);

                        let contractIdx = this.contractNavList.findIndex((item) => item?.cMContractNavID == cMContractNavID)

                        if (contractIdx != -1) {
                            this.contractNavList[contractIdx].isAccepted = true
                        }

                        if (this.contractNavList.filter(x => x.isAccepted == true && x.cMContractID == this.contractId).length == this.contractNavList.filter(x => x.cMContractID == this.contractId).length) {
                            this.cmMemberStatus(5);
                        }
                        this.cmContractStatus(3);
                        this.redirectNav(true)
                        this.drawClearFinal();
                        this.drawClearInitial();
                        this._notifyService.showSuccess(docName + " was accepted successfully", "", 3000);

                    }
                    this.enableAll();
                    this.showSpinner = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.enableAll();
                this.showSpinner = false
                this._globalService.error("cm-contract-client.productAccept", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
        //});
    }

    productAcceptAll() {
        this.showSpinner = true;
        if (this.contractNavList?.length > 0) {
            if (!this.contractNavList[0]?.isAccepted) {
                this.Log(`Accept & Sign All Clicked. Accepted.`);

                if (Object.keys(this.contracts[0]?.members[0])?.length > 0) {
                    if (this.contracts[0]?.members[0]?.eSigFullImage == null && this.contracts[0]?.members[0]?.eSigInitialsImage == null && this.contracts[0]?.members[0].eInitials == null) {
                        this._notifyService.showError("Please sign the document.", "", 7000);
                        this.enableAll();
                        this.showSpinner = false;
                        return;
                    }
                }
                else {
                    this._notifyService.showError("Please sign the document.", "", 7000);
                    this.enableAll();
                    this.showSpinner = false;
                    return;
                }
            }

            this.contractNavList.forEach(async (item, index) => {
                this.contractAcceptation.contractId = this.contractId;
                this.contractAcceptation.contractNavID = item.cMContractNavID;

                await this._contractService.cmContractNavisAccepted(this.encryptedUser, this.contractAcceptation, this.isAI)
                    .then(async (result: SimpleResponse) => {
                        if (result) {
                            if (result.messageBool) {

                                this.Log(`${item.cmProductName} Accepted.`);
                                item.isAccepted = true;
                                //let contractIdx = this.contractNavList.findIndex((item) => item?.cMContractNavID == cMContractNavID)

                                //if (contractIdx != -1) {
                                //    this.contractNavList[contractIdx].isAccepted = true
                                //}

                                if (this.contractNavList.filter(x => x.isAccepted == true && x.cMContractID == this.contractId).length == this.contractNavList.filter(x => x.cMContractID == this.contractId).length) {
                                    this.cmMemberStatus(5);
                                    this.cmContractStatus(3);
                                    this.redirectNav(true)
                                }
                                this.drawClearFinal();
                                this.drawClearInitial();
                                this._notifyService.showSuccess(item.cmProductName + " was accepted successfully", "", 3000);
                            }
                            this.enableAll();
                            this.showSpinner = false
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this.enableAll();
                        this.showSpinner = false
                        this._globalService.error("cm-contract-client.productAccept", err.message, null);
                        this._utilityService.handleErrorResponse(err);
                    });
            });

            //for (let i = 0; i < this.contractNavList?.length; i++) {
            //    let _contractNavList = this.contractNavList.slice();
            //    let c = _contractNavList[i];

            //    let cMContractNavID = c.cMContractNavID;
            //    let docName = c.cmProductName;
            //    this.contractAcceptation.contractId = this.contractId;
            //    this.contractAcceptation.contractNavID = cMContractNavID;

            //    await this._contractService.cmContractNavisAccepted(this.encryptedUser, this.contractAcceptation, this.isAI)
            //        .then(async (result: SimpleResponse) => {
            //            if (result) {
            //                if (result.messageBool) {

            //                    this.Log(`${docName} Accepted.`);

            //                    let contractIdx = this.contractNavList.findIndex((item) => item?.cMContractNavID == cMContractNavID)

            //                    if (contractIdx != -1) {
            //                        this.contractNavList[contractIdx].isAccepted = true
            //                    }

            //                    if (this.contractNavList.filter(x => x.isAccepted == true && x.cMContractID == this.contractId).length == this.contractNavList.filter(x => x.cMContractID == this.contractId).length) {
            //                        this.cmMemberStatus(5);
            //                    }
            //                    this.cmContractStatus(3);
            //                    this.redirectNav(true)
            //                    this.drawClearFinal();
            //                    this.drawClearInitial();
            //                    this._notifyService.showSuccess(docName + " was accepted successfully", "", 3000);
            //                }

            //                this.showSpinner = false
            //            }
            //        })
            //        .catch((err: HttpErrorResponse) => {
            //            this.showSpinner = false
            //            this._globalService.error("cm-contract-client.productAccept", err.message, null);
            //            this._utilityService.handleErrorResponse(err);
            //        });
            //}

            //setTimeout(() => {
            //    this.checkIfAcceptAllProdSucc();
            //}, 300)
        }
        else
            this.showSpinner = false;
    }

    async checkIfAcceptAllProdSucc() {
        this.showSpinner = true;
        if (this.contractNavList?.length > 0) {
            for (let i = 0; i < this.contractNavList?.length; i++) {
                if (!this.contractNavList[i]?.isAccepted) {
                    let _contractNavList = this.contractNavList.slice();
                    let c = _contractNavList[i];

                    let cMContractNavID = c.cMContractNavID;
                    let docName = c.cmProductName;
                    this.contractAcceptation.contractId = this.contractId;
                    this.contractAcceptation.contractNavID = cMContractNavID;

                    await this._contractService.cmContractNavisAccepted(this.encryptedUser, this.contractAcceptation, this.isAI)
                        .then(async (result: SimpleResponse) => {
                            if (result) {
                                if (result.messageBool) {

                                    this.Log(`${docName} Accepted.`);

                                    //let contractIdx = this.contractNavList.findIndex((item) => item?.cMContractNavID == cMContractNavID)

                                    //if (contractIdx != -1) {
                                    //    this.contractNavList[contractIdx].isAccepted = true
                                    //}

                                    //if (this.contractNavList.filter(x => x.isAccepted == true && x.cMContractID == this.contractId).length == this.contractNavList.filter(x => x.cMContractID == this.contractId).length) {
                                    //    this.cmMemberStatus(5);
                                    //}
                                    //this.cmContractStatus(3);
                                    //this.redirectNav(true)
                                    //this.drawClearFinal();
                                    //this.drawClearInitial();
                                    //this._notifyService.showSuccess(docName + " was accepted successfully", "", 3000);
                                }

                                this.showSpinner = false;
                            }
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.showSpinner = false;
                            this._globalService.error("cm-contract-client.productAccept", err.message, null);
                            this._utilityService.handleErrorResponse(err);
                        });
                    break;
                }
            }
        }
        else
            this.showSpinner = false;

    }

    async cmContractStatus(statusID: number) {
        await this._contractService.cmContractStatusUpdate(this.encryptedUser, this.contractId, this.clpCompanyId, statusID, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-cmContractStatus", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cmMemberStatus(statusID: number) {
        await this._contractService.cmMemberStatusUpdate(this.encryptedUser, this.clpCompanyId, this.memberId, statusID, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {

                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-cmContractStatus", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async processLoadDocCalls() {

        const apiCallObservables = this.contractNavList.map((item) =>
            this.loadDocs(item?.cMProductDocID, item?.cMContractNavID)
        );

        await forkJoin(apiCallObservables).toPromise();

    }

    setValueForDocAccept() {
        this.contractAcceptation.contractId = this.contractId;
        if (this.signaturePadFinal?.isEmpty() == false)
            this.contractAcceptation.eSigFullImage = this.signaturePadFinal.toDataURL('image/png', 0.9);
        else
            this.contractAcceptation.eSigFullImage = '';
        if (this.signaturePad2?.isEmpty() == false)
            this.contractAcceptation.eSigInitialsImage = this.signaturePad2.toDataURL('image/png', 0.9);
        else
            this.contractAcceptation.eSigInitialsImage = '';
        this.contractAcceptation.eFullName = this.productDocsGroup.controls["initialFinalSignature"].value;
        this.contractAcceptation.eInitials = this.productDocsGroup.controls["initial"].value;
    }

    showHidePaytype(paytype) {
        switch (paytype) {
            case 'bank': this.payType = "bank";
                break;
            case 'card': this.payType = "card";
                break;
            case 'cash': this.payType = "cash";
                break;
            case 'check': this.payType = "check";
                break;
            default:
        }
    }

    showModal() {
        //if (_transactionId == -2) {
        $('#PaymentMethodModal').modal('show');
        this.ccForm = this.prepareCCForm();
        this.bankForm = this.prepareBankForm();
        this.ccForm.reset();
        this.bankForm.reset();
        this.payType = 'card';

        this.ccForm.patchValue({
            name: `${this.contactDetails?.firstName} ${this.contactDetails?.lastName}`,
            address: this.contactDetails?.add1,
            zipcode: this.contactDetails?.zip,
            phone: this.contactDetails?.mobile,
            email: this.contactDetails?.email,
            city: this.contactDetails?.city,
            state: this.contactDetails?.state,
            country: this.contactDetails?.country ? this.contactDetails?.country != 'US' ? 'US' : this.contactDetails?.country : '0'
        });

        this.bankForm.patchValue({
            accountName: `${this.contactDetails?.firstName} ${this.contactDetails?.lastName}`,
            accountAddress: this.contactDetails?.add1,
            accountZip: this.contactDetails?.zip,
            accountPhone: this.contactDetails?.mobile,
            accountEmail: this.contactDetails?.email,
            accountCity: this.contactDetails?.city,
            accountState: this.contactDetails?.state,
            accountCountry: this.contactDetails?.country,
            accountType: 'checking'
        });
        //}
    }

    hideModal() {
        $('#PaymentMethodModal').modal('hide');
    }

    async unixTimestamp() {
        await this._contractService.unixTimestamp(this.contractId, this.clpCompanyId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.unixDate = result?.messageInt
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.unixTimestamp", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    //#region payment

    private prepareCCForm(): FormGroup {
        return this.fb.group({
            creditCard: [{ value: '' }, [CreditCardValidators.validateCCNumber]],
            name: [{ value: '' }, [Validators.required, Validators.pattern(/^[a-zA-Z\s'-]+$/)]],
            exp: [{ value: '' }, [Validators.required]],
            cvc: [{ value: '' }, [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]],
            address: [{ value: '' }, [Validators.required]],
            zipcode: [{ value: '' }, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]{5,7}$/)]],
            phone: [{ value: '' }, [Validators.required]],
            email: [{ value: '' }, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/)]],
            city: [{ value: '' }, [Validators.required]],
            state: [{ value: '' }, [Validators.required]],
            country: [{ value: '' }, [Validators.required]],
            //promoCode: [{ value: '' }]
        });
    }

    private prepareBankForm(): FormGroup {
        return this.fb.group({
            cMPaymentItemID: [0],
            routingNumber: [{ value: '' }, [Validators.required]],
            accountNumber: [{ value: '' }, [Validators.required]],
            accountType: ['checking'],
            institution: ['', [Validators.required]],
            businessName: [''],
            accountName: [{ value: '' }, [Validators.required]],
            accountPhone: [{ value: '' }, [Validators.required]],
            accountEmail: [{ value: '' }, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/)]],
            accountAddress: [{ value: '' }, [Validators.required]],
            accountCity: [{ value: '' }, [Validators.required]],
            accountZip: [{ value: '' }, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]{5,7}$/)]],
            accountState: [''],
            accountCountry: [''],
        });
    }



    verifyExp(event: any) {
        if (event.target.value.length == 5) {
            let exp = event.target.value;

            let strmonth = exp.substring(0, 2);
            let year = '20' + exp.substring(3, 5);
            let month = +strmonth - 1;

            if (strmonth === '00' || month > 11) {
                this.creditcardform.exp.setErrors({ 'incorrect': true });
                return;
            }

            var d = new Date(+year, month, 1);
            var n = new Date();

            if (n > d)
                this.creditcardform.exp.setErrors({ 'incorrect': true });
        }
    }

    changeUpperCase(e) {
        if (this.creditcardform.zipcode.value) {
            let _zip = this.creditcardform.zipcode.value;
            this.creditcardform.zipcode.setValue(_zip.toUpperCase())
        }
    }

    //#endregion

    async onVerify() {
        this.CheckSession(async () => {
            switch (this.payType) {
                case 'card': {
                    let cc: CC = {
                        contactId: this.contactId,
                        cmMemberId: this.memberId,
                        contractId: this.contractId,
                        vCustomerId: this.vendorCustomerId,
                        memberFeeId: this.memberFeeid,
                        //total: this.changeToNumber(this.paymentForm.controls['signupFee'].value),
                        total: 0,
                        creditCard: this.creditcardform.creditCard.value,
                        name: this.creditcardform.name.value,
                        exp: this.creditcardform.exp.value,
                        cvc: this.creditcardform.cvc.value,
                        address: this.creditcardform.address.value,
                        zipcode: this.creditcardform.zipcode.value,
                        phone: this.creditcardform.phone.value,
                        email: this.creditcardform.email.value,
                        city: this.creditcardform.city.value,
                        state: this.creditcardform.state.value,
                        country: this.creditcardform.country.value,
                        txnId: 0
                    }

                    //this.holdPayState()
                    await this.verifyCreditCard(cc);
                    this.cMPaymentTxn_Load();
                    this.hideModal();

                }
                    break;
                case 'bank': {
                    let bank: Bank = {
                        contactId: this.contactId,
                        cmMemberId: this.memberId,
                        contractId: this.contractId,
                        vCustomerId: this.vendorCustomerId,
                        total: 0,
                        routingNumber: this.bankform.routingNumber.value,
                        accountNumber: this.bankform.accountNumber.value,
                        accountType: this.bankform.accountType.value,
                        institution: this.bankform.institution.value,
                        businessName: this.bankform.businessName.value,
                        accountName: this.bankform.accountName.value,
                        accountPhone: this.bankform.accountPhone.value,
                        accountEmail: this.bankform.accountEmail.value,
                        accountAddress: this.bankform.accountAddress.value,
                        accountCity: this.bankform.accountCity.value,
                        accountZip: this.bankform.accountZip.value,
                        accountState: this.bankform.accountState.value,
                        accountCountry: this.bankform.accountCountry.value
                    }

                    this.verifyBankAccount(bank);
                }
                    break;
                case 'cash': {
                    let cash: CheckCash = {
                        contactId: this.contactId,
                        cmMemberId: this.memberId,
                        contractId: this.contractId,
                        vCustomerId: this.vendorCustomerId,
                        memberFeeId: this.memberFeeid,
                        isCash: true,
                        total: 0
                    }

                    this.verifyCheckCash(cash);
                }
                    break;
                case 'check': {
                    let check: CheckCash = {
                        contactId: this.contactId,
                        cmMemberId: this.memberId,
                        contractId: this.contractId,
                        vCustomerId: this.vendorCustomerId,
                        memberFeeId: this.memberFeeid,
                        isCash: false,
                        total: 0
                    }

                    this.verifyCheckCash(check);
                }
                    break;
            }
        });
    }

    holdPayState() {
        this.paymentState = []
        this.paymentMethods.controls.forEach((item, index) => {
            let _paymentSource = {
                cMPaymentItemID: item?.getRawValue()?.cMPaymentItemID,
                cMContractFeeID: item?.getRawValue()?.cMContractFeeID,
                cMPaymentTxnID: item?.getRawValue()?.cMPaymentTxnID,
                signupFee: item?.getRawValue()?.signupFee,
                paymentType: item?.getRawValue()?.paymentType,
                isPaymentDone: item?.getRawValue()?.isPaymentDone,
            }

            this.paymentState.push(_paymentSource)
        });

    }


    disableOnVerify() {
        switch (this.payType) {
            case 'bank': {
                if (!this.bankform.routingNumber.value ||
                    !this.bankform.accountNumber.value ||
                    !this.bankform.accountName.value ||
                    !this.bankform.accountPhone.value ||
                    !this.bankform.accountEmail.value ||
                    !this.bankform.accountAddress.value ||
                    !this.bankform.accountCity.value ||
                    !this.bankform.institution.value ||
                    !this.bankform.accountZip.value)
                    return true;
                else {
                    if ((this.bankform.routingNumber.errors && (this.bankform.routingNumber.touched || this.bankform.routingNumber.dirty)) ||
                        (this.bankform.accountNumber.errors && (this.bankform.accountNumber.touched || this.bankform.accountNumber.dirty)) ||
                        (this.bankform.accountName.errors && (this.bankform.accountName.touched || this.bankform.accountName.dirty)) ||
                        (this.bankform.accountPhone.errors && (this.bankform.accountPhone.touched || this.bankform.accountPhone.dirty)) ||
                        (this.bankform.accountEmail.errors && (this.bankform.accountEmail.touched || this.bankform.accountEmail.dirty)) ||
                        (this.bankform.accountAddress.errors && (this.bankform.accountAddress.touched || this.bankform.accountAddress.dirty)) ||
                        (this.bankform.accountCity.errors && (this.bankform.accountCity.touched || this.bankform.accountCity.dirty)) ||
                        (this.bankform.accountZip.errors && (this.bankform.accountZip.touched || this.bankform.accountZip.dirty)) ||
                        (this.bankform.institution.errors && (this.bankform.institution.touched || this.bankform.institution.dirty))
                    )
                        return true;
                }
            }
                break;
            case 'card': {
                if (!this.creditcardform.creditCard.value ||
                    !this.creditcardform.name.value ||
                    !this.creditcardform.exp.value ||
                    !this.creditcardform.cvc.value ||
                    !this.creditcardform.address.value ||
                    !this.creditcardform.zipcode.value ||
                    !this.creditcardform.phone.value ||
                    !this.creditcardform.email.value ||
                    !this.creditcardform.city.value || !this.creditcardform.state.value || !this.creditcardform.country.value)
                    return true;
                else {
                    if ((this.creditcardform.creditCard.errors && (this.creditcardform.creditCard.touched || this.creditcardform.creditCard.dirty)) ||
                        (this.creditcardform.name.errors && (this.creditcardform.name.touched || this.creditcardform.name.dirty)) ||
                        (this.creditcardform.exp.errors && (this.creditcardform.exp.touched || this.creditcardform.exp.dirty)) ||
                        (this.creditcardform.cvc.errors && (this.creditcardform.cvc.touched || this.creditcardform.cvc.dirty)) ||
                        (this.creditcardform.address.errors && (this.creditcardform.address.touched || this.creditcardform.address.dirty)) ||
                        (this.creditcardform.zipcode.errors && (this.creditcardform.zipcode.touched || this.creditcardform.zipcode.dirty)) ||
                        (this.creditcardform.phone.errors && (this.creditcardform.phone.touched || this.creditcardform.phone.dirty)) ||
                        (this.creditcardform.email.errors && (this.creditcardform.email.touched || this.creditcardform.email.dirty)) ||
                        (this.creditcardform.city.errors && (this.creditcardform.city.touched || this.creditcardform.city.dirty)) ||
                        (this.creditcardform.state.errors && (this.creditcardform.state.touched || this.creditcardform.state.dirty)) ||
                        (this.creditcardform.country.errors && (this.creditcardform.country.touched || this.creditcardform.country.dirty))
                    )
                        return true;
                }
            }
                break;
            default:
                return false;
        }
    }

    async verifyCreditCard(cc: CC) {
        this.isPayment = true;
        await this._paymentService.addCreditCard(this.encryptedUser, cc)
            .then(async (result: SimpleResponse) => {
                this.isPayment = false;
                if (result.messageBool) {
                    //this.unixDate = result?.messageInt
                    this.lastPaymentId = result?.messageString
                    this._notifyService.showSuccess("Your credit card was added successfully.", "Success", 3000);

                    if (cc.txnId > 0) {
                        //refresh cc details everywhere.
                        this.cMPaymentTxn_Load();
                        this.cMPaymentItem_Charge_Get(true);
                    }

                    this.Log(`${result?.messageString2} added.`);
                }
                else if (!isNullOrUndefined(result.messageString2)) {
                    this._notifyService.showError(result.messageString2, "Failed", 7000);

                    this.Log(`Verifying Credit Card failed: ${result?.messageString2}.`);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.isPayment = false
                this.hideModal();
                this._globalService.error("cm-contract-client.addCreditCard", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    addNewEntry() {
        if (this.memberFee?.isPaymentSplitable) {
            let isControlExist = false
            this.paymentMethods.controls.forEach((ctrl) => {
                if (ctrl?.getRawValue()?.cMPaymentTxnID == -1) {
                    isControlExist = true
                }
            })

            if (!isControlExist) {
                let _paymentSource = {
                    cMPaymentItemID: 0,
                    cMContractFeeID: 0,
                    cMPaymentTxnID: 0,
                    signupFee: this.paymentMethods?.length == 0 ? this.changeToCurrency(this.memberFee?.feeTotal) : "$0.00",
                    paymentType: 0
                }

                this.paymentState.push(_paymentSource)
            }
        }

    }

    async verifyBankAccount(bank: Bank) {
        await this._paymentService.addBankAccount(this.encryptedUser, bank)
            .then(async (result: SimpleResponse) => {
                this.isPayment = false;
                if (result) {
                    this.hideModal();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.isPayment = false;
                this.hideModal();
                this._globalService.error("cm-contract-client.addBankAccount", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async verifyCheckCash(cc: CheckCash) {

        if (this.payType == 'cash') {
            let isCashPayExists = this.paymentTrxs.find(item => item?.paymentType == 4)
            if (isCashPayExists) {
                this.hideModal();
                return;
            }
        }

        if (this.payType == 'check') {
            let isCheckPayExists = this.paymentTrxs.find(item => item?.paymentType == 3)
            if (isCheckPayExists) {
                this.hideModal();
                return;
            }
        }
        this.isPayment = true;
        await this._paymentService.addCheckCash(this.encryptedUser, cc)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.isPayment = false;
                    //this.unixDate = result?.messageInt
                    if (result.messageBool) {

                        //await this.addNewEntry()
                        this.cMPaymentTxn_Load();
                        let p = cc.isCash ? 'Cash' : 'Check';
                        this._notifyService.showSuccess(`A ${p} payment was added.`, "Payment", 3000);
                        this.hideModal();
                    }
                    else {
                        this._notifyService.showError(`An error occurred adding a payment. Our team has been notified.`, "Payment", 7000);
                        this.hideModal();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.isPayment = false
                this.hideModal();
                this._globalService.error("cm-contract-client.addCheckCash", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getProgressBar(stepComplete: number) {
        setTimeout(() => {
            if (this.progressPreFetch <= 100)
                this.progressPreFetch += stepComplete;
            else
                this.progressPreFetch = 100;
        }, 100);
    }


    resizeCanvas() {
        var canvas = document.getElementsByTagName("canvas")[0];
        var canvas1 = document.getElementsByTagName("canvas")[1];
        if (canvas) {
            var card = document.getElementsByClassName("signature-pad");
            var outer = document.getElementsByClassName("signature-pad");
            canvas.width = outer[0].clientWidth;
            canvas.height = 200;
        }
        if (canvas1) {
            var outer1 = document.getElementsByClassName("signature-pad1");
            canvas1.width = outer1[0].clientWidth;
            canvas1.height = 200;
        }
    }

    async cMPaymentTxn_Load() {
        await this._paymentService.cMPaymentTxn_Load(this.encryptedUser, this.contractId, this.memberId, eContractFeeType.All, this.isAI)
            .then(async (result: CMPaymentTxnResponse) => {
                if (result) {
                    this.paymentTrxsFinal = [];
                    this.paymentMethods.clear();
                    this.paymentTrxs = result?.cmPaymentTxnList;
                    this.allCardPayments = result?.totalPaymentByCard;
                    this.allCardPayments.forEach((item) => item.isCharged = false)

                    await this.setPaySourceDD();
                    if (!this.memberFee?.isPaymentSplitable && this.lastPaymentId != '') {
                        let lastCardObj = this.paymentTrxs.filter((item) => item?.vPaymentMethodID == this.lastPaymentId)[0]
                        this.paymentState[0].cMPaymentTxnID = lastCardObj?.cMPaymentTxnID
                    }

                    if (this.paymentState?.length > 0) {
                        this.paymentState.forEach((item) => {
                            let payObject = this.paymentTrxs.filter((pay) => pay?.cMPaymentTxnID == item?.cMPaymentTxnID)[0]

                            let lastPayObject = <CMPaymentTxn>{}
                            switch (this.payType) {
                                case 'card':
                                    lastPayObject = this.paymentTrxs.filter((item) => item?.vPaymentMethodID == this.lastPaymentId)[0]
                                    break;
                                //TODO: NEED TO IMPLEMENT
                                //case 'bank':
                                //    lastPayObject = this.paymentTrxs.filter((item) => item?.vPaymentMethodID == this.lastPaymentId)[0]
                                //    break;
                                case 'cash':
                                    lastPayObject = this.paymentTrxs.filter((item) => item?.paymentType == 4)[0]
                                    break;
                                case 'check':
                                    lastPayObject = this.paymentTrxs.filter((item) => item?.paymentType == 3)[0]
                                    break;
                            }

                            if (payObject?.cMPaymentTxnID == item?.cMPaymentTxnID) {
                                let _paymentSource = this.fb.group({
                                    cMPaymentItemID: item.cMPaymentItemID,
                                    cMContractFeeID: item?.cMContractFeeID,
                                    cMPaymentTxnID: [{ value: this.checkIfLastPayExist(item.cMPaymentTxnID), disabled: item?.isPaymentDone }],
                                    signupFee: [{
                                        value: item.signupFee, disabled: item?.isPaymentDone
                                    }],
                                    paymentType: item?.paymentType,
                                    isPaymentDone: item?.isPaymentDone
                                });
                                this.paymentMethods.push(_paymentSource);
                            }
                            else {
                                let _paymentSource = this.fb.group({
                                    cMPaymentItemID: item?.cMPaymentItemID,
                                    cMContractFeeID: this.memberFeeid,
                                    cMPaymentTxnID: this.checkIfLastPayExist(lastPayObject?.cMPaymentTxnID),
                                    signupFee: item?.signupFee,
                                    paymentType: lastPayObject?.paymentType,
                                    isPaymentDone: item?.isPaymentDone
                                });
                                this.paymentMethods.push(_paymentSource);
                            }
                        })
                    }

                    this.holdPayState()

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.addBankAccount", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    checkIfLastPayExist(cMPaymentTxnID) {
        if (this.paySourceDD?.length > 0) {
            let isExists = this.paySourceDD?.find((item) => item?.cMPaymentTxnID == cMPaymentTxnID);
            if (isExists) {
                return cMPaymentTxnID;
            }
        }
        return -1;
    }

    setPaySourceDD() {
        this.paySourceDD = []

        const trnxSet = new Set<number>();

        this.paymentTrxs.forEach(obj => {
            if (!this.memberFee?.isCollectNow) {
                if (obj.paymentType <= 2) {
                    if (!trnxSet.has(obj.cMPaymentTxnID)) {
                        trnxSet.add(obj.cMPaymentTxnID);
                        this.paySourceDD.push(obj);
                    }
                }
            }
            else {
                if (!trnxSet.has(obj.cMPaymentTxnID)) {
                    trnxSet.add(obj.cMPaymentTxnID);
                    this.paySourceDD.push(obj);
                }
            }
        });
    }


    getTransactionDetails(memberFeeId: number) {
        if (this.paymentTrxs && this.paymentTrxs.length > 0) {
            if (this.paymentTrxs.some(x => x.memberFeeId == memberFeeId) == true) {
                this.totalBeingPaid = 0;
                this.paymentMethods.controls.forEach((item, index) => {
                    this.totalBeingPaid += +item.getRawValue().signupFee;
                });
                this.displayBeingPaid = this.changeToCurrency(this.totalBeingPaid);
            }
        }
    }

    async CMPaymentItem_Get(memberFeeId: number) {
        await this._paymentService.CMPaymentItem_Get(this.encryptedUser, memberFeeId, this.isAI)
            .then(async (result: CMPaymentItem[]) => {
                if (result) {
                    this.cmPaymentItems = [];
                    await this.paymentMethods.clear();
                    this.cmPaymentItems = UtilityService.clone(result);

                    this.setPaySourceDD();

                    if (this.cmPaymentItems.length > 0) {
                        this.cmPaymentItems.forEach((item) => {
                            if (item.cMMemberFeeID == memberFeeId) {
                                let payTrnx = this.paymentTrxs?.filter((pay) => pay?.cMPaymentTxnID == item?.cMPaymentTxnID)[0]
                                let _paymentSource = this.fb.group({
                                    cMPaymentItemID: item.cMPaymentItemID,
                                    cMContractFeeID: item.cMContractFeeID,
                                    cMPaymentTxnID: [{ value: this.checkIfLastPayExist(item.cMPaymentTxnID), disabled: item?.isPaymentDone }],
                                    signupFee: [{
                                        value: this.changeToCurrency(item.feeTotal), disabled: item?.isPaymentDone
                                    }],
                                    paymentType: payTrnx?.paymentType,
                                    isPaymentDone: item?.isPaymentDone
                                });
                                this.paymentMethods.push(_paymentSource);
                                this.lastcMPaymentTxnID = item.cMPaymentTxnID;
                            }
                        });
                    }
                    else {
                        this.paymentMethods.clear();
                        let _paymentSource = this.fb.group({
                            cMPaymentItemID: 0,
                            cMContractFeeID: this.memberFeeid,
                            cMPaymentTxnID: this.checkIfLastPayExist(this.lastcMPaymentTxnID),
                            signupFee: this.changeToCurrency(this.memberFee?.feeTotal),
                            paymentType: this.paySourceDD[0]?.paymentType ? this.paySourceDD[0]?.paymentType : 0,
                            isPaymentDone: false
                        });
                        this.paymentMethods.push(_paymentSource);
                    }


                    this.holdPayState();
                    this.onChangeAmount(false);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.addBankAccount", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });

    }

    addPayment() {
        if (this.paymentForm.controls['cMPaymentTxnID'].value == "-1") {
            this._notifyService.showError("Please select a payment method.", "", 7000);
        }
        else {
            this.transactionId = +this.paymentForm.controls['cMPaymentTxnID'].value;
            if (isNullOrUndefined(this.paymentTrxsFinal))
                this.paymentTrxsFinal = [];
            if (this.paymentTrxsFinal.some(x => x.cMPaymentTxnID == this.transactionId) == true) {
                this._notifyService.showError("this payment method was already added", "", 7000);
            }
            else {
                let payTxn = this.paymentTrxs.filter(y => y.cMPaymentTxnID == this.transactionId)[0];
                this.paymentTrxsFinal.push(payTxn);
            }
        }
    }


    paymentChange(amunt: number, txnId) {
        this.paymentTrxsFinal.forEach((item) => {
            if (item.cMPaymentTxnID == txnId) {
                item.total = amunt;
            }
        });
        this.totalBeingPaid = 0;
        this.paymentTrxsFinal.forEach((item) => {
            this.totalBeingPaid += +item.total;
            item.displayAmount = this.changeToCurrency(+item.total);
        });
        this.displayBeingPaid = this.changeToCurrency(this.totalBeingPaid);
    }

    async transactionUpdate(feeName: string) {
        this.totalBeingPaid = 0;
        this.paymentMethods.controls.forEach((item, index) => {
            if (item.getRawValue().cMPaymentTxnID == -1) {
                this._notifyService.showError("Please choose valid payment Source.", "", 7000);
                return;
            }
            else if (item.getRawValue().cMPaymentTxnID == -1) {
                this._notifyService.showError("Please choose valid payment Source.", "", 7000);
                return;
            }
            else {
                this.totalBeingPaid += this.changeToNumber(item.getRawValue().signupFee);
            }
        });
        this.displayBeingPaid = this.changeToCurrency(this.totalBeingPaid);
        if (this.totalBeingPaid < (+this.memberFee?.feeTotal))
            this._notifyService.showError("Your payment amount is less than " + feeName + "'s amount", "", 7000);
        else if (this.totalBeingPaid > (+this.memberFee?.feeTotal))
            this._notifyService.showError("Your payment amount excceded " + feeName + "'s amount", "", 7000);
        else {
            this.paymentMethods.controls.forEach(async (item, index) => {
                if (this.paymentTrxs.some(x => x.memberFeeId == this.memberFeeid) == true) {
                    await this._paymentService.CMPaymentTxn_Update(this.encryptedUser, item.getRawValue().cMPaymentTxnID, this.changeToNumber(item.getRawValue().signupFee), this.isAI)
                        .then(async (result: SimpleResponse) => {
                            if (result?.statusCode == 1) {
                                if (this.paymentMethods.controls.length - 1 == index) {
                                    this._notifyService.showSuccess(`The ${feeName} payment was saved successfully`, "", 3000);
                                    this.getMemberFee(this.memberId, true);
                                    this.cMPaymentTxn_Load();
                                }
                            }
                        })
                        .catch((err: HttpErrorResponse) => {
                            this._globalService.error("cm-contract-client.transactionUpdate", err.message, null);
                            this._utilityService.handleErrorResponse(err);
                        });
                }
                else {
                    let _payTxn = this.paymentTrxs.filter(t => t.cMPaymentTxnID == item.value.cMPaymentTxnID)[0];
                    if (_payTxn) {
                        _payTxn.memberFeeId = this.memberFeeid;
                        _payTxn.total = this.changeToNumber(item.value.signupFee);
                        await this._paymentService.CMPaymentTxn_Create(this.encryptedUser, _payTxn, this.isAI)
                            .then(async (result: SimpleResponse) => {
                                if (result?.statusCode == 1) {
                                    if (this.paymentMethods.controls.length - 1 == index) {
                                        this._notifyService.showSuccess(`The ${feeName} payment was saved successfully`, "", 3000);
                                        this.getMemberFee(this.memberId, true);
                                        this.cMPaymentTxn_Load();
                                    }
                                }
                            })
                            .catch((err: HttpErrorResponse) => {
                                this._globalService.error("cm-contract-client.CMPaymentTxn_Create", err.message, null);
                                this._utilityService.handleErrorResponse(err);
                            });
                    }
                }
            });
        }
    }

    hideMemberPaymentAll() {
        let isAnyPaySaved = false;
        for (let i = 0; i < this.memberFees?.length; i++) {
            if (this.memberFees[i]?.isDone) {
                isAnyPaySaved = true
                break;
            }
        }

        if (this.paySourceDD.length == 1 && this.cmPaymentItems?.length == 0 && this.paymentMethods?.length == 1 && this.memberFees.length > 1 && !isAnyPaySaved) {
            if (this.paySourceDD[0].paymentType == 1) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    async saveMemberPaymentAll() {
        this.showSpinner = true;
        //this.CheckSession(async () => {
        const control = this.paymentMethods.at(0);
        const cMPaymentTxnID = control.get('cMPaymentTxnID').value;
        if (cMPaymentTxnID == -1 || cMPaymentTxnID == 0) {
            this._notifyService.showError("Please choose a valid payment Source.", "", 7000);
            this.Log(`A valid payment source was not chosen when Applying to All Fees`);
            this.enableAll();
            this.showSpinner = false;
            return;
        }

        for (let i = 0; i < this.memberFees.length; i++) {
            const item = this.memberFees[i];
            this.cmPaymentItem.cMContractFeeID = item?.cmContractFeeID;
            this.cmPaymentItem.cMMemberFeeID = item?.cmMemberFeeID;
            this.cmPaymentItem.cMPaymentItemID = 0;
            this.cmPaymentItem.cMPaymentTxnID = cMPaymentTxnID;
            this.cmPaymentItem.feeTotal = item.feeTotal;
            this.cmPaymentItem.feeBese = item.feeBase;
            this.cmPaymentItem.isPaymentDone = false;
            await this._paymentService.CMPaymentItem_Create(this.encryptedUser, this.cmPaymentItem, this.isAI)
                .then(async (result: SimpleResponse) => {
                    if (result?.statusCode == 1) {
                        if (this.memberFees?.length - 1 == i) {
                            this._notifyService.showSuccess(`The ${item?.cmFeeName} payment was saved successfully`, "", 3000);
                            this.Log(`${item?.cmFeeName} payment for ${item.feeTotal} was saved.`);
                            this.CMPaymentItem_Get(this.cmPaymentItem?.cMMemberFeeID);
                            await this.getMemberFee(this.memberId, true);
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.enableAll();
                    this.showSpinner = false
                    this._globalService.error("cm-contract-client.transactionUpdate", err.message, null);
                    this._utilityService.handleErrorResponse(err);
                    this.Log(`${item?.cmFeeName} payment for ${item.feeTotal} was not saved ${err.message}.`);
                });
        }
        this.enableAll();
        this.showSpinner = false;

        setTimeout(() => {
            this.checkIfApplyAllSuccess();
        }, 500)
        //});
    }

    async checkIfApplyAllSuccess() {
        const control = this.paymentMethods.at(0);
        const cMPaymentTxnID = control.get('cMPaymentTxnID').value;
        for (let i = 0; i < this.memberFees?.length; i++) {
            if (!this.memberFees[i]?.isDone) {
                const item = this.memberFees[i];
                this.cmPaymentItem.cMContractFeeID = item?.cmContractFeeID;
                this.cmPaymentItem.cMMemberFeeID = item?.cmMemberFeeID;
                this.cmPaymentItem.cMPaymentItemID = 0;
                this.cmPaymentItem.cMPaymentTxnID = cMPaymentTxnID;
                this.cmPaymentItem.feeTotal = item.feeTotal;
                this.cmPaymentItem.feeBese = item.feeBase;
                this.cmPaymentItem.isPaymentDone = false;
                await this._paymentService.CMPaymentItem_Create(this.encryptedUser, this.cmPaymentItem, this.isAI)
                    .then(async (result: SimpleResponse) => {
                        if (result?.statusCode == 1) {
                            //if (this.memberFees?.length - 1 == i) {
                            //this._notifyService.showSuccess(`The ${item?.cmFeeName} payment was saved successfully`, "", 3000);
                            this.Log(`${item?.cmFeeName} payment for ${item.feeTotal} was saved.`);
                            //this.CMPaymentItem_Get(this.cmPaymentItem?.cMMemberFeeID);
                            //await this.getMemberFee(this.memberId, true);
                            //}
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this.enableAll();
                        this.showSpinner = false;
                        this._globalService.error("cm-contract-client.transactionUpdate", err.message, null);
                        this._utilityService.handleErrorResponse(err);
                        this.Log(`${item?.cmFeeName} payment for ${item.feeTotal} was not saved ${err.message}.`);
                    });
                break;
            }
        }
        this.enableAll();
        this.showSpinner = false;
    }

    disableAll() {
        $(':button').prop('disabled', true);
    }

    enableAll() {
        $(':button').prop('disabled', false);
    }

    async saveMemberPayment(feeName: string, cmMemberFeeID: number) {
        this.CheckSession(async () => {
            await this.navigationUpdate(eCMNavigation_Update_Type.isReview, false);
            this.totalBeingPaid = 0;

            for (let i = 0; i < this.paymentMethods?.length; i++) {
                const control = this.paymentMethods.at(i);
                const cMPaymentTxnID = control.get('cMPaymentTxnID').value;
                const signupFee = control.get('signupFee').value;

                if (cMPaymentTxnID == -1 || cMPaymentTxnID == 0) {
                    this._notifyService.showError("Please choose a valid Payment Source.", "", 7000);
                    this.Log(`A valid payment source was not chosen when Saving a Fee.`);
                    this.enableAll();
                    return;
                }
                else {
                    this.totalBeingPaid += this.changeToNumber(signupFee);
                }
            }

            this.totalBeingPaid = Number(this.totalBeingPaid.toFixed(2));
            //this.displayBeingPaid = this.changeToCurrency(this.totalBeingPaid);
            if (this.totalBeingPaid < (+this.memberFee?.feeTotal)) {
                this._notifyService.showError("Your payment amount is less than " + feeName + "'s amount", "", 7000);
                this.Log(`Payment amount is less than ${feeName}'s amount (total being paid: ${this.totalBeingPaid}, fee total: ${this.memberFee?.feeTotal}).`);
            }
            else if (this.totalBeingPaid > (+this.memberFee?.feeTotal)) {
                this._notifyService.showError("Your payment amount exceeded " + feeName + "'s amount", "", 7000);
                this.Log(`Payment amount exceeded ${feeName}'s amount (total being paid: ${this.totalBeingPaid}, fee total: ${this.memberFee?.feeTotal}).`);
            }
            else {
                let feeId = this.contractFees?.filter(c => c.cMFeeName == feeName)[0]?.cMContractFeeID;
                await this.mergeMultipleCashCheckPays(3)
                await this.mergeMultipleCashCheckPays(4)
                this.paymentMethods.controls.forEach(async (item, index) => {
                    this.cmPaymentItem.cMContractFeeID = feeId;
                    this.cmPaymentItem.cMMemberFeeID = cmMemberFeeID;
                    this.cmPaymentItem.cMPaymentItemID = item.getRawValue().cMPaymentItemID;
                    this.cmPaymentItem.cMPaymentTxnID = item.getRawValue().cMPaymentTxnID;
                    this.cmPaymentItem.feeTotal = this.changeToNumber(item.getRawValue().signupFee);
                    this.cmPaymentItem.feeBese = this.changeToNumber(item.getRawValue().signupFee);
                    this.cmPaymentItem.isPaymentDone = false;
                    this.showSpinner = true
                    await this._paymentService.CMPaymentItem_Create(this.encryptedUser, this.cmPaymentItem, this.isAI)
                        .then(async (result: SimpleResponse) => {
                            this.enableAll();
                            this.showSpinner = false
                            if (result?.statusCode == 1) {
                                if (this.paymentMethods.controls.length - 1 == index) {
                                    this._notifyService.showSuccess(`The ${feeName} payment was saved successfully`, "", 3000);
                                    this.Log(`${feeName} payment for ${item.getRawValue().signupFee} was saved.`);
                                    this.CMPaymentItem_Get(cmMemberFeeID);
                                    this.getMemberFee(this.memberId, true);
                                }
                            }
                        })
                        .catch((err: HttpErrorResponse) => {
                            this.showSpinner = false
                            this.enableAll();
                            this._globalService.error("cm-contract-client.transactionUpdate", err.message, null);
                            this._utilityService.handleErrorResponse(err);
                            this.Log(`${feeName} payment for ${item.getRawValue().signupFee} was not saved ${err.message}.`);
                        });
                });
            }
        });
    }

    mergeMultipleCashCheckPays(payType) {

        const formArray = this.paymentForm.get('paymentMethods') as FormArray;
        let totalSignupFee = 0;
        const filteredArray = formArray.controls.filter((control) => control.value.paymentType == payType);

        if (filteredArray.length > 0) {
            filteredArray.forEach((control) => {
                const signupFeeValue = this.changeToNumber(control.value.signupFee);
                totalSignupFee += signupFeeValue;
            });

            const mergedObject = {
                cMPaymentItemID: filteredArray[0].value.cMPaymentItemID,
                cMContractFeeID: 0,
                cMPaymentTxnID: [{
                    value: filteredArray[0].value.cMPaymentTxnID, disabled: filteredArray[0]?.value?.isPaymentDone
                }],
                signupFee: [{
                    value: this.changeToCurrency(totalSignupFee), disabled: filteredArray[0]?.value?.isPaymentDone
                }],
                paymentType: payType
            };

            formArray.controls = formArray.controls.filter((control) => control.value.paymentType != payType);

            formArray.push(this.fb.group(mergedObject));
        }

    }

    splitEvenly() {

        let splitAmount = ((this.memberFee.feeTotal) / this.paymentMethods.controls.length);
        this.paymentForm.get('paymentMethods')['controls'].forEach(mem => {
            mem.controls['signupFee'].setValue(this.changeToCurrency(splitAmount))
        });

        this.onChangeAmount(false)
    }

    adjustPayControlValue(total?) {
        if (total > this.memberFee.feeTotal) {

            let diff = total - this.memberFee.feeTotal
            let value = this.changeToNumber(this.paymentMethods.at(this.paymentMethods?.length - 1).get("signupFee").getRawValue()) - diff
            this.paymentMethods.at(this.paymentMethods?.length - 1).get("signupFee").setValue(this.changeToCurrency(value))

        }
        if (total < this.memberFee.feeTotal) {

            let diff = this.memberFee.feeTotal - total
            let value = this.changeToNumber(this.paymentMethods.at(this.paymentMethods?.length - 1).get("signupFee").getRawValue()) + diff
            this.paymentMethods.at(this.paymentMethods?.length - 1).get("signupFee").setValue(this.changeToCurrency(value))

        }

        let amountTotal = 0
        this.paymentMethods.controls.forEach((item, index) => {
            amountTotal += this.changeToNumber(item.getRawValue().signupFee);
        });
        this.displayBeingPaid = this.changeToCurrency(this.memberFee?.feeTotal - amountTotal)
    }

    confirmDelete(transactionId) {
        this.delTransId = transactionId;
        $('#deletePayConfirmation').modal('show');
    }

    async deleteCard() {
        await this._paymentService.CMPaymentTxn_Delete(this.encryptedUser, this.delTransId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result?.statusCode == 1) {
                    this.cMPaymentTxn_Load();
                    $('#deletePayConfirmation').modal('hide');
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.deleteCard", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getPaymentReviews() {
        await this._contractService.GetPaymentReviews(this.encryptedUser, this.clpCompanyId, this.siteId, this.contractId, this.memberId, this.isAI)
            .then(async (result: PaymentReviewListResponse) => {
                if (result) {
                    if (result) {
                        let _paymentReview = UtilityService.clone(result);
                        this.paymentReview = UtilityService.clone(_paymentReview?.paymentReviews);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.getPaymentReviews", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveReview() {
        this.CheckSession(async () => {
            this.navigationUpdate(eCMNavigation_Update_Type.isReview, true);

            await this.cMPaymentItem_Charge_Get(false);

            if (this.paymentChargeItems?.length == 0) {
                this.navigationUpdate(eCMNavigation_Update_Type.isPaymentCollect, true);
                this.dtSignedMembers_Update();
            }

            this.Log("Review was Accepted & Signed.");

            setTimeout(() => {
                this.showDivItem(this.stepper, '#tdPay', '20', null, false, 3)
            }, 500);
        });
    }

    async onClickCharge(paymentItem) {
        this.CheckSession(async () => {
            let carIdx = this.paymentChargeItems.findIndex((item) => item?.txnId == paymentItem?.txnId)

            this.showSpinner = true
            await this._paymentService.Charge(this.encryptedUser, this.clpCompanyId, this.contractId, this.contactId, paymentItem?.txnId, paymentItem?.Total, this.isAI)
                .then(async (result: SimpleResponse) => {
                    this.showSpinner = false
                    if (result?.messageBool) {
                        this._notifyService.showSuccess(`Your payment of $${this.formatNumber(paymentItem?.Total)} was successful.`, "Success", 3000);
                        this.Log(`Payment ${this.chargeFailed ? '(Retry)' : ''} of $${this.formatNumber(paymentItem?.Total)} was successful.`);

                        if (carIdx != -1) {
                            this.paymentChargeItems[carIdx].isPaymentDone = true;
                        }
                        await this.CMPaymentItem_isPaymentDone_Update(paymentItem.txnId);

                        this.cMPaymentItem_Charge_Get(false);
                        this.verifyNonCashCharges();
                    }
                    else {
                        this._notifyService.showError(`Your payment of $${this.formatNumber(paymentItem?.Total)} was unsuccessful. ${result?.messageString2}`, "Issue", 10000);
                        this.chargeFailed = true;
                        this.Log(`Payment ${this.chargeFailed ? '(Retry)' : ''} of $${this.formatNumber(paymentItem?.Total)} was unsuccessful. ${result?.messageString2}`);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("cm-contract-client.Charge", err.message, null);
                    this._utilityService.handleErrorResponse(err);
                    this.Log(`Payment ${this.chargeFailed ? '(Retry)' : ''} of $${this.formatNumber(paymentItem?.Total)} was unsuccessful. ${err.message}`);
                });
        });
    }



    verifyNonCashCharges() {
        if (this.userId == 0) {
            let bank_card_complete = true;
            this.paymentChargeItems.forEach(i => {
                if (i.paymentType <= 2 && !i.isPaymentDone) {
                    bank_card_complete = false;
                }
            });

            if (bank_card_complete) {
                this.paymentChargeItems.forEach(i => {
                    if (i.paymentType > 2 && !i.isPaymentDone) {
                        this.contactPaymentComplete = true;
                    }
                });
            }
        }
    }

    async cMPaymentItem_Charge_Get(isLoad) {
        await this._paymentService.cMPaymentItem_Charge_Get(this.encryptedUser, this.contractId, this.memberId, this.isAI)
            .then(async (result: PayListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.paymentChargeItems = response?.payList;

                    if (this.paymentChargeItems.length > 0) {
                        for (var i = 0; i < this.paymentChargeItems?.length; i++) {
                            if (!this.paymentChargeItems[i]?.isPaymentDone) {
                                this.isAllPayDone = false;
                                this.verifyNonCashCharges();
                                break;
                            }
                            else {
                                this.isAllPayDone = true;
                            }
                        }
                    }
                    else {
                        this.isAllPayDone = true;//User with no payments should fully process;
                    }

                    if ((this.isAllPayDone && !isLoad)) {
                        this.navigationUpdate(eCMNavigation_Update_Type.isPaymentCollect, true);
                        this.showDivItem(this.stepper, '#tdDLUpload', '21', null, false, 3);
                        this.dtSignedMembers_Update();
                        this.cmMemberStatus(6);
                        this.cmContractStatus(4);
                        this.ProcessorContractPDFConvert_Add();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.cMPaymentItem_Charge_Get", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async dtSignedMembers_Update() {
        await this._contractService.dtSignedMembers_Update(this.encryptedUser, this.contractId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.dtSignedMembers_Update", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cMPaymentItem_Delete(fee, index) {
        await this._paymentService.cMPaymentItem_Delete(this.encryptedUser, fee.value.cMPaymentItemID, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response) {
                        this.removePaymentMethod(fee, index)
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.cMPaymentItem_Delete", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onChangeAmount(isFromInp, feeIndex?, payfee?) {

        if (isFromInp) {

            if (this.paymentMethods?.length > 1) {
                let devideFee = (this.memberFee?.feeTotal - this.changeToNumber(payfee?.value?.signupFee)) / (this.paymentMethods?.length - 1)

                this.paymentMethods.controls.forEach((item, index) => {
                    if (index != feeIndex) {
                        item.get('signupFee').setValue(this.changeToCurrency(devideFee))
                    }
                });
            }

        }

        let amountTotal = 0
        this.paymentMethods.controls.forEach((item, index) => {
            amountTotal += this.changeToNumber(item.getRawValue().signupFee);
        });

        amountTotal = Number(amountTotal.toFixed(2));

        await this.adjustPayControlValue(amountTotal)
    }

    async CMPaymentItem_isPaymentDone_Update(txnId) {

        await this._paymentService.CMPaymentItem_isPaymentDone_Update(this.encryptedUser, txnId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.CMPaymentItem_isPaymentDone_Update", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async ProcessorContractPDFConvert_Add() {
        await this._contractService.ProcessorContractPDFConvert_Add(this.encryptedUser, this.clpCompanyId, this.contractId, this.contactId, this.siteId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.getDownloadStatus();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.isForPreviewDownload = false
                this._globalService.error("cm-contract-client.ProcessorContractPDFConvert_Add", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async ProcessorContractPDFConvert_GET() {
        await this._contractService.ProcessorContractPDFConvert_Get(this.encryptedUser, this.clpCompanyId, this.contractId, this.contactId, this.siteId, false, this.isAI)
            .then(async (result: ContractPdfConvertContainer) => {
                if (result) {
                    this.contractPdfConvertContainer = UtilityService.clone(result);
                    if (this.contractPdfConvertContainer?.processed) {
                        clearTimeout(this.isSubscribe);
                        this.isDownload = true;
                        this.isPreviewDownloaded = true
                        if (this.isForPreviewDownload) {
                            this.downloadContract()
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.isForPreviewDownload = false
                this._globalService.error("cm-contract-client.ProcessorContractPDFConvert_GET", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDownloadStatus() {
        this.isSubscribe = setTimeout(() => {
            this.ProcessorContractPDFConvert_GET();
            this.getDownloadStatus();
        }, 3000);
    }

    async downloadContract() {
        this.isDownloaded = true;
        if (this.contractPdfConvertContainer?.processed) {
            await this._contractService.GetContractPDFAsync(this.encryptedUser, this.contractPdfConvertContainer?.fileName, this.contractPdfConvertContainer?.fileLength)
                .then((result: any) => {
                    if (result) {
                        let file = new Blob([result], { type: 'application/pdf' });
                        let fileURL = URL.createObjectURL(file);
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        if (this.contactDetails)
                            fileLink.download = this.contactDetails?.firstName + '_' + this.contactDetails?.lastName + "_Contracts";
                        else
                            fileLink.download = "ContractDocuments.pdf";

                        fileLink.click();
                        this.isDownloaded = false;
                        this.isForPreviewDownload = false;
                        this.Log(`${fileLink.download}.pdf downloaded.`);
                        //setTimeout(function () {
                        //    window.open(fileURL, '_blank');
                        //}, 1000);
                        $('#modalExportDocument').modal('hide');
                    } else {
                        this.isDownloaded = false;
                        this.isForPreviewDownload = false;
                        this.Log(`Contract document not downloaded.`);
                        this._notifyService.showError('An error occurred downloading your contract. Please contact your rep.', '', 7000);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.isDownloaded = false;
                    this.isForPreviewDownload = false
                    this._utilityService.handleErrorResponse(err);
                    $('#modalExportDocument').modal('hide');
                    $('#modalExportDocumentAzure').modal('hide');
                    this.Log(`Contract document not downloaded. ${err.message}`);
                });

        }
        else {
            this.isDownloaded = false;
            this.ProcessorContractPDFConvert_GET();
        }
    }

    disableReview() {
        for (var i = 0; i < this.contractNavList?.length; i++) {
            if (!this.contractNavList[i]?.isAccepted) {
                this.isAllDocAccepted = false;
                break;
            }
            else {
                this.isAllDocAccepted = true;
            }
        }

        let isDetailsAccepted = this.navigation?.isDetails
        if (isDetailsAccepted && this.isAllDocAccepted && this.isPaySetup) {
            return false;
        }
        return true;
    }

    async onPaySourceChange(event, i, payfee) {
        let value = event?.target?.value
        if (value == 0) {
            this.Log(`Add New Payment Method Clicked.`);
            this.showModal();
            let lastPaySrcSelected = this.paymentState?.filter((item) => item?.cMPaymentItemID == payfee?.value?.cMPaymentItemID)[0];
            this.paymentMethods.at(i).get('cMPaymentTxnID').setValue(lastPaySrcSelected?.cMPaymentTxnID);
            return;
        }
        let payObj = this.paymentTrxs.filter((item) => item?.cMPaymentTxnID == payfee?.value?.cMPaymentTxnID)[0];
        this.paymentForm.get('paymentMethods')['controls'].forEach(mem => {
            if (payfee?.value?.cMPaymentTxnID == mem?.value?.cMPaymentTxnID) {
                mem.controls['paymentType'].setValue(payObj?.paymentType);
            }
        });

        this.holdPayState()
    }

    mobileMenu() {
        this.toggleMobileMenu = !this.toggleMobileMenu;
        let menu = document.getElementById("mobileMenu") as HTMLElement;
        if (menu) {
            menu.classList.toggle("mobile-menu");
        }
    }

    hideEditCardModal() {
        $('#editCardModal').modal('hide');
    }

    async onEditCard(paymentItem: Pay) {
        this.CheckSession(async () => {
            this.payType = 'card'
            let card = this.paymentChargeItems.filter((item) => item?.txnId == paymentItem?.txnId)[0]

            //open up the Card edit screen (no cash or check or bank option);
            //if card reentered is the same last 4 as the earlier one, then call _stripeService.UpdateCreditCard else call _stripeService.AddCreditCard and update VPaymentMethodId on CMPaymentTxn

            this.ccForm = this.prepareCCForm();
            this.ccForm.reset();
            await this.retrieveCreditCard(card?.txnId);
            this.Log('Update Card clicked.');

            this.ccForm.patchValue({
                creditCard: "",
                name: `${this.contactDetails?.firstName} ${this.contactDetails?.lastName}`,
                address: this.contactDetails?.add1,
                zipcode: this.contactDetails?.zip,
                phone: this.contactDetails?.mobile,
                email: this.contactDetails?.email,
                city: this.contactDetails?.city,
                state: this.contactDetails?.state,
                country: this.contactDetails?.country ? this.contactDetails?.country != 'US' ? 'US' : this.contactDetails?.country : '0'
            });
        });
    }

    async retrieveCreditCard(cmpaymentTxnID: number) {
        this.editCardPayTxnId = cmpaymentTxnID
        await this._paymentService.retrieveCreditCard(this.encryptedUser, this.contractId, this.contactId, cmpaymentTxnID, this.isAI)
            .then(async (result: RetrieveCardResponse) => {
                if (result?.cardDetails) {
                    let response = UtilityService.clone(result);
                    this.editCardDetails = response?.cardDetails
                    $('#editCardModal').modal('show');
                }
                else {
                    this._notifyService.showError("The card details could not be loaded. Please try again.", "", 7000)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.CMPaymentItem_isPaymentDone_Update", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onUpdateCreditCard() {
        this.CheckSession(async () => {
            let cc: CC = {
                contactId: this.contactId,
                cmMemberId: this.memberId,
                contractId: this.contractId,
                vCustomerId: this.vendorCustomerId,
                memberFeeId: this.memberFeeid,
                total: 0,
                creditCard: this.creditcardform.creditCard.value,
                name: this.creditcardform.name.value,
                exp: this.creditcardform.exp.value,
                cvc: this.creditcardform.cvc.value,
                address: this.creditcardform.address.value,
                zipcode: this.creditcardform.zipcode.value,
                phone: this.creditcardform.phone.value,
                email: this.creditcardform.email.value,
                city: this.creditcardform.city.value,
                state: this.creditcardform.state.value,
                country: this.creditcardform.country.value,
                txnId: this.editCardPayTxnId
            }

            let last4digits = cc?.creditCard?.trim()?.slice(-4);
            let month = parseInt(cc?.exp.substring(0, 2), 10);
            let year = +`20${cc?.exp.substring(2)}`;
            if (last4digits == this.editCardDetails?.last4 && month == this.editCardDetails?.exp_month && year == this.editCardDetails?.exp_year) {
                await this.updateCreditCard(cc) //only updates billing information
                this.hideEditCardModal()
            }
            else {
                await this.verifyCreditCard(cc);
                this.hideEditCardModal();
            }
        });
    }

    async updateCreditCard(cc) {
        this.showSpinner = true;
        await this._paymentService.updateCreditCard(this.encryptedUser, cc, this.contactId, this.editCardPayTxnId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this._notifyService.showSuccess("The credit card was updated successfully.", "Success", 3000);
                    }
                    else {
                        this._notifyService.showError("The credit card could not be updated. Please try again.", "Error", 7000);
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-client.CMPaymentItem_isPaymentDone_Update", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    formatNumber(number) {
        let fixed = number.toFixed(2);
        let parts = fixed.split('.');

        let int = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let formatted = int + '.' + parts[1];

        return formatted;
    }

    getFormattedDate(date: string) {
        if (date) {
            let d = new Date(date);
            let curr_date = d.getDate();
            let curr_month = d.getMonth() + 1; //Months are zero based
            let curr_year = d.getFullYear();
            return curr_month + "/" + curr_date + "/" + curr_year;
        }
        else
            return '';
    }

    async Log(message) {
        let log: CMContractLog = {
            sessionID: this.sessionId,
            clpUserID: this.user.cLPUserID,
            contactID: this.contactId,
            cmContractID: this.contractId,
            isFaceToFace: !this.is_client,
            notes: message
        }
        await this._contractService.CMContractLog_Create(this.encryptedUser, log, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.Log", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    previewUrlFront: string | ArrayBuffer | null = null;
    previewUrlBack: string | ArrayBuffer | null = null;

    onFileSelected(event: Event, type: string): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();

                reader.onload = () => {
                    this.previewUrlFront = reader.result;
                };

                reader.readAsDataURL(file);
                if (type == 'front') {
                    this.dlFront = file
                    if (this.dlFront.size > 5242880) {
                        this.dlFront = null;
                        this._notifyService.showError("File size too large Please upload file less than 5MB.", "Error", 30000);
                        return;
                    }
                    reader.onload = () => {
                        this.previewUrlFront = reader.result;
                    };
                }
                else {
                    this.dlBack = file
                    if (this.dlBack.size > 5242880) {
                        this.dlBack = null;
                        this._notifyService.showError("File size too large Please upload file less than 5MB.", "Error", 3000);
                        return;
                    }
                    reader.onload = () => {
                        this.previewUrlBack = reader.result;
                    };
                }

            }
            else {
                this._notifyService.showError("Please upload a valid image file.", "Error", 3000);
            }
        }
    }

    onFileSelect(event: SelectEvent, type: string): void {

        const file = event.files[0].rawFile as File;

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            if (type == 'front') {
                this.dlFront = file
                if (!this.isValidFile(this.dlFront)) {
                    this.previewUrlFront = null;
                    return;
                }
                if (this.dlFront.size > 5242880) {
                    this.dlFront = null;
                    this._notifyService.showError("File size too large Please upload file less than 5MB.", "Error", 3000);
                    return;
                }
                reader.onload = () => {
                    this.previewUrlFront = reader.result;
                };
            }
            else {
                this.dlBack = file
                if (!this.isValidFile(this.dlBack)) {
                    this.previewUrlBack = null;
                    return;
                }
                if (this.dlBack.size > 5242880) {
                    this.dlBack = null;
                    this._notifyService.showError("File size too large Please upload file less than 5MB.", "Error", 3000);
                    return;
                }
                reader.onload = () => {
                    this.previewUrlBack = reader.result;
                };
            }
        }
    }

    isValidFile(file: any): boolean {
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'bmp'];
        const type: string = file.type.toLowerCase();
        let extension = type.split('/')[1];
        return allowedExtensions.includes(extension);
    }

    async uploadDL() {
        let formData = new FormData();
        if (!this.dlFront) {
            return this._notifyService.showError("Please upload front side of DL.", "Error", 3000);
        }
        if (!this.dlBack) {
            return this._notifyService.showError("Please upload back side of DL.", "Error", 3000);
        }
        formData.append('dlFront', this.dlFront, this.dlFront.name);
        formData.append('dlBack', this.dlBack, this.dlBack.name);

        this.showSpinner = true;
        await this._contractService.CM_MemberDLFileUpload(this.encryptedUser, formData, this.contractId, this.memberId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.CMContract_CheckMemberDL();
                        this.dtSignedMembers_Update();
                        this._notifyService.showSuccess("Driving license uploaded successfully.", "Success", 3000);
                        await this.navigationUpdate(eCMNavigation_Update_Type.isDLUploaded, true);
                        await this.cmContractStatus(4);
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("cm-contract-client.Log", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async CMContract_CheckMemberDL() {
        await this._contractService.CMContract_CheckMemberDL(this.encryptedUser, this.contractId, this.clpCompanyId, this.contactId, this.siteId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageBool) {
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.Log", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async ContractOpenAutomation() {
        await this._contractService.ContractOpenAutomation(this.encryptedUser, this.clpCompanyId, this.contractId, this.contactId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageBool) {
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.Log", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    get paymentMethods(): FormArray {
        return this.paymentForm.get("paymentMethods") as FormArray
    }

    get creditcardform() { return this.ccForm.controls; }

    get bankform() { return this.bankForm.controls; }
}
