import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BCMCLPUser, TeamCodes } from '../../models/clpuser.model';
import { CMClub, CMSite, SiteDDListResponse } from '../../models/cm-club-service.model';
import { DropDownItem, IntDropDownItem, SimpleResponse } from '../../models/generic-response.model';
import { Fee, Product, ProductResponse, SimpleProduct } from '../../models/product.model';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import * as $ from "jquery";
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CMContractService } from '../../services/cm-contract.service';
import { CMClubService } from '../../services/cm-club.service';
import { CMContractMember, Contact, ContactDetails, CMContract, ContractResponse, CMContractSetting, CMContractSettingResponse, CMMemberStatus, CMContractFeeListResponse, CMContractFee, CMMemberFeeListResponse, CMMemberFee, FeeButton, ContractPdfConvertContainer } from '../../models/cm-contract';
import { ProductService } from '../../services/product.service';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { NotificationService } from '../../services/notification.service';
import { CountryStateService } from '../../services/country-state.service';
import { Search, SearchListResponse } from '../../models/search.model';
import { SearchContactService } from '../../services/shared/search-contact.service';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { CountryPlaceholder } from '../../models/country-state.model';
import { CountryCode } from 'libphonenumber-js';
import { formatDate } from '@progress/kendo-angular-intl';
import { Title } from '@angular/platform-browser';
import { PaymentService } from '../../services/payment.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { ConfigDetails } from '../../models/app-config.model';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
declare var $: any;

@Component({
    selector: 'app-cm-contract',
    templateUrl: './cm-contract.component.html',
    styleUrl: './cm-contract.component.css',
    providers: [provideNativeDateAdapter()],

})
export class CmContractComponent {
    agentForm: FormGroup;
    siteTaxForm: FormGroup;
    termForm: FormGroup;
    paymentForm: FormGroup;
    contactForm: FormGroup;
    contactForm2: FormGroup;
    textForm: FormGroup;
    testModeForm: FormGroup;

    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
    isAI: boolean;
    contactId: number = 0;
    newContactId: number = 0;
    encryptedUser: string = ''
    user: BCMCLPUser;
    mailMergeTemplateList: IntDropDownItem[];
    cMProductUnused: SimpleProduct[];
    curSite: CMSite = <CMSite>{}
    curTeam: TeamCodes = <TeamCodes>{};
    curSiteSub: Subscription;
    clubConfig: CMClub;
    productList: Product[];
    product: Product = <Product>{}
    /*    countryDD: DropDownItem[];*/
    stateList: DropDownItem[];
    countryDD: CountryPlaceholder[];
    clubId: number = 0;
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    placeHolder: string = "";
    mobile_mask: string = "";
    selectedAgreements: number[] = [];
    selectedRules: number[] = [];
    siteList: CMSite[];
    site: CMSite = <CMSite>{};
    clpCompanyId: number;
    contracts: CMContract[] = [];
    contract: CMContract = <CMContract>{};
    contractMember: CMContractMember = <CMContractMember>{};
    //contact: ContactDetails = <ContactDetails>{};;
    contacts: ContactDetails[];
    siteId: number = -1;
    classCode1: number = 0;
    public siteSelected: number;
    public siteSelectedName: string;
    public productSelected: number;
    public productSelectedName: string;
    memberFee: number = 0;
    memberFeeTax: any = 0;
    signupFee: number = 0;
    signupFeeTax: any = 0;
    totalSignupFee: number = 0;
    totalMemberFee: number = 0;
    sites: CMSite[];
    contactDetails: any = [];
    public format: FormatSettings = {
        displayFormat: 'MM/dd/yyyy',
        inputFormat: 'MM/dd/yy'
    };

    dtDate: Date = new Date();
    dtStartDate: Date = new Date();
    dtBeginDate: Date = new Date();
    dtEndDate: Date = new Date();
    dtDOB: Date = new Date();

    isContactCreate: boolean = false;
    countryCode: string = "US";
    stateCode: string = "";
    searchList: Search[] = [];
    inputBoxValue: string = "";
    contractId: number = 0;
    contactData: Contact = <Contact>{};
    contractEdit: boolean = false;
    dtSign: any;
    //dtCNStart: Date = new Date();
    //dtPayBegin: Date = new Date();
    //dtCntExp: Date = new Date();
    memberFeeUpdated: number;
    signupFeeUpdated: number;
    isSeasonal: boolean = false;
    isEveryday: boolean = false;

    ContractSetting: CMContractSetting = <CMContractSetting>{};
    placeholder: string = "";
    memContactId: number;
    delContactId: number;
    membersForm: FormGroup
    emailTxtMsg: boolean = false;
    contractFees: CMContractFee[];
    memberFeeList: CMMemberFee[];
    feeButtonList: FeeButton[] = [];
    isValidFee: boolean = true;
    isMemberExist: boolean;
    taxRate: number;
    isEditMem: boolean = false
    maxMembers: number = 0
    dltMemIndex: number = 0;
    isPaymentDone: number = 0;
    showSpinner: boolean;

    txtContactId: number = 0;
    txtContact_i: number = 0;
    bcmSiteUrl: string;

    phSendContractMessageText: string = '';
    phSendContractMediaURL: string = '';
    contractStatusId: number = 0;
    isDownloaded: boolean;
    isSubscribe: any;
    contractPdfConvertContainer: ContractPdfConvertContainer;
    triggerContactId: number = 0;


    constructor(private localService: LocalService,
        private fb: FormBuilder,
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _contractService: CMContractService,
        private _clubService: CMClubService,
        private titleService: Title,
        private _productService: ProductService,
        private _notifyService: NotificationService,
        private _countryStateService: CountryStateService,
        private _searchContactService: SearchContactService,
        private _paymentService: PaymentService,
        private _appconfigService: AppConfigService,
        public datepipe: DatePipe,
    ) {
        this.titleService.setTitle('Contract Manager');
        this.localService.setShowMenu(true);
        this.localService.setIsContract(true);
        this.localService.setIsCmManager(false);
        this.localService.setRouteName("cm-contract")
    }

    ngOnInit() {
        this.agentForm = this.prepareAgentForm();
        this.termForm = this.prepareTermForm();
        this.siteTaxForm = this.prepareSiteTaxForm();
        this.paymentForm = this.preparePaymentForm();
        this.contactForm = this.prepareContactForm();
        this.contactForm2 = this.prepareContactForm2();
        this.membersForm = this.prepareMemberForm();
        this.textForm = this.prepareTextForm();
        this.testModeForm = this.prepareTestModeForm();

        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
        this._route.queryParamMap.subscribe(params => {
            this.encryptedUser = params.get('r');

            this._route.paramMap.subscribe(queryParams => {
                this.contactId = +queryParams.get('contactId');
                this.newContactId = this.contactId;
            });

            if (!this.encryptedUser?.includes(":")) {
                this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            }
            if (this.encryptedUser) {
                this.authenticateR().then(() => {
                    if (this.user) {
                        localStorage.setItem("token", this.encryptedUser);
                        this.refreshContract()

                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                this._router.navigate(['/unauthorized']);
            }
        });
    }


    async dtSignedMembers_Update() {
        await this._contractService.dtSignedMembers_Update(this.encryptedUser, this.contractId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.dtSignedMembers_Update", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async refreshContract() {
        this.showSpinner = true
        await this.dtSignedMembers_Update()
        this.getBCMBaseUrl()
        this.getCountry();
        this.getCountryStateList(this.countryCode);

        this.getInitialContact();
        this.agentForm.patchValue({
            agentName: this.user.firstName + " " + this.user.lastName
        });
        this.termForm.patchValue({
            term: ''
        });

        setTimeout(() => {
            this.showSpinner = false
        }, 3000)
    }

    //#region prepare forms

    prepareMemberForm() {
        return this.fb.group({
            members: this.fb.array([this.addMember()]),
        })
    }

    addMember(): FormGroup {
        return this.fb.group({
            firstName: [''],
            lastName: [''],
            add1: [''],
            city: [''],
            state: [''],
            zip: [''],
            email: [''],
            mobile: [''],
            signupFee: [''],
            membershipFee: [''],
            memberFees: this.fb.array([this.addMemberFees()]),
            contactId: [''],
            statusId: [0],
            cMMemberID: [0],
            isPrimary: [false],
            isF2FLinkCopied: [false],
            isExtraMem: [false]
        });
    }

    addMemberFees() {
        return this.fb.group({
            cMContractFeeID: new FormControl(0),
            cmProductFeeID: new FormControl(0),
            cmProductID: new FormControl(0),
            cmMemberFeeID: new FormControl(0),
            cMFeeName: new FormControl(""),
            feeTotal: new FormControl(0),
            feeBase: new FormControl(0),
            feeTax: new FormControl(0),
            isTaxable: new FormControl(0)
        })
    }

    prepareAgentForm() {
        return this.fb.group({
            agentName: ['']
        })
    }

    prepareTestModeForm() {
        return this.fb.group({
            processorTestMode: [false]
        })
    }

    prepareTermForm() {
        return this.fb.group({
            term: ['']
        })
    }

    getBCMBaseUrl() {
        this._appconfigService.bCMGetConfigKeyValue(this.encryptedUser, "bcmsite")
            .subscribe(async (result: ConfigDetails) => {
                if (result) {
                    this.bcmSiteUrl = result?.configValue
                }
            });
    }

    prepareSiteTaxForm() {
        let expires = this.addDays(this.dtStartDate, 7);
        let end = this.addMonths(this.dtBeginDate, 12);

        return this.fb.group({
            SignupFee: ['', [Validators.required]],
            SignupFeeTax: [''],
            MembershipFee: ['', [Validators.required]],
            MembershipFeeTax: [''],
            fees: this.fb.array([]),
            dtSignedMembers: [''],
            contractStart: [this.dtStartDate],
            contractStartLabel: [this.getFormattedDate(this.dtStartDate)],
            contractEnd: [end],
            contractEndLabel: [this.getFormattedDate(end)],
            paymentBegin: [this.dtBeginDate],
            paymentBeginLabel: [this.getFormattedDate(this.dtBeginDate)],
            contractExpires: [expires],
            contractExpiresLabel: [this.getFormattedDate(expires)],
            specialterms: [''],
            productDesc: [''],
            isJanuary: [false],
            isFebruary: [false],
            isMarch: [false],
            isApril: [false],
            isMay: [false],
            isJune: [false],
            isJuly: [false],
            isAugust: [false],
            isSeptember: [false],
            isOctober: [false],
            isNovember: [false],
            isDecember: [false],
            isMonday: [false],
            isTuesday: [false],
            isWednesday: [false],
            isThursday: [false],
            isFriday: [false],
            isSaturday: [false],
            isSunday: [false]
        })
    }

    preparePaymentForm() {
        return this.fb.group({
            isEntryACH: [false],
            isEntryCC: [false],
            isEntryCheck: [false],
            isEntryCash: [false],
            isMonthlyACH: [false],
            isMonthlyCC: [false],
            isMonthlyCheck: [false],
            isAffirmEntry: [false]
        })
    }

    prepareContactForm() {
        return this.fb.group({
            txtFirstName: [{ value: '' }, [Validators.required]],
            txtLastName: [{ value: '' }, [Validators.required]],
            txtEmail: [{ value: '' }, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/)]],
            txtMobilePhone: ['', [Validators.required]],
            txtHomePhone: [{ value: '' }],
            txtAddress: [{ value: '' }],
            txtCity: [{ value: '' }],
            txtZip: [{ value: '' }],
            contactId: [{ value: 0 }]
        })
    }

    prepareContactForm2() {
        return this.fb.group({
            dl: [{ value: '' }],
            dob: [''],
            emgContactName: [{ value: '' }],
            txtEmgCntPhone: [{ value: '' }],
        })
    }

    validateNumber(event: KeyboardEvent) {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();

                if (!control.valid && !control.disabled) {
                    this._notifyService.showError(field, control.value + ' is invalid.', 5000);
                }
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    private async authenticateR() {
        await this.localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    //#endregion

    //#region dropdown activity

    async getCountry() {
        await this._countryStateService.getListWithPlaceholder(this.encryptedUser)
            .then(async (result: CountryPlaceholder[]) => {
                if (result) {
                    this.countryDD = UtilityService.clone(result);
                    this.countryDD?.unshift({
                        country_Code: "",
                        country_Name: "--Select--",
                        placeholder: ""
                    });
                    this.onChangeCountry(this.countryCode)
                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeCountry(countryCode) {
        this.stateCode = ""
        this.getCountryStateList(countryCode)
        var country_Code = this.countryDD.filter(x => x.country_Code == countryCode)[0]?.country_Code;
        this.placeholder = this.countryDD.filter(x => x.country_Code == countryCode)[0]?.placeholder;

        if (!isNullOrUndefined(country_Code) && !isNullOrUndefined(this.placeholder)) {
            this.placeHolder = this._countryStateService.parseSimplePhone(this.placeholder, country_Code as CountryCode);
            this.mobile_mask = this._countryStateService.replaceZero(this.placeHolder);
        }
    }

    async getCountryStateList(countryCode: string) {
        await this._countryStateService.getCountryStateList(this.encryptedUser, countryCode ? countryCode : "US")
            .then(async (result: DropDownItem[]) => {
                if (result) {
                    this.stateList = UtilityService.clone(result);
                    this.stateList?.unshift({
                        value: "",
                        text: "--Select--",
                        isSelected: false
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    //#endregion

    //#region contact

    async getContactDetails(callback) {
        await this._contractService.LoadContractMemberDisplay(this.encryptedUser, this.contactId, this.isAI).
            then(async (result: any) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response) {
                        if (callback) {
                            callback(response);
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContactDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getInitialContact() {
        this.getContactDetails((response) => {
            this.contactDetails = [];
            this.contactDetails.push(response);
            this.classCode1 = response.class1Codes;
            this.getSiteData();
        });
    }

    async getContactData(isRefresh: boolean = false) {
        this.getContactDetails((response) => {
            this.contactDetails = [];
            this.contactDetails.push(response);
            this.classCode1 = response.class1Codes;
            this.buildContactList(isRefresh);
        });
    }

    async getContactById(_contactId: number) {
        this.getContactDetails((response) => {
            this.contactDetails.push(response);
            this.isContactCreate = true;
            this.patchContact(response);
        });
    }

    buildContactList(isRefresh: boolean = false) {
        if (this.contacts == null || this.contacts == undefined) {
            this.contacts = [];
        }
        this.contactDetails.forEach((c) => {
            let contact: ContactDetails = {
                contactId: c.contactID,
                add1: c.add1,
                city: c.city,
                country: c.country,
                state: c.state,
                zip: c.zip,
                mobile: c.mobile,
                homePhone: c.homePhone,
                firstName: c.firstName,
                lastName: c.lastName,
                email: c.email,
                signupFee: c.signupFee,
                memberFee: c.memberFee,
                custom4: c.custom4,
                custom5: c.custom5,
                custom6: c.custom6,
                custom7: c.custom7,
                dl: c.dl,
                dob: c.dob,
                emergencyCName: c.emergencyCName,
                emergencyCPhone: c.emergencyCPhone,
                cMMemberID: !isNullOrUndefined(this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.cMMemberID) ? this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.cMMemberID : 0,
                statusId: this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.statusID
            };
            if (this.contacts.some(cnt => cnt.contactId == c.contactID) == false)
                this.contacts.push(contact);
            else {
                this.contacts.forEach((item) => {
                    if (item.contactId == c.contactID) {
                        item.add1 = contact.add1;
                        item.city = contact.city;
                        item.country = contact.country;
                        item.state = contact.state;
                        item.zip = contact.zip;
                        item.mobile = contact.mobile;
                        item.homePhone = contact.homePhone;
                        item.firstName = contact.firstName;
                        item.lastName = contact.lastName;
                        item.email = contact.email;
                        item.signupFee = contact.signupFee;
                        item.memberFee = contact.memberFee;
                        item.custom4 = contact.custom4;
                        item.custom5 = contact.custom5;
                        item.custom6 = contact.custom6;
                        item.custom7 = contact.custom7;
                        item.statusId = contact.statusId;
                        item.cMMemberID = contact.cMMemberID;
                        item.dl = contact.dl;
                        item.dob = contact.dob;
                        item.emergencyCName = contact.emergencyCName;
                        item.emergencyCPhone = contact.emergencyCPhone;
                    }
                });
            }
        });

        this.members.controls = [];
        this.members.removeAt(0);
        if (this.contacts?.length > 0) {
            this.contacts = this.toSetPrimaryMemAtFirst(this.contacts);
            this.contacts?.forEach(async (element, index) => {

                let memFee: CMMemberFee[] = [];
                let member: CMContractMember = <CMContractMember>{}
                if (this.contracts[0]?.members?.some((item) => item.cMMemberID == element?.cMMemberID) == true) {
                    member = this.contracts[0]?.members?.filter((item) => item.cMMemberID == element?.cMMemberID)[0];
                    memFee = member?.CMMemberFees;
                }
                if (memFee && memFee?.length == 0) {
                    this.contracts[0]?.members[0]?.CMMemberFees.forEach((item) => {
                        let obj = {
                            cmContractFeeID: item?.cmContractFeeID,
                            cmProductFeeID: item?.cmProductFeeID,
                            cmProductID: item?.cmProductID,
                            cmMemberFeeID: 0,
                            cmFeeName: item?.cmFeeName,
                            feeTotal: item?.feeTotal,
                            feeTax: 0,
                            isTaxable: item?.isTaxable,
                            memberID: item?.memberID,
                            contactID: 0,
                            sOrder: item?.sOrder,
                            feeBase: 0,
                            isDone: false,
                            memberSplitable: 0,
                            isPrimary: false,
                            isPaymentSplitable: item?.isPaymentSplitable,
                            isCollectNow: item?.isCollectNow

                        }
                        memFee.push(obj)
                    });

                    //if no member fee exists, pull it from product 
                    if (memFee.length == 0) {
                        if (this.product?.fees?.length > 0) {
                            this.product?.fees.forEach((fee, index) => {
                                let obj = {
                                    cmContractFeeID: 0,
                                    cmProductFeeID: fee?.cmProductFeeID,
                                    cmProductID: fee?.cmProductID,
                                    cmMemberFeeID: 0,
                                    cmFeeName: fee?.cmFeeName,
                                    feeTotal: 0,
                                    feeTax: Math.round(this.changeToNumber(fee.feeAmount.toString()) * (this.site.taxRate / 100)),
                                    isTaxable: fee?.isTaxable,
                                    memberID: 0,
                                    contactID: 0,
                                    sOrder: fee?.sOrder,
                                    feeBase: this.newContactId == element.contactId ? fee?.feeAmount : 0,
                                    //feeBase: fee.memberSplitable == 1 || fee.memberSplitable == 2 ? this.newContactId == element.contactId ? fee?.feeAmount : 0 : fee.memberSplitable == 3 ? fee?.feeAmount : fee?.feeAmount,
                                    isDone: false,
                                    memberSplitable: 0,
                                    isPrimary: false,
                                    isPaymentSplitable: fee?.isPaymentSplitable,
                                    isCollectNow: fee?.isCollectNow
                                }
                                memFee.push(obj)
                            });
                        }
                    }
                }

                memFee = memFee.sort((a, b) => a.sOrder - b.sOrder);
                let memObj = new FormGroup({
                    firstName: new FormControl(element.firstName),
                    lastName: new FormControl(element.lastName),
                    add1: new FormControl(element.add1),
                    city: new FormControl(element.city),
                    state: new FormControl(element.state),
                    zip: new FormControl(element.zip),
                    email: new FormControl(element.email),
                    mobile: new FormControl(element.mobile),
                    signupFee: new FormControl(this.changeToCurrency(element.signupFee)),
                    membershipFee: new FormControl(this.changeToCurrency(element.memberFee)),
                    memberFees: this.fb.array((memFee ?? []).map(fee => this.fb.group({
                        cMContractFeeID: new FormControl(fee?.cmContractFeeID),
                        cmProductFeeID: new FormControl(fee?.cmProductFeeID),
                        cmProductID: new FormControl(fee?.cmProductID),
                        cmMemberFeeID: new FormControl(fee?.cmMemberFeeID),
                        cMFeeName: new FormControl(fee?.cmFeeName),
                        feeTotal: new FormControl(this.changeToCurrency(fee.feeTotal)),
                        feeBase: new FormControl(this.changeToCurrency(fee.feeBase)),
                        feeTax: new FormControl(Math.round(this.changeToNumber(fee.feeTotal.toString()) * (this.site.taxRate / 100))),
                        isPrimary: new FormControl(member.isPrimary),
                        isTaxable: new FormControl(fee?.isTaxable)
                    }))),
                    contactId: new FormControl(element.contactId),
                    statusId: new FormControl(element.statusId),
                    cMMemberID: new FormControl(element.cMMemberID),
                    isF2FLinkCopied: new FormControl(false),
                    isExtraMem: new FormControl(member?.isExtraMem)

                })

                this.members.push(memObj);
            });
        }

        if (this.contractFees?.length > 0) {
            this.fees.controls = [];
            this.fees.removeAt(0);
            this.feeButtonList = [];

            this.contractFees.forEach((fee, index) => {
                this.fees.push(
                    this.fb.group({
                        cMContractFeeID: fee.cMContractFeeID,
                        cmProductFeeID: fee.cMProductFeeID,
                        cmFeeName: fee.cMFeeName,
                        feeAmount: this.changeToCurrency(fee.feeBase),
                        feeTax: this.changeToCurrency((fee.feeBase * this.site.taxRate) / 100) + ' (' + this.site.taxRate + '%)',
                        isCollectNow: fee.isCollectNow,
                        isTaxable: fee.isTaxable,
                        isRecurring: fee.isRecurring,
                        recursEvery: fee.recursEvery,
                        recursEveryInterval: fee.recursEveryInterval,
                        isPaymentSplitable: fee.isPaymentSplitable,
                        memberSplitable: fee.memberSplitable,
                        sOrder: fee.sOrder
                    })
                );

                if (fee.memberSplitable == 2) {
                    this.feeButtonList.push({
                        cMContractFeeID: fee?.cMContractFeeID,
                        cMProductFeeID: fee.cMProductFeeID,
                        cMProductID: fee.cMProductID,
                        cMFeeName: fee.cMFeeName
                    });
                }
            });

        }
        if (isRefresh)
            this.refreshMemAmount();

    }

    toSetPrimaryMemAtFirst<T>(array): T[] {
        const index = array.findIndex(obj => obj.contactId == this.newContactId);
        if (index !== -1) {
            const objectToMove = array.splice(index, 1)[0];
            array.unshift(objectToMove);
        }
        return array;
    }

    async getMemberFee(_memberId: number) {
        await this._contractService.getMemberFee(this.encryptedUser, this.contractId, _memberId, this.isAI).
            then(async (result: CMMemberFeeListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.memberFeeList = response.memberFees;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractFee", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getMembershipFees(mem) {
        let controls = mem.controls['memberfees']?.controls.fees['controls'];
        return controls;
    }

    async patchContact(contact) {
        await this.contactForm.patchValue({
            txtFirstName: contact.firstName,
            txtLastName: contact.lastName,
            txtEmail: contact.email,
            txtMobilePhone: contact.mobile,
            txtHomePhone: contact.homePhone,
            txtZip: contact.zip,
            txtCity: contact.city,
            txtAddress: contact.add1,
            contactId: contact.contactId
        });

        this.countryCode = contact.country;
        this.stateCode = contact.state;

        await this.contactForm2.patchValue({
            dl: contact.dl,
            emgContactName: contact.emergencyCName,
            txtEmgCntPhone: contact.emergencyCPhone,
            dob: contact.dob
        });
    }

    importPrimary() {
        if (this.contacts.length > 0) {
            this.contactForm.patchValue({
                txtHomePhone: this.contacts[0]?.homePhone,
                txtZip: this.contacts[0]?.zip,
                txtCity: this.contacts[0]?.city,
                txtAddress: this.contacts[0]?.add1
            });

            this.countryCode = this.contacts[0]?.country;
            this.stateCode = this.contacts[0]?.state;
        }
    }

    //#endregion

    //#region site

    async getSiteData() {
        await this._clubService.Site_GetDD(this.encryptedUser, this.clpCompanyId, this.contactId, this.isAI).
            then(async (result: SiteDDListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.siteList = response.sites;
                    this.clubId = response.clubId;

                    this.agentForm.patchValue({
                        agentName: response.agentName
                    });

                    if (this.classCode1) {
                        if (this.siteList.some(b => b.class1Code == this.classCode1) == true) {
                            this.site = this.siteList.filter(l => l.class1Code == this.classCode1)[0];
                            this.taxRate = this.site?.taxRate
                            this.setSite(this.site.siteID);

                            this.siteSelected = this.site.siteID

                            let filtered_s = this.siteList.filter(s => s.siteID == this.siteSelected);
                            if (filtered_s) {
                                this.siteSelectedName = filtered_s[0]?.siteName;
                            }
                        }
                    }
                    else {
                        this._notifyService.showError(`Please select a site in this contact's details.`, "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getSiteData", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async siteChange(siteId) {
        this.site = this.siteList?.filter((item) => item?.siteID == siteId)[0];
        this.setSite(this.site.siteID);
    }

    async setSite(siteId) {
        this.siteId = siteId;
        this.siteSelected = this.siteId;

        await this.getProduct();
        this.getContractDetailsById();
        this.getContractSetting();
    }

    //#endregion

    //#region product

    async getProduct() {
        await this._productService.CMProduct_Get(this.encryptedUser, this.clpCompanyId, this.clubId, this.siteId, this.isAI)
            .then(async (result: ProductResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.productList = response?.products;
                    let filtered = this.productList.filter(p => p.isDefault && p.isActive);
                    if (filtered) {
                        this.product = filtered[0];

                        this.maxMembers = this.product?.intMaxMembers;
                        this.setProduct(this.product);

                        this.productSelected = this.product?.cmProductID;

                        let p_filtered = this.productList.filter(p => p.cmProductID == this.productSelected);
                        if (p_filtered) {
                            this.productSelectedName = p_filtered[0]?.productName;
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    setProduct(product: Product) {
        this.productSelected = product?.cmProductID;

        this.termForm.patchValue({
            term: this.product?.contractTerm
        });

        this.siteTaxForm.patchValue({
            productDesc: this.product?.productDesc
        });

        this.isSeasonal = this.product?.isSeasonal;
        this.isEveryday = this.product?.daysOfWeekOnPlan.split(',').length == 7;


        let beginDate = this.siteTaxForm.controls["paymentBegin"].value;
        //let end = this.addMonths(this.dtBeginDate, +this.product?.contractTerm);
        let end = this.addMonths(beginDate, +this.product?.contractTerm);

        if (!this.isEveryday) {
            this.siteTaxForm.patchValue({
                isMonday: this.product?.isMonday,
                isTuesday: this.product?.isTuesday,
                isWednesday: this.product?.isWednesday,
                isThursday: this.product?.isThursday,
                isFriday: this.product?.isFriday,
                isSaturday: this.product?.isSaturday,
                isSunday: this.product?.isSunday,
                contractEnd: end,
                contractEndLabel: this.getFormattedDate(end)
            });
        }
        else {
            this.siteTaxForm.patchValue({
                contractEnd: end,
                contractEndLabel: this.getFormattedDate(end)
            });
        }

        this.fees.controls = [];
        this.fees.removeAt(0);
        this.feeButtonList = [];

        if (this.product?.fees?.length > 0) {
            this.product?.fees.forEach((fee, index) => {
                this.fees.push(
                    this.fb.group({
                        cMContractFeeID: 0,
                        cmProductFeeID: fee.cmProductFeeID,
                        cmProductID: fee.cmProductID,
                        cmFeeName: fee.cmFeeName,
                        feeAmount: this.changeToCurrency(fee.feeAmount),
                        feeTax: this.changeToCurrency((fee.feeAmount * this.site.taxRate) / 100) + ' (' + this.site.taxRate + '%)',
                        isCollectNow: fee.isCollectNow,
                        isTaxable: fee.isTaxable,
                        isRecurring: fee.isRecurring,
                        recursEvery: fee.recursEvery,
                        recursEveryInterval: fee.recursEveryInterval,
                        isPaymentSplitable: fee.isPaymentSplitable,
                        memberSplitable: fee.memberSplitable,
                        sOrder: fee.sOrder
                    })
                );

                if (fee.memberSplitable == 2) {
                    this.feeButtonList.push({
                        cMContractFeeID: fee.cmProductFeeID,
                        cMProductFeeID: fee.cmProductFeeID,
                        cMProductID: fee.cmProductID,
                        cMFeeName: fee.cmFeeName
                    });
                }
            });

        }


        //if (this.contactDetails.length > 0) {
        //    this.totalMemberFee = this.product?.feeMembership;
        //    this.totalSignupFee = this.product?.feeSignUp;
        //}

        this.buildContactList();
    }

    contractStartChange(event: MatDatepickerInputEvent<Date>) {
        let expires = this.addDays(event.value, 7);

        this.siteTaxForm.patchValue({
            contractExpires: expires,
            contractExpiresLabel: this.getFormattedDate(expires)
        });
    }

    contractBeginsChange(event: MatDatepickerInputEvent<Date>) {
        let term = +this.termForm.controls["term"].value;
        let end = this.addMonths(event.value, term);

        this.siteTaxForm.patchValue({
            contractEnd: end,
            contractEndLabel: this.getFormattedDate(end)
        });
    }

    changeTerm() {
        let beginDate = this.siteTaxForm.controls["paymentBegin"].value;
        let end = this.addMonths(beginDate, +this.termForm.controls["term"].value);

        this.siteTaxForm.patchValue({
            contractEnd: end,
            contractEndLabel: this.getFormattedDate(end)
        });
    }

    getProductDescription() {
        return this.siteTaxForm.controls['productDesc'].value;
    }

    productChange(productId) {
        this.product = this.productList.filter(p => p.cmProductID == productId)[0];
        this.maxMembers = this.product?.intMaxMembers
        this.setProduct(this.product)
    }
    //#endregion

    //#region CMContract details

    async getContractSetting() {
        await this._contractService.cmSiteContractSetting_Get(this.encryptedUser, this.siteId, this.isAI).
            then(async (result: CMContractSettingResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.ContractSetting = response.setting;
                    this.patchConfigForm();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchConfigForm() {
        this.paymentForm.patchValue({
            isEntryCC: this.ContractSetting?.isEntryCC,
            isEntryACH: this.ContractSetting?.isEntryACH,
            isEntryCheck: this.ContractSetting?.isEntryCheck,
            isEntryCash: this.ContractSetting?.isEntryCash,
            isMonthlyCC: this.ContractSetting?.isMonthlyCC,
            isMonthlyACH: this.ContractSetting?.isMonthlyACH,
            isMonthlyCheck: this.ContractSetting?.isMonthlyCheck,
        });
    }

    async getContractDetails(_contractId) {
        await this._contractService.cmContractGet(this.encryptedUser, this.clpCompanyId, 0, this.siteId, this.contractId, this.isAI).
            then(async (result: ContractResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contracts = [];
                    this.contracts = UtilityService.clone(response.contracts);
                    if (this.contracts && this.contracts.length > 0) {

                        this.totalSignupFee = 0;
                        this.totalMemberFee = 0;
                        this.patchContractData();
                        if (this.contracts[0]?.members) {
                            this.contacts = [];
                            this.contracts[0]?.members.forEach((item) => {
                                this.contactId = item.contactID;
                                this.totalSignupFee += item.feeSignUp;
                                this.totalMemberFee += item.feeMembership;
                                this.getContactData(false);
                            });
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContractDetailsById() {
        await this._contractService.cmContractGetById(this.encryptedUser, this.user?.cLPUserID, this.newContactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.contractId = response.messageInt;
                        this.cMContractFee_Get(this.contractId, () => {
                            this.getContractDetails(this.contractId);
                            this.contractEdit = false;
                        });
                        this.checkContractPayment();
                    }
                    else {
                        this.contractId = 0;
                        this.contractEdit = true;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContractDeletedById() {
        await this._contractService.cmContractGetById(this.encryptedUser, this.user?.cLPUserID, this.newContactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.contractId = response.messageInt;
                        this.cMContractFee_Get(this.contractId, () => {
                            this.getContractDetails(this.contractId);
                            //this.contractEdit = false;
                        });
                        this.checkContractPayment();
                    }
                    else {
                        this.contractId = 0;
                        this.contractEdit = true;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDeletedById", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    calculateSignUpFee($event) {
        this.totalSignupFee = +$event;
        console.log('calculateEntryFee', $event);
        this.contacts.forEach((item) => {
            if (item.contactId == this.contactId)
                item.signupFee = +$event
        });
    }

    calculateMemberFee($event) {
        this.totalMemberFee = +$event;
        console.log('calculateMemberFee', $event);
        this.contacts.forEach((item) => {
            if (item.contactId == this.contactId)
                item.memberFee = +$event
        });
    }

    async saveContract() {
        let invalidDates = ''
        if (this.siteTaxForm.controls['contractStart'].value == null || this.siteTaxForm.controls['contractStart'].value == undefined) {
            invalidDates = "Contract Start, "
        }

        if (this.siteTaxForm.controls['paymentBegin'].value == null || this.siteTaxForm.controls['paymentBegin'].value == undefined) {
            invalidDates = invalidDates + "Payment Begin, "
        }

        if (this.siteTaxForm.controls['contractExpires'].value == null || this.siteTaxForm.controls['contractExpires'].value == undefined) {
            invalidDates = invalidDates + "Contract Expire "
        }

        if (invalidDates?.length > 0) {
            this._notifyService.showError(invalidDates + "dates are invalid", "", 3000)
            return
        }

        this.prepareContractForm();
        let isPayByEveryMem = true
        let feeName = ""
        if (this.product?.fees?.length > 0) {
            this.product?.fees.forEach((fee) => {
                if (fee?.memberSplitable == 3) {
                    this.contract.memberfees.forEach((mem) => {

                        let isExtraMem = this.contract.members.find((item) => item?.cMMemberID == mem?.memberID).isExtraMem;
                        if (fee?.cmProductFeeID == mem?.cmProductFeeID && fee?.feeAmount > 0 && !isExtraMem) {
                            if (mem?.feeBase == 0) {
                                isPayByEveryMem = false;
                                feeName = `${mem?.cmFeeName}, ` + feeName;
                            }
                        }
                    })
                }
            });
        }

        if (!isPayByEveryMem) {
            this._notifyService.showError(`For ${feeName} every member must be given an amount.`, "", 5000);
            return
        }

        if (!this.contract.cmProductID) {
            this._notifyService.showError(`Please select a product`, "", 3000);
            return
        }

        this.showSpinner = true
        await this._contractService.CMContract_Update(this.encryptedUser, this.contract, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    this.showSpinner = false
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.contractId = response.messageInt;

                        if (this.contractStatusId == 0)
                            await this.cmContractStatus(1);
                        this.getContractDetails(this.contractId);
                        this.cMContractFee_Get(this.contractId, () => {

                        });
                        this._notifyService.showSuccess("Contract saved successfully.", "Save", 3000);
                        this.contractEdit = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract.SaveContract", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


        async deleteContract() {
            this.showSpinner = true
            await this._contractService.CMContract_Delete(this.encryptedUser, this.contractId, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        if (response.messageBool) {
                            this.contractId = 0;
                            this.contracts = [];
                            this.contractEdit = false;
                            this.isValidFee = true;
                            this.contactId = this.newContactId
                            this.productList = []
                            this.product = <Product>{}
                            this.contacts = []
                            this.contractFees = []
                            this.fees.controls = [];
                            this.fees.removeAt(0);
                            this.feeButtonList = []
                            this.clearMembersFormArray()
                            this.getInitialContact();
                            this.contractStatusId = 0;
                            this.agentForm.patchValue({
                                agentName: this.user.firstName + " " + this.user.lastName
                            });
                            this.termForm.patchValue({
                                term: ''
                            });
                            this.siteTaxForm.patchValue({
                                specialterms: "",
                            });
                            this.testModeForm.patchValue({
                                processorTestMode: false
                            });
                            this._notifyService.showSuccess("Contract deleted successfully.", "Delete", 3000);
                        }
                        this.showSpinner = false
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("cm-contract.DeleteContract", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
            $('#deleteContractConfirmation').modal('hide');
        }

        clearMembersFormArray() {
            this.members.controls.forEach((group: FormGroup) => {
                const memberFees = group.get('memberFees') as FormArray;

                if (memberFees) {
                    memberFees.clear();
                }
            });

            this.members.clear();
        }

        EditContract() {
            if (this.contractId > 0) {
                this.contractEdit = true;
            }
        }

        confirmDelete() {
            $('#deleteContractConfirmation').modal('show');
        }

        prepareContractForm() {
            this.contract.clpcompanyID = this.clpCompanyId;
            this.contract.clpUserID = this.user.cLPUserID;
            this.contract.cmContractID = this.contractId;
            this.contract.cmProductID = this.productSelected;
            this.contract.statusID = this.contractStatusId;
            let class4Code = this.productList.filter(p => p.cmProductID == this.productSelected)[0]?.class4Code;

            this.contract.class4Code = class4Code;

            this.contract.siteID = this.siteId;
            this.contract.clubID = this.clubId;
            this.contract.agentName = this.agentForm.controls["agentName"].value;
            this.contract.testMode = this.testModeForm.controls["processorTestMode"].value;

            this.contract.taxRate = this.site.taxRate;
            this.contract.dtContractEnd = this.datepipe.transform(this.siteTaxForm.controls["contractEnd"].value, 'MM-dd-yyyy');
            this.contract.dtContractStart = this.datepipe.transform(this.siteTaxForm.controls["contractStart"].value, 'MM-dd-yyyy');
            this.contract.dtPaymentBegins = this.datepipe.transform(this.siteTaxForm.controls["paymentBegin"].value, 'MM-dd-yyyy');
            this.contract.dtExpires = this.datepipe.transform(this.siteTaxForm.controls["contractExpires"].value, 'MM-dd-yyyy');
            this.contract.productDesc = this.siteTaxForm.controls['productDesc'].value ? this.siteTaxForm.controls['productDesc'].value : this.product?.productDesc;
            this.contract.contractTerm = this.termForm.controls["term"].value;
            this.contract.specialTerms = this.siteTaxForm.controls["specialterms"].value;

            if (this.isSeasonal)
                this.contract.monthsOnPlan = this.getSeasonalPlanText();

            if (!this.isEveryday)
                this.contract.daysOfWeekOnPlan = this.getWeeklyPlanText();

            this.contract.isSignUpCC = this.paymentForm.controls["isEntryCC"].value;
            this.contract.isSignUpACH = this.paymentForm.controls["isEntryACH"].value;
            this.contract.isSignUpCheck = this.paymentForm.controls["isEntryCheck"].value;
            this.contract.isSignUpCash = this.paymentForm.controls["isEntryCash"].value;
            this.contract.isMembershipCC = this.paymentForm.controls["isMonthlyCC"].value;
            this.contract.isMembershipACH = this.paymentForm.controls["isMonthlyACH"].value;
            this.contract.isMembershipCheck = this.paymentForm.controls["isMonthlyCheck"].value;
            this.contract.members = [];
            this.contract.fees = [];
            this.contract.memberfees = [];
            if (this.fees.value?.length > 0) {
                this.fees?.value.forEach((f, index) => {

                    let tax = Math.round(this.changeToNumber(f.feeAmount) * (this.site.taxRate / 100) * 100) / 100;

                    var contractFee = <CMContractFee>{
                        cMContractFeeID: f.cMContractFeeID,
                        cMContractID: 0,
                        feeBase: this.changeToNumber(f.feeAmount),
                        feeTax: f.isTaxable ? tax : 0,
                        sOrder: f?.sOrder,
                        cMProductFeeID: f?.cmProductFeeID,
                        cMFeeName: f?.cmFeeName
                    }

                    this.contract.fees.push(contractFee);
                });
            }

            this.members.value.forEach(row => {
                //get all the fees here and add it to the member

                var contractMember = <CMContractMember>{
                    contactID: row.contactId,
                    cmContractID: this.contractId,
                    cMMemberID: row.cMMemberID,
                    dtContractStartLabel: 0,
                    dtCreated: this.datepipe.transform(new Date(), 'MM-dd-yyyy'),
                    dtCreatedLabel: '',
                    dteSig: this.datepipe.transform(new Date(), 'MM-dd-yyyy'),
                    dteSigLabel: '',
                    eSigFullImage: '',
                    eSigInitialsImage: '',
                    statusID: row.statusId ? row.statusId : 1,
                    taxMembership: 0,
                    taxSignUp: 0,
                    isPrimary: row.contactId == this.newContactId ? true : false,
                    isExtraMem: row?.isExtraMem ? true : this.contractStatusId == 4 && row.cMMemberID == 0 ? true : false
                }

                this.contract.members.push(contractMember);

                row.memberFees.forEach(f => {
                    let mtax = Math.round(this.changeToNumber(f.feeBase.toString()) * (this.site.taxRate / 100) * 100) / 100;

                    var memberFee = <CMMemberFee>{
                        cmMemberFeeID: f.cmMemberFeeID,
                        cmContractFeeID: f.cMContractFeeID,
                        cmProductFeeID: f.cmProductFeeID,
                        cmProductID: f.cmProductID,
                        memberID: row.cMMemberID,
                        contactID: row.contactId,
                        cmFeeName: f.cMFeeName,
                        feeTotal: this.changeToNumber(f.feeTotal),
                        feeTax: f.isTaxable ? mtax : 0,
                        sOrder: 0,
                        feeBase: this.changeToNumber(f.feeBase)
                    }

                    this.contract.memberfees.push(memberFee);
                });
            });
        }

        getSeasonalPlanText(): string {
            let plantype = '';

            if (this.isSeasonal) {
                plantype = this.siteTaxForm.controls["isJanuary"].value + "," + this.siteTaxForm.controls["isFebruary"].value + "," + this.siteTaxForm.controls["isMarch"].value + ',' + this.siteTaxForm.controls["isApril"].value + "," + this.siteTaxForm.controls["isMay"].value + "," + this.siteTaxForm.controls["isJune"].value + ',' + this.siteTaxForm.controls["isJuly"].value + "," + this.siteTaxForm.controls["isAugust"].value + "," + this.siteTaxForm.controls["isSeptember"].value + "," + this.siteTaxForm.controls["isOctober"].value + "," + this.siteTaxForm.controls["isNovember"].value + "," + this.siteTaxForm.controls["isDecember"].value;
            }

            return plantype;
        }

        getWeeklyPlanText(): string {
            let plantype = '';

            if (!this.isEveryday) {
                plantype = this.siteTaxForm.controls["isMonday"].value + "," + this.siteTaxForm.controls["isTuesday"].value + "," + this.siteTaxForm.controls["isWednesday"].value + ',' + this.siteTaxForm.controls["isThursday"].value + "," + this.siteTaxForm.controls["isFriday"].value + "," + this.siteTaxForm.controls["isSaturday"].value + ',' + this.siteTaxForm.controls["isSunday"].value;
            }

            return plantype;
        }

        getSearchData(txt, value: string = "") {
            let Search: Search = <Search>{};
            Search.searchText = txt;
            this.inputBoxValue = txt;
            Search.searchValue = "";
            this.getContactSearch(Search);
        }

        async goToLink(value: string, str: string = "") {
            if (value) {
                var id = this.searchList.filter(item => item.searchText == value)[0]?.searchValue;
                if (id?.includes("ct")) {
                    this.contactId = +id?.split("ct")[1];
                    this.isMemberExist = false
                    await this.cMMember_Exists(this.contactId)

                    if (this.isMemberExist) {
                        return this._notifyService.showError("This member already exists.", "Duplicate", 3000);
                    }
                    this.getContactById(this.contactId);
                    this.autocomplete.reset();
                    //this.getContactData();
                    //this.hideModalContact();
                }
            }
            else {
                this.autocomplete.reset();
            }
        }

        patchContractData() {
            this.contractId = this.contracts[0]?.cmContractID;
            this.contractStatusId = this.contracts[0]?.statusID;
            this.agentForm.patchValue({
                agentName: this.contracts[0]?.agentName,
            });
            this.testModeForm.patchValue({
                processorTestMode: this.contracts[0]?.testMode,
            });
            this.productSelected = this.contracts[0]?.cmProductID;

            let filtered = this.productList.filter(p => p.cmProductID == this.productSelected);
            if (filtered) {
                this.productSelectedName = filtered[0]?.productName;
            }

            this.siteSelected = this.contracts[0]?.siteID;

            let filtered_s = this.siteList.filter(s => s.siteID == this.siteSelected);
            if (filtered_s) {
                this.siteSelectedName = filtered_s[0]?.siteName;
            }
            this.siteTaxForm.patchValue({
                specialterms: this.contracts[0]?.specialTerms,
                productDesc: this.contracts[0]?.productDesc,
                dtSignedMembers: this.contracts[0]?.dtSignedMembers != '0001-01-01T00:00:00' ? this.getFormattedDate(new Date(this.contracts[0]?.dtSignedMembers)) : '',
                contractStart: new Date(this.contracts[0]?.dtContractStart),
                contractStartLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtContractStart)),
                paymentBegin: new Date(this.contracts[0]?.dtPaymentBegins),
                paymentBeginLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtPaymentBegins)),
                contractExpires: new Date(this.contracts[0]?.dtExpires),
                contractExpiresLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtExpires)),
                contractEnd: new Date(this.contracts[0]?.dtContractEnd),
                contractEndLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtContractEnd)),
                contractSigned: this.getFormattedDate(new Date(this.contracts[0]?.dtExpires))
            });

            this.termForm.patchValue({
                term: this.contracts[0]?.contractTerm
            });

            this.paymentForm.patchValue({
                isEntryCC: this.contracts[0]?.isSignUpCC,
                isEntryACH: this.contracts[0]?.isSignUpACH,
                isEntryCheck: this.contracts[0]?.isSignUpCheck,
                isEntryCash: this.contracts[0]?.isSignUpCash,
                isMonthlyCC: this.contracts[0]?.isMembershipCC,
                isMonthlyACH: this.contracts[0]?.isMembershipACH,
                isMonthlyCheck: this.contracts[0]?.isMembershipCheck
            });

            if (this.contracts[0]?.daysOfWeekOnPlan) {
                let _weekOfPlan = this.contracts[0]?.daysOfWeekOnPlan.split(',');
                this.siteTaxForm.patchValue({
                    isMonday: this.getBool(_weekOfPlan[0]),
                    isTuesday: this.getBool(_weekOfPlan[1]),
                    isWednesday: this.getBool(_weekOfPlan[2]),
                    isThursday: this.getBool(_weekOfPlan[3]),
                    isFriday: this.getBool(_weekOfPlan[4]),
                    isSaturday: this.getBool(_weekOfPlan[5]),
                    isSunday: this.getBool(_weekOfPlan[6]),
                });
            }
            if (this.contracts[0]?.monthsOnPlan) {
                let _monthPlan = this.contracts[0]?.monthsOnPlan.split(',');

                this.siteTaxForm.patchValue({
                    isJanuary: this.getBool(_monthPlan[0]),
                    isFebruary: this.getBool(_monthPlan[1]),
                    isMarch: this.getBool(_monthPlan[2]),
                    isApril: this.getBool(_monthPlan[3]),
                    isMay: this.getBool(_monthPlan[4]),
                    isJune: this.getBool(_monthPlan[5]),
                    isJuly: this.getBool(_monthPlan[6]),
                    isAugust: this.getBool(_monthPlan[7]),
                    isSeptember: this.getBool(_monthPlan[8]),
                    isOctober: this.getBool(_monthPlan[9]),
                    isNovember: this.getBool(_monthPlan[10]),
                    isDecember: this.getBool(_monthPlan[11]),
                });
            }
        }

        getFormattedDate(d: Date) {
            let curr_date = d.getDate();
            let curr_month = d.getMonth() + 1; //Months are zero based
            let curr_year = d.getFullYear();
            return curr_month + "/" + curr_date + "/" + curr_year;
        }

        confirmMemberDlt(_contactId: number, cntId: number, index) {
            $('#deleteMemberConfirmation').modal('show');
            this.memContactId = _contactId;
            this.delContactId = cntId;
            this.dltMemIndex = index
        }

        async memberDelete() {
            if (this.contracts[0]?.members.length > 0 && this.memContactId > 0) {
                /*            let memberId = this.contracts[0]?.members.filter(m => m.cMMemberID == this.memContactId)[0].cMMemberID;*/
                await this._contractService.cmMemberDelete(this.encryptedUser, this.memContactId, this.isAI).
                    then(async (result: SimpleResponse) => {
                        if (result) {
                            let response = UtilityService.clone(result);
                            if (response.messageInt > 0) {
                                this.getContractDeletedById();
                                this.contacts = this.contacts.filter(c => c.contactId != this.delContactId).slice();
                                this.contactDetails = this.contactDetails.filter(dc => dc.contactId != this.delContactId).slice();
                                this.getContactData(true);
                                $('#deleteMemberConfirmation').modal('hide');
                                this._notifyService.showSuccess("The member was deleted successfully.", "Delete", 3000);
                            }
                            else {
                                this._notifyService.showError("An error occured.", "Try again.", 3000);
                            }

                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("cm-contract.memberDelete", err.message, null,
                        );
                        this._utilityService.handleErrorResponse(err);
                    });
            }
            else {
                this.contacts = this.contacts.filter(c => c.contactId != this.delContactId).slice();
                this.contactDetails = this.contactDetails.filter(dc => dc.contactId != this.delContactId).slice();
                this.contactId = this.newContactId;
                this.getContactData(true);
                this.members.removeAt(this.dltMemIndex);
                this._notifyService.showSuccess("The member was deleted successfully.", "Delete", 3000);
                $('#deleteMemberConfirmation').modal('hide');
            }
        }

        refreshMemAmount() {
            this.siteTaxForm.get('fees')['controls'].forEach((item, index) => {
                this.onChangeFees(item, index);
            });
        }

        getBool(value: string): boolean {
            let isTrue: boolean = false;
            if (value.indexOf('false') > -1)
                isTrue = false;
            else
                isTrue = true;
            return isTrue;
        }

        addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        addMonths(date, months) {
            var result = new Date(date);
            result.setMonth(result.getMonth() + months);
            return result;
        }

        getContactSearch(searchData: Search) {
            this._searchContactService.getContactSearchData(this.encryptedUser, this.user.cLPUserID, searchData)
                .then(async (result: SearchListResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        this.searchList = response.searchList;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("buzz-index.getLeadHistory", err.message, null, "clpUserId " + this.user.cLPUserID + "," + "searchData " + searchData);
                    this._utilityService.handleErrorResponse(err);
                });
        }

        async saveContact() {

            this.validateAllFormFields(this.contactForm);
            if (!this.contactForm.valid)
                return;

            this.prepareContact();
            this.showSpinner = true;

            await this._contractService.CMContact_Create(this.encryptedUser, this.contactData).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        this.showSpinner = false
                        let response = UtilityService.clone(result);
                        if (response.messageInt > 0) {
                            this.contactId = response.messageInt;
                            this.triggerContactId = this.contactId
                            this.getContactData(false);
                            this._notifyService.showSuccess(this.isEditMem ? "The member was updated successfully." : "The member was added successfully.", "Success", 3000);
                            this.hideModalContact();
                        }

                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("cm-contract.saveContact", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }

        saveContactValid() {
            if (!this.isContactCreate ||
                !this.cform.txtFirstName.value || this.cform.txtFirstName.value == '' || (this.cform.txtFirstName.errors && (this.cform.txtFirstName.touched || this.cform.txtFirstName.dirty)) ||
                !this.cform.txtLastName.value || this.cform.txtLastName.value == '' || (this.cform.txtLastName.errors && (this.cform.txtLastName.touched || this.cform.txtLastName.dirty)) ||
                !this.cform.txtEmail.value || this.cform.txtEmail.value == '' || (this.cform.txtEmail.errors && (this.cform.txtEmail.touched || this.cform.txtEmail.dirty)) ||
                !this.cform.txtMobilePhone.value || this.cform.txtMobilePhone.value == '' || (this.cform.txtMobilePhone.errors && (this.cform.txtMobilePhone.touched || this.cform.txtMobilePhone.dirty))) {
                return true;
            }
            else
                return false;
        }

        prepareContact() {
            this.contactData.contactID = this.contactId;
            this.contactData.firstName = this.contactForm.controls["txtFirstName"].value;
            this.contactData.lastName = this.contactForm.controls["txtLastName"].value;
            this.contactData.email = this.contactForm.controls["txtEmail"].value;
            this.contactData.mobile = this.contactForm.controls["txtMobilePhone"].value;
            this.contactData.homePhone = this.contactForm.controls["txtHomePhone"].value;
            this.contactData.add1 = this.contactForm.controls["txtAddress"].value;
            this.contactData.city = this.contactForm.controls["txtCity"].value;
            this.contactData.zip = this.contactForm.controls["txtZip"].value;
            this.contactData.state = this.stateCode;
            this.contactData.country = this.countryCode;
            this.contactData.dl = this.contactForm2.controls["dl"].value;
            this.contactData.dob = this.contactForm2.controls["dob"].value;
            this.contactData.emergencyCName = this.contactForm2.controls["emgContactName"].value;
            this.contactData.emergencyCPhone = this.contactForm2.controls["txtEmgCntPhone"].value;
            this.contactData.cLPUserID = this.user.cLPUserID;
            this.contactData.cLPCompanyID = this.user.cLPCompanyID;
        }



        openContact(newAdd: boolean) {
            $('#contactModal').modal('show');
            this.searchList = [];
            this.autocomplete.reset();
            setTimeout(() => {
                this.autocomplete.focus();
            }, 1000);
            if (newAdd) {
                this.contactId = 0;
                this.contactForm = this.prepareContactForm();
            }
        }

        hideModalContact() {
            this.isContactCreate = false;
            this.isEditMem = false
            $('#contactModal').modal('hide');
        }

        contactHide() {
            this.isContactCreate = !this.isContactCreate;

            if (this.isContactCreate) {
                this.contactForm.reset();
                this.contactForm2.reset();
            }
        }


        getFee(contactId, feeType): string {
            let amount: string = '0';
            if (this.contracts && this.contracts.length > 0) {
                this.contracts[0]?.members.forEach((item) => {
                    if (item.contactID == contactId) {
                        if (feeType == 's') {
                            amount = this.changeToCurrency(item.feeSignUp);
                        }
                        else {
                            amount = this.changeToCurrency(item.feeMembership);
                        }
                    }
                });
            }
            else {
                if (this.contacts.length > 0) {
                    if (feeType == 's') {
                        amount = this.changeToCurrency(this.contacts.filter(c => c.contactId == contactId)[0]?.signupFee);
                    }
                    else {
                        amount = this.changeToCurrency(this.contacts.filter(c => c.contactId == contactId)[0]?.memberFee);
                    }
                }
            }
            return amount;
        }

        feeChange(fee) {
            let total = 0
            this.isValidFee = true
            let feeName = ''

            if (this.contractId == 0) {
                this.membersForm.get('members')['controls'].forEach(mem => {
                    let memberFee = mem?.get('memberFees')['controls'].filter((item) => item.value.cmProductFeeID == fee?.value?.cmProductFeeID)[0]
                    total = total + this.changeToNumber(memberFee?.value?.feeBase)
                })

                let productFee = this.product?.fees?.filter((item) => item.cmProductFeeID == fee?.value?.cmProductFeeID)[0];

                if (total > productFee?.feeAmount) {
                    this.isValidFee = false;
                    feeName = productFee?.cmFeeName;
                } else if (total < productFee?.feeAmount) {
                    this.isValidFee = false;
                    feeName = productFee?.cmFeeName;
                }
            }
            else {

                this.membersForm.get('members')['controls'].forEach(mem => {
                    let memberFee = mem?.get('memberFees')['controls'].filter((item) => item.value.cMContractFeeID == fee?.value?.cMContractFeeID)[0]
                    total = total + this.changeToNumber(memberFee?.value?.feeBase)
                })

                let contractFee = this.contractFees?.filter((item) => item.cMContractFeeID == fee?.value?.cMContractFeeID)[0]

                if (total > contractFee?.feeBase) {
                    this.isValidFee = false;
                    feeName = contractFee?.cMFeeName;
                } else if (total < contractFee?.feeBase) {
                    this.isValidFee = false;
                    feeName = contractFee?.cMFeeName;
                }
            }


            if (!this.isValidFee) {
                this._notifyService.showError(`${feeName} is not equal to the total ${feeName} base fee.`, "", 5000);
            }
        }

        memberFeeChanges(fee, member) {
            try {
                let totalFee = this.changeToNumber(fee?.value?.feeBase);
                let equalFee = totalFee / this.contacts.length;
                this.membersForm.get('members')['controls'].forEach(mem => {
                    mem?.get('memberFees')['controls'].forEach(c => {
                        if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 1) {
                            if (mem?.value.contactId == this.newContactId)
                                c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.value?.feeBase)));
                        }
                        if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 2)
                            c.controls['feeBase'].setValue(this.changeToCurrency(equalFee));
                        if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 3)
                            c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.value?.feeBase)));
                    });
                });
            } catch (e) {
                console.log('splitEvenlyOnChange', e);
            }
        }


        changeToCurrency(value: number): string {
            let final = '$0';

            if (value > 0) {
                final = `$${(Math.round(value * 100) / 100).toFixed(2)}`;
            }

            return final;
        }

        changeToNumber(value: string): number {
            if (value != null && value != undefined) {
                if (value?.toString().includes('$'))
                    return Number(value?.replace(/\$/g, ''));
                else
                    return Number(value);
            }
            else {
                return 0
            }
        }

        getStatus(statusId: number): string {
            var status = 'Pending';
            if (!isNullOrUndefined(statusId)) {
                switch (statusId) {
                    case CMMemberStatus.None: {
                        status = "Pending";
                        break;
                    }
                    case CMMemberStatus.Ready_To_Send: {
                        status = "Ready To Send";
                        break;
                    }
                    case CMMemberStatus.Sent: {
                        status = "Sent";
                        break;
                    }
                    case CMMemberStatus.Registered: {
                        status = "Registered";
                        break;
                    }
                    case CMMemberStatus.Info_Update: {
                        status = "Info Update";
                        break;
                    }
                    case CMMemberStatus.eSigned: {
                        status = "eSigned";
                        break;
                    }
                    case CMMemberStatus.Completed: {
                        status = "Active";
                        break;
                    }
                    default: {
                        status = "Pending";
                        break;
                    }
                }
            }
            return status;
        }

        setEvenly(fee: FeeButton) {

            if (this.contacts && this.contacts.length > 1) {

                this.siteTaxForm.get('fees')['controls'].forEach(item => {
                    if (item.value.cmProductFeeID == fee.cMProductFeeID) {

                        let simpleFee = 0, perFee = 0;
                        simpleFee = this.changeToNumber(item?.value?.feeAmount); //tax is adding at backend.
                        perFee = simpleFee / this.contacts.length;
                        this.membersForm.get('members')['controls'].forEach(mem => {
                            mem?.get('memberFees')['controls'].forEach(c => {
                                if (c.value.cmProductFeeID == fee.cMProductFeeID) {
                                    c.controls['feeBase'].setValue(this.changeToCurrency(perFee))
                                }
                            })
                        })
                    }

                });


                this.feeChange(fee);
                //console.log('setEvenly', fee);
                //let _product: Product = this.productList?.filter(p => p.cmProductID == fee.cMProductID)[0];
                //let _fee = _product?.fees?.filter(f => f.cmProductFeeID == fee.cMProductFeeID)[0];

                //simpleFee = _fee.feeAmount + (_fee.feeAmount * (this.contracts[0].taxRate / 100));

                //this.membersForm.get('members')['controls'].forEach(mem => {
                //    mem?.get('memberFees')['controls'].forEach(c => {
                //        if (c.value.cmProductFeeID == fee.cMProductFeeID) {
                //            c.controls['feeBase'].setValue(this.changeToCurrency(perFee))
                //        }
                //    })
                //})
            }
        }

        splitEvenlyOnChange(fee) {
            try {
                let totalFee = this.changeToNumber(fee?.feeAmount);
                let equalFee = totalFee / this.contacts.length;
                this.membersForm.get('members')['controls'].forEach(mem => {
                    mem?.get('memberFees')['controls'].forEach(c => {
                        if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 1) {
                            if (mem?.value.contactId == this.newContactId)
                                c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.feeAmount)));
                        }
                        if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 2)
                            c.controls['feeBase'].setValue(this.changeToCurrency(equalFee));
                        if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 3)
                            c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.feeAmount)));
                    });
                });
            } catch (e) {
                console.log('splitEvenlyOnChange', e);
            }
        }

        openContactForm(_contactId: number) {
            this.isEditMem = true
            if (this.contractEdit) {
                let _contact = this.contacts.filter(c => c.contactId == _contactId)[0];
                if (_contact) {
                    this.isContactCreate = true;
                    this.contactId = _contactId;
                    this.patchContact(_contact);
                    this.openContact(false);
                }
            }
        }

        redirctf2f(_contactId: number) {
            if (this.contractStatusId != 4 && this.members.value.filter(x => x.contactId == _contactId)[0].statusId != 6) {
                this.cmContractStatus(2);
            }
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`/cm-contract-client/${_contactId}/${this.user.cLPUserID}/${this.contractId}`])
            );

            window.open(url, '_blank');
        }

        async cmContractStatus(statusID: number) {
            await this._contractService.cmContractStatusUpdate(this.encryptedUser, this.contractId, statusID, this.isAI)
                .then(async (result: SimpleResponse) => {
                    if (result) {

                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("cm-contract-cmContractStatus", err.message, null);
                    this._utilityService.handleErrorResponse(err);
                });
        }


        async sendMemberEmail(toContactID: number, i: number) {
            await this._contractService.sendMemberEmail(this.encryptedUser, toContactID, this.contractId, this.user?.cLPCompanyID, this.user?.cLPUserID, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        if (response.messageBool) {
                            this.emailTxtMsg = true;
                            this._notifyService.showSuccess(`Your mail was sent successfully.`, "", 3000);
                            const control = this.members.at(i).get('statusId');
                            if (control) {
                                control.setValue(2);
                            }
                        }
                        else {
                            this._notifyService.showError(`Please select an email template from contract settings & try again.`, "", 5000);
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm-contract.sendMemberEmail", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }

        async sendMemberText(toContactID: number, i: number) {
            this.txtContactId = toContactID;
            this.txtContact_i = i;
            this.textForm = this.prepareTextForm();
            this.textForm.reset();

            this.getTextMessagePH(this.user?.cLPUserID);
        }

    private prepareTextForm(): FormGroup {
        return this.fb.group({
            txtMsg: [{ value: '' }],
            mediaURL: [{ value: '' }],
            sendEmail: [{ value: true }]
        });
    }

    async sendText() {
        let txtMsg = this.textform.txtMsg.value;
        let mediaURL = this.textform.mediaURL.value;
        let sendEmail = this.textform.sendEmail.value;

        if (txtMsg.length > 0) {
            await this._contractService.sendMemberText(this.encryptedUser, this.txtContactId, this.contractId, this.user?.cLPCompanyID, this.user?.cLPUserID, txtMsg, mediaURL, sendEmail, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        if (response.messageBool) {
                            this.emailTxtMsg = true;
                            this._notifyService.showSuccess(`Your text message was sent successfully.`, "", 3000);

                            if (sendEmail) {
                                if (response.messageBool2) {
                                    this._notifyService.showSuccess(`Your mail was sent successfully.`, "", 3000);
                                }
                                else {
                                    this._notifyService.showError(`A problem occurred sending your mail.`, "", 3000);
                                }
                            }

                            const control = this.members.at(this.txtContact_i).get('statusId');
                            if (control) {
                                control.setValue(2);
                            }

                            $('#textConfirmation').modal('hide');
                        }
                        else {
                            this._notifyService.showError(`A problem occurred sending your text message.`, "", 3000);
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm-contract.sendText", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async cMContractFee_Get(cmContractID: number, callback) {
        await this._contractService.cMContractFee_Get(this.encryptedUser, cmContractID, this.isAI).
            then(async (result: CMContractFeeListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contractFees = response?.contractFees;
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.saveContact", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    //#endregion

    isShowFee(fee) {

        let contractFee = this.contractFees?.filter((item) => item.cMContractFeeID == fee.value?.cMContractFeeID)[0];

        return contractFee?.memberSplitable == 2;
    }

    isFeeHidden(fee) {
        let productFee = <Fee>{}
        productFee = this.product?.fees?.filter((item) => item.cmProductFeeID == fee.value?.cmProductFeeID)[0];
        if (productFee?.feeAmount == 0) {
            return true;
        }

        return false;
    }

    isFeeBaseReadOnly(mem, fee) {
        // fee base input is read only for fees in which payment is paid by one member only & member is not primary.

        if (!this.contractEdit) {
            return true;
        }

        if (this.contractId == 0) {
            let productFee = <Fee>{}
            productFee = this.product?.fees?.filter((item) => item.cmProductFeeID == fee.value?.cmProductFeeID)[0];
            if (productFee?.memberSplitable == 1 || productFee?.feeAmount == 0) {
                return true;
            }
        }
        else {
            let contractFee = <CMContractFee>{}
            contractFee = this.contractFees?.filter((item) => item.cMContractFeeID == fee.value?.cMContractFeeID)[0];
            if (contractFee?.memberSplitable == 1 || contractFee?.feeBase == 0) {
                return true;
            }
            else {
                if (this.contractStatusId == 4 && (mem?.value?.statusId == null || mem?.value?.statusId == undefined || mem?.value?.statusId == 1)) {
                    return true;
                }
            }
        }

        return false;
    }

    checkMaxMem() {
        if (this.contacts?.length < this.maxMembers) {
            return true
        }
        else {
            return false
        }
    }

    async cMMember_Exists(contactId: number) {
        await this._contractService.cMMember_Exists(this.encryptedUser, contactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.isMemberExist = response?.messageBool;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.saveContact", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeFees(fee, index) {
        let value = this.changeToNumber(fee.value.feeAmount) * (this.taxRate / 100);
        const feeControl = this.fees.at(index).get('feeTax') as FormControl;
        feeControl.setValue(this.changeToCurrency(value) + ' (' + this.taxRate + '%)');
        this.splitEvenlyOnChange(fee?.value);
        //if (this.feeButtonList?.length > 0)
        //else {
        //    this.membersForm.get('members')['controls'].forEach((mem) => {
        //        if (mem?.value.contactId == this.newContactId) {
        //            mem?.get('memberFees')['controls'].forEach((c, index) => {
        //                if (c.value.cMContractFeeID == fee?.value.cMContractFeeID) {
        //                    let feeAmount = this.changeToNumber(fee?.value?.feeAmount);
        //                    c.controls['feeBase'].setValue(this.changeToCurrency(feeAmount));
        //                }
        //            });
        //        }
        //    });
        //}
    }

    async checkContractPayment() {
        await this._paymentService.CMContract_isPaymentDone(this.encryptedUser, this.contractId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.isPaymentDone = response.messageInt;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.checkContractPayment", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyF2FUrl(_contactId) {
        const textToCopy = `${this.bcmSiteUrl}cm-contract-client/${_contactId}/${this.user.cLPUserID}/${this.contractId}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            this.membersForm.get('members')['controls'].forEach(mem => {
                if (mem.value.contactId == _contactId) {
                    mem.controls['isF2FLinkCopied'].setValue(true);
                }
            });
            setTimeout(() => {
                this.membersForm.get('members')['controls'].forEach(mem => {
                    if (mem.value.contactId == _contactId) {
                        mem.controls['isF2FLinkCopied'].setValue(false);
                    }
                });
            }, 2000); // Reset after 2 seconds
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }

    async getTextMessagePH(userId: number) {
        await this._contractService.getTextMessagePH(this.encryptedUser, userId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.phSendContractMessageText = response?.messageString;
                    this.phSendContractMediaURL = response?.messageString2;

                    this.textForm.patchValue({
                        txtMsg: this.phSendContractMessageText,
                        mediaURL: this.phSendContractMediaURL,
                        sendEmail: true
                    });

                    $('#textConfirmation').modal('show');
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getTextMessagePH", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    async downloadContract() {
        this.isDownloaded = true;
        this.getDownloadStatus();

    }

    getDownloadStatus() {
        this.isSubscribe = setTimeout(() => {
            this.ProcessorContractPDFConvert_GET();
            this.getDownloadStatus();
        }, 1000);
    }

    async ProcessorContractPDFConvert_GET() {
        await this._contractService.ProcessorContractPDFConvert_Get(this.encryptedUser, this.clpCompanyId, this.contractId, this.newContactId, true, this.isAI)
            .then(async (result: ContractPdfConvertContainer) => {
                if (result) {
                    this.contractPdfConvertContainer = UtilityService.clone(result);
                    if (this.contractPdfConvertContainer?.processed) {
                        clearTimeout(this.isSubscribe);
                        this.downloadContractPdf()
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract-client.ProcessorContractPDFConvert_GET", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async downloadContractPdf() {
        this.isDownloaded = true;
        if (this.contractPdfConvertContainer?.processed) {
            await this._contractService.GetContractPDFAsync(this.encryptedUser, this.contractPdfConvertContainer?.fileName, this.contractPdfConvertContainer?.fileLength)
                .then((result: any) => {
                    if (result) {
                        let file = new Blob([result], { type: 'application/pdf' });
                        let fileURL = URL.createObjectURL(file);
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        //if (this.contactDetails)
                        //    fileLink.download = "ContractDocuments"
                        //else
                        fileLink.download = "ContractDocuments.pdf";

                        fileLink.click();
                        this.isDownloaded = false;

                    } else {
                        console.log("exportToPDF.statusCode: Error Getting Data");
                        this.isDownloaded = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.isDownloaded = false;
                    this._utilityService.handleErrorResponse(err);
                });

        }
        else {
            this.isDownloaded = false;
            this.ProcessorContractPDFConvert_GET();
        }
    }

    get cform() { return this.contactForm.controls; }

    get textform() { return this.textForm.controls; }

    get members(): FormArray {
        return this.membersForm.get("members") as FormArray
    }

    get memberFees(): FormArray {
        return (this.membersForm.controls["members"] as FormArray).controls['memberFees'] as FormArray
    }

    get fees(): FormArray {
        return this.siteTaxForm.get("fees") as FormArray
    }
}
