<app-nav-menu *ngIf="isShowMenu"></app-nav-menu>
<div *ngIf="!isClientContract">
    <div class="wrapper-main">
        <div *ngIf="isShowMenu">
            <div class="left-side-section" *ngIf="!isContract">
                <app-left-side-menu *ngIf="isCmManager"></app-left-side-menu>
                <app-location-left-menu *ngIf="!isCmManager"></app-location-left-menu>
            </div>
        </div>
        <div class="right-side-section" [ngClass]="isContract ? 'right-side-section-contract' : ''">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div *ngIf="isClientContract">
    <div>
        <router-outlet></router-outlet>
    </div>
</div>

