import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appCurrency]'
})
export class CurrencyDirective {

    private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

    constructor(private el: ElementRef, private control: NgControl) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        let current: string = this.el.nativeElement.value;
        const position: number = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');

        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: FocusEvent) {
        let value: string = this.el.nativeElement.value;
        console.log("value", value)
        if (value && !value.includes('.')) {
            value = `${value}.00`;
        } else if (value.split('.')[1]?.length === 1) {
            value = `${value}0`;
        }
        if (value) {
            if (value?.includes('$')) {
                this.control.control?.setValue(`${value}`);
            }
            else {
                this.control.control?.setValue(`$${value}`);
            }
        }
    }

    @HostListener('focus', ['$event'])
    onFocus(event: FocusEvent) {
        let value: string = this.el.nativeElement.value;
        this.control.control?.setValue(value.replace('$', ''));
    }

}
