import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../../models/app-config.model';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { CMClubService } from '../../services/cm-club.service';
import { NotificationService } from '../../services/notification.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TeamOfficeService } from '../../services/team-office.service';
import { UserService } from '../../services/user.service';

import { BCMCLPUser, CLPUser, TeamCodes, UserResponse } from '../../models/clpuser.model';
import { CMClub, CMClubResponse, CMProcessor, CMProcessorResponse, CMProcessorVendor, CMProcessorVendorListResponse, CMEnvironment } from '../../models/cm-club-service.model';
import { SimpleResponse, UserDD } from '../../models/generic-response.model';

@Component({
    selector: 'app-cm-payment',
    templateUrl: './cm-payment.component.html',
    styleUrl: './cm-payment.component.css'
})
export class CmPaymentComponent implements OnDestroy {
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: BCMCLPUser;
    clubConfigForm: FormGroup;

    clubConfig: CMClub = <CMClub>{};
    curClubId: number = 0
    showSpinner: boolean;
    curTeam: TeamCodes = <TeamCodes>{};
    curTeamCode: number = 0;

    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };

    private teamSub: Subscription;

    clpCompanyId: number;
    slurpyUserId: number;
    userRole: number;
    isAI: boolean = false;
    paymentProcForm: FormGroup;
    processor: CMProcessor;
    env: CMEnvironment[] = [{ envID: 1, envName: 'Production' }, { envID: 2, envName: 'Test' }, { envID: 3, envName: 'Sandbox' }];
    vendors: CMProcessorVendor[] = [];

    constructor(private _localService: LocalService,
        private _teamOfficeService: TeamOfficeService,
        private _userService: UserService,
        private _utilityService: UtilityService,
        private fb: FormBuilder,
        private clubService: CMClubService,
        private _notifyService: NotificationService,
        private _globalService: GlobalService,
        private _appconfigService: AppConfigService,
        private _router: Router,
    ) {
        this._localService.setShowMenu(true);
        this._localService.setRouteName("cm-payment");
        this._localService.setIsCmManager(true);
        this._localService.updateTeamDD(true);


    }

    ngOnInit() {
        this.paymentProcForm = this.preparePaymentProcForm();
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
        this.encryptedUser = localStorage.getItem("token");
        if (!this.encryptedUser?.includes(":")) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0");
        }
        if (this.encryptedUser) {
            this.showSpinner = true;
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadPage();
                    this.showSpinner = false;
                }
                else {
                    this.showSpinner = false;
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this.showSpinner = false
            this._router.navigate(['/unauthorized']);
        }
    }

    preparePaymentProcForm() {
        return this.fb.group({
            vendorID: [0],
            prodCred1: [''],
            prodCred2: [''],
            testCred1: [''],
            testCred2: [''],
            sandboxCred1: [''],
            sandboxCred2: [''],
            custom1: [''],
            custom2: [''],
            custom3: [''],
            custom4: [''],
            custom5: [''],
            isCCActive: [false],
            isACHActive: [false],
            activeEnvironment: [0],
            isSendDiagEmail: [false],
            diagEmails: [""],
            prodAPIURL: [""],
            testAPIURL: [""],
            sandboxAPIURL: [""],
        })
    }

    async loadPage() {
        if (this.userRole >= 3 || this.slurpyUserId > 0) {
            this.teamSub = this._teamOfficeService.curTeam.subscribe(async (data: any) => {
                if (data != 0 && data != null) {
                    this.curTeam = data
                    if (this.curTeam?.teamCode > 0) {
                        this.curTeamCode = this.curTeam?.teamCode;
                        this.rEF_CMProcessorVendor_Get();
                        await this.CMClub_Get(this.curTeam.teamCode);
                        this.cMProcessor_Load();
                    }
                }
                else {
                    this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
                    if (this.curTeam?.teamCode > 0) {
                        this.rEF_CMProcessorVendor_Get();
                        await this.CMClub_Get(this.curTeam.teamCode);
                        this.cMProcessor_Load();
                    }
                }
            })

        }
        else {
            this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
            if (this.curTeam && this.curTeam.teamCode > 0) {
                this.rEF_CMProcessorVendor_Get();
                await this.CMClub_Get(this.curTeam.teamCode);
                this.cMProcessor_Load();
            }
        }
    }



    async CMClub_Get(teamCode) {
        this.showSpinner = true
        if (!teamCode || teamCode == 0) {
            this._notifyService?.showError("No team is selected", "Error", 3000)
            return
        }
        await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, teamCode, this.isAI).
            then(async (result: CMClubResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clubConfig = response?.clubConfig;
                    this.curClubId = this.clubConfig?.clubId;
                    this.showSpinner = false;
                }

            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    private async authenticateR() {

        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                    this.slurpyUserId = this.user?.slurpyUserId;
                    this.userRole = this.user?.userRole;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cMProcessor_Load() {
        if (this.clubConfig?.clubId > 0) {
            await this.clubService.cMProcessor_Load(this.encryptedUser, this.clubConfig?.clubId, this.isAI)
                .then(async (result: CMProcessorResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.processor = response?.processor;
                        this.patchCmPaymentProcForm();
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm-payment.cMProcessor_Load", err.message, null);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            console.log("error : cMProcessor_Load")
        }
    }

    async rEF_CMProcessorVendor_Get() {
        await this.clubService.rEF_CMProcessorVendor_Get(this.encryptedUser, this.isAI)
            .then(async (result: CMProcessorVendorListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.vendors = response.vendors;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-payment.rEF_CMProcessorVendor_Get", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchCmPaymentProcForm() {
        this.paymentProcForm.controls['vendorID'].patchValue(this.processor?.vendorID);
        this.paymentProcForm.controls['prodCred1'].patchValue(this.processor?.prodCred1);
        this.paymentProcForm.controls['prodCred2'].patchValue(this.processor?.prodCred2);
        this.paymentProcForm.controls['testCred1'].patchValue(this.processor?.testCred1);
        this.paymentProcForm.controls['testCred2'].patchValue(this.processor?.testCred2);
        this.paymentProcForm.controls['sandboxCred1'].patchValue(this.processor?.sandboxCred1);
        this.paymentProcForm.controls['sandboxCred2'].patchValue(this.processor?.sandboxCred2);
        this.paymentProcForm.controls['custom1'].patchValue(this.processor?.custom1);
        this.paymentProcForm.controls['custom2'].patchValue(this.processor?.custom2);
        this.paymentProcForm.controls['custom3'].patchValue(this.processor?.custom3);
        this.paymentProcForm.controls['custom4'].patchValue(this.processor?.custom4);
        this.paymentProcForm.controls['custom5'].patchValue(this.processor?.custom5);
        this.paymentProcForm.controls['isCCActive'].patchValue(this.processor?.isCCActive);
        this.paymentProcForm.controls['isACHActive'].patchValue(this.processor?.isACHActive);
        this.paymentProcForm.controls['activeEnvironment'].patchValue(this.processor?.activeEnvironment);
        this.paymentProcForm.controls['isSendDiagEmail'].patchValue(this.processor?.isSendDiagEmail);
        this.paymentProcForm.controls['diagEmails'].patchValue(this.processor?.diagEmails);
        this.paymentProcForm.controls['prodAPIURL'].patchValue(this.processor?.prodAPIURL);
        this.paymentProcForm.controls['prodCred1'].patchValue(this.processor?.prodCred1);
        this.paymentProcForm.controls['prodCred2'].patchValue(this.processor?.prodCred2);
        this.paymentProcForm.controls['sandboxAPIURL'].patchValue(this.processor?.sandboxAPIURL);
        this.paymentProcForm.controls['sandboxCred1'].patchValue(this.processor?.sandboxCred1);
        this.paymentProcForm.controls['sandboxCred2'].patchValue(this.processor?.sandboxCred2);
        this.paymentProcForm.controls['testAPIURL'].patchValue(this.processor?.testAPIURL);
        this.paymentProcForm.controls['testCred1'].patchValue(this.processor?.testCred1);
        this.paymentProcForm.controls['testCred2'].patchValue(this.processor?.testCred2);
    }

    async cMProcessor_Update() {
        let cmProcessor = <CMProcessor>{}
        cmProcessor.clubID = this.clubConfig?.clubId
        cmProcessor.cmProcessorID = this.processor?.cmProcessorID
        cmProcessor.vendorID = this.paymentProcForm.controls['vendorID'].value;
        cmProcessor.activeEnvironment = this.paymentProcForm.controls['activeEnvironment'].value;
        cmProcessor.custom1 = this.paymentProcForm.controls['custom1'].value;
        cmProcessor.custom2 = this.paymentProcForm.controls['custom2'].value;
        cmProcessor.custom3 = this.paymentProcForm.controls['custom3'].value;
        cmProcessor.custom4 = this.paymentProcForm.controls['custom4'].value;
        cmProcessor.custom5 = this.paymentProcForm.controls['custom5'].value;
        cmProcessor.isACHActive = this.paymentProcForm.controls['isACHActive'].value;
        cmProcessor.isCCActive = this.paymentProcForm.controls['isCCActive'].value;
        cmProcessor.isSendDiagEmail = this.paymentProcForm.controls['isSendDiagEmail'].value;
        cmProcessor.diagEmails = this.paymentProcForm.controls['diagEmails'].value;
        cmProcessor.prodAPIURL = this.paymentProcForm.controls['prodAPIURL'].value;
        cmProcessor.prodCred1 = this.paymentProcForm.controls['prodCred1'].value;
        cmProcessor.prodCred2 = this.paymentProcForm.controls['prodCred2'].value;
        cmProcessor.sandboxAPIURL = this.paymentProcForm.controls['sandboxAPIURL'].value;
        cmProcessor.sandboxCred1 = this.paymentProcForm.controls['sandboxCred1'].value;
        cmProcessor.sandboxCred2 = this.paymentProcForm.controls['sandboxCred2'].value;
        cmProcessor.testAPIURL = this.paymentProcForm.controls['testAPIURL'].value;
        cmProcessor.testCred1 = this.paymentProcForm.controls['testCred1'].value;
        cmProcessor.testCred2 = this.paymentProcForm.controls['testCred2'].value;

        await this.clubService.cMProcessor_Update(this.encryptedUser, cmProcessor, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);

                    if (response && response.messageInt > 0) {
                        this.processor!.cmProcessorID = response.messageInt;

                        this._notifyService.showSuccess("Details updated.", "Success", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-payment.cMProcessor_Load", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }


    ngOnDestroy() {
        if (this.teamSub) {
            this.teamSub.unsubscribe();
        }
        //if (this.clubIdSub) {
        //    this.clubIdSub.unsubscribe()
        //}
        //this.curClubId = 0
        //this.clubService.setCurClubId(0)
    }
}
