<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Employee Manager</h3>
    </div>
    <div class="card-section">
        <div class="card-panel">
            <div class="field-form-panel">
                <div class="flex-form">
                   
                    <div class="form-group">
                        <label>Site</label>
                        <!--<kendo-dropdownlist class="form-control"
                                            [data]="sites"
                                            [filterable]="true"
                                            textField="siteName"
                                            valueField="siteID"
                                            [valuePrimitive]="true"
                                            [(ngModel)]="selectedSiteID">
                        </kendo-dropdownlist>-->

                        <kendo-combobox [data]="sites"
                                        class="form-control"
                                        [valuePrimitive]="true"
                                        [kendoDropDownFilter]="filterSettings"
                                        [filterable]="true"
                                        textField="siteName"
                                        [(ngModel)]="selectedSiteID"
                                        valueField="siteID">
                        </kendo-combobox>
                    </div>
                    <div class="form-group">
                        <label>Manager</label>
                        <!--<kendo-dropdownlist class="form-control"
                                            [data]="clpUsersDD"
                                            [filterable]="true"
                                            textField="text"
                                            valueField="value"
                                            [valuePrimitive]="true"
                                            [(ngModel)]="selectedUserID">
                        </kendo-dropdownlist>-->

                        <kendo-combobox [data]="clpUsersDD"
                                        class="form-control"
                                        [valuePrimitive]="true"
                                        [kendoDropDownFilter]="filterSettings"
                                        [filterable]="true"
                                        textField="text"
                                        [(ngModel)]="selectedUserID"
                                        valueField="value">
                        </kendo-combobox>
                    </div>
                    <div class="form-group">
                        <label>Roles</label>
                        <!--<kendo-dropdownlist class="form-control"
                                            [data]="allRolesList"
                                            [filterable]="true"
                                            textField="roleName"
                                            valueField="roleName"
                                            [valuePrimitive]="true"
                                            [(ngModel)]="roleName">
                        </kendo-dropdownlist>-->

                        <kendo-combobox [data]="allRolesList"
                                        class="form-control"
                                        [valuePrimitive]="true"
                                        [kendoDropDownFilter]="filterSettings"
                                        [filterable]="true"
                                        textField="roleName"
                                        [(ngModel)]="roleName"
                                        valueField="roleName">
                        </kendo-combobox>

                    </div>
                </div>
            </div>
            <div class="button-group">
                <div class="form-group">
                    <div class="button-group">
                        <button class="primary-btn" (click)="getSearchFilter()">Filter</button>
                        <!--<button class="primary-btn margin-left15">Download</button>-->
                        <button class="primary-btn margin-left15" (click)="openEmployee()">Add New Employee &nbsp;<i class="fa fa-plus"></i></button>
                    </div>
                </div>
            </div>
            <div class="table-panel">
                <div class="">
                    <div class="text-right">
                        <div class="button-group" style="border:none;">
                        </div>
                    </div>
                </div>
                <div class="table-body">
                    <table mat-table [dataSource]="dataSource" class="emp-table" matSort>
                        <ng-container matColumnDef="userName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee</th>
                            <td mat-cell *matCellDef="let element">{{element.userName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="phoneNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                            <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef>Email</th>
                            <td mat-cell *matCellDef="let element">{{element.email}}</td>
                        </ng-container>
                        <ng-container matColumnDef="siteID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
                            <td mat-cell *matCellDef="let element">{{getSitenName(element.siteID)}}</td>
                        </ng-container>
                        <ng-container matColumnDef="roleName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                            <td mat-cell *matCellDef="let element">{{element.roleName}}</td>
                        </ng-container>
                        <!--<ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let element">
                                <button class="btn-primary" (click)="onEdit(element)"><span></span></button>
                                <button class="btn-primary" data-toggle="modal" data-target="#deleteRoleModal" (click)="openDltConfirm(element)"><span></span></button>
                            </td>
                        </ng-container>-->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"
                            [ngClass]="{'selected-row': selectedRowIndex == row.sn}">
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="employeeModal" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{isEditMem ? 'Edit Employee' : 'Add Employee'}} </h4>
                <button type="button" class="close" (click)="hideModalEmployee()">&times;</button>
            </div>
            <div class="modal-body">
                <div class="edit-panel" style="padding-left:0px;">
                    <div class="edit-colunm full-width">
                        <div [hidden]="!isEmployeeCreate">
                            <form [formGroup]="addEmployeeForm">
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Employee Name</label>
                                        <kendo-combobox [data]="clpUsersDD"
                                                        class="form-control"
                                                        [valuePrimitive]="true"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        textField="text"
                                                        formControlName="employeeName"
                                                        valueField="value">
                                        </kendo-combobox>
                                        <div *ngIf="addEmployeeForm.controls.employeeName.errors && (addEmployeeForm.controls.employeeName.touched || addEmployeeForm.controls.employeeName.dirty)">
                                            <div class="login-error" *ngIf="addEmployeeForm.controls.employeeName.errors.required">Employee is required. </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Role</label>
                                        <kendo-dropdownlist [data]="allRolesList"
                                                            #dropdownlist
                                                            [filterable]="true"
                                                            formControlName="role"
                                                            (close)="onClose($event)"
                                                            (filterChange)="handleFilter($event)">
                                            <ng-template kendoDropDownListNoDataTemplate>
                                                <div>
                                                    No data found.
                                                    <button class="add-new" *ngIf="filter" kendoButton (click)="addNew()">
                                                        Add new
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </kendo-dropdownlist>

                                        <div *ngIf="addEmployeeForm.controls.role.errors && (addEmployeeForm.controls.role.touched || addEmployeeForm.controls.role.dirty)">
                                            <div class="login-error" *ngIf="addEmployeeForm.controls.role.errors.required">Please Select a Role or Add a New Role. </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-extended" style="margin-top:20px;">
                                    <div class="col-sm-6">
                                        <label class="fldtitle">Site</label>
                                        <kendo-combobox [data]="sites"
                                                        class="form-control"
                                                        [valuePrimitive]="true"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        textField="siteName"
                                                        formControlName="site"
                                                        valueField="siteID">
                                        </kendo-combobox>
                                        <div *ngIf="addEmployeeForm.controls.site.errors && (addEmployeeForm.controls.site.touched || addEmployeeForm.controls.site.dirty)">
                                            <div class="login-error" *ngIf="addEmployeeForm.controls.site.errors.required">Please Select the Site. </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="form-group center">
                    <button class="btn primary-btn" type="submit" (click)="saveEmployee()">Save <i *ngIf="showSpinner" class="fa fa-spinner fa-spin"></i></button>
                    <button class="btn secondary-btn" type="button" (click)="hideModalEmployee()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
