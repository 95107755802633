import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse } from '../models/generic-response.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';
import { CC, Bank, CheckCash, eContractFeeType, CMPaymentTxnResponse, CMPaymentTxn, CMPaymentItem, CMPaymentItem_Charge, PayListResponse, RetrieveCardResponse } from '../models/cm-contract';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    private baseUrl: string;
    private api: string = 'api/Payment';


    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getVendorCustomerId(encryptedUser: string, contractId: number, contactId: number, memberId: number, email: string, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Vendor_GetCustomerId/${contractId}/${contactId}/${memberId}/${email}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe().toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "getVendorCustomerId"); });
        return a;
    }

    async Charge(encryptedUser: string, contractId: number, contactId: number, cmPaymentTxnID: number, total: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Charge/${contractId}/${contactId}/${cmPaymentTxnID}/${total}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "Charge"); });
        return a;
    }    

    async addCreditCard(encryptedUser: string, cc: CC): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/AddCreditCard/`, cc, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "addCreditCard"); });
        return a;
    }

    async addBankAccount(encryptedUser: string, bank: Bank): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/AddBankAccount/`, bank, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "addBankAccount"); });
        return a;
    }

    async addCheckCash(encryptedUser: string, cc: CheckCash): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/AddCheckCash/`, cc, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "addCheckCash"); });
        return a;
    }

    async cMPaymentTxn_Load(encryptedUser: string, cmContractId: number, cmMemberId: number, feeType: eContractFeeType = eContractFeeType.All, IsAI: boolean): Promise<CMPaymentTxnResponse | void> {
        const a = await this.httpClient
            .get<CMPaymentTxnResponse>(`${this.baseUrl}/CMPaymentTxn_Load/${cmContractId}/${cmMemberId}/${feeType}?isAI=${IsAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "cMPaymentTxn_Load"); });
        return a;
    }
    
    async CMPaymentItem_Get(encryptedUser: string, cmMemberFeeId: number, IsAI: boolean): Promise<CMPaymentItem[] | void> {
        const a = await this.httpClient
            .get<CMPaymentItem[]>(`${this.baseUrl}/CMPaymentItem_Get/${cmMemberFeeId}?isAI=${IsAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentItem_Get"); });
        return a;
    }

    async CMPaymentTxn_Update(encryptedUser: string, cmPaymentTxnID: number, total: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMPaymentTxn_Update/${cmPaymentTxnID}/${total}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentTxn_Update"); });
        return a;
    }    
    
    async CMPaymentTxn_Create(encryptedUser:string, cmPaymentTxn:CMPaymentTxn, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMPaymentTxn_Create/?isAI=${isAI}`, cmPaymentTxn, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentTxn_Create"); });
        return a;
    }    

    async CMPaymentTxn_Delete(encryptedUser: string, cmPaymentTxnID: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMPaymentTxn_Delete/${cmPaymentTxnID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentTxn_Delete"); });
        return a;
    }

    async CMPaymentItem_Create(encryptedUser: string, cmPaymentItem: CMPaymentItem, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CMPaymentItem_Create/?isAI=${isAI}`, cmPaymentItem, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentItem_Create"); });
        return a;
    }
    async cMPaymentItem_Charge_Get(encryptedUser: string, contractID: number, memberID: number, isAI = false): Promise<PayListResponse | void> {
        const a = await this.httpClient
            .get<PayListResponse>(`${this.baseUrl}/CMPaymentItem_Charge_Get/${contractID}/${memberID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentItem_Charge_Get"); });
        return a;
    }

    async cMPaymentItem_Delete(encryptedUser: string, cmPaymentItemID: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMPaymentItem_Delete/${cmPaymentItemID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentItem_Charge_Get"); });
        return a;
    }

    async CMContract_isPaymentDone(encryptedUser: string, contractId: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMContract_isPaymentDone/${contractId}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMContract_isPaymentDone"); });
        return a;
    }

    async CMPaymentItem_isPaymentDone_Update(encryptedUser: string, cmPaymentTxnID: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMPaymentItem_isPaymentDone_Update/${cmPaymentTxnID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "CMPaymentItem_isPaymentDone_Update"); });
        return a;
    }

    async retrieveCreditCard(encryptedUser: string, contactid: number, cmpaymentTxnID: number, isAI = false): Promise<RetrieveCardResponse | void> {
        const a = await this.httpClient
            .get<RetrieveCardResponse>(`${this.baseUrl}/RetrieveCreditCard/${contactid}/${cmpaymentTxnID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "RetrieveCreditCard"); });
        return a;
    }


    async updateCreditCard(encryptedUser: string, cc: CC, contactid: number, cmpaymentTxnID: number, isAI = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/UpdateCreditCard/${contactid}/${cmpaymentTxnID}?isAI=${isAI}`, cc, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "PaymentService", "UpdateCreditCard"); });
        return a;
    }

}
