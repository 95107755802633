import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../../models/app-config.model';
import { BCMCLPUser, CLPUser, TeamCodeResponseIEnumerable, TeamCodes, TeamResponse, UserResponse } from '../../models/clpuser.model';
import { CMClub, CMClubResponse, FilterTeam_Load_GeneralResponse } from '../../models/cm-club-service.model';
import { DropDownItem, SimpleResponse, UserDD } from '../../models/generic-response.model';
import { RoleFeaturePermissions } from '../../models/role-container.model';
import { CMClubService } from '../../services/cm-club.service';
import { CountryStateService } from '../../services/country-state.service';
import { NotificationService } from '../../services/notification.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TeamOfficeService } from '../../services/team-office.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-cm-general',
    templateUrl: './cm-general.component.html',
    styleUrl: './cm-general.component.css'
})
export class CmGeneralComponent implements OnDestroy {
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: BCMCLPUser;
    userAI: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    teamFilterDD: TeamCodes[];
    userList: UserDD[] = [{
        value: 0,
        text: "Select",
    }];
    clubConfigForm: FormGroup
    teamCodeDetails: TeamResponse;
    club: CMClub;
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    clubConfig: CMClub = <CMClub>{};
    curClubId: number = 0
    showSpinner: boolean;
    selectedUser: string;
    curTeam: TeamCodes = <TeamCodes>{};
    curTeamCode: number = 0
    isBtnDisabled: boolean;
    private teamSub: Subscription;
    private clubIdSub: Subscription;
    submitted: boolean;
    clpCompanyId: number;
    slurpyUserId: number;
    userRole: number;
    isAI: boolean = false;
    countryCode: string = "US";
    stateCode: string = "";
    stateList: DropDownItem[];
    countryDD: DropDownItem[];
    constructor(private _localService: LocalService,
        private _teamOfficeService: TeamOfficeService,
        private _userService: UserService,
        private _utilityService: UtilityService,
        private fb: FormBuilder,
        private clubService: CMClubService,
        private _notifyService: NotificationService,
        private _globalService: GlobalService,
        private _appconfigService: AppConfigService,
        private _countryStateService: CountryStateService,
        private _router: Router,
    ) {
        this._localService.setShowMenu(true)
        this._localService.setRouteName("cm-general")
        this._localService.setIsCmManager(true);
        this._localService.updateTeamDD(true)


    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.clubConfigForm = this.prepareClubConfigForm()
        this.encryptedUser = localStorage.getItem("token");
        if (!this.encryptedUser?.includes(":")) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
        }
        if (this.encryptedUser) {
            this.showSpinner = true
            this.authenticateR().then(() => {
                if (this.user || this.userAI) {
                    this.loadPage();
                    this.showSpinner = false;
                    this.countryCode = "US";
                    this.getCountry();                   
                }
                else {
                    this.showSpinner = false
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this.showSpinner = false
            this._router.navigate(['/unauthorized']);
        }
    }
    async loadPage() {
        if (this.userRole >= 3 || this.slurpyUserId > 0) {
            this.teamSub = this._teamOfficeService.curTeam.subscribe(async (data: any) => {
                if (data != 0 && data != null) {
                    this.curTeam = data
                    if (this.curTeam?.teamCode > 0) {
                        await this.filterTeamLoadGeneral(true, this.curTeam?.teamCode)
                        this.curTeamCode = this.curTeam?.teamCode
                        await this.CMClub_Get(this.curTeam.teamCode)
                        await this.cLPUserGetListByTeamCode(this.clpCompanyId, this.clubConfig?.teamCode)
                        this.patchClubConfigForm()
                    }
                }
                else {
                    this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
                    if (this.curTeam?.teamCode > 0) {
                        await this.filterTeamLoadGeneral(true, this.curTeam?.teamCode)
                        await this.CMClub_Get(this.curTeam.teamCode)
                        await this.cLPUserGetListByTeamCode(this.clpCompanyId, this.clubConfig?.teamCode)
                        this.patchClubConfigForm()
                    }
                    else {
                        await this.filterTeamLoadGeneral(true, 0)
                    }
                }
            })

            this.clubIdSub = this.clubService.curClubId.subscribe(async (id: number) => {
                this.curClubId = id
                if (this.curClubId == -1) {
                    this._notifyService?.showSuccess("Fill the fields to create new club.", "", 3000)
                    this.clubConfig = <CMClub>{};
                    this.clubConfig.clubId = 0
                    this.curClubId = 0
                    this.clubConfigForm.reset()
                    this.clubConfigForm.controls['teamCode'].patchValue(0)
                    this.clubConfigForm.controls['ownerUserId'].patchValue(0)
                }
            })
        }
        else {
            this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
            if (this.curTeam && this.curTeam.teamCode > 0) {
                await this.CMClub_Get(this.curTeam.teamCode)
                await this.cLPUserGetListByTeamCode(this.clpCompanyId, this.clubConfig?.teamCode)
                this.patchClubConfigForm()
            }
            //this.isBtnDisabled = this.setSubmitBtnDisable(this.curTeam?.teamCode)
        }
    }

    setSubmitBtnDisable(teamCode): boolean {
        //if (teamCode > 0 && (this.userRole >= 3 || this.slurpyUserId > 0)) {
        //  return false
        //}
        //else if (teamCode > 0 && this.userRole < 3) {
        //  return false
        //}
        //else {
        return true
        //}

    }


    async CMClub_Get(teamCode) {
        this.showSpinner = true
        if (!teamCode || teamCode == 0) {
            this._notifyService?.showError("No team is selected", "Error", 3000)
            return
        }
        await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, teamCode, this.isAI).
            then(async (result: CMClubResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clubConfig = response?.clubConfig
                    this.curClubId = this.clubConfig?.clubId
                    this.showSpinner = false
                }

            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareClubConfigForm() {
        return this.fb.group({
            clubName: ['', [Validators.required]],
            teamCode: [0, [Validators.required]],
            clubEIN: [''],
            clubRegion: [''],
            clubTerritory: [''],
            ownerUserId: [0],
            clubCity: [''],
            clubAddress: [''],
            clubZip: [''],
            country: ['US'],
            state: [''],
        })
    }


    private async authenticateR() {

        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                    this.slurpyUserId = this.user?.slurpyUserId
                    this.userRole = this.user?.userRole
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCountry() {
        await this._countryStateService.getCountryGetList(this.encryptedUser)
            .then(async (result: DropDownItem[]) => {
                if (result) {
                    this.countryDD = UtilityService.clone(result);
                    this.countryDD?.unshift({
                        value: "",
                        text: "--Select--",
                        isSelected: true
                    });
                    this.getCountryStateList(this.countryCode);
                    //this.stateCode = this.curSite?.state ? this.curSite?.state : ""
                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeCountry(countryCode) {
        this.stateCode = "";
        if (countryCode == '') {
            this._notifyService.showError("Please select valid country!", "", 3000);
            this.countryCode = "US";
        }
        else
            this.getCountryStateList(countryCode);
    }

    async getCountryStateList(countryCode: string) {
        await this._countryStateService.getCountryStateList(this.encryptedUser, countryCode)
            .then(async (result: DropDownItem[]) => {
                if (result) {
                    this.stateList = UtilityService.clone(result);
                    this.stateList?.unshift({
                        value: "",
                        text: "--Select--",
                        isSelected: false
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async cLPUserGetListByTeamCode(clpCompanyId, teamCode) {
        this.showSpinner = true
        if (!isNullOrUndefined(clpCompanyId) && !isNullOrUndefined(teamCode)) {
            await this._userService.cLPUserGetListByTeamCode(this.encryptedUser, clpCompanyId, teamCode).
                then(async (result: UserDD[]) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.userList = response
                        this.userList.unshift({
                            value: 0,
                            text: "Select",
                        });

                        this.clubConfigForm.controls['ownerUserId'].patchValue(0)
                        this.showSpinner = false
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("contact-task.getLoadUsers", err.message, null,
                        'clpCompanyId: ' + this.clpCompanyId
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async getTeamDD() {
        await this._teamOfficeService.teamCode_GetList(this.encryptedUser, this.clpCompanyId, this.isAI)
            .then(async (result: TeamCodeResponseIEnumerable) => {
                if (result) {
                    this.teamFilterDD = UtilityService.clone(result?.teamCodes);
                    this.teamFilterDD.unshift({
                        teamCode: 0,
                        cLPCompanyID: this.clpCompanyId,
                        display: "Select",
                        sOrder: 0,
                        companyName: ""
                    });

                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async filterTeamLoadGeneral(isReload, tCode) {
        let teamCode = tCode ? tCode : 0
        await this.clubService.filterTeamLoadGeneral(this.encryptedUser, this.clpCompanyId, teamCode, isReload, this.isAI)
            .then(async (result: FilterTeam_Load_GeneralResponse) => {
                if (result) {
                    this.teamFilterDD = UtilityService.clone(result?.filterNotMappedTeams);
                    this.teamFilterDD.unshift({
                        teamCode: 0,
                        cLPCompanyID: this.clpCompanyId,
                        display: "Select",
                        sOrder: 0,
                        companyName: ""
                    });

                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async onSubmitClubConfigForm() {
        if (this.slurpyUserId > 0 && (this.clubConfig == null || Object.keys(this.clubConfig)?.length == 0)) {
            this._notifyService?.showError("Select team or Select Add new.", "Error", 3000)
            return
        }
        if (this.userRole >= 3 && this.slurpyUserId == 0 && (this.clubConfig == null || Object.keys(this.clubConfig)?.length == 0)) {
            this._notifyService?.showError("Select a team you wants to update.", "Error", 3000)
            return
        }
        if (this.clubConfigForm.valid && this.clubConfigForm.controls['teamCode'].value > 0) {
            this.submitted = true
            let clubConfig: CMClub = <CMClub>{}
            clubConfig.clubId = this.curClubId > 0 ? this.curClubId : 0
            clubConfig.clubName = this.clubConfigForm.controls['clubName'].value
            clubConfig.eIN = this.clubConfigForm.controls['clubEIN'].value
            clubConfig.clpcompanyID = this.clpCompanyId
            clubConfig.territory = this.clubConfigForm.controls['clubTerritory'].value
            clubConfig.region = this.clubConfigForm.controls['clubRegion'].value
            clubConfig.userId = this.clubConfigForm.controls['ownerUserId'].value
            clubConfig.teamCode = this.clubConfigForm.controls['teamCode'].value;
            clubConfig.DLId = this.clubConfig.DLId;
            clubConfig.EmergencyNameId = this.clubConfig.EmergencyNameId;
            clubConfig.EmergencyPhoneId = this.clubConfig.EmergencyPhoneId;
            clubConfig.DOBId = this.clubConfig.DOBId;
            clubConfig.city = this.clubConfigForm.controls['clubCity'].value;
            clubConfig.address = this.clubConfigForm.controls['clubAddress'].value;
            clubConfig.zip = this.clubConfigForm.controls['clubZip'].value;
            clubConfig.country = this.countryCode;
            clubConfig.state = this.stateCode;

            await this.clubService.CMClub_Update(this.encryptedUser, clubConfig, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        if (this.curClubId > 0) {
                            this._notifyService?.showSuccess("Club updated successfully.", "Success", 3000)
                        }
                        else {
                            this._notifyService?.showSuccess("Club created successfully.", "Success", 3000)
                        }
                        if (response.messageInt > 0) {
                            this.curClubId = response.messageInt;
                        }

                        if (this.userRole >= 3 || this.slurpyUserId > 0) {
                            this.curTeam = this.teamFilterDD?.filter((team) => team?.teamCode == this.curTeamCode)[0]
                            localStorage.setItem("curTeam", JSON.stringify(this.curTeam))
                            this._localService.updateTeamDD(true)
                            this._teamOfficeService.setCurTeam(this.curTeam)
                            //this.filterTeamLoadGeneral(true, this.curTeam?.teamCode)
                        }
                        this.submitted = false
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.submitted = false
                    this._globalService.error("contact-task.getLoadUsers", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            if (!this.clubConfigForm.controls['clubName'].valid) {
                this._notifyService?.showError("Club name is required.", "Error", 3000)
                return
            }
            if (!this.clubConfigForm.controls['teamCode'].valid || this.clubConfigForm.controls['teamCode'].value == 0) {
                this._notifyService?.showError("Team is required.", "Error", 3000)
            }
        }
    }

    async onChangeTeamCode(teamCode) {
        if (teamCode > 0) {
            this.curTeamCode = teamCode
            await this.cLPUserGetListByTeamCode(this.clpCompanyId, teamCode)
        }
    }

    ngOnDestroy() {
        if (this.teamSub) {
            this.teamSub.unsubscribe();
        }
        if (this.clubIdSub) {
            this.clubIdSub.unsubscribe()
        }
        //this.curClubId = 0
        this.clubService.setCurClubId(0)
    }

    patchClubConfigForm() {
        this.clubConfigForm.controls['clubName'].patchValue(this.clubConfig?.clubName)
        this.clubConfigForm.controls['ownerUserId'].patchValue(this.clubConfig?.userId)
        this.clubConfigForm.controls['clubEIN'].patchValue(this.clubConfig?.eIN)
        this.clubConfigForm.controls['clubRegion'].patchValue(this.clubConfig?.region)
        this.clubConfigForm.controls['clubTerritory'].patchValue(this.clubConfig?.territory)
        this.clubConfigForm.controls['teamCode'].patchValue(this.clubConfig?.teamCode > 0 ? this.clubConfig?.teamCode : 0)
        this.clubConfigForm.controls['ownerUserId'].patchValue(this.clubConfig?.userId > 0 ? this.clubConfig?.userId : 0)
        this.clubConfigForm.controls['clubCity'].patchValue(this.clubConfig?.city)
        this.clubConfigForm.controls['clubAddress'].patchValue(this.clubConfig?.address)
        this.clubConfigForm.controls['clubZip'].patchValue(this.clubConfig?.zip)
        this.clubConfigForm.controls['country'].patchValue(this.clubConfig?.country)
        this.clubConfigForm.controls['state'].patchValue(this.clubConfig?.state)
        this.stateCode = this.clubConfig?.state;
        this.countryCode = this.clubConfig?.country;
    }
}
