import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TeamCodeResponseIEnumerable, TeamResponse } from '../models/clpuser.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class TeamOfficeService {
    private baseUrl: string;
    private api: string = 'api/TeamCode';
    private apiAI: string = 'api/AITeamCode';

    team = new BehaviorSubject<{}>(0);
    curTeam = this.team.asObservable();
    baseUrlAI: string;


    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
        this.baseUrlAI = _baseUrl + this.apiAI;
    }

    setCurTeam(value) {
        this.team.next(value);
    }

    async teamCode_GetList(encryptedUser: string, clpCompanyId: number, isAI: boolean): Promise<TeamCodeResponseIEnumerable | void> {
        const a = await this.httpClient
            .get<TeamCodeResponseIEnumerable>(`${this.baseUrl}/TeamCode_GetList/${clpCompanyId}/${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "TeamOfficeSetupService", "TeamCode_GetList"); });
        return a;
    }

    async teamCode_GetListAI(encryptedUser: string, clpCompanyId: number): Promise<TeamCodeResponseIEnumerable | void> {
        const a = await this.httpClient
            .get<TeamCodeResponseIEnumerable>(`${this.baseUrlAI}/TeamCode_GetList/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "TeamOfficeSetupService", "TeamCode_GetList"); });
        return a;
    }

    async teamCodeLoad(encryptedUser: string, teamCode: number): Promise<TeamResponse | void> {
        const a = await this.httpClient
            .get<TeamResponse>(`${this.baseUrl}/TeamCode_Load/${teamCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "teamCode - " + teamCode, encryptedUser, "TeamOfficeSetupService", "TeamCode_Load"); });
        return a;
    }

}
