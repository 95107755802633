import { DocumentMailMergeService } from '../../../services/document-mail-merge.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BCMCLPUser, CLPUser, TeamCodes } from '../../../models/clpuser.model';
import { eFeatures } from '../../../models/enum.model';
import { GlobalService } from '../../../services/shared/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { IntDropDownItem, IntDropDownItemListResponse, SimpleResponse } from '../../../models/generic-response.model';
import { CMClubService } from '../../../services/cm-club.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CMClub, CMClubResponse, CMSite, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { CMClubSiteConfig, CMClubSiteConfigListResponse } from '../../../models/cm-club-service.model';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { AppConfigService } from '../../../services/shared/app-config.service';
import { ConfigDetails } from '../../../models/app-config.model';

@Component({
    selector: 'app-lm-engage-dash',
    templateUrl: './lm-engage-dash.component.html',
    styleUrl: './lm-engage-dash.component.css'
})
export class LmEngageDashComponent implements OnDestroy {
    encryptedUser: string = '';
    user: BCMCLPUser;
    mailMergeTempList: IntDropDownItem[];
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    clubConfig: CMClub;
    clubSiteConfig: CMClubSiteConfig;
    clubSiteId: number = 0;
    customID1: any = 0;
    customID2: any = 0;
    customID3: any = 0;
    customID4: any = 0;
    customID5: any = 0;
    customID6: any = 0;
    customID7: any = 0;
    customID8: any = 0;
    customID9: any = 0;
    customID10: any = 0;
    site = {} as CMSite;
    sites: CMSite[];
    curSiteId: number = 0
    curTeam: TeamCodes = <TeamCodes>{};
    curSite: CMSite = <CMSite>{};
    showSpinner: boolean;
    submitted: boolean;
    curSiteSub: Subscription;
    clpCompanyId: number;
    isAI: boolean = false;
    constructor(private localService: LocalService,
        private documentMailMergeService: DocumentMailMergeService,
        public _localService: LocalService,
        private _globalService: GlobalService,
        private clubService: CMClubService,
        private _notifyService: NotificationService,
        private _appconfigService: AppConfigService,
        private _router: Router,
        private _utilityService: UtilityService
    ) {

        this.localService.setShowMenu(true)
        this.localService.setIsCmManager(false);
        this.localService.setRouteName("sm-engage-dash")
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.encryptedUser = localStorage.getItem("token");
        this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")

        if (this.encryptedUser) {
            this.authenticateR().then(async () => {
                if (this.user) {
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }

    async loadData() {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || "{}")
        this.mailMergeTemplateGetList();
        await this.CMClub_Get();
        await this.clubSiteConfigSearch();
        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = await value;
            this.getEngage(this.curSite?.siteID);
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
                this.getEngage(this.curSite?.siteID);
            }
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lm-engage-dash.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mailMergeTemplateGetList() {
        this.showSpinner = true
        await this.documentMailMergeService.mailMergeTemplateGetList(this.encryptedUser, this.clpCompanyId, this.user?.cLPUserID)
            .then(async (result: IntDropDownItemListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.mailMergeTempList = response?.intDropDownItemList;
                    if (this.mailMergeTempList?.length > 0) {
                        this.mailMergeTempList.unshift({ id: 0, text: ' -- Select --' });

                    }
                    this.customID1 = this.mailMergeTempList[0].id.toString();
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("lm-engage-dash.mailMergeTemplateGetList", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async CMClub_Get() {
        if (this.curTeam?.teamCode) {
            this.showSpinner = true
            await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, this.curTeam?.teamCode, this.isAI).
                then(async (result: CMClubResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubConfig = response?.clubConfig;
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this._globalService.error("lm-engage-dash.CMClub_Get", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async clubSiteConfigSearch() {
        if (this.clubConfig?.clubId > 0 && this.curTeam?.teamCode > 0) {
            this.showSpinner = true
            await this.clubService.clubSiteConfigSearch(this.encryptedUser, 0, this.clubConfig?.clubId, this.curTeam?.teamCode, this.clpCompanyId, this.isAI).
                then(async (result: CMClubSiteConfigListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubSiteId = response?.messageInt;
                        this.clubSiteConfig = response?.clubSiteConfigs[response?.clubSiteConfigs?.length - 1];
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this._globalService.error("lm-engage-dash.clubSiteConfigSearch", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }

    }

    async engageSaved() {
        if (this.customID1 == 0 && this.customID2 == 0 && this.customID3 == 0 && this.customID4 == 0 && this.customID5 == 0 && this.customID6 == 0 && this.customID7 == 0 && this.customID8 == 0 && this.customID9 == 0 && this.customID10 == 0) {
            this._notifyService?.showError("No value selected.", "Error", 3000);
            return
        }
        this.site.siteID = this.curSite?.siteID;
        this.site.engDashCustomMMT1D1 = this.customID1;
        this.site.engDashCustomMMT1D2 = this.customID2;
        this.site.engDashCustomMMT1D3 = this.customID3;
        this.site.engDashCustomMMT1D4 = this.customID4;
        this.site.engDashCustomMMT1D5 = this.customID5;
        this.site.engDashCustomMMT1D6 = this.customID6;
        this.site.engDashCustomMMT1D7 = this.customID7;
        this.site.engDashCustomMMT1D8 = this.customID8;
        this.site.engDashCustomMMT1D9 = this.customID9;
        this.site.engDashCustomMMT1D10 = this.customID10;
        this.submitted = true
        await this.clubService.CMSite_Engage_Update(this.encryptedUser, this.site, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.getEngage(this.curSite?.siteID);
                    this._notifyService?.showSuccess("Engage dashboard saved successfully", "Success", 3000);
                }
                this.submitted = false
            })
            .catch((err: HttpErrorResponse) => {
                this.submitted = false
                this._globalService.error("lm-engage-dash.engageSaved", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getEngage(siteId) {
        if (siteId > 0) {
            this.showSpinner = true
            await this.clubService.siteSearch(this.encryptedUser, siteId, 0, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites;
                        this.customID1 = +this.sites[0].engDashCustomMMT1D1;
                        this.customID2 = +this.sites[0].engDashCustomMMT1D2;
                        this.customID3 = +this.sites[0].engDashCustomMMT1D3;
                        this.customID4 = +this.sites[0].engDashCustomMMT1D4;
                        this.customID5 = +this.sites[0].engDashCustomMMT1D5;
                        this.customID6 = +this.sites[0].engDashCustomMMT1D6;
                        this.customID7 = +this.sites[0].engDashCustomMMT1D7;
                        this.customID8 = +this.sites[0].engDashCustomMMT1D8;
                        this.customID9 = +this.sites[0].engDashCustomMMT1D9;
                        this.customID10 = +this.sites[0].engDashCustomMMT1D10;
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("lm-engage-dash.getEngage", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    ngOnDestroy() {
        if (this.curSiteSub) {
            this.curSiteSub.unsubscribe()
        }
    }

}
