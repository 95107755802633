<div class="main-wrapper-section">
    <div class="common-header">
        <div class="common-layout-section">
            <div class="common-header">
                <h3>Products</h3>
                <div class="display-flex">
                    <button class="primary-btn margin-left15" type="button" (click)="showBulkModal()">Bulk Edit Rate Sheet <i class="fa fa-edit"></i></button>
                    <button class="btn primary-btn" *ngIf="!isShowAddNewProd" (click)="showAddNewProduct()">Add New Product <i class="fa fa-plus"></i></button>
                    <select class="form-control margin-left15" *ngIf="isShowAddNewProd" (change)="onChangeContractType($event)">
                        <option selected="selected" value="">-Add New Product-</option>
                        <option value="-1">-Cancel Add Product-</option>
                        <option *ngFor="let item of cMProductUnused" [value]="item?.productID">{{item?.productName}}</option>
                    </select>
                    <select class="form-control margin-left15" *ngIf="isShowClone && sites.length > 1" (change)="onChangeCloneOpt($event)">
                        <option selected="selected" value="">-Select Action-</option>
                        <option value="-1" *ngFor="let item of cloneOpts" [value]="item?.value">{{item?.display}}</option>
                    </select>
                    <button class="btn primary-btn margin-left15" *ngIf="!isShowClone && sites?.length > 1" (click)="showCloneDD()">Clone</button>
                </div>
            </div>
            <div class="common-head-panel">
                <div class="common-column common-column-No"></div>
                <div class="common-column common-column-product">Product</div>
                <div class="common-column common-column-documents">Documents</div>
                <div class="common-column common-column-settings">Settings</div>
                <div class="common-column common-column-description">Descriptions</div>
                <div class="common-column common-column-edit"></div>
            </div>
            <div class="common-body-panel" *ngFor="let item of productList; let i = index">
                <div class="common-column common-column-No">
                    <label class="mobile-label">Order</label>
                    <div class="text-center">{{item?.sOrder}}</div>
                    <div class="text-center" *ngIf="item?.isDefault" matTooltip="Your default product" matTooltipHideDelay="50">
                        <svg class="card-star-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.05 19.07">
                            <defs></defs>
                            <polygon class="card-star-svg" points="10.03 0 13.12 6.28 20.05 7.28 15.04 12.17 16.22 19.07 10.03 15.81 3.83 19.07 5.01 12.17 0 7.28 6.93 6.28 10.03 0" />
                        </svg>
                    </div>
                </div>
                <div class="common-column common-column-product">
                    <label class="mobile-label">Product</label>
                    <div class="common-column-product-headline">
                        <label>{{item?.productName}}</label>
                    </div>
                    <div class="" style="margin-top: -10px;">
                        <div *ngFor="let fee of item?.fees">
                            <div style="margin-bottom:10px;">
                                <span style="margin-left:0px;"><b>{{fee?.cmFeeName}}:</b></span>
                                <span style="margin-left:10px;">{{fee?.feeAmount | currency}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common-column common-column-documents">
                    <label class="mobile-label">Documents</label>
                    <div class="" style="margin-top: -10px;">
                        <div *ngFor="let doc of item?.documents">
                            <div style="padding-top: 10px;"><b>{{doc?.cmProductName}}</b></div>
                            <div> <a href="{{mySoLink}}marketing/mailmergetemplate.aspx?mmtid={{doc['mailMergeTemplateID']}}" target="_blank">{{getMailMergeTemplateName(doc['mailMergeTemplateID'])}}</a></div>
                        </div>
                    </div>
                </div>
                <div class="common-column common-column-settings">
                    <label class="mobile-label">Setting</label>
                    <div>
                        <label>Days of the week</label>
                        <span>{{daysOfWeekFormat(item?.daysOfWeekOnPlan)}}</span>
                    </div>
                    <div class="margin-top10">
                        <label>Seasonal</label>
                        <span>{{item?.isSeasonal ? "Yes" : "No"}}</span>
                    </div>
                    <div class="margin-top10">
                        <label>Max Members</label>
                        <span>{{item?.intMaxMembers}}</span>
                    </div>
                    <div class="margin-top10">
                        <label>Display Pricing Inline</label>
                        <span>{{item?.isShowPricing ? "Yes" : "No"}}</span>
                    </div>
                    <div class="margin-top10">
                        <label>Term (Months)</label>
                        <span>{{item?.contractTerm}}</span>
                    </div>
                </div>
                <div class="common-column common-column-description">
                    <label class="mobile-label">Descriptions</label>  
                    <div>
                        <label>Product Description</label>
                        <span [innerHtml]="item?.productDesc"></span>
                    </div>
                    <div class="margin-top10">
                        <label>Price Sheet Description</label>
                        <span [innerHtml]="item?.priceSheetDescLive"></span>
                    </div>
                </div>
                <div class="common-column common-column-edit">
                    <label class="mobile-label">Action</label>
                    <div>
                        <div class="text-center" matTooltip="Your product is {{item?.isActive ? 'active' : 'inactive'}}" matTooltipHideDelay="50">
                            <i class="fa fa-circle fa-xl active-product" [ngClass]="item?.isActive ? 'active-product' : 'inactive-product'" aria-hidden="true"></i>
                        </div>
                        <div class="text-center margin-top20" [hidden]="!ready">
                            <div class="product-edit-icon" (click)="editProduct(item)"><a><img src="..\assets\img\Edit-Icon.svg" /></a></div>
                            <div data-toggle="modal" data-target="#deleteProductConfirmation" class="product-delete-icon" (click)="showConfirmModal(item)"><a><img src="..\assets\img\delete-icon.svg" /></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Edit Mode -->
<div id="myModal" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-xl">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Product Settings</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <form [formGroup]="editProductForm" (ngSubmit)="cMProductUpdate()">
                <div class="modal-body">
                    <div class="edit-panel cal-height">
                        <div class="row">
                            <div class="col-sm-6">
                                <label>Product</label>
                            </div>
                            <div class="col-sm-6 text-right">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isActive" formControlName="isActive">
                                    <label class="custom-control-label" for="isActive">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <h5>{{product?.productName}}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12"><hr /></div>
                        </div>
                        <div class="row row-header-tight">
                            <div class="col-sm-12">
                                <h4>Fees</h4>
                            </div>
                        </div>
                        <div class="row-buffer-both">
                            <div formArrayName="fees">
                                <div class="row-buffer-top" *ngFor="let fee of editProductForm.get('fees')['controls']; let i=index">
                                    <div [formGroupName]="i" class="product-panel">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <input type="text" class="form-control" formControlName="cmFeeName" maxlength="50" placeholder="Fee Name" id="cmFeeName-{{ i }}">
                                            </div>
                                            <div class="col-sm-2">
                                                <input type="text" class="form-control" formControlName="feeAmount" maxlength="50" placeholder="$100" appCurrency (blur)="feeChange(i)" id="feeAmount-{{ i }}">
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="custom-control custom-switch fee-collect-now">
                                                    <input type="checkbox" class="custom-control-input" formControlName="isCollectNow" id="isCollectNow-{{ i }}" />
                                                    <label class="custom-control-label" for="isCollectNow-{{ i }}">Collect Now</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="custom-control custom-switch fee-collect-now">
                                                    <input type="checkbox" class="custom-control-input" formControlName="isTaxable" id="isTaxable-{{ i }}" />
                                                    <label class="custom-control-label" for="isTaxable-{{ i }}">Taxable</label>
                                                </div>
                                            </div>
                                            <div class="margin-top5 col-sm-3 text-right">
                                                <a style="cursor:pointer;" title="Remove Fee" (click)="removeFee(i)"><i class="material-icons" style="color:#dc3545;">remove_circle</i></a>
                                            </div>
                                        </div>
                                        <div class="row margin-top10">
                                            <!--<div class="col-sm-2"></div>-->
                                            <div class="col-sm-2">
                                                <div class="custom-control custom-switch fee-recurring">
                                                    <input type="checkbox" class="custom-control-input" formControlName="isRecurring" id="isRecurring-{{ i }}">
                                                    <label class="custom-control-label" for="isRecurring-{{ i }}">Recurring</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 product-recurring" [hidden]="!fee.value.isRecurring">
                                                <div class="form-group">
                                                    <div class="float-left product-recurring-label">Recurs every </div>
                                                    <div class="float-left product-recurring-interval" style="width:70px;">
                                                        <select class="form-control" formControlName="recursEveryInterval" id="recursEveryInterval-{{ i }}" style="width:70px;">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div class="float-left product-recurring-every" style="width:100px;">
                                                        <select class="form-control" formControlName="recursEvery" id="recursEvery-{{ i }}" style="width:120px;">
                                                            <option value="1">day(s)</option>
                                                            <option value="2">month(s)</option>
                                                            <option value="3">year(s)</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-5">
                                                <div class="form-group">
                                                    <div class="float-left product-recurring-label">Payment can be </div>
                                                    <div class="float-left product-recurring-interval">
                                                        <select class="form-control" formControlName="memberSplitable" id="memberSplitable-{{ i }}">
                                                            <option value="1">paid by primary member</option>
                                                            <option value="2">splittable by each member</option>
                                                            <option value="3">paid by every member</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row margin-top10">
                                            <div class="col-sm-6">
                                                <div class="custom-control custom-switch fee-collect-now">
                                                    <input type="checkbox" class="custom-control-input" formControlName="isPaymentSplitable" id="isPaymentSplitable-{{ i }}">
                                                    <label class="custom-control-label" for="isPaymentSplitable-{{ i }}">Fee can be split into different payment methods</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="custom-control custom-switch fee-collect-now">
                                                    <div class="clickable" (click)="copyToClip('PH_CMContractFee' + clean(fee.value.cmFeeName))">PH_CMContractFee{{clean(fee.value.cmFeeName)}}</div>
                                                    <div class="clickable" (click)="copyToClip('PH_CMContractTax' + clean(fee.value.cmFeeName))">PH_CMContractTax{{clean(fee.value.cmFeeName)}}</div>
                                                    <div class="clickable" (click)="copyToClip('PH_CMContractTot' + clean(fee.value.cmFeeName))">PH_CMContractTot{{clean(fee.value.cmFeeName)}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <button class="primary-btn" type="button" (click)="addFee()">Add Fee</button>
                            </div>
                        </div>
                        <!--<div class="row">
                            <div class="col-sm-3">
                                <label>Sign Up Fee</label>
                                <input class="form-control" type="text" formControlName="feeSignUp" maxlength="50" tabindex="0" appCurrency>
                            </div>
                            <div class="col-sm-3">
                                <label>Membership Fee</label>
                                <input type="text" class="form-control" formControlName="feeMembership" maxlength="50" tabindex="0" appCurrency>
                            </div>
                        </div>-->
                        <div class="row row-buffer-both">
                            <div class="col-sm-3">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isDefault" formControlName="isDefault">
                                    <label class="custom-control-label" for="isDefault">Set As Default</label>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isShowPricing" formControlName="isShowPricing">
                                    <label class="custom-control-label" for="isShowPricing">Display Pricing Inline</label>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isSeasonal" formControlName="isSeasonal">
                                    <label class="custom-control-label" for="isSeasonal">Seasonal (Months will be selectable)</label>
                                </div>
                            </div>
                        </div>
                        <div class="row row-buffer-top row-header">
                            <div class="col-sm-12">
                                <label>Days of the Week</label>
                            </div>
                        </div>
                        <div class="row row-buffer-top">
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isMonday" formControlName="isMonday">
                                    <label class="custom-control-label" for="isMonday">Mon</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isTuesday" formControlName="isTuesday">
                                    <label class="custom-control-label" for="isTuesday">Tues</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isWednesday" formControlName="isWednesday">
                                    <label class="custom-control-label" for="isWednesday">Wed</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isThursday" formControlName="isThursday">
                                    <label class="custom-control-label" for="isThursday">Thu</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isFriday" formControlName="isFriday">
                                    <label class="custom-control-label" for="isFriday">Fri</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isSaturday" formControlName="isSaturday">
                                    <label class="custom-control-label" for="isSaturday">Sat</label>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="isSunday" formControlName="isSunday">
                                    <label class="custom-control-label" for="isSunday">Sun</label>
                                </div>
                            </div>
                        </div>
                        <div class="row row-buffer-top">
                            <div class="col-sm-12">
                                <label>Product Description</label>
                                <ckeditor [editor]="editor" [config]="editorConfig" formControlName="productDesc"></ckeditor>
                            </div>
                        </div>
                        <div class="row row-buffer-top">
                            <div class="col-sm-12">
                                <label>Rate Sheet Description</label>
                                <div class="rate-sheet-description" [innerHtml]="getPriceSheetDescription()"></div>
                            </div>
                        </div>
                        <div class="row row-buffer-top">
                            <div class="col-sm-2">
                                <label>Term (Months)</label>
                                <input class="form-control" formControlName="contractTerm" mask="00" />
                            </div>
                            <div class="col-sm-2">
                                <label>Max Members</label>
                                <select class="form-control" formControlName="intMaxMembers">
                                    <option selected="selected" value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div>
                        </div>
                        <div class="row row-buffer-top">
                            <div class="col-sm-12"><hr /></div>
                        </div>
                        <div class="row row-header-tight">
                            <div class="col-sm-12">
                                <h4>Add Documents</h4>
                            </div>
                        </div>
                        <div class="row-buffer-both">
                            <div formArrayName="productDoc">
                                <div class="row-buffer-top" *ngFor="let invoice of editProductForm.get('productDoc')['controls']; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-sm-3">
                                            <input type="text" class="form-control" formControlName="cmProductName" placeholder="Document Name">
                                        </div>
                                        <div class="col-sm-8">
                                            <select class="form-control" formControlName="mailMergeTemplateID">
                                                <!--<option value="0">-Select-</option>-->
                                                <option *ngFor="let item of mailMergeTemplateList" [value]="+item?.id">{{item?.text}}</option>
                                            </select>
                                        </div>
                                        <div class="margin-top5">
                                            <a style="cursor:pointer;" title="Remove Document" (click)="removeDoc(i)"><i class="material-icons" style="color:#dc3545;">remove_circle</i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <button class="primary-btn" type="button" (click)="addProductDoc()">Add Document</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group center">
                        <button class="btn primary-btn" type="submit">Save</button>
                        <button class="btn secondary-btn" type="button" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>

<!-- Bulk Edit Mode -->
<div id="bulkEditModal" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Bulk Edit Rate Sheet</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="text-center margin-top20">
                <button type="button" class="primary-btn" (click)="cMProductPublishToDraft()">Sync Published</button>
                <button type="button" class="primary-btn margin-left15" style="background: #697686; border-color: #697686 " (click)="cMProductDraftToPublish()">Sync Draft</button>
                <button class="primary-btn margin-left15" type="button" (click)="cMProductaveRateSheetDescription()">Save</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="bulkEditProductForm" (ngSubmit)="cMProductaveRateSheetDescription()">
                    <div formArrayName="bulkEditProduct" cdkDropList (cdkDropListDropped)="drop($event)">
                        <div class="common-layout-section">
                            <div class="common-head-panel">
                                <div class="common-column common-column-No">Product</div>
                                <div class="common-column common-column-published">Published Rate Sheet Description</div>
                                <div class="common-column common-column-draft">Draft Rate Sheet Description</div>
                            </div>
                            <div *ngFor="let invoice of bulkEditProductForm.get('bulkEditProduct')['controls']; let i=index" cdkDrag>
                                <div class="common-body-header">
                                    <label><b>{{getControlName(i)}}</b></label>
                                </div>
                                <div class="common-body-panel" [formGroupName]="i">
                                    <div class="common-column common-column-No">
                                        <input class="form-control" formControlName="sOrder" style="width:auto; max-width:40px;" />
                                    </div>
                                    <div class="common-column common-column-published">
                                        <ckeditor [editor]="editor" [config]="editorConfig" formControlName="priceSheetDescLive"></ckeditor>
                                    </div>
                                    <div class="common-column common-column-draft">
                                        <ckeditor [editor]="editor" [config]="editorConfig" formControlName="priceSheetDescDraft"></ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group center">
                            <button class="btn primary-btn" type="submit">Save</button>
                            <button class="btn secondary-btn" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>


</div>
<!-- Delete Product Modal-->
<div id="deleteProductConfirmation" class="modal fade" role="dialog" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <h6 class="modal-title">Are you sure you want to delete this product <b>{{product?.productName}}</b>?</h6>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success" (click)="cMProductDelete()">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
