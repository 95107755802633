
import { Component, OnDestroy } from '@angular/core';
import { LocalService } from '../../../services/shared/local.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BCMCLPUser, CLPUser, TeamCodeResponseIEnumerable, TeamCodes, TeamResponse, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserPermissions } from '../../../models/enum.model';
import { SimpleResponse, UserDD } from '../../../models/generic-response.model';
import { RoleFeaturePermissions } from '../../../models/role-container.model';
import { CMClubService } from '../../../services/cm-club.service';
import { NotificationService } from '../../../services/notification.service';
import { GlobalService } from '../../../services/shared/global.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TeamOfficeService } from '../../../services/team-office.service';
import { UserService } from '../../../services/user.service';
import { CMClub, CMClubResponse, CMSite, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { CMClubSiteConfig, CMClubSiteConfigListResponse } from '../../../models/cm-club-service.model';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../services/shared/app-config.service';
import { ConfigDetails } from '../../../models/app-config.model';

@Component({
    selector: 'app-lm-marketing',
    templateUrl: './lm-marketing.component.html',
    styleUrl: './lm-marketing.component.css'
})
export class LmMarketingComponent implements OnDestroy {

    encryptedUser: string = '';
    userResponse: UserResponse;
    user: BCMCLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    teamFilterDD: TeamCodes[];
    userList: UserDD[];
    marketingForm: FormGroup
    teamCodeDetails: TeamResponse;
    clubConfig: CMClub;
    clubSiteConfig: CMClubSiteConfig;
    site = {} as CMSite;
    sites: CMSite[];
    curTeam: TeamCodes = <TeamCodes>{};
    curSite: CMSite = <CMSite>{};
    showSpinner: boolean;
    submitted: boolean;
    curSiteSub: Subscription;
    clpCompanyId: number;
    isAI: boolean = false;
    constructor(private _localService: LocalService,
        private _teamOfficeService: TeamOfficeService,
        private _userService: UserService,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private fb: FormBuilder,
        private clubService: CMClubService,
        private _notifyService: NotificationService,
        private _appconfigService: AppConfigService,
        private _globalService: GlobalService,
        private _router: Router,
    ) {

        this._localService.setShowMenu(true)
        this._localService.setIsCmManager(false);
        this._localService.setRouteName('sm-marketing');
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.marketingForm = this.prepareMarketingForm();
        this.encryptedUser = localStorage.getItem("token");
        if (!this.encryptedUser?.includes(":")) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
        }

        if (this.encryptedUser) {
            this.authenticateR().then(async () => {
                if (this.user) {
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }

    }

    async loadData() {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || '{}')
        await this.CMClub_Get();
        this.clubSiteConfigSearch();
        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = await value
            this.getMarketing(this.curSite?.siteID)
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
                this.getMarketing(this.curSite?.siteID)
            }
        })

    }

    prepareMarketingForm() {
        return this.fb.group({
            mktCustomText1: [''],
            mktCustomText2: [''],
            mktCustomText3: [''],
            mktCustomText4: [''],
            mktCustomText5: [''],
            mktMultiText1: [''],
            mktMultiText2: [''],
            mktMultiText3: [''],
            mktMultiText4: [''],
            mktMultiText5: [''],
        })
    }

    async CMClub_Get() {
        if (this.curTeam?.teamCode > 0) {
            this.showSpinner = true
            await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, this.curTeam?.teamCode, this.isAI).
                then(async (result: CMClubResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubConfig = response?.clubConfig
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }


    async clubSiteConfigSearch() {
        this.showSpinner = true
        await this.clubService.clubSiteConfigSearch(this.encryptedUser, 0, this.clubConfig?.clubId, this.curTeam?.teamCode, this.clpCompanyId, this.isAI).
            then(async (result: CMClubSiteConfigListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clubSiteConfig = response?.clubSiteConfigs[response?.clubSiteConfigs?.length - 1]
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mktSaved() {
        this.site.siteID = this.curSite?.siteID;
        this.site.mktCustomText1 = this.marketingForm.controls['mktCustomText1'].value;
        this.site.mktCustomText2 = this.marketingForm.controls['mktCustomText2'].value;
        this.site.mktCustomText3 = this.marketingForm.controls['mktCustomText3'].value;
        this.site.mktCustomText4 = this.marketingForm.controls['mktCustomText4'].value;
        this.site.mktCustomText5 = this.marketingForm.controls['mktCustomText5'].value;
        this.site.mktMultiText1 = this.marketingForm.controls['mktMultiText1'].value;
        this.site.mktMultiText2 = this.marketingForm.controls['mktMultiText2'].value;
        this.site.mktMultiText3 = this.marketingForm.controls['mktMultiText3'].value;
        this.site.mktMultiText4 = this.marketingForm.controls['mktMultiText4'].value;
        this.site.mktMultiText5 = this.marketingForm.controls['mktMultiText5'].value;
        this.submitted = true
        await this.clubService.CMSite_Update_Mkt(this.encryptedUser, this.site, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this._notifyService?.showSuccess("Marketing saved successfully", "Success", 3000);
                }
                this.submitted = false
            })
            .catch((err: HttpErrorResponse) => {
                this.submitted = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getMarketing(siteId) {
        if (siteId > 0) {
            this.showSpinner = true
            await this.clubService.siteSearch(this.encryptedUser, siteId, 0, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites;
                        this.patchMarketing();
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    copyToClip(value) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);


        this._notifyService?.showSuccess(value + " has been copied to your clipboard.", "Success", 3000);
    }

    patchMarketing() {
        this.marketingForm.controls['mktCustomText1'].patchValue(this.sites[0].mktCustomText1)
        this.marketingForm.controls['mktCustomText2'].patchValue(this.sites[0].mktCustomText2)
        this.marketingForm.controls['mktCustomText3'].patchValue(this.sites[0].mktCustomText3)
        this.marketingForm.controls['mktCustomText4'].patchValue(this.sites[0].mktCustomText4)
        this.marketingForm.controls['mktCustomText5'].patchValue(this.sites[0].mktCustomText5)
        this.marketingForm.controls['mktMultiText1'].patchValue(this.sites[0].mktMultiText1)
        this.marketingForm.controls['mktMultiText2'].patchValue(this.sites[0].mktMultiText2)
        this.marketingForm.controls['mktMultiText3'].patchValue(this.sites[0].mktMultiText3)
        this.marketingForm.controls['mktMultiText4'].patchValue(this.sites[0].mktMultiText4)
        this.marketingForm.controls['mktMultiText5'].patchValue(this.sites[0].mktMultiText5)
    }

    ngOnDestroy() {
        if (this.curSiteSub) {
            this.curSiteSub.unsubscribe()
        }
    }
}
