import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DropDownFilterSettings, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import { BCMCLPUser, TeamCodes } from '../../../models/clpuser.model';
import { IntDropDownItem, IntDropDownItemListResponse, SimpleResponse } from '../../../models/generic-response.model';
import { Product, ProductResponse, RateSheetDescription, SimpleProduct, SimpleProductResponse } from '../../../models/product.model';
import { CMClub, CMClubResponse, CMSite, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { ConfigDetails } from '../../../models/app-config.model';

import { DocumentMailMergeService } from '../../../services/document-mail-merge.service';
import { ProductService } from '../../../services/product.service';
import { CMClubService } from '../../../services/cm-club.service';
import { NotificationService } from '../../../services/notification.service';
import { GlobalService } from '../../../services/shared/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { AppConfigService } from '../../../services/shared/app-config.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
declare var $: any;

@Component({
    selector: 'app-lm-products',
    templateUrl: './lm-products.component.html',
    styleUrl: './lm-products.component.css'
})
export class LmProductsComponent {
    isAI: boolean;
    encryptedUser: string = ''
    user: BCMCLPUser;
    mailMergeTemplateList: IntDropDownItem[];
    cMProductUnused: SimpleProduct[];
    curSite: CMSite = <CMSite>{}
    curTeam: TeamCodes = <TeamCodes>{};
    curSiteSub: Subscription;
    clubConfig: CMClub;
    productList: Product[];
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    selectedAgreements: number[] = [];
    selectedRules: number[] = [];

    product: Product;
    maxMembers: number;
    editProductForm: FormGroup
    displayedColumns: string[] = ['#', 'type', 'fees', 'templates', 'rateSheet', 'terms', 'publish', ' '];

    rateSheetDescription: RateSheetDescription[] = [];
    bulkEditProductForm: FormGroup;
    siteForm: FormGroup;
    class4Code: number = 0;
    isShowClone: boolean = false
    cloneOpts = []
    sites: CMSite[];
    public editor = ClassicEditor;
    public editorConfig = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link']
    };
    isShowAddNewProd: boolean;
    mySoLink: string = '';
    ready: boolean = false;

    constructor(private localService: LocalService,
        private _router: Router,
        private _utilityService: UtilityService,
        private fb: FormBuilder,
        private _globalService: GlobalService,
        private documentService: DocumentMailMergeService,
        private productService: ProductService,
        private _notifyService: NotificationService,
        private _clubService: CMClubService,
        private _appconfigService: AppConfigService
    ) {

        this.localService.setShowMenu(true)
        this.localService.setIsCmManager(false);
        this.localService.setRouteName("sm-products")

    }

    prepareEditProductForm() {
        return this.fb.group({
            //feeSignUp: ["0"],
            //feeMembership: ["0"],
            productDesc: [''],
            isDefault: [false],
            isActive: [false],
            isShowPricing: [false],
            isSeasonal: [false],
            priceSheetDescLive: [''],
            contractTerm: ['0'],
            intMaxMembers: [1],
            productDoc: this.fb.array([]),
            fees: this.fb.array([]),
            isMonday: [false],
            isTuesday: [false],
            isWednesday: [false],
            isThursday: [false],
            isFriday: [false],
            isSaturday: [false],
            isSunday: [false],
        })
    }

    prepareBulkEditProductForm() {
        return this.fb.group({
            bulkEditProduct: this.fb.array([
                this.fb.group({
                    cmProductID: [0],
                    productName: [''],
                    sOrder: 0,
                    priceSheetDescLive: [''],
                    priceSheetDescDraft: [''],
                })
            ]),
        })
    }

    get bulkEditProduct(): FormArray {
        return this.bulkEditProductForm.get("bulkEditProduct") as FormArray
    }

    get productDoc(): FormArray {
        return this.editProductForm.get("productDoc") as FormArray
    }

    get fees(): FormArray {
        return this.editProductForm.get("fees") as FormArray
    }

    newProductDoc(): FormGroup {
        return this.fb.group({
            cmProductID: 0,
            cmProductDocID: 0,
            cmProductName: "",
            mailMergeTemplateID: 0,
            mailMergeTemplate: "",
            sOrder: 0
        })
    }

    newFee(): FormGroup {
        return this.fb.group({
            cmProductID: 0,
            cmProductFeeID: 0,
            cmFeeName: "",
            feeAmount: 0,
            isCollectNow: false,
            isTaxable: true,
            isRecurring: false,
            recursEvery: 2,//(1 = day, 2 = month, 3 = year)
            recursEveryInterval: 1,//ex: 1 day, 3 weeks, 6 months, 1 year
            isPaymentSplitable: false,
            memberSplitable: 2,//1 = paid by one member, 2 = split by each member, 3 = paid by every member
            sOrder: 0
        })
    }

    addProductDoc() {
        this.productDoc.push(this.newProductDoc());
    }

    removeDoc(index: number): void {
        if (this.productDoc?.length > 1) {
            this.productDoc.removeAt(index);

        }
    }

    addFee() {
        this.fees.push(this.newFee());
    }

    removeFee(index: number): void {
        if (this.fees?.length > 1) {
            this.fees.removeAt(index);

        }
    }

    feeChange(i) {
        let value = this.changeToNumber(this.editProductForm.controls['fees'].value[i].feeAmount)
        if (value > 99999) {
            this.fees.at(i).get('feeAmount')?.setValue("$0.00")
            return this._notifyService.showError("Fees maximum value reached.", "", 3000)
        }

    }
    ngOnInit() {
        this.editProductForm = this.prepareEditProductForm();
        this.bulkEditProductForm = this.prepareBulkEditProductForm();
        this.addProductDoc();
        this.addFee();
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
        this.encryptedUser = localStorage.getItem("token");
        if (!this.encryptedUser?.includes(":")) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0");
        }
        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData();
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }

    }



    private async authenticateR() {
        await this.localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadData() {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || '{}')
        await this.CMClub_Get(this.curTeam.teamCode)
        this.mailMergeTemplateGetList()
        this.curSiteSub = this._clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = value
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
            }
            this.siteSearch()
            this.cMProductGetUnused(this.clubConfig?.clubId, this.curSite?.siteID)
            this.cMProductGet(this.clubConfig?.clubId, this.curSite?.siteID)
        });
        this._appconfigService.bCMGetConfigKeyValue(this.encryptedUser, "MySo_Link")
            .subscribe(async (result: ConfigDetails) => {
                if (result) {
                    this.mySoLink = result?.configValue
                }
            });
    }

    async CMClub_Get(teamCode) {
        if (!teamCode || teamCode == 0) {
            this._notifyService?.showError("No team is selected.", "Error", 3000)
            return
        }
        await this._clubService.CMClub_Get(this.encryptedUser, this.user?.cLPCompanyID, teamCode, this.isAI).
            then(async (result: CMClubResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.clubConfig = response?.clubConfig
                }

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("site-fields.clubConfigGet", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mailMergeTemplateGetList() {
        await this.documentService.bCMMailMergeTemplateGetListByTeam(this.encryptedUser, this.user.cLPCompanyID, this.curTeam?.teamCode, this.isAI)
            .then(async (result: IntDropDownItemListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.mailMergeTemplateList = response?.intDropDownItemList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cMProductGetUnused(clubId, siteId) {
        if (clubId > 0 && siteId > 0) {
            await this.productService.cMProductGetUnused(this.encryptedUser, this.user.cLPCompanyID, clubId, siteId, this.isAI)
                .then(async (result: SimpleProductResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.cMProductUnused = response?.products;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            console.log("error : cMProductGetUnused")
        }
    }


    async cMProductGet(clubId, siteId) {
        if (clubId > 0 && siteId > 0) {
            await this.productService.CMProduct_Get(this.encryptedUser, this.user.cLPCompanyID, clubId, siteId, this.isAI)
                .then(async (result: ProductResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.productList = response?.products;
                        this.productList.forEach((item, index) => item.sOrder = index + 1);
                        this.ready = true;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            console.log("error : cMProductGet")
        }
    }

    showConfirmModal(product: Product) {
        this.product = product;
    }

    async cMProductDelete() {
        if (this.product?.cmProductID > 0) {
            await this.productService.cMProductDelete(this.encryptedUser, this.product?.cmProductID, this.isAI)
                .then(async (result: ProductResponse) => {
                    if (result && result?.messageBool) {
                        let response = UtilityService.clone(result);
                        this._notifyService.showSuccess("Product deleted successfully!", "", 3000)
                        $('#deleteProductConfirmation').modal('hide');
                        this.cMProductGet(this.clubConfig?.clubId, this.curSite?.siteID);
                        this.cMProductGetUnused(this.clubConfig?.clubId, this.curSite?.siteID);
                    }
                    else {
                        this._notifyService.showError("Try again!", "", 3000)
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            console.log("error : cMProductGet")
        }
    }

    async cMProductaveRateSheetDescription() {
        this.rateSheetDescription = this.bulkEditProductForm.controls['bulkEditProduct'].value
        if (this.rateSheetDescription?.length > 0) {
            await this.productService.cMProductaveRateSheetDescription(this.encryptedUser, this.rateSheetDescription, this.isAI)
                .then(async (result: ProductResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        $('#bulkEditModal').modal('hide');
                        this.cMProductGet(this.clubConfig?.clubId, this.curSite?.siteID);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            console.log("error : cMProductaveRateSheetDescription")
        }
    }

    getPriceSheetDescription() {
        return this.editProductForm.controls['priceSheetDescLive'].value;
    }

    async cMProductUpdate() {
        let sameDocName = false;
        sameDocName = this.hasDuplicateValue(this.editProductForm.controls['productDoc'].value, 'cmProductName');

        if (sameDocName) {
            this._notifyService.showError(`Please use a unique name for your document.`, "Duplicate", 5000);
            return;
        }

        let sameFeeName = false;
        sameFeeName = this.hasDuplicateValue(this.editProductForm.controls['fees'].value, 'cmFeeName');

        if (sameFeeName) {
            this._notifyService.showError(`Please use a unique name for your fee.`, "Duplicate", 5000);
            return;
        }


        

        let product: Product = <Product>{}
        product.class4Code = +this.class4Code;
        product.cmProductID = +this.product?.cmProductID > 0 ? this.product?.cmProductID : 0;
        product.clpcompanyID = this.user?.cLPCompanyID;
        product.clubID = this.clubConfig?.clubId;
        product.contractTerm = this.editProductForm.controls['contractTerm'].value != '' ? this.editProductForm.controls['contractTerm'].value : '0';
        product.documents = this.editProductForm.controls['productDoc'].value;

        let sOrder = 1;
        product.documents.forEach(p => {
            p.sOrder = sOrder;
            sOrder++;
        });

        product.fees = this.editProductForm.controls['fees'].value;

        sOrder = 1;
        product.fees.forEach(f => {
            f.feeAmount = this.changeToNumber(f.feeAmount.toString());
            f.sOrder = sOrder;
            sOrder++;
        });

        //product.feeMembership = this.changeToNumber(this.editProductForm.controls['feeMembership'].value);
        product.productDesc = this.editProductForm.controls['productDesc'].value;
        //product.feeSignUp = this.changeToNumber(this.editProductForm.controls['feeSignUp'].value);
        product.isActive = this.editProductForm.controls['isActive'].value;
        product.isDefault = this.editProductForm.controls['isDefault'].value;
        product.isIncludeInPriceSheet = false;
        product.isSeasonal = this.editProductForm.controls['isSeasonal'].value;
        product.isShowPricing = this.editProductForm.controls['isShowPricing'].value;

        product.isMonday = this.editProductForm.controls['isMonday'].value;
        product.isTuesday = this.editProductForm.controls['isTuesday'].value;
        product.isWednesday = this.editProductForm.controls['isWednesday'].value;
        product.isThursday = this.editProductForm.controls['isThursday'].value;
        product.isFriday = this.editProductForm.controls['isFriday'].value;
        product.isSaturday = this.editProductForm.controls['isSaturday'].value;
        product.isSunday = this.editProductForm.controls['isSunday'].value;

        product.priceSheetDescDraft = "";
        product.priceSheetDescLive = this.editProductForm.controls['priceSheetDescLive'].value ? this.editProductForm.controls['priceSheetDescLive'].value : "";
        product.rateIncreasePct = 0;
        product.intMaxMembers = +this.editProductForm.controls['intMaxMembers'].value;
        product.rateIncreaseTerm = "";
        product.siteID = this.curSite?.siteID;
        if (product?.cmProductID == 0) {
            product.sOrder = this.productList?.length > 0 ? this.productList?.length + 1 : 1;
        }

        //if (product.productDesc == '' || product.productDesc == null || product.productDesc == undefined) {
        //    this._notifyService.showError(`Please add product description.`, "Missing", 3000);
        //    return;
        //}

        if (product?.class4Code > 0) {
            await this.productService.cMProductUpdate(this.encryptedUser, product, this.isAI)
                .then(async (result: SimpleResponse) => {
                    if (result && result?.messageInt > 0) {
                        let response = UtilityService.clone(result);
                        //this.resetEditForm()
                        this.isShowAddNewProd = false
                        this._notifyService.showSuccess("Product updated successfully.", "", 3000)
                        this.cMProductGet(this.clubConfig?.clubId, this.curSite?.siteID);
                        this.cMProductGetUnused(this.clubConfig?.clubId, this.curSite?.siteID);
                    }
                    else {
                        this._notifyService.showError("An error occured.", "", 3000);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this._notifyService?.showError("An error occured", "Error", 3000);
        }
    }

    daysOfWeekFormat(value) {
        let days: string[] = value.split(',');

        if (days.length == 7) {
            return 'Everyday';
        }
        else {
            let final = '';

            days.forEach(d => {
                final = final + this.capitalize(d).substring(0, 3) + ', ';
            });

            return final.substring(0, final.length - 2);
        }
    }

    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    hasDuplicateValue(arr, key): boolean {
        const values = arr.map(item => item[key]);
        const uniqueValues = new Set(values);
        return values.length !== uniqueValues.size;
    }

    changeToNumber(value: string): number {
        if (value != null && value != undefined) {
            if (value?.toString().includes('$'))
                return Number(value?.replace(/\$/g, ''));
            else
                return Number(value);
        }
        else {
            return 0
        }
    }

    async editProduct(p: Product) {
        this.product = p;
        this.class4Code = this.product?.class4Code

        await this.editProductForm.patchValue({
            productDesc: this.product.productDesc,
            isDefault: this.product.isDefault,
            isActive: this.product.isActive,
            isSeasonal: this.product.isSeasonal,
            priceSheetDescLive: this.product.priceSheetDescLive,
            contractTerm: this.product.contractTerm,
            isShowPricing: this.product.isShowPricing,
            intMaxMembers: this.product.intMaxMembers,
            isMonday: this.product.isMonday,
            isTuesday: this.product.isTuesday,
            isWednesday: this.product.isWednesday,
            isThursday: this.product.isThursday,
            isFriday: this.product.isFriday,
            isSaturday: this.product.isSaturday,
            isSunday: this.product.isSunday
        });

        this.productDoc.controls = [];
        this.productDoc.removeAt(0);
        if (this.product?.documents?.length > 0) {
            this.product?.documents.forEach((d, index) => {
                this.productDoc.push(
                    this.fb.group({
                        cmProductDocID: d.cmProductDocID,
                        cmProductID: d.cmProductID,
                        cmProductName: d.cmProductName,
                        mailMergeTemplateID: d.mailMergeTemplateID,
                        sOrder: d.sOrder
                    })
                );
            });
        }
        else {
            this.addProductDoc();
        }
        this.fees.controls = [];
        this.fees.removeAt(0);
        if (this.product?.fees?.length > 0) {
            this.product?.fees.forEach((f, index) => {
                this.fees.push(
                    this.fb.group({
                        cmProductFeeID: f.cmProductFeeID,
                        cmProductID: f.cmProductID,
                        cmFeeName: f.cmFeeName,
                        feeAmount: this.changeToCurrency(f.feeAmount),
                        isCollectNow: f.isCollectNow,
                        isTaxable: f.isTaxable,
                        isRecurring: f.isRecurring,
                        recursEvery: f.recursEvery,
                        recursEveryInterval: f.recursEveryInterval,
                        isPaymentSplitable: f.isPaymentSplitable,
                        memberSplitable: f.memberSplitable,
                        sOrder: f.sOrder
                    })
                );
                $('#myModal').modal('show');
            });
        }
        else {
            this.addFee();
            $('#myModal').modal('show');
        }
    }

    changeToCurrency(value: number): string {
        let final = '$0';

        if (value > 0) {
            final = `$${(Math.round(value * 100) / 100).toFixed(2)}`;
        }

        return final;
    }

    onChangeContractType(event) {
        if (event?.target?.value == -1) {
            this.isShowAddNewProd = false;
            return;
        }
        this.class4Code = event?.target?.value;
        this.editProductForm.reset();
        this.editProductForm = this.prepareEditProductForm();
        this.addProductDoc();
        this.addFee();
        this.product = <Product>{}
        this.cMProductUpdate();
    }

    onValueChange(multiSelect: MultiSelectComponent) {
        multiSelect.clearFilter();
    }

    changeValueKendoEditor($event) {

    }

    showBulkModal() {
        this.bulkEditProduct.controls = [];
        this.bulkEditProduct.removeAt(0);
        if (this.productList?.length > 0) {
            this.productList.forEach((element, index) => {
                this.bulkEditProduct.push(
                    this.fb.group({
                        cmProductID: element.cmProductID,
                        productName: element.productName,
                        sOrder: element.sOrder,
                        priceSheetDescLive: element.priceSheetDescLive,
                        priceSheetDescDraft: element.priceSheetDescDraft
                    })
                );
            });
            $('#bulkEditModal').modal('show');
        }
        else {
            this._notifyService.showError("Add at-least one product.", "", 3000)
        }
    }

    getControlName(i) {
        return this.bulkEditProductForm.controls['bulkEditProduct'].value[i].productName;
    }

    getMailMergeTemplateName(mailMergeTemplateId) {
        let tempName = ""
        if (mailMergeTemplateId > 0) {
            if (this.mailMergeTemplateList?.length > 0) {
                this.mailMergeTemplateList.filter((temp) => {
                    if (temp?.id == mailMergeTemplateId) {
                        tempName = temp?.text
                    }
                })
            }
        }
        return tempName
    }

    showCloneDD() {
        this.isShowClone = true
    }

    onChangeCloneOpt(event) {
        let value = event?.target?.value
        if (value == 0) {
            this.isShowClone = false
        }
        else {
            this.cloneProducts(value)
        }
    }

    async cMProductPublishToDraft() {
        if (this.curSite?.siteID > 0) {
            await this.productService.cMProductPublishToDraft(this.encryptedUser, this.curSite?.siteID, this.isAI)
                .then(async (result: ProductResponse) => {
                    if (result && result?.messageBool) {
                        let response = UtilityService.clone(result);
                        $('#bulkEditModal').modal('hide');
                        this._notifyService.showSuccess("Rate Sheet Description copied from Published to Draft!", "", 5000)
                    }
                    else {
                        this._notifyService.showError("Try again!", "", 3000)
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this._notifyService.showSuccess("No site selected", "", 3000)
        }
    }

    async cMProductDraftToPublish() {
        if (this.curSite?.siteID > 0) {
            await this.productService.cMProductDraftToPublish(this.encryptedUser, this.curSite?.siteID, this.isAI)
                .then(async (result: ProductResponse) => {
                    if (result && result?.messageBool) {
                        let response = UtilityService.clone(result);
                        $('#bulkEditModal').modal('hide');
                        this._notifyService.showSuccess("Rate Sheet Description copied from Draft to Published!", "", 5000)
                    }
                    else {
                        this._notifyService.showError("Try again!", "", 3000)
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this._notifyService.showSuccess("No site selected.", "", 3000)
        }
    }

    async cloneProducts(toSiteId) {
        if (this.curSite?.siteID > 0) {
            await this.productService.cMProductclone(this.encryptedUser, this.user?.cLPCompanyID, this.clubConfig?.clubId, this.curSite?.siteID, toSiteId, this.isAI)
                .then(async (result: ProductResponse) => {
                    if (result && result?.messageBool) {
                        let response = UtilityService.clone(result);
                        //$('#bulkEditModal').modal('hide');
                        this.isShowClone = false
                        this._notifyService.showSuccess("These location defaults have been cloned successfully.", "", 5000)
                    }
                    else {
                        this._notifyService.showError("Try again!", "", 3000)
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this._notifyService.showSuccess("No site selected.", "", 3000)
        }
    }

    async siteSearch() {
        if (this.clubConfig?.clubId > 0) {
            await this._clubService.siteSearch(this.encryptedUser, 0, this.clubConfig?.clubId, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites
                        this.cloneOpts = []
                        this.cloneOpts = [{ display: "-All Sites-", value: -1 }, { display: "-Cancel Clone-", value: 0 }]
                        this.sites.forEach((item) => {
                            if (item?.siteID != this.curSite?.siteID) {
                                let obj = {
                                    display: item?.siteName,
                                    value: item?.siteID
                                }
                                this.cloneOpts.push(obj)
                            }
                        })
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    showAddNewProduct() {
        this.isShowAddNewProd = true
    }

    drop(event: CdkDragDrop<FormGroup[]>) {
        const previousIndex = event.previousIndex;
        const currentIndex = event.currentIndex;
        const cardsArray = this.bulkEditProduct.controls;
        const [movedCard] = cardsArray.splice(previousIndex, 1);
        cardsArray.splice(currentIndex, 0, movedCard);
    }

    getFeeValue(value) {
        console.log(value);
    }

    copyToClip(value) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        selBox.textContent = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this._notifyService?.showSuccess(value + " has been copied to your clipboard.", "Success", 3000);
    }

    clean(value) {
        value = value.replaceAll('/', '');
        value = value.replaceAll(' ', '');
        value = value.replaceAll('&', '');
        value = value.replaceAll('(', '');
        value = value.replaceAll(')', '');
        value = value.replaceAll('[', '');
        value = value.replaceAll(']', '');
        value = value.replaceAll('{', '');
        value = value.replaceAll('}', '');

        return value;
    }
}
