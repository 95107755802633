import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { ConfigDetails } from '../../../models/app-config.model';
import { BCMCLPUser, CLPUser } from '../../../models/clpuser.model';
import { AutomationProcessDD, CMSite, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { eCampaignTemplateOwnerType } from '../../../models/enum.model';
import { keyValue, SimpleResponse } from '../../../models/generic-response.model';
import { CMClubService } from '../../../services/cm-club.service';
import { NotificationService } from '../../../services/notification.service';
import { AppConfigService } from '../../../services/shared/app-config.service';
import { GlobalService } from '../../../services/shared/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-lm-triggers',
    templateUrl: './lm-triggers.component.html',
    styleUrl: './lm-triggers.component.css'
})
export class LmTriggersComponent implements OnDestroy {
    encryptedUser: string = '';
    user: BCMCLPUser;
    webFormList: keyValue[];
    automationProcessList: AutomationProcessDD[];
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };

    site = {} as CMSite;
    newLeadId: number = 0;
    newMemberId: number = 0;
    contractSent: number = 0;
    contractOpen: number = 0;
    waitingCountersign: number = 0;
    contractExpired: number = 0;
    contactUpdateId: number = 0;
    referralRequest: number = 0;
    sites: CMSite[];
    curSite: CMSite = <CMSite>{};
    showSpinner: boolean;
    submitted: boolean;
    curSiteSub: Subscription;
    clpCompanyId: number;
    isAI: boolean = false;

    constructor(private localService: LocalService,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private clubService: CMClubService,
        private _appconfigService: AppConfigService,
        private _globalService: GlobalService,
        private _router: Router,
        private _notifyService: NotificationService,
    ) {

        this.localService.setIsCmManager(false);
        this.localService.setShowMenu(true)
        this.localService.setRouteName('sm-triggers');
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.encryptedUser = localStorage.getItem("token");
        this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")

        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }

    async loadData() {
        await this.webFormListDDGet()
        await this.automationProcessListGetByUser();
        this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = await value;
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
                this.getTrigger(this.curSite?.siteID);
            }
            else {
                this.getTrigger(this.curSite?.siteID);
            }
        })

    }

    private async authenticateR() {
        await this.localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async webFormListDDGet() {
        this.showSpinner = true
        await this.clubService.webFormListDDGet(this.encryptedUser, this.clpCompanyId, this.isAI).
            then(async (result: keyValue[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.webFormList = response
                    this.webFormList.unshift({
                        key: 0,
                        value: "--Select--",
                        isSelected: false
                    })
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async automationProcessListGetByUser() {
        this.showSpinner = true
        await this.clubService.automationProcessListGetByUser(this.encryptedUser, this.clpCompanyId, this.user?.cLPUserID, eCampaignTemplateOwnerType.Unknown, this.isAI).
            then(async (result: AutomationProcessDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.automationProcessList = response
                    this.automationProcessList.unshift({
                        campaignTemplateID: 0,
                        cLPUserID: 0,
                        cLPCompanyID: 0,
                        campaignTemplateName: "--Select--",
                        templateName: ""
                    })
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async triggerSaved() {
        this.site.siteID = this.curSite?.siteID ? this.curSite?.siteID : 0;
        this.site.newLead = this.newLeadId ? this.newLeadId : 0;
        this.site.newMember = this.newMemberId ? this.newMemberId : 0;
        this.site.contractSent = this.contractSent ? this.contractSent : 0;
        this.site.contractOpen = this.contractOpen ? this.contractOpen : 0;
        this.site.waitingCountersign = this.waitingCountersign ? this.waitingCountersign : 0;
        this.site.contractExpiration = this.contractExpired ? this.contractExpired : 0;
        this.site.contactUpdate = this.contactUpdateId ? this.contactUpdateId : 0;
        this.site.referralRequest = this.referralRequest ? this.referralRequest : 0;
        this.submitted = true
        await this.clubService.CMSite_Trigger_Update(this.encryptedUser, this.site, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this._notifyService?.showSuccess("Trigger saved successfully", "Success", 3000);
                }
                this.submitted = false
            })
            .catch((err: HttpErrorResponse) => {
                this.submitted = false
                this._globalService.error("contact-task.getLoadUsers", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTrigger(siteId) {
        if (siteId > 0) {
            this.showSpinner = true
            await this.clubService.siteSearch(this.encryptedUser, siteId, 0, 0, 0, this.isAI).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites;
                        if (this.sites.length > 0) {
                            this.newLeadId = this.sites[0]?.newLead > 0 ? this.sites[0]?.newLead : this.webFormList[0]?.key;
                            this.newMemberId = this.sites[0]?.newMember > 0 ? this.sites[0]?.newMember : this.webFormList[0]?.key;
                            this.contractSent = this.sites[0]?.contractSent > 0 ? this.sites[0]?.contractSent : this.webFormList[0]?.key;
                            this.contractOpen = this.sites[0]?.contractOpen > 0 ? this.sites[0]?.contractOpen : this.webFormList[0]?.key;
                            this.waitingCountersign = this.sites[0]?.waitingCountersign > 0 ? this.sites[0]?.waitingCountersign : this.automationProcessList[0]?.campaignTemplateID;
                            this.contractExpired = this.sites[0]?.contractExpiration > 0 ? this.sites[0]?.contractExpiration : this.webFormList[0]?.key;
                            this.contactUpdateId = this.sites[0]?.contactUpdate > 0 ? this.sites[0]?.contactUpdate : this.automationProcessList[0]?.campaignTemplateID;
                            this.referralRequest = this.sites[0]?.referralRequest > 0 ? this.sites[0]?.referralRequest : this.automationProcessList[0]?.campaignTemplateID;
                        }
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this._notifyService.showSuccess("Site not found. Go back to configuration.", "Error", 3000);
        }
    }

    ngOnDestroy() {
        if (this.curSiteSub) {
            this.curSiteSub.unsubscribe()
        }
    }
}
