import { Component } from '@angular/core';
import { LocalService } from '../../../services/shared/local.service';

@Component({
  selector: 'app-lm-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './lm-dashboard.component.html',
  styleUrl: './lm-dashboard.component.css'
})
export class LmDashboardComponent {
  constructor(private localService: LocalService) {
    this.localService.setShowMenu(true)
    this.localService.setIsCmManager(false);
    this.localService.setRouteName("sm-dashboard")
  }
}
