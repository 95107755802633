import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalService } from './local.service';
import { UtilityService } from './utility.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private baseUrl: string;
  private api: string = "api/DBNotifications";
  constructor(
    private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string,
    private _utilityService: UtilityService,
    private _router: Router,
    public _localService: LocalService) {
    this.baseUrl = _baseUrl + this.api;
  }

  error(message: string, error_message: string, object: any = null, parameter: string = '') {
    let _check = window.localStorage.getItem('is_enable_log');
    if (_check == "True") {
      let objectPropertiesInstring: string = "";
      if (object != null) {
        objectPropertiesInstring = this._utilityService.getObjectPropertiesInstring(object);
        objectPropertiesInstring = objectPropertiesInstring + "<br> Json string" + JSON.stringify(object);
      }
      let msg: string = error_message + "<br>" +
        (error_message != null ? error_message + "<br><hr>" : "") +
        parameter + "<br><hr>" +
        objectPropertiesInstring;
      console.error('[' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss SSS") + '] ' + message, msg);
    }
  }
}
