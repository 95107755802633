import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { BCMCLPUser, TeamCodes } from '../../../models/clpuser.model';
import { CMSite } from '../../../models/cm-club-service.model';
import { CMContractSetting, CMContractSettingResponse } from '../../../models/cm-contract';
import { IntDropDownItem, IntDropDownItemListResponse } from '../../../models/generic-response.model';
import { CMClubService } from '../../../services/cm-club.service';
import { CMContractService } from '../../../services/cm-contract.service';
import { DocumentMailMergeService } from '../../../services/document-mail-merge.service';
import { NotificationService } from '../../../services/notification.service';
import { GlobalService } from '../../../services/shared/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';


declare var $: any;

@Component({
    selector: 'app-lm-contract-settings',
    templateUrl: './lm-contract-settings.component.html',
    styleUrl: './lm-contract-settings.component.css'
})
export class LmContractSettingsComponent {
    isAI: boolean;
    encryptedUser: string = ''
    user: BCMCLPUser;
    curSite: CMSite = <CMSite>{}
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    clpCompanyId: number;

    curTeam: TeamCodes = <TeamCodes>{};
    curSiteSub: Subscription;
    siteData: IntDropDownItem = <IntDropDownItem>{};
    public siteSelected: number;
    submitted: boolean;
    configForm: FormGroup;

    ContractSetting: CMContractSetting = <CMContractSetting>{};

    dtDate: Date = new Date();
    mailMergeTempList: IntDropDownItem[];
    emailTempList: IntDropDownItem[];
    showSpinner: boolean;
    CCAuthMMID: any = 0;
    ACHAuthMMID: any = 0;
    CheckAuthMMID: any = 0;
    CashAuthMMID: any = 0;
    ContractEmailID: any = 0;
    dLMMID: any = 0;
    signOffID: any = 0;

    constructor(private localService: LocalService,
        private fb: FormBuilder,
        private _router: Router,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _notifyService: NotificationService,
        private _contractService: CMContractService,
        private _clubService: CMClubService,
        private documentMailMergeService: DocumentMailMergeService,
    ) {
        this.localService.setShowMenu(true)
        this.localService.setIsCmManager(false);
        this.localService.setRouteName("sm-contract-settings")
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.encryptedUser = localStorage.getItem("token");
        this.configForm = this.prepareConfigForm()
        //this.siteForm = this.prepareSiteForm();
        if (!this.encryptedUser?.includes(":")) {
            this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
        }
        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData();
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }

    async loadData() {
        this.curTeam = JSON.parse(localStorage.getItem("curTeam") || '{}')
        this.curSiteSub = this._clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = value
            if (this.curSite == null) {
                this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
            }
            this.mailMergeTemplateGetList(() => {
                this.emailTemplateGetList(() => {
                    this.getContractSetting();
                });
            });
        });
    }

    patchConfigForm() {
        this.configForm.patchValue({
            isEntryACH: this.ContractSetting?.isEntryACH,
            isEntryCC: this.ContractSetting?.isEntryCC,
            isEntryCheck: this.ContractSetting?.isEntryCheck,
            isEntryCash: this.ContractSetting?.isEntryCash,
            isMonthlyACH: this.ContractSetting?.isMonthlyACH,
            isMonthlyCC: this.ContractSetting?.isMonthlyCC,
            isMonthlyCheck: this.ContractSetting?.isMonthlyCheck,
            CCAuthMMID: this.ContractSetting?.CCAuthMMID,
            ACHAuthMMID: this.ContractSetting?.ACHAuthMMID,
            CheckAuthMMID: this.ContractSetting?.CheckAuthMMID,
            CashAuthMMID: this.ContractSetting?.CashAuthMMID,
            ContractEmailID: this.ContractSetting?.ContractEmailID,
            dLMMID: this.ContractSetting?.dLMMID,
            signOffID: this.ContractSetting?.signOffID
        });
    }

    async getContractSetting() {
        await this._contractService.cmSiteContractSetting_Get(this.encryptedUser, this.curSite.siteID, this.isAI).
            then(async (result: CMContractSettingResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.ContractSetting = response.setting;
                    this.patchConfigForm();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mailMergeTemplateGetList(callback) {
        this.showSpinner = true
        await this.documentMailMergeService.mailMergeTemplateGetList(this.encryptedUser, this.clpCompanyId, this.user?.cLPUserID)
            .then(async (result: IntDropDownItemListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.mailMergeTempList = response?.intDropDownItemList
                    if (this.mailMergeTempList?.length > 0) {
                        this.mailMergeTempList.unshift({ id: 0, text: ' -- Select --' });

                    }
                    this.CCAuthMMID = this.mailMergeTempList[0].id.toString();

                    if (callback) {
                        callback();
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("lm-contract-settings.mailMergeTemplateGetList", err.message, null, '');
                this._utilityService.handleErrorResponse(err);
            });
    }

    async emailTemplateGetList(callback) {
        this.showSpinner = true
        await this.documentMailMergeService.emailTemplateGetList(this.encryptedUser, this.clpCompanyId, this.user?.cLPUserID)
            .then(async (result: IntDropDownItemListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.emailTempList = response?.intDropDownItemList
                    this.emailTempList.unshift({ id: 0, text: ' -- Select --' });
                    if (this.emailTempList?.length > 0) {
                        this.ContractEmailID = this.emailTempList[0]?.id.toString();
                    }
                    if (callback) {
                        callback();
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("lm-contract-settings.emailTemplateGetList", err.message, null, '');
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveContractSetting() {
        this.ContractSetting.isEntryACH = this.configForm.controls['isEntryACH'].value;
        this.ContractSetting.isEntryCC = this.configForm.controls['isEntryCC'].value;
        this.ContractSetting.isEntryCheck = this.configForm.controls['isEntryCheck'].value;
        this.ContractSetting.isEntryCash = this.configForm.controls['isEntryCash'].value;
        this.ContractSetting.isMonthlyACH = this.configForm.controls['isMonthlyACH'].value;
        this.ContractSetting.isMonthlyCC = this.configForm.controls['isMonthlyCC'].value;
        this.ContractSetting.isMonthlyCheck = this.configForm.controls['isMonthlyCheck'].value;
        this.ContractSetting.isAffirmEntry = this.configForm.controls['isAffirmEntry'].value;
        this.ContractSetting.CCAuthMMID = this.configForm.controls['CCAuthMMID'].value;
        this.ContractSetting.ACHAuthMMID = this.configForm.controls['ACHAuthMMID'].value;
        this.ContractSetting.CheckAuthMMID = this.configForm.controls['CheckAuthMMID'].value;
        this.ContractSetting.CashAuthMMID = this.configForm.controls['CashAuthMMID'].value;
        this.ContractSetting.ContractEmailID = this.configForm.controls['ContractEmailID'].value ;
        this.ContractSetting.dLMMID = this.configForm.controls['dLMMID'].value ;
        this.ContractSetting.signOffID = this.configForm.controls['signOffID'].value ;

        await this._contractService.cmSiteContractSetting_Update(this.encryptedUser, this.ContractSetting, this.isAI).
            then(async (result: IntDropDownItemListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);

                    this._notifyService?.showSuccess(`Contract Settings are saved for ${this.curSite.siteName}.`, "Settings", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lm-contract-settings.cmSiteContractSetting_Update", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareConfigForm() {
        return this.fb.group({
            isEntryACH: [false],
            isEntryCC: [false],
            isEntryCheck: [false],
            isEntryCash: [false],
            isMonthlyACH: [false],
            isMonthlyCC: [false],
            isMonthlyCheck: [false],
            isAffirmEntry: [false],
            CCAuthMMID: [0],
            ACHAuthMMID: [0],
            CheckAuthMMID: [0],
            CashAuthMMID: [0],
            ContractEmailID: [0],
            dLMMID: [0],
            signOffID: [0],
        })
    }

    private async authenticateR() {
        await this.localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lm-contract-settings.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
