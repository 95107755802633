<div class="main-wrapper-section">
  <div class="common-header">
    <h3>Sale Dashboard</h3>
  </div>
  <div class="common-header">
    <select class="form-control" style="width:auto; background:none; border:none; padding-left:0px; color:#212529; font-weight:500;">
      <option>Previous Week</option>
      <option>Current Week</option>
    </select>
  </div>

  <div class="alignment-content">
    <div class="flex-row">
      <div class="flex-colunm">
        <div class="common-card">
          <div class="card-panel">
            <h6>Successfully Delivered</h6>
            <p>488,874 sent</p>
          </div>
          <div class="graph-panel">
            <kendo-circularprogressbar progressColor="#3779f8" [value]="value" class="custom-progress-bar" style="width:300px; height:300px">
              <ng-template kendoCircularProgressbarCenterTemplate let-color="color" let-value="value">
                <span [style.color]="color">{{ value }}%</span><br />
                <span [style.color]="color">454,656</span>
              </ng-template>
            </kendo-circularprogressbar>
          </div>
        </div>
      </div>
      <div class="flex-colunm1">
        <div class="inner-flex-row">
          <div class="common-card">
            <div class="card-panel">
              <h6>Memberships</h6>
              <div class="hightlight-text">
                <h1>27,632</h1>
                <span>+2.5% <img src="../../../assets/img/up-arrow.svg" /></span>
              </div>
              <p>488,874 sent</p>
            </div>
          </div>
          <div class="common-card">
            <div class="card-panel">
              <h6>By Sales Rep</h6>
              <div class="hightlight-text">
                <h1>$13,748</h1>
                <span>+1.7% <img src="../../../assets/img/up-arrow.svg" /></span>
              </div>
              <p>Compared to (12,365 last month)</p>
            </div>
          </div>
          <div class="common-card">
            <div class="card-panel">
              <h6>Agreements</h6>
              <div class="hightlight-text">
                <h1>3,471</h1>
                <span>+12.5% <img src="../../../assets/img/up-arrow.svg" /></span>
              </div>
              <p>Compared to (1,009 last month)</p>
            </div>
          </div>
          <div class="common-card">
            <div class="card-panel">
              <h6>Entry Fee</h6>
              <div class="hightlight-text">
                <h1>$13,748</h1>
                <span>+1.7% <img src="../../../assets/img/up-arrow.svg" /></span>
              </div>
              <p>Compared to (12,365 last month)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row pair-card">
      <div class="flex-colunm">
        <div class="common-card">
          <div class="card-panel">
            <h6>Monthly Fees</h6>
            <h1>$2,657</h1>
            <kendo-chart>
              <kendo-chart-series>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [visible]="false"></kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [visible]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series-item type="column" min="0"
                                         [data]="cashFlowData" color="#3779f8"
                                         field="amount" categoryField="period" summaryField="summary">
                </kendo-chart-series-item>

              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>
      </div>
      <div class="flex-colunm1">
        <div class="inner-flex-row">
          <div class="common-card full-width">
            <div class="card-panel">
              <h6>Total Sales</h6>
              <h1>$23,657</h1>
              <kendo-chart>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="categories"></kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [visible]="false"></kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>
                  <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [visible]="false"></kendo-chart-value-axis-item>
                  </kendo-chart-value-axis>
                  <!-- Line series -->
                  <kendo-chart-series-item type="line"
                                           [style]="style"
                                           [data]="originalData"
                                           [markers]="{ visible: false }"
                                           [color]="'#007bff'"></kendo-chart-series-item>
                  <!-- Area series -->
                  <kendo-chart-series-item [line]="{ style: styleArea }"
                                           type="area"
                                           [data]="originalData"
                                           [color]="'#007bff'"
                                           [markers]="{ visible: false }">
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-section">
      <div class="card-panel">
        <div class="table-panel">
          <div class="card-header">
            <h5>Highlights</h5>
          </div>
          <div class="table-body">
            <table mat-table [dataSource]="dataSource" class="">
              <!-- Define columns -->
              <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
                <th mat-header-cell *matHeaderCellDef>{{ col | titlecase }}</th>
                <td mat-cell *matCellDef="let element">
                  <!--{{ element[col] }}-->
                  <ng-container *ngIf="col != 'difference'; else difference">
                    {{element[col]}}
                  </ng-container>
                  <ng-template #difference>
                    {{element[col]}}<img src="../../../assets/img/up-arrow.svg" />
                  </ng-template>
                </td>
              </ng-container>

              <!-- Assign columns to the table -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
