import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigDetails } from '../../../models/app-config.model';
import { BCMCLPUser, CLPUser, UserResponse } from '../../../models/clpuser.model';
import { CMSite } from '../../../models/cm-club-service.model';
import { eFeatures } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/role-container.model';
import { CMClubService } from '../../../services/cm-club.service';
import { AppConfigService } from '../../../services/shared/app-config.service';
import { GlobalService } from '../../../services/shared/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-location-left-menu',
    templateUrl: './location-left-menu.component.html',
    styleUrl: './location-left-menu.component.css'
})
export class LocationLeftMenuComponent {
    encryptedUser: string = '';
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: BCMCLPUser;
    routeName: string;
    siteName: string;
    curSite: CMSite;
    isAI: boolean = false;

    constructor(public _localService: LocalService, private _route: ActivatedRoute,
        private _globalService: GlobalService,
        private _router: Router,
        private _clubService: CMClubService,
        private _appconfigService: AppConfigService,
        private _utilityService: UtilityService
    ) {

        this._localService.routeName.subscribe(value => this.routeName = value)
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
        this.encryptedUser = localStorage.getItem("token");
        this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")

        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData()
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            this._router.navigate(['/unauthorized']);
        }
    }

    loadData() {
        this._clubService.curSite.subscribe(async (value: CMSite) => {
            this.curSite = value
            if (this.curSite == null) {
                this.curSite = JSON.parse(localStorage.getItem("curSite") || '{}')
            }
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToLink(routeName) {
        switch (routeName) {
            case 'sm-dashboard':
                this._router.navigate(['/sm-dashboard']);
                this._localService.setRouteName('sm-dashboard')
                break;
            case 'sm-general':
                this._router.navigate(['/sm-general'])
                this._localService.setRouteName('sm-general')
                break;
            case 'sm-triggers':
                this._router.navigate(['/sm-triggers'])
                this._localService.setRouteName('sm-triggers')
                break;
            case 'sm-products':
                this._router.navigate(['/sm-products'])
                this._localService.setRouteName('sm-products')
                break;
            case 'sm-links':
                this._router.navigate(['/sm-links'])
                this._localService.setRouteName('sm-links')
                break;
            case 'sm-marketing':
                this._router.navigate(['/sm-marketing'])
                this._localService.setRouteName('sm-marketing')
                break;
            case 'sm-discounts':
                this._router.navigate(['/sm-discounts'])
                this._localService.setRouteName('sm-discounts')
                break;
            case 'sm-employees':
                this._router.navigate(['/sm-employees'])
                this._localService.setRouteName('sm-employees')
                break;
            case 'sm-contracts':
                this._router.navigate(['/sm-contracts'])
                this._localService.setRouteName('sm-contracts')
                break;
            case 'sm-contract-settings':
                this._router.navigate(['/sm-contract-settings'])
                this._localService.setRouteName('sm-contract-settings')
                break;
            case 'sm-engage-dash':
                this._router.navigate(['/sm-engage-dash'])
                this._localService.setRouteName('sm-engage-dash')
                break;
            case 'sm-vessels':
                this._router.navigate(['/sm-vessels'])
                this._localService.setRouteName('sm-vessels')
                break;

        }
    }
}
