<div class="main-wrapper-section">
  <div class="common-header">
    <h3>Triggers</h3>
  </div>
  <div class="card-section">
    <div class="card-panel">
        <div class="field-form-panel">
            <div class="flex-form">
                <div class="form-group">
                    <label>Contract Sent</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="automationProcessList"
                                    [valuePrimitive]="true"
                                    textField="campaignTemplateName"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="contractSent"
                                    valueField="campaignTemplateID">
                    </kendo-combobox>
                </div>
                <div class="form-group">
                    <label>Contract Opened</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="automationProcessList"
                                    [valuePrimitive]="true"
                                    textField="campaignTemplateName"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="contractOpen"
                                    valueField="campaignTemplateID">
                    </kendo-combobox>
                </div>
                <div class="form-group">
                    <label>Contract Waiting Countersign</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="automationProcessList"
                                    [valuePrimitive]="true"
                                    textField="campaignTemplateName"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="waitingCountersign"
                                    valueField="campaignTemplateID">
                    </kendo-combobox>
                </div>
            </div>
            <div class="flex-form">
                <div class="form-group">
                    <label>Contract Active (New Member)</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="webFormList"
                                    [valuePrimitive]="true"
                                    textField="value"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="newMemberId"
                                    valueField="key">
                    </kendo-combobox>
                </div>
                <div class="form-group">
                    <label>Contract Expiration</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="webFormList"
                                    [valuePrimitive]="true"
                                    textField="value"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="contractExpired"
                                    valueField="key">
                    </kendo-combobox>
                </div>
            </div>
            <hr />
            <div class="flex-form">
                <div class="form-group">
                    <label>New Lead</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="webFormList"
                                    [valuePrimitive]="true"
                                    textField="value"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="newLeadId"
                                    valueField="key">
                    </kendo-combobox>
                </div>
                <div class="form-group">
                    <label>Contact Update</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="automationProcessList"
                                    [valuePrimitive]="true"
                                    textField="campaignTemplateName"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="contactUpdateId"
                                    valueField="campaignTemplateID">
                    </kendo-combobox>
                </div>
                <div class="form-group">
                    <label>Referral Request</label>
                    <kendo-combobox class="form-control min-width300"
                                    [data]="automationProcessList"
                                    [valuePrimitive]="true"
                                    textField="campaignTemplateName"
                                    [kendoDropDownFilter]="filterSettings"
                                    [filterable]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="referralRequest"
                                    valueField="campaignTemplateID">
                    </kendo-combobox>
                </div>
            </div>
        </div>
      <div class="button-group">
        <div class="form-group">
          <button class="primary-btn" (click)="triggerSaved()">Save<i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
