import { Component } from '@angular/core';
import { LocalService } from '../../../services/shared/local.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BCMCLPUser, CLPUser, TeamCodes, TeamResponse, UserResponse } from '../../../models/clpuser.model';
import { SimpleResponse, UserDD } from '../../../models/generic-response.model';
import { RoleFeaturePermissions } from '../../../models/role-container.model';
import { CMClubService } from '../../../services/cm-club.service';
import { NotificationService } from '../../../services/notification.service';
import { GlobalService } from '../../../services/shared/global.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TeamOfficeService } from '../../../services/team-office.service';
import { UserService } from '../../../services/user.service';
import { CMClub, CMClubResponse, CMSite, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { CMClubSiteConfig, CMClubSiteConfigListResponse } from '../../../models/cm-club-service.model';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../services/shared/app-config.service';
import { ConfigDetails } from '../../../models/app-config.model';

@Component({
  selector: 'app-lm-links',
  templateUrl: './lm-links.component.html',
  styleUrl: './lm-links.component.css'
})
export class LmLinksComponent implements OnDestroy {

  encryptedUser: string = '';
  userResponse: UserResponse;
  user: BCMCLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  teamFilterDD: TeamCodes[];
  userList: UserDD[];
  linksForm: FormGroup
  teamCodeDetails: TeamResponse;
  clubConfig: CMClub;
  clubSiteConfig: CMClubSiteConfig;
  site = {} as CMSite;
  sites: CMSite[];
  curTeam: TeamCodes = <TeamCodes>{};
  curSite: CMSite = <CMSite>{};
  showSpinner: boolean;
  submitted: boolean;
  curSiteSub: Subscription;
  clpCompanyId: number;
  isAI: boolean = false;
  constructor(private _localService: LocalService,
    private _teamOfficeService: TeamOfficeService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _utilityService: UtilityService,
    private fb: FormBuilder,
    private clubService: CMClubService,
    private _notifyService: NotificationService,
    private _globalService: GlobalService,
    private _appconfigService: AppConfigService,
    private _router: Router,
  ) {
    
    this._localService.setShowMenu(true)
    this._localService.setIsCmManager(false);
    this._localService.setRouteName('sm-links');
  }

  ngOnInit() {
    this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false
    this.linksForm = this.prepareLinksForm();
    this.encryptedUser = localStorage.getItem("token");
    this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")

    if (this.encryptedUser) {
      this.authenticateR().then(async () => {
        if (this.user) {
          this.loadData()
        }
        else {
          this._router.navigate(['/unauthorized']);
        }
      });
    }
    else {
      this._router.navigate(['/unauthorized']);
    }
  }

  async loadData() {
    this.curTeam = JSON.parse(localStorage.getItem("curTeam") || '{}')
    await this.CMClub_Get();
    this.clubSiteConfigSearch();
    this.curSiteSub = this.clubService.curSite.subscribe(async (value: CMSite) => {
      this.curSite = await value
      this.getLinks(this.curSite?.siteID);
      if (this.curSite == null) {
        this.curSite = await JSON.parse(localStorage.getItem("curSite") || '{}')
        this.getLinks(this.curSite?.siteID);
      }
    })

  }

  prepareLinksForm() {
    return this.fb.group({
      visitURL: [''],
      houseURL: [''],
      vesselsURL: [''],
      kioskURL: [''],
      Custom1: [''],
      Custom2: [''],
      Custom3: [''],
      Custom4: [''],
      Custom5: [''],
    })
  }

  async CMClub_Get() {
    this.showSpinner = true
    await this.clubService.CMClub_Get(this.encryptedUser, this.clpCompanyId, this.user?.teamCode, this.isAI).
      then(async (result: CMClubResponse) => {
        if (result) {
          let response = UtilityService.clone(result);
          this.clubConfig = response?.clubConfig

        }
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
        this.showSpinner = false
        this._globalService.error("site-fields.clubConfigGet", err.message, null,
        );
        this._utilityService.handleErrorResponse(err);
      });
  }


  async clubSiteConfigSearch() {
    if (this.curTeam?.teamCode > 0) {
      this.showSpinner = true
      await this.clubService.clubSiteConfigSearch(this.encryptedUser, 0, this.clubConfig?.clubId, this.curTeam?.teamCode, this.clpCompanyId, this.isAI).
        then(async (result: CMClubSiteConfigListResponse) => {
          if (result) {
            let response = UtilityService.clone(result);
            this.clubSiteConfig = response?.clubSiteConfigs[response?.clubSiteConfigs?.length - 1]
          }
          this.showSpinner = false
        })
        .catch((err: HttpErrorResponse) => {
          this.showSpinner = false
          this._globalService.error("site-fields.clubConfigGet", err.message, null,
          );
          this._utilityService.handleErrorResponse(err);
        });
    }
    else {
      this._notifyService.showError("Some error occured.", "Error", 3000)
    }
  }


  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, this.isAI)
        .then(async (result: BCMCLPUser) => {
          if (result) {
            this.user = UtilityService.clone(result);
            this.clpCompanyId = this.user?.cLPCompanyID
          }
        })
        .catch((err: HttpErrorResponse) => {
          this._globalService.error("navMenu.authenticateR", err.message, null);
          this._utilityService.handleErrorResponse(err);
        });
  }

  async linksSaved() {
    this.site.siteID = this.curSite?.siteID;
    this.site.scheduleSiteVisitURL = this.linksForm.controls['visitURL'].value;
    this.site.scheduleOpenHouseURL = this.linksForm.controls['houseURL'].value;
    this.site.vesselsURL = this.linksForm.controls['vesselsURL'].value;
    this.site.kioskURL = this.linksForm.controls['kioskURL'].value;
    this.site.lnkCustom1URL = this.linksForm.controls['Custom1'].value;
    this.site.lnkCustom2URL = this.linksForm.controls['Custom2'].value;
    this.site.lnkCustom3URL = this.linksForm.controls['Custom3'].value;
    this.site.lnkCustom4URL = this.linksForm.controls['Custom4'].value;
    this.site.lnkCustom5URL = this.linksForm.controls['Custom5'].value;
    this.submitted = true
    await this.clubService.CMSite_Update_Links(this.encryptedUser, this.site, this.isAI).
      then(async (result: SimpleResponse) => {
        if (result) {
          let response = UtilityService.clone(result);
          this.getLinks(this.curSite?.siteID);
          this._notifyService?.showSuccess("Links saved successfully", "Success", 3000);
        }
        this.submitted = false
      })
      .catch((err: HttpErrorResponse) => {
        this.submitted = false
        this._globalService.error("contact-task.getLoadUsers", err.message, null,
        );
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getLinks(siteId) {
    if (siteId > 0) {
      this.showSpinner = true
      await this.clubService.siteSearch(this.encryptedUser, siteId, 0, 0, 0, this.isAI).
        then(async (result: CMSiteListResponse) => {
          if (result) {
            let response = UtilityService.clone(result);
            this.sites = response?.sites;
            this.patchLinks()
          }
          this.showSpinner = false
        })
        .catch((err: HttpErrorResponse) => {
          this.showSpinner = false
          this._globalService.error("site-fields.clubConfigGet", err.message, null,
          );
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  patchLinks() {
    this.linksForm.controls['visitURL'].patchValue(this.sites[0]?.scheduleSiteVisitURL)
    this.linksForm.controls['houseURL'].patchValue(this.sites[0]?.scheduleOpenHouseURL)
    this.linksForm.controls['vesselsURL'].patchValue(this.sites[0]?.vesselsURL)
    this.linksForm.controls['kioskURL'].patchValue(this.sites[0]?.kioskURL)
    this.linksForm.controls['Custom1'].patchValue(this.sites[0]?.lnkCustom1URL)
    this.linksForm.controls['Custom2'].patchValue(this.sites[0]?.lnkCustom2URL)
    this.linksForm.controls['Custom3'].patchValue(this.sites[0]?.lnkCustom3URL)
    this.linksForm.controls['Custom4'].patchValue(this.sites[0]?.lnkCustom4URL)
    this.linksForm.controls['Custom5'].patchValue(this.sites[0]?.lnkCustom5URL)
  }

  ngOnDestroy() {
    if (this.curSiteSub) {
      this.curSiteSub.unsubscribe()
    }
  }
}
