import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigDetails } from '../../models/app-config.model';
import { BCMCLPUser, CLPUser, TeamCodes, UserResponse } from '../../models/clpuser.model';
import { eFeatures } from '../../models/enum.model';
import { RoleFeaturePermissions } from '../../models/role-container.model';
import { CMClubService } from '../../services/cm-club.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TeamOfficeService } from '../../services/team-office.service';

@Component({
    selector: 'app-left-side-menu',
    templateUrl: './left-side-menu.component.html',
    styleUrl: './left-side-menu.component.css'
})
export class LeftSideMenuComponent implements OnInit {
    encryptedUser: string = '';
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: BCMCLPUser;
    routeName: string;
    curClub: any;
    curTeam: TeamCodes = <TeamCodes>{};
    clpCompanyId: number;
    isAI: boolean = false;
    constructor(public _localService: LocalService, private _route: ActivatedRoute,
        private _globalService: GlobalService,
        private _router: Router,
        private _utilityService: UtilityService,
        private teamOfficeService: TeamOfficeService,
        private _appconfigService: AppConfigService,
        private clubService: CMClubService
    ) {

        this._localService.routeName.subscribe(value => this.routeName = value);
    }

    ngOnInit() {
        this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
        this.encryptedUser = localStorage.getItem("token");
        this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0");
        if (this.encryptedUser) {
            this.authenticateR().then(() => {
                if (this.user) {
                    this.loadData();
                }
                else {
                    //this._router.navigate(['/unauthorized']);
                }
            });
        }
        else {
            //this._router.navigate(['/unauthorized']);
        }
    }

    loadData() {

        this.teamOfficeService.curTeam.subscribe(async (data: any) => {
            if (data != 0) {
                this.curTeam = data;
            }
            else {
                this.curTeam = JSON.parse(localStorage.getItem('curTeam') || '{}');
            }
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToLink(routeName) {
        switch (routeName) {
            case 'cm-dashboard':
                this._router.navigate(['/cm-dashboard']);
                this._localService.setRouteName("cm-dashboard");
                break;
            case 'cm-locations':
                this._router.navigate(['/cm-locations']);
                this._localService.setRouteName("cm-locations");
                break;
            case 'site-fields':
                this._router.navigate(['/site-fields']);
                this._localService.setRouteName("site-fields");
                break;
            case 'cm-general':
                this._router.navigate(['/cm-general']);
                this._localService.setRouteName("cm-general");
                break;
            case 'cm-locations':
                this._router.navigate(['/cm-locations']);
                break;
            case 'cm-employees':
                this._router.navigate(['/cm-employees']);
                break;
            case 'cm-contracts':
                this._router.navigate(['/cm-contracts']);
                break;
            case 'cm-payment':
                this._router.navigate(['/cm-payment']);
                this._localService.setRouteName("cm-payment");
                break;
            case 'cm-contract-mapping':
                this._router.navigate(['/cm-contract-mapping']);
                this._localService.setRouteName("cm-contract-mapping");
                break;
        }
    }
}
