import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserDD } from '../models/generic-response.model';
import { ContactCustomField } from '../models/clpuser.model';
import { delayedRetryHttp } from './shared/delayed-retry';
import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private baseUrl: string;
    private api: string = "api/User";
    private apiAI: string = "api/AIUser";
    baseUrlAI: string;

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
        this.baseUrlAI = _baseUrl + this.apiAI;
    }

    async cLPUserGetListByTeamCode(encryptedUser: string, clpCompanyId: number, teamCode: number): Promise<UserDD[] | void> {
        const http$ = await this.httpClient
            .get<UserDD[]>(`${this.baseUrl}/CLPUser_GetListByTeamCode/${clpCompanyId}/${teamCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "includeNewUsers - ", encryptedUser, "UserService", "getCLPCompany_LoadUsers") });

        return http$;
    }

    async cLPUserGetListBySite(encryptedUser: string, class1Code: number): Promise<UserDD[] | void> {
        const http$ = await this.httpClient
            .get<UserDD[]>(`${this.baseUrl}/CLPUser_GetListBySite/${class1Code}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "class1Code - " + class1Code, encryptedUser, "UserService", "getCLPCompany_LoadUsers") });

        return http$;
    }

    async cLPUserGetListByTeamCodeAI(encryptedUser: string, clpCompanyId: number, teamCode: number): Promise<UserDD[] | void> {
        const http$ = await this.httpClient
            .get<UserDD[]>(`${this.baseUrlAI}/CLPUser_GetListByTeamCode/${clpCompanyId}/${teamCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "includeNewUsers - ", encryptedUser, "UserService", "getCLPCompany_LoadUsers") });

        return http$;
    }

    async cLPUserGetListBySiteAI(encryptedUser: string, class1Code: number): Promise<UserDD[] | void> {
        const http$ = await this.httpClient
            .get<UserDD[]>(`${this.baseUrlAI}/CLPUser_GetListBySite/${class1Code}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "class1Code - " + class1Code, encryptedUser, "UserService", "getCLPCompany_LoadUsers") });

        return http$;
    }

    async contactField_LoadCustomList(encryptedUser: string, clpCompanyId: number): Promise<ContactCustomField[] | void> {
        const http$ = await this.httpClient
            .get<ContactCustomField[]>(`${this.baseUrl}/ContactField_LoadCustomList/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "UserService", "contactField_LoadCustomList") });

        return http$;
    }
}
