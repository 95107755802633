<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Marketing</h3>
    </div>
    <div class="card-section">
        <form [formGroup]="marketingForm">
            <div class="card-panel">
                <div class="flex-form">
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktCustomText1 ? clubSiteConfig?.mktCustomText1 :  "Custom Text 1"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktCustomText1')">PH_CMSiteMktCustomText1</div>
                        <input class="form-control" formControlName="mktCustomText1" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktCustomText2 ? clubSiteConfig?.mktCustomText2 :  "Custom Text 2"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktCustomText2')">PH_CMSiteMktCustomText2</div>
                        <input class="form-control" formControlName="mktCustomText2" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktCustomText3 ? clubSiteConfig?.mktCustomText3 :  "Custom Text 3"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktCustomText3')">PH_CMSiteMktCustomText3</div>
                        <input class="form-control" formControlName="mktCustomText3" maxlength="50" />
                    </div>
                </div>
                <div class="flex-form">
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktCustomText4 ? clubSiteConfig?.mktCustomText4 :  "Custom Text 4"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktCustomText4')">PH_CMSiteMktCustomText4</div>
                        <input class="form-control" formControlName="mktCustomText4" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktCustomText5 ? clubSiteConfig?.mktCustomText5 :  "Custom Text 5"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktCustomText5')">PH_CMSiteMktCustomText5</div>
                        <input class="form-control" formControlName="mktCustomText5" maxlength="50" />
                    </div>
                </div>
                <hr />
                <div class="flex-form">
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktMultitext1 ? clubSiteConfig?.mktMultitext1 :  "Multi Text 1"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktMultiText1')">PH_CMSiteMktMultiText1</div>
                        <textarea class="form-control" formControlName="mktMultiText1"></textarea>
                    </div>
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktMultitext2 ? clubSiteConfig?.mktMultitext2 :  "Multi Text 2"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktMultiText2')">PH_CMSiteMktMultiText2</div>
                        <textarea class="form-control" formControlName="mktMultiText2"></textarea>
                    </div>
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktMultitext3 ? clubSiteConfig?.mktMultitext3 :  "Multi Text 3"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktMultiText3')">PH_CMSiteMktMultiText3</div>
                        <textarea class="form-control" formControlName="mktMultiText3"></textarea>
                    </div>
                </div>
                <div class="flex-form">
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktMultitext4 ? clubSiteConfig?.mktMultitext4 :  "Multi Text 4"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktMultiText4')">PH_CMSiteMktMultiText4</div>
                        <textarea class="form-control" formControlName="mktMultiText4"></textarea>
                    </div>
                    <div class="form-group">
                        <label>
                            {{clubSiteConfig?.mktMultitext5 ? clubSiteConfig?.mktMultitext5 :  "Multi Text 5"}}
                        </label>
                        <div class="clickable m-placeholder" (click)="copyToClip('PH_CMSiteMktMultiText5')">PH_CMSiteMktMultiText5</div>
                        <textarea class="form-control" formControlName="mktMultiText5"></textarea>
                    </div>
                </div>
                <div class="button-group">
                    <div class="form-group">
                        <button class="primary-btn" (click)="mktSaved()">Save<i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
