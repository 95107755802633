import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { LocalService } from '../../services/shared/local.service';

@Component({
  selector: 'app-missing-credentials',
  standalone: true,
  imports: [],
  templateUrl: './missing-credentials.component.html',
  styleUrl: './missing-credentials.component.css'
})
export class MissingCredentialsComponent {
  //private _appConfig: AppConfig;

  /** missing-credentials ctor */
  constructor(private _localService: LocalService, private _route: ActivatedRoute, private _router: Router) {
    if (!isNullOrUndefined(this._router.getCurrentNavigation().extras.state)) {
      
    }
    this._localService.setShowMenu(false)
      //this._localService.isMenu = this._router.getCurrentNavigation().extras.state.isMenu;
    //this._route.paramMap.subscribe(async params => {
    //  if (params.has('isMenu')) {
    //    this._localService.isMenu = Boolean(params.get('isMenu'));
    //  }
    //});
  }
  ngOnInit() {
    //this.redirectToMySO();
  }

  onBackClick() {
    //window.location.replace('https://localhost:44320/');
  }

  private async readConfig() {
    //this._appConfig = UtilityService.AppConfig;
  }

  private async redirectToMySO() {
    await this.readConfig();

    // window.location.href = this._appConfig.portalURL;
  }

}
