export interface SimpleResponse extends GenericResponse {
    messageString: string,
    messageString2: string,
    messageString3: string,
    messageString4: string,
    messageString5: string,
    messageInt: number,
    messageInt2: number,
    messageLong: number,
    messageBool: boolean,
    messageBool2: boolean,
    //contact-module
    list: keyValue[];
    dictionary: any[];
    dt: Date;
}

export interface GenericResponse {
    statusCode: number,
    errorMsg: string
}

export interface keyValue {
    key: number;
    value: string;
    isSelected: boolean;
}

export interface UserDD {
    value: number;
    text: string;
}

export interface IntDropDownItemListResponse extends SimpleResponse {
    intDropDownItemList: IntDropDownItem[];
}

export interface IntDropDownItem {
    id: number;
    text: string;
}

export interface DropDownItem {
    value: string;
    text: string;
    isSelected: boolean;
}
