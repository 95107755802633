<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Contract Mapping</h3>
    </div>
    <div class="card-section">
        <!--<form [formGroup]="siteForm">-->
        <div class="card-panel margin-top20">
            <form [formGroup]="configForm" (ngSubmit)="saveClubMapping()">
                <div class="payment-grid">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Club Field</th>
                                <th>Contact Field</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Driver's License</td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="contactCustomFields"
                                                        [valuePrimitive]="true"
                                                        textField="customValue"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="DLId"
                                                        valueField="customTitle">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Emergency Contact Name</td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="contactCustomFields"
                                                        [valuePrimitive]="true"
                                                        textField="customValue"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="EmergencyNameId"
                                                        valueField="customTitle">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Emergency Contact Phone</td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="contactCustomFields"
                                                        [valuePrimitive]="true"
                                                        textField="customValue"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="EmergencyPhoneId"
                                                        valueField="customTitle">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Date of Birth</td>
                                <td>
                                    <div class="custom-control">
                                        <kendo-combobox class="form-control" [data]="contactCustomFields"
                                                        [valuePrimitive]="true"
                                                        textField="customValue"
                                                        [kendoDropDownFilter]="filterSettings"
                                                        [filterable]="true"
                                                        formControlName="DOBId"
                                                        valueField="customTitle">
                                        </kendo-combobox>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="button-group">
                    <div class="form-group">
                        <button class="primary-btn" type="submit">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

